import client from "./client";

export const getPlanInfo = ({ history, paymentInfo, brand } = {}) =>
    client.get("/v3/bizSubsInfos", { params: { history, paymentInfo, brand } });
export const getPlanTable = () => client.get("/v3/bizSubsInfos/subs-table");
export const changeStatusFromInterruption = () =>
    client.patch("/v3/bizSubsInfos/alert-interruption/hide");
export const changePlan = ({ plan }) => client.patch("/v3/bizSubsInfos/plan", { plan });
export const unsubscribePlan = ({ cancel, reasonForUnSubs, detailReasonForUnSubs }) =>
    client.patch(
        "/v3/bizSubsInfos/unsubscribe",
        {
            reasonForUnSubs,
            detailReasonForUnSubs
        },
        {
            params: { cancel }
        }
    );
export const removeSparePayment = () => client.delete("/v3/bizSubsInfos/spare-payment");
