import { createAction, handleActions } from "redux-actions";
import produce from "immer";

import { createRequestActionTypes } from "lib/createRequestSaga";

export const [GET_MY_PROJECT, GET_MY_PROJECT_SUCCESS, GET_MY_PROJECT_FAILURE] =
    createRequestActionTypes("myProject/GET_MY_PROJECT");
export const [CANCEL_PROJECT, CANCEL_PROJECT_SUCCESS, CANCEL_PROJECT_FAILURE] =
    createRequestActionTypes("myProject/CANCEL_PROJECT");
export const [APPLY_CANCEL_PROJECT, APPLY_CANCEL_PROJECT_SUCCESS, APPLY_CANCEL_PROJECT_FAILURE] =
    createRequestActionTypes("myProject/APPLY_CANCEL_PROJECT");

export const [GET_REVIEW, GET_REVIEW_SUCCESS, GET_REVIEW_FAILURE] =
    createRequestActionTypes("myProject/GET_REVIEW");
export const [SUBMIT_REVIEW, SUBMIT_REVIEW_SUCCESS, SUBMIT_REVIEW_FAILURE] =
    createRequestActionTypes("myProject/SUBMIT_REVIEW");
export const [REMOVE_REVIEW, REMOVE_REVIEW_SUCCESS, REMOVE_REVIEW_FAILURE] =
    createRequestActionTypes("myProject/REMOVE_REVIEW");
export const [SUBMIT_ITEMS, SUBMIT_ITEMS_SUCCESS, SUBMIT_ITEMS_FAILURE] =
    createRequestActionTypes("myProject/SUBMIT_ITEMS");
const RESET_SUBMIT_REVIEW_STATE = "myProject/RESET_SUBMIT_REVIEW_STATE";

export const getMyProject = createAction(GET_MY_PROJECT, options => options);

export const cancelProject = createAction(CANCEL_PROJECT, id => id);
export const applyCancelProject = createAction(APPLY_CANCEL_PROJECT);

export const getReview = createAction(GET_REVIEW, id => id);
export const submitReview = createAction(SUBMIT_REVIEW, ({ id, info }) => ({ id, info }));
export const removeReview = createAction(REMOVE_REVIEW, id => id);
export const submitItems = createAction(SUBMIT_ITEMS, obj => obj);
export const resetSubmitReviewState = createAction(RESET_SUBMIT_REVIEW_STATE);

const initialState = {
    counts: 0,
    list: [],
    review: {},
    isSubmitReviewSuccess: false,
    isSubmitItemsSuccess: false
};

const myProject = handleActions(
    {
        [GET_MY_PROJECT]: state =>
            produce(state, draft => {
                draft.counts = initialState.counts;
                draft.list = initialState.list;
            }),
        [GET_MY_PROJECT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.counts = payload.totalCounts;
                draft.list = payload.requests;
            }),
        [CANCEL_PROJECT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.list = state.list.filter(val => val._id !== payload);
                draft.counts = state.counts - 1;
            }),
        [APPLY_CANCEL_PROJECT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.list = state.list.map(val =>
                    val.campaignRequest === payload.requestId
                        ? { ...val, applyForCancelStatus: "waiting" }
                        : val
                );
            }),
        [GET_REVIEW_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            review: data
        }),
        [GET_REVIEW_FAILURE]: (state, { payload: data }) => ({
            ...state,
            reviewError: data
        }),
        [SUBMIT_REVIEW_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.isSubmitReviewSuccess = true;
                draft.list = state.list.map(item =>
                    item.campaignRequest === payload._id
                        ? { ...item, isSubmitted: true, submittedAt: payload.submittedAt }
                        : item
                );
            }),
        [REMOVE_REVIEW_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.list = state.list.map(val =>
                    val.campaignRequest === payload
                        ? { ...val, isSubmitted: false, isEvaluated: false }
                        : val
                );
            }),
        [SUBMIT_ITEMS_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.isSubmitItemsSuccess = true;
                draft.list = state.list.map(val =>
                    val.campaignRequest === payload ? { ...val, isEvaluated: true } : val
                );
            }),
        [RESET_SUBMIT_REVIEW_STATE]: state =>
            produce(state, draft => {
                draft.review = initialState.review;
                draft.isSubmitReviewSuccess = initialState.isSubmitReviewSuccess;
                draft.isSubmitItemsSuccess = initialState.isSubmitItemsSuccess;
            })
    },
    initialState
);

export default myProject;
