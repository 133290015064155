import {
    SELF_BASIC,
    SELF_STANDARD,
    SELF_PREMIUM,
    ADDITION_RECOMMEND_USE,
    ADDITION_PRIVATE_USE,
    ADDITION_EMERGENCY_USE,
    ADDITION_SECONDARY_USE,
    SUBSCRIPTION_STARTER,
    SUBSCRIPTION_GROWTH,
    SUBSCRIPTION_PROFESSIONAL,
    SUBSCRIPTION_ENTERPRISE
} from "scheme/price";

export const returnSelfPrice = plan => {
    switch (plan) {
        case "standard":
            return SELF_STANDARD;
        case "premium":
            return SELF_PREMIUM;
        default:
            return SELF_BASIC;
    }
};

export const returnProjectServiceFee = recruitmentForm => {
    if (!recruitmentForm || !Object.keys(recruitmentForm).length) {
        return SELF_BASIC;
    }
    if (recruitmentForm.type === "self") {
        return returnSelfPrice(recruitmentForm.plan);
    }
    if (recruitmentForm.type === "subscription") {
        return 0;
    }
    return SELF_BASIC;
};

export const returnSubscriptionPrice = (plan, addVat) => {
    const ratio = addVat ? 1.1 : 1;
    switch (plan) {
        case "starter":
            return Math.floor(SUBSCRIPTION_STARTER * ratio);
        case "growth":
            return Math.floor(SUBSCRIPTION_GROWTH * ratio);
        case "professional":
            return Math.floor(SUBSCRIPTION_PROFESSIONAL * ratio);
        case "enterprise":
            return Math.floor(SUBSCRIPTION_ENTERPRISE * ratio);
        default:
            return 0;
    }
};

export const compareSubscriptionPlan = (a, b) => {
    const planArray = ["starter", "growth", "professional", "enterprise"];
    const levelA = planArray.indexOf(a);
    const levelB = planArray.indexOf(b);

    if (levelA < 0) return -1;
    if (levelB < 0) return -1;

    if (levelA === levelB) return 0;
    if (levelA > levelB) return 1;
    return -1;
};

export const checkSecondaryUseWithLegacy = addition => {
    if (
        addition &&
        addition.secondaryUse &&
        addition.secondaryUse.status &&
        addition.secondaryUse.legacy
    ) {
        return true;
    }
    return false;
};

export const checkSecondaryUseWithoutLegacy = addition => {
    if (
        addition &&
        addition.secondaryUse &&
        addition.secondaryUse.status &&
        !addition.secondaryUse.legacy
    ) {
        return true;
    }
    return false;
};

export const checkAdditionPrice = (addition, recruits) => {
    let price = 0;

    if (addition.recommend && addition.recommend.status) {
        price += ADDITION_RECOMMEND_USE;
    }
    if (addition.private && addition.private.status) {
        price += ADDITION_PRIVATE_USE;
    }
    if (addition.emergency && addition.emergency.status) {
        price += ADDITION_EMERGENCY_USE;
    }
    if (addition.secondaryUse && addition.secondaryUse.status) {
        price += ADDITION_SECONDARY_USE * (recruits || 0);
    }

    return price;
};

export const returnAddFee = ({ value, rate }) => {
    return Math.round(value * (1 + rate / 100));
};

export const returnSubtractFee = ({ value, rate }) => {
    return Math.round(value * (1 - rate / 100));
};

export const returnFormattedPrice = value => {
    if (!value) return 0;
    return Math.round(value / 10) * 10;
};
