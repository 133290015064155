import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory, useParams } from "react-router-dom";
import { showLoading, hideLoading } from "stores/modules/loading";
import {
    getNaverProfile,
    handleLoginModal,
    getUserInfoAsTokenInLocalStorage
} from "stores/modules/auth";
import { getAppleCookieData } from "lib/common";
import { setStorage, removeStorage } from "lib/storage";

const CheckLoginContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const user = useSelector(({ auth }) => auth.userInfo.user);
    const naverProfile = useSelector(({ auth }) => auth.naverProfile);
    const naverLoginError = useSelector(({ auth }) => auth.naverProfileError);
    const isAlreadyUser = useSelector(({ auth }) => auth.isAlreadyUser);

    const { returnTo, return_to, isChangeInfoPage } = queryString.parse(window.location.search);
    const appleLoginData = getAppleCookieData(document.cookie);

    useEffect(() => {
        if (window.location.pathname === "/login" && !return_to && !isChangeInfoPage) {
            history.push("/");
        }
        if (window.location.search.indexOf("code=") >= 0) {
            dispatch(showLoading({ type: "checkLogin" }));
            const naverCode = window.location.search.split("code=")[1].split("&")[0];
            dispatch(
                getNaverProfile({
                    naverCode,
                    category: params.userType,
                    ...(isChangeInfoPage && { isChangeInfoPage: true })
                })
            );
        }

        if (appleLoginData.token) {
            if (params.userType === "Normal") {
                removeStorage("reviewshare-at");
                setStorage("reviewshare-re-at", appleLoginData.token);
            }
            if (params.userType === "Seller") {
                removeStorage("reviewshare-re-at");
                setStorage("reviewshare-at", appleLoginData.token);
            }

            dispatch(getUserInfoAsTokenInLocalStorage({ init: true }));
        }

        if (returnTo) {
            dispatch(handleLoginModal({ status: true }));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user && !returnTo) {
            history.push("/");
        }
        // eslint-disable-next-line
    }, [user, returnTo]);

    useEffect(() => {
        dispatch(hideLoading({ type: "checkLogin" }));
        const { userType } = params;

        if (naverProfile) {
            if (isChangeInfoPage) {
                history.push(userType === "Seller" ? "/biz/myinfo" : "/myinfo");
            } else {
                history.push(userType === "Seller" ? "/signin" : "/register");
            }
        }

        if (isAlreadyUser) {
            history.push("/");
        }

        if (naverLoginError) {
            if (isChangeInfoPage) {
                history.push(userType === "Seller" ? "/biz/myinfo" : "/myinfo");
            }
        }
        // eslint-disable-next-line
    }, [dispatch, naverProfile, naverLoginError, isAlreadyUser]);

    return null;
};

export default CheckLoginContainer;
