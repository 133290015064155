import { takeEvery } from "redux-saga/effects";
import { createRequestSagaBase } from "lib/createRequestSaga";
import * as bannerAPI from "lib/api/banner";

import { GET_BANNER_LIST } from "stores/modules/banner/action";

const getBannerListSaga = createRequestSagaBase({
    type: GET_BANNER_LIST,
    api: bannerAPI.getBannerList
});

export function* bannerSaga() {
    yield takeEvery(GET_BANNER_LIST, getBannerListSaga);
}
