import { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { setCookie } from "lib/cookie";

const useTrackPage = () => {
    const location = useLocation();

    useEffect(() => {
        window.gtag("config", "UA-134266866-1", {
            page_location: location.pathname,
            page_path: location.pathname
        });

        window.gtag("config", "AW-854806619", {
            page_location: location.pathname
        });
    }, [location]);
};

const ScrollEvent = ({ history, location }) => {
    const trackPage = useTrackPage;

    if (process.env.REACT_APP_ENV_NAME === "production") {
        trackPage();
    }

    useEffect(() => {
        const listen = history.listen(info => {
            const hasChildPath = ["mypage"];
            const current = {
                path: location.pathname,
                depth: location.pathname.split("/")
            };
            const change = {
                path: info.pathname,
                depth: info.pathname.split("/")
            };
            setCookie({ name: "reviewshare_prev_url", value: current.path, exp: 1 });
            setCookie({ name: "reviewshare_current_url", value: change.path, exp: 1 });

            if (current.path === change.path) return;
            if (current.depth[2] && change.depth[2] && hasChildPath.indexOf(current.depth[1]) > -1)
                return;

            if (
                (current.path === "/portfolio" && change.path.indexOf("/portfolio/") > -1) ||
                (current.path === "/talent" && change.path.indexOf("/talent/") > -1)
            ) {
                //포폴 리스트에서 상세로 이동할 때
                sessionStorage.setItem(
                    `scrollHeight-${current.path.split("/")[1]}`,
                    window.scrollY
                );
            }

            if (current.depth[1] === change.path.split("/")[1]) {
                if (current.path === "/project" && change.path.indexOf("/project/") > -1) {
                    //프로젝트 리스트에서 상세로 이동할 때
                    sessionStorage.setItem("scrollHeight", window.scrollY);
                    return window.scrollTo(0, 0);
                } else if (
                    current.path === "/mytalent/order" &&
                    change.path.indexOf("/mytalent/order/") > -1
                ) {
                    //리뷰어 거래 리스트에서 상세로 이동할 때
                    sessionStorage.setItem("scrollHeight-order", window.scrollY);
                }
            }

            window.scrollTo(0, 0);
        });

        return () => {
            listen();
        };

        // eslint-disable-next-line
    }, [location.pathname]);

    return null;
};

export default withRouter(ScrollEvent);
