import { useIntl } from "react-intl";
import capitalize from "lodash/capitalize";

// msg { id: "action.search.target" },
// values { name: translate({ id: "item.brand.name" }) }

const useTranslate = () => {
    const { formatMessage } = useIntl();

    const returnValue = (id, values) => {
        if (!id) return "";
        const text = formatMessage({ id }, values);
        return values ? capitalize(text) : text;
    };

    return returnValue;
};

export default useTranslate;
