import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { createRequestSagaBase } from "lib/createRequestSaga";
import * as suggestAPI from "lib/api/suggest";
import { uploadResult as uploadResultAPI } from "lib/api/common";

import {
    GET_RECENT_SUGGESTION,
    GET_RECENT_SUGGESTION_SUCCESS,
    GET_RECENT_SUGGESTION_FAILURE,
    GET_SUGGESTION,
    GET_SUGGESTION_SUCCESS,
    GET_SUGGESTION_FAILURE,
    GET_REVIEWER_INFO,
    GET_REVIEWER_INFO_SUCCESS,
    GET_REVIEWER_INFO_FAILURE,
    GET_SUGGESTION_LIST_OF_BUSINESS,
    GET_SUGGESTION_LIST_OF_BUSINESS_SUCCESS,
    GET_SUGGESTION_LIST_OF_BUSINESS_FAILURE,
    GET_SUGGESTION_LIST_OF_REVIEWER,
    GET_SUGGESTION_LIST_OF_REVIEWER_SUCCESS,
    GET_SUGGESTION_LIST_OF_REVIEWER_FAILURE,
    CANCEL_SUGGESTION,
    CANCEL_SUGGESTION_SUCCESS,
    CANCEL_SUGGESTION_FAILURE,
    ACCEPT_SUGGESTION,
    ACCEPT_SUGGESTION_SUCCESS,
    ACCEPT_SUGGESTION_FAILURE,
    REJECT_SUGGESTION,
    REJECT_SUGGESTION_SUCCESS,
    REJECT_SUGGESTION_FAILURE,
    SUBMIT_RESULT,
    SUBMIT_RESULT_SUCCESS,
    SUBMIT_RESULT_FAILURE,
    CHANGE_SUBMIT_END_DAY
} from "stores/modules/suggest/action";

import { OPEN_ALERT } from "stores/modules/alert";
import { SHOW_LOADING, HIDE_LOADING } from "stores/modules/loading";

const getRecentSuggestionSaga = function*(action) {
    try {
        const { data } = yield call(suggestAPI.getRecentSuggestion, action.payload);
        yield put({
            type: GET_RECENT_SUGGESTION_SUCCESS,
            payload: data
        });
    } catch (e) {
        yield put({
            type: GET_RECENT_SUGGESTION_FAILURE,
            payload: { error: e.response }
        });
    }
};

const getSuggestionSaga = function*(action) {
    try {
        const { data } = yield call(suggestAPI.getSuggestion, action.payload);
        yield put({
            type: GET_SUGGESTION_SUCCESS,
            payload: data
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.suggestion.get.detail.fail" }
        });
        yield put({
            type: GET_SUGGESTION_FAILURE,
            payload: { error: e.response }
        });
    }
};

const getReviewerInfoSaga = function*(action) {
    try {
        const { data } = yield call(suggestAPI.getReviewerInfo, action.payload);
        yield put({
            type: GET_REVIEWER_INFO_SUCCESS,
            payload: data
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "리뷰어 정보를 불러오지 못했습니다." }
        });
        yield put({
            type: GET_REVIEWER_INFO_FAILURE,
            payload: { error: e.response }
        });
    }
};

const getSuggestionListOfBusinessSaga = function*(action) {
    try {
        const { data } = yield call(suggestAPI.getSuggestionListOfBusiness, action.payload);
        yield put({
            type: GET_SUGGESTION_LIST_OF_BUSINESS_SUCCESS,
            payload: data
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.suggestion.get.list.fail" }
        });
        yield put({
            type: GET_SUGGESTION_LIST_OF_BUSINESS_FAILURE,
            payload: { error: e.response }
        });
    }
};

const getSuggestionListOfReviewerSaga = function*(action) {
    try {
        const { data } = yield call(suggestAPI.getSuggestionListOfReviewer, action.payload);
        yield put({
            type: GET_SUGGESTION_LIST_OF_REVIEWER_SUCCESS,
            payload: data
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.suggestion.get.list.fail" }
        });
        yield put({
            type: GET_SUGGESTION_LIST_OF_REVIEWER_FAILURE,
            payload: { error: e.response }
        });
    }
};

const cancelSuggestionSaga = function*(action) {
    try {
        const { data } = yield call(suggestAPI.cancelSuggestion, action.payload);
        yield put({
            type: CANCEL_SUGGESTION_SUCCESS,
            payload: data
        });
        if (data.isRefunded) {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "message.points.refund.success" }
            });
        }
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.suggestion.cancel.fail" }
        });
        yield put({
            type: CANCEL_SUGGESTION_FAILURE,
            payload: { error: e.response }
        });
    }
};

const acceptSuggestionSaga = function*(action) {
    try {
        const { data } = yield call(suggestAPI.acceptSuggestion, action.payload);
        yield put({ type: ACCEPT_SUGGESTION_SUCCESS, payload: data });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.suggestion.accept.fail" }
        });
        yield put({
            type: ACCEPT_SUGGESTION_FAILURE,
            payload: { error: e.response }
        });
    }
};

const rejectSuggestionSaga = function*(action) {
    try {
        const { data } = yield call(suggestAPI.rejectSuggestion, action.payload);
        yield put({ type: REJECT_SUGGESTION_SUCCESS, payload: data });
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.suggestion.reject.success" }
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.suggestion.reject.fail" }
        });
        yield put({
            type: REJECT_SUGGESTION_FAILURE,
            payload: { error: e.response }
        });
    }
};

const submitResultSaga = function*(action) {
    const { fileList, isSubmitted } = action.payload;
    try {
        yield put({ type: SHOW_LOADING, payload: { type: action.type } });
        const { data } = yield call(suggestAPI.submitResult, {
            ...action.payload,
            fileList: fileList.map(item => ({
                name: `${item.name}.${item.mimeType}`,
                mimeType: item.mimeType
            }))
        });
        if (fileList.length > 0) {
            for (let index = 0; index < fileList.length; index++) {
                const item = data?.uploadUrlList?.[index];
                const { file } = fileList[index];

                yield call(uploadResultAPI, {
                    url: item?.url,
                    file,
                    type: file.type
                });
            }
        }
        yield put({ type: SUBMIT_RESULT_SUCCESS, prev: action.payload });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: `${isSubmitted ? "수정" : "제출"}에 실패하였습니다.` }
        });
        yield put({
            type: SUBMIT_RESULT_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put({ type: HIDE_LOADING, payload: { type: action.type } });
    }
};

const changeSubmitEndDaySaga = createRequestSagaBase({
    type: CHANGE_SUBMIT_END_DAY,
    api: suggestAPI.changeSubmitEndDay,
    alertSuccessMsg: "마감일이 연장됐어요.",
    alertErrorMsg: "제출 마감일 변경에 실패하였습니다."
});

export function* suggestSaga() {
    yield takeLatest(GET_RECENT_SUGGESTION, getRecentSuggestionSaga);
    yield takeLatest(GET_SUGGESTION, getSuggestionSaga);
    yield takeLatest(GET_REVIEWER_INFO, getReviewerInfoSaga);
    yield takeLatest(GET_SUGGESTION_LIST_OF_BUSINESS, getSuggestionListOfBusinessSaga);
    yield takeLatest(GET_SUGGESTION_LIST_OF_REVIEWER, getSuggestionListOfReviewerSaga);
    yield takeLeading(CANCEL_SUGGESTION, cancelSuggestionSaga);
    yield takeLeading(ACCEPT_SUGGESTION, acceptSuggestionSaga);
    yield takeLeading(REJECT_SUGGESTION, rejectSuggestionSaga);
    yield takeLeading(SUBMIT_RESULT, submitResultSaga);
    yield takeLeading(CHANGE_SUBMIT_END_DAY, changeSubmitEndDaySaga);
}
