import { call, put, takeLatest, takeLeading, takeEvery } from "redux-saga/effects";
import { createRequestSagaBase } from "lib/createRequestSaga";
import * as relationAPI from "lib/api/relation";
import { getRelationTagList as getRelationTagListAPI } from "lib/api/tag";

import { openAlert } from "stores/modules/alert";
import {
    GET_RELATION_LIST,
    GET_RELATION_DETAIL,
    GET_RELATION_TAG_LIST,
    CHANGE_RELATION_STATUS,
    CHANGE_RELATION_STATUS_SUCCESS,
    CHANGE_RELATION_STATUS_FAILURE,
    CHANGE_RELATION_STATUS_IN_LIST,
    CHANGE_RELATION_STATUS_IN_LIST_SUCCESS,
    CHANGE_RELATION_STATUS_IN_LIST_FAILURE,
    changeRelationStatus,
    setChangeCondition
} from "stores/modules/relation/action";
import { changeRelationStatusInAllList } from "stores/modules/portfolio/action";

const getRelationListSaga = createRequestSagaBase({
    type: GET_RELATION_LIST,
    api: relationAPI.getRelationList,
    progress: true
});

const getRelationDetailSaga = createRequestSagaBase({
    type: GET_RELATION_DETAIL,
    api: relationAPI.getRelationDetail,
    progress: true
});

const getRelationTagListSaga = createRequestSagaBase({
    type: GET_RELATION_TAG_LIST,
    api: getRelationTagListAPI
});

function* changeRelationStatusSaga(action) {
    const { status, userList, showCompleteStatus } = action.payload;

    try {
        const userIdList = userList.map(item => item._id);
        let res = {};

        if (["follow", "cancelFollow"].indexOf(status) > -1) {
            res = yield call(relationAPI.changeFollowStatus, {
                follow: status === "follow",
                userIdList
            });
        } else if (["block", "cancelBlock"].indexOf(status) > -1) {
            res = yield call(relationAPI.changeBlockStatus, {
                block: status === "block",
                userIdList
            });
        } else {
            return;
        }

        if (showCompleteStatus) {
            const newUserList = [...userList];
            // res.data.userList: 첫번째 유저만 있음
            newUserList[0] = [...res.data.userList][0];

            yield put(
                setChangeCondition({
                    type: status,
                    status: "complete",
                    totalCounts: res.data.totalCounts,
                    userList: newUserList,
                    showCompleteStatus
                })
            );
        } else {
            yield put(
                setChangeCondition({
                    type: status,
                    status: "complete",
                    userList,
                    showCompleteStatus
                })
            );
        }

        yield put(
            changeRelationStatusInAllList({
                userIdList: userList.map(item => item._id),
                status: ["follow", "block"].indexOf(status) > -1 ? status : "none"
            })
        );
        yield put({
            type: CHANGE_RELATION_STATUS_SUCCESS,
            prev: action.payload
        });
    } catch (error) {
        yield put(
            setChangeCondition({
                type: status,
                status: "fail",
                userList
            })
        );
        yield put(
            openAlert({
                text: "상태 변경에 실패했습니다."
            })
        );
        yield put({
            type: CHANGE_RELATION_STATUS_FAILURE,
            payload: { error: error.response }
        });
    }
}

function* changeRelationStatusInListSaga(action) {
    try {
        const { status } = action.payload;
        if (["follow", "cancelFollow", "cancelBlock"].indexOf(status) > -1) {
            yield put(changeRelationStatus(action.payload));
        } else if (status !== "block") {
            // status가 block인 경우 따로 체크 과정 거치고 changeRelationStatus 실행함, state만 변경하는 용도
            return;
        }
        yield put({
            type: CHANGE_RELATION_STATUS_IN_LIST_SUCCESS,
            prev: action.payload
        });
    } catch (error) {
        yield put(
            openAlert({
                text: "상태 변경에 실패했습니다."
            })
        );
        yield put({
            type: CHANGE_RELATION_STATUS_IN_LIST_FAILURE,
            payload: { error: error.response }
        });
    }
}

export function* relationSaga() {
    yield takeLatest(GET_RELATION_LIST, getRelationListSaga);
    yield takeLatest(GET_RELATION_DETAIL, getRelationDetailSaga);
    yield takeLatest(GET_RELATION_TAG_LIST, getRelationTagListSaga);
    yield takeEvery(CHANGE_RELATION_STATUS, changeRelationStatusSaga);
    yield takeLeading(CHANGE_RELATION_STATUS_IN_LIST, changeRelationStatusInListSaga);
}
