import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { StandardModal, Profile, IconFont, Button, AlertModal } from "reviewshare-common-component";
import { numberWithCommas } from "lib/common";
import { setChangeCondition } from "stores/modules/relation/action";
import FormattedMessage from "components/common/FormattedMessage";
import RelationStatusTag from "components/relation/common/RelationStatusTag";

import style from "./AfterChangeRelation.module.scss";

const AfterChangeRelation = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const type = useSelector(({ relation }) => relation.changeCondition.type);
    const status = useSelector(({ relation }) => relation.changeCondition.status);
    const totalCounts = useSelector(({ relation }) => relation.changeCondition.totalCounts);
    const userList = useSelector(({ relation }) => relation.changeCondition.userList);
    const showCompleteStatus = useSelector(
        ({ relation }) => relation.changeCondition.showCompleteStatus
    );

    const onCloseModal = () => {
        dispatch(setChangeCondition({ status: null }));
    };

    const onMoveRelationList = () => {
        history.push(`/biz/relation/list?status=${type}`);
        onCloseModal();
    };

    if (["follow", "block", "cancelBlock"].indexOf(type) === -1) return null;
    if (status !== "complete") return null;
    if (!showCompleteStatus) return null;

    if (type === "cancelBlock") {
        return <AlertModal handleModal={onCloseModal}>차단 해제가 완료됐어요.</AlertModal>;
    }

    return (
        <StandardModal
            className={style.completeModal}
            closeModal={onCloseModal}
            modalFooter={
                <Button className="theme1 size-l m-size-l" onClick={onCloseModal}>
                    <FormattedMessage id="action.ok" />
                </Button>
            }
        >
            <div className={style.modalInner}>
                <Profile img={userList?.[0]?.profile?.secure_url} className="size-m m-size-m" />
                <RelationStatusTag status={type} className={style.tag} />
                <p className={style.completeMsg}>
                    {userList?.[0].nickname}님
                    {totalCounts > 1 && ` 외 ${numberWithCommas(totalCounts - 1)}명`}을{" "}
                    {type === "follow" && "팔로우"}
                    {type === "block" && "차단"}했어요.
                </p>
                <button type="button" onClick={onMoveRelationList} className={style.link}>
                    {type === "follow" && "팔로우"}
                    {type === "block" && "차단"} 목록 보러가기 <IconFont icon="icon_arrow_right" />
                </button>
            </div>
        </StandardModal>
    );
};

export default AfterChangeRelation;
