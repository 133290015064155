import { useState, useEffect } from "react";
import throttle from "lodash/throttle";

const useScrollEnd = ({ selector, reverse, ref } = {}) => {
    const [isEndOfPage, setState] = useState(false);

    const onScroll = () => {
        const selectedElement = selector
            ? document.querySelector(selector)
            : ref
            ? ref.current
            : null;
        const element = selectedElement || document.documentElement;

        let endOfPage = false;
        if (reverse) {
            endOfPage = element.scrollTop;
        } else {
            endOfPage = element.scrollHeight - (element.scrollTop + element.clientHeight);
        }

        if (!isEndOfPage && endOfPage <= 500) {
            setState(true);
        } else {
            setState(false);
        }
    };

    useEffect(() => {
        const selectedElement = selector
            ? document.querySelector(selector)
            : ref
            ? ref.current
            : null;
        const element = selectedElement || window;

        element.addEventListener("scroll", throttle(onScroll, 150));
        return () => element.removeEventListener("scroll", onScroll);
        // eslint-disable-next-line
    }, []);

    return isEndOfPage;
};

export default useScrollEnd;
