import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { chatSocket } from "lib/api/socket";
import { addMessageItem, setWarningMessageBox } from "stores/modules/messenger/action";
import { handleBrandUnreadStatus } from "stores/modules/brand/action";
import WarningBox from "components/messenger/main/WarningBox";
import MessageBoxInfo from "components/messenger/main/MessageBoxInfo";
import MessageList from "components/messenger/main/MessageList";
import WriteMessage from "components/messenger/main/WriteMessage";

const MessageBoxDetailContainer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        chatSocket.emit("position_update", "inMessageBox");
        chatSocket.on("new_msg_in_message_box", item => {
            dispatch(addMessageItem(item));
        });
        chatSocket.on("update_brand_list", item => {
            dispatch(handleBrandUnreadStatus(item));
        });
        chatSocket.on("is_need_warning_for_direct_trade", data => {
            dispatch(setWarningMessageBox(data.messageBoxId));
        });

        return () => {
            chatSocket.emit("position_update", "messageModalOn");
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <MessageBoxInfo />
            <WarningBox position="list" />
            <MessageList />
            <WriteMessage />
        </>
    );
};

export default MessageBoxDetailContainer;
