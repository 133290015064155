import React from "react";
import { REWARD_SECONDARY_USE } from "scheme/price";
import { numberWithCommas } from "lib/common";
import FormattedMessage from "components/common/FormattedMessage";

export const USER_LINK_SCHEME = [
    { label: "menu.re.info", link: "/myinfo", icon: "icon_memo", className: "info" },
    { label: "menu.re.points.total", link: "/mypoint", className: "point" }
];

export const MY_TALENT_QUICK_MENU_LIST = [
    { label: "item.talent", link: "/mytalent/talent", value: "service" },
    {
        label: "status.order.re.request",
        link: "/mytalent/order?status=request",
        value: "request"
    },
    {
        label: "status.order.re.running",
        link: "/mytalent/order?status=running",
        value: "running"
    },
    {
        label: "status.order.re.complete",
        link: "/mytalent/order?status=complete",
        value: "complete"
    }
];

export const MY_ACTIVITY_QUICK_MENU_LIST = [
    { label: "menu.re.project.select", link: "/mypage/chosen", value: "chosenProject" },
    { label: "menu.re.suggestion.list", link: "/mypage/suggestion/waiting", value: "suggestion" },
    {
        label: "menu.re.content.list",
        link: "/mypage/content",
        value: "content"
    }
];

export const MYPAGE_TAB_LIST_SCHEME = [
    {
        label: "menu.re.project.list",
        path: [
            "/mypage/request",
            "/mypage/chosen",
            "/mypage/unsubmit",
            "/mypage/complete",
            "/mypage/cancel"
        ],
        sub: [
            {
                label: "status.project.re.request",
                path: ["/mypage/request"]
            },
            {
                label: "status.project.re.chosen",
                path: ["/mypage/chosen"]
            },
            {
                label: "status.project.re.unsubmit",
                path: ["/mypage/unsubmit"]
            },
            {
                label: "status.project.re.complete",
                path: ["/mypage/complete"]
            },
            {
                label: "status.project.re.cancel",
                path: ["/mypage/cancel"]
            }
        ]
    },
    {
        label: "menu.re.suggestion.list",
        path: ["/mypage/suggestion/waiting", "/mypage/suggestion/running"],
        sub: [
            { label: "status.suggestion.waiting", path: ["/mypage/suggestion/waiting"] },
            {
                label: "status.suggestion.running",
                path: ["/mypage/suggestion/running"]
            }
        ]
    },
    {
        label: "menu.re.content.list",
        path: ["/mypage/content"]
    }
];

export const MYPROJECT_SORT_SCHEME = {
    request: [
        { label: "sort.project.request.recent", value: "default" },
        { label: "sort.project.request.endAt.order", value: "dDay" }
    ],
    chosen: [
        { label: "sort.project.chosen.recent", value: "default" },
        { label: "sort.project.submit.endAt.order", value: "dDay" }
    ]
    // unsubmit: [
    //     { label: "제출 지연일 낮은순", value: 1 },
    //     { label: "제출 지연일 높은순", value: 2 }
    // ]
};

export const SUBMISSION_FILE_BOX_CAUTION_TEXT = {
    review: [],
    content: ["사진은 10MB씩 최대 20장, 영상은 2GB씩 최대 2개까지 첨부 가능해요."],
    shortform: [],
    event: ["이벤트 참여 내역 또는 참여 결과를 캡처한 사진을 제출해 주세요."],
    survey: [
        "사진은 10MB씩 최대 20장까지 첨부 가능해요.",
        "설문 참여 내역 또는 참여 결과를 캡쳐한 사진을 제출해 주세요."
    ],
    interview: [
        "사진은 10MB씩 최대 20장까지 첨부 가능해요.",
        "인터뷰 참여 내역 또는 인터뷰 화면을 캡처한 사진을 제출해 주세요."
    ],
    originFile: [
        `원본 파일 제출 프로젝트로 ${numberWithCommas(REWARD_SECONDARY_USE)}P가 추가로 지급됩니다.`
    ]
};

export const CREATE_FEED_CAUTION_BY_TYPE = {
    common: [
        "리뷰쉐어에서 진행하지 않은 다양한 콘텐츠를 올려주세요.",
        "제출되지 않을 경우, 잠시 후(약 5분 뒤)에 재시도해 주세요."
    ],
    url: ["네이버블로그, 인스타그램(릴스), 유튜브(쇼츠), 틱톡 콘텐츠를 등록할 수 있어요."],
    file: ["사진은 10MB씩 최대 20장까지 첨부 가능해요.", "영상은 2GB씩 최대 2개까지 첨부 가능해요."]
};

export const SUBMIT_SUCCESS_CAUTION_TEXT = {
    project: [
        "결과물은 리워드 포인트 지급 전까지 수정 및 삭제가 가능합니다.",
        "리워드 포인트 지급 일정 등 관련 내용은 프로젝트 담당자님에게 문의해 주세요."
    ],
    suggestion: ["리워드 포인트가 있는 경우, 제출 마감일로부터 15일 이내에 지급됩니다."]
};

export const MYPAGE_ACTIVITY_COUNT = [
    {
        label: "item.score",
        value: "totalScore",
        unit: "unit.score",
        path: "/score",
        message: (
            <p>
                <FormattedMessage id="message.score.criterion" />
            </p>
        )
    },
    {
        label: "item.portfolio.activity.project.chosen.counts",
        value: "totalProcessedProjectCounts",
        unit: "unit.time",
        modal: true
    },
    {
        label: "item.portfolio.opinion.receive",
        value: "opinions",
        unit: "unit.percent",
        message: (
            <p>
                <FormattedMessage
                    id="message.opinion.criterion"
                    values={{
                        emphasis: (
                            <span className="blue-font">
                                <FormattedMessage id="message.opinion.criterion.emphasis" />
                            </span>
                        )
                    }}
                />
            </p>
        ),
        modal: true
    },
    {
        label: "item.portfolio.activity.project.submit.rate",
        value: "submissionRate",
        unit: "unit.percent"
    },
    {
        label: "item.portfolio.activity.project.cancel.counts",
        value: "projectCancelCounts",
        unit: "unit.time",
        path: "/mypage/cancel",
        message: (
            <p>
                <FormattedMessage
                    id="message.project.re.cancel.count.criterion"
                    values={{
                        emphasis: (
                            <span className="blue-font">
                                <FormattedMessage id="message.project.re.cancel.count.criterion.emphasis" />
                            </span>
                        )
                    }}
                />
            </p>
        )
    },
    {
        label: "item.portfolio.activity.project.unsubmit.counts",
        value: "unsubmitCounts",
        unit: "unit.count",
        path: "/mypage/unsubmit",
        message: (
            <p>
                <FormattedMessage
                    id="message.project.re.unsubmit.count.criterion"
                    values={{
                        emphasis: (
                            <span className="blue-font">
                                <FormattedMessage id="message.project.re.unsubmit.count.criterion.emphasis" />
                            </span>
                        )
                    }}
                />
            </p>
        )
    }
];

export const REVIEWER_CANCEL_REQUEST_REASONS = [
    {
        label: "프로젝트 진행의사 없어짐",
        value: "noIntention"
    },
    { label: "프로젝트 정보 잘못 봄", value: "lookWrong" },
    { label: "개인 스케줄로 프로젝트 진행 불가", value: "personalSchedule" },
    { label: "특수사항(상해,사고 등)", value: "accident" },
    {
        label: "브랜드측 귀책(매장 폐업, 배송 일정 지연, 제품 불량, 예약불가, 연락두절 등)",
        value: "bizFault"
    },
    { label: "기타(리뷰어 개인사정)", value: "etcReviewer" },
    { label: "기타(브랜드 개인사정)", value: "etcBiz" }
];

export const REVIEWER_LEAVE_REASON_LIST = [
    { label: "item.leave.type.badService", value: "badService" },
    { label: "item.leave.type.userInfoWrong", value: "userInfoWrong" },
    {
        label: "item.leave.type.changeBiz",
        value: "changeBiz"
    },
    { label: "item.leave.type.badProject", value: "badProject" },
    { label: "item.leave.type.notChosen", value: "notChosen" },
    { label: "item.leave.type.notUse", value: "notUse" },
    { label: "item.leave.type.etc", value: "etc" }
];

export const BUSINESS_LEAVE_REASON_LIST = [
    { label: "item.leave.type.badService", value: "badService" },
    { label: "item.leave.type.userInfoWrong", value: "userInfoWrong" },
    {
        label: "item.leave.type.changeReviewer",
        value: "changeReviewer"
    },
    { label: "item.leave.type.badReviewerForProject", value: "badReviewerForProject" },
    { label: "item.leave.type.badReviewerForSuggestion", value: "badReviewerForSuggestion" },
    { label: "item.leave.type.badReviewer", value: "badReviewer" },
    { label: "item.leave.type.badPrice", value: "badPrice" },
    { label: "item.leave.type.etc", value: "etc" }
];
