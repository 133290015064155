import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { createRequestActionTypes } from "lib/createRequestSaga";

export const RESET_INICIS_DATA = "payment/RESET_INICIS_DATA";
export const RESET_READY_FOR_CHARGE = "payment/RESET_READY_FOR_CHARGE";

export const GET_BILL = "payment/GET_BILL";
export const GET_BILL_SUCCESS = "payment/GET_BILL_SUCCESS";
export const GET_BILL_FAILURE = "payment/GET_BILL_FAILURE";

export const GET_PAYMENT_HISTORY = "payment/GET_PAYMENT_HISTORY";
export const GET_PAYMENT_HISTORY_SUCCESS = "payment/GET_PAYMENT_HISTORY_SUCCESS";
export const GET_PAYMENT_HISTORY_FAILURE = "payment/GET_PAYMENT_HISTORY_FAILURE";

export const [
    REQUEST_SUBSCRIPTION,
    REQUEST_SUBSCRIPTION_SUCCESS,
    REQUEST_SUBSCRIPTION_FAILURE
] = createRequestActionTypes("payment/REQUEST_SUBSCRIPTION");
export const [
    REQUEST_SUBSCRIPTION_PAYMENT,
    REQUEST_SUBSCRIPTION_PAYMENT_SUCCESS,
    REQUEST_SUBSCRIPTION_PAYMENT_FAILURE
] = createRequestActionTypes("payment/REQUEST_SUBSCRIPTION_PAYMENT");

export const resetInicisData = createAction(RESET_INICIS_DATA);
export const resetReadyForCharge = createAction(RESET_READY_FOR_CHARGE);
export const getBill = createAction(GET_BILL, obj => obj || {});
export const getPaymentHistory = createAction(GET_PAYMENT_HISTORY, obj => obj || {});

export const requestSubscription = createAction(REQUEST_SUBSCRIPTION, options => options);
export const requestSubscriptionPayment = createAction(
    REQUEST_SUBSCRIPTION_PAYMENT,
    options => options
);

const initialState = {
    bill: {},
    history: {},
    readyForCharge: false,
    readyForSubscribe: false,
    readyForSubscribePayment: false,
    inicisData: {}
};

const payment = handleActions(
    {
        [RESET_INICIS_DATA]: state =>
            produce(state, draft => {
                draft.readyForCharge = false;
                draft.readyForSubscribe = false;
                draft.readyForSubscribePayment = false;
                draft.inicisData = {};
            }),
        [RESET_READY_FOR_CHARGE]: state =>
            produce(state, draft => {
                draft.readyForCharge = false;
            }),
        [GET_BILL_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.bill = payload;
            }),
        [GET_PAYMENT_HISTORY_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.history = payload;
            }),
        [REQUEST_SUBSCRIPTION_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.inicisData = payload.data;
                draft.readyForSubscribe = true;
            }),
        [REQUEST_SUBSCRIPTION_PAYMENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.inicisData = payload.data;
                draft.readyForSubscribePayment = true;
            })
    },
    initialState
);

export default payment;
