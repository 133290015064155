import { useState, useCallback } from "react";
import { callDirectApi } from "lib/common";
import { showLoading, hideLoading } from "stores/modules/loading";
import { useDispatch } from "react-redux";

const useDirectApi = ({ api, loading }) => {
    const dipatch = useDispatch();
    const [receivedData, setData] = useState(null);
    const [receivedError, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const fetchData = useCallback(
        async apiParams => {
            setIsFetching(true);
            if (loading) {
                dipatch(showLoading({ type: `${api.name}` }));
            }
            try {
                const res = await callDirectApi(api, apiParams);
                setData(res.data);
            } catch (error) {
                setError(error.message);
            }
            if (loading) {
                dipatch(hideLoading({ type: `${api.name}` }));
            }
            setIsFetching(false);
            // console.log("res.data:", res.data);
        },
        // eslint-disable-next-line
        [api]
    );

    return { data: receivedData, fetch: fetchData, error: receivedError, isFetching };
};

export default useDirectApi;
