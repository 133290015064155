import axios from "axios";
import moment from "moment";
import { store } from "Root";
import { setServerVersion, setToday } from "stores/modules/common/action";

const client = axios.create();

const TIME_OUT_VALUE = 1000 * 60 * 10;

client.defaults.baseURL = process.env.REACT_APP_API_URL;
client.defaults.timeout = TIME_OUT_VALUE;

if (process.env.REACT_APP_ENV_NAME === "development") {
    client.defaults.withCredentials = true;
}

client.interceptors.request.use(config => {
    // Authorization token
    if (localStorage["reviewshare-at"]) {
        config.headers.Authorization = `Bearer ${localStorage["reviewshare-at"]}`;
    } else if (localStorage["reviewshare-re-at"]) {
        config.headers.Authorization = `Bearer ${localStorage["reviewshare-re-at"]}`;
    }
    // config.withCredentials = true;
    return config;
});

client.interceptors.response.use(
    config => {
        const headerToday = moment(config.headers["date"]).format("YYYY-MM-DD");
        const { serverVersion, today } = store.getState().common;
        if (
            serverVersion !== config.headers["x-client-version"] &&
            config.config.url.indexOf(process.env.REACT_APP_SERVERLESS_URL) === -1
        ) {
            store.dispatch(setServerVersion(config.headers["x-client-version"]));
        }
        if (today !== headerToday) {
            store.dispatch(setToday(headerToday));
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default client;
