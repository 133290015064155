import client from "./client";

export const choiseUser = selectedReviewerList =>
    client.put(`/campaignRequest/choiseUser`, selectedReviewerList);
export const changeToReadStatus = ({ campaignRequestId, category }) =>
    client.put(`/campaignRequest/readstatus`, { id: campaignRequestId, category });
export const bindExcelDownloadLink = projectId =>
    client.post(`/campaignRequest/downloadExcel`, { type: "Reviewer", id: projectId });

// export const recommandCampaignRequest = ({ campaignRequestId, recommand }) =>
//     client.put(`/campaignRequest/recommand`, { id: campaignRequestId, recommand });
// export const recommendRequest = ({ requestId, recommend, notRecommend }) =>
//     client.put(`/campaignRequest/recommend`, { requestId, recommend, notRecommend });

//report
export const getOpinions = ({ userId, recommendCount = undefined }) =>
    client.get(`/campaignRequests/opinions`, { params: { userId, recommendCount } });
export const setOpinions = ({ requestId, category, message }) =>
    client.patch(`/campaignRequests/${requestId}/opinions`, { category, message });
export const updateDeliveryInfo = ({ requestId, deliveryCompany, invoiceNumber }) =>
    client.put(`/campaignRequest/${requestId}/delivery-info`, { deliveryCompany, invoiceNumber });
export const changeSubmitEndDay = ({ requestId, newSubmitEndDay }) =>
    client.patch(`/campaignRequest/submitEndDay/${requestId}`, { newSubmitEndDay });
export const submitEvaluateItems = obj => client.post("/v2/requests/submitEvaluateItems", obj);
export const getContentFileList = requestId =>
    client.get(`/v3/campaignRequests/${requestId}/content-file-list`);
export const downloadContentFileList = ({ requestId, fileIdList }) =>
    client.get(`/v3/campaignRequests/${requestId}/content-file-zip-url`, {
        params: {
            "select-ids": fileIdList ? fileIdList.join(",") : undefined
        }
    });
export const getCancelHistory = reviewerId =>
    client.get(`/v3/campaignRequests/${reviewerId}/cancel-history`);

export const applyCancelRequestByBiz = obj =>
    client.patch("/v3/campaignRequests/cancel-apply-biz", obj);
export const getApplyCancelRequestByBusiness = requestId =>
    client.get(`campaignRequest/${requestId}/apply-for-cancel`);
export const confirmApplyCancelRequest = ({ requestId, action }) =>
    client.put(`/campaignRequest/${requestId}/confirm-cancel?action=${action}`);

export const getReview = id => client.get(`/campaignRequest/${id}`);
export const submitReview = ({ id, info }) =>
    client.patch(`/campaignRequest/submit/${id}`, info, {
        headers: { "Content-Type": "multipart/form-data" }
    });
export const removeReview = id => client.delete(`/campaignRequest/unsubmit/${id}`);

export const applyCancelRequest = ({ requestId, formData }) =>
    client.put(`/campaignRequest/${requestId}/apply-for-cancel`, formData);
export const getApplyCancelRequestByReviewer = requestId =>
    client.get(`campaignRequest/${requestId}/status-apply-for-cancel`);

//reviewer
export const getMyProjectByReviewer = ({ status, type, skip, limit, sort }) =>
    client.get("/v3/campaignRequests", {
        params: {
            status,
            type: type || "all",
            skip,
            limit,
            sort
        }
    });
