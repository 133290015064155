import { takeLatest } from "redux-saga/effects";
import createRequestSaga, { createRequestSagaBase } from "lib/createRequestSaga";
import { getMainPortfolioList as getMainPortfolioListAPI } from "lib/api/portfolio";
import { getMainProjectList as getMainProjectListAPI } from "lib/api/project";
import { getFeedList as getMainFeedListAPI } from "lib/api/feed";
import { getCategoryList as getCategoryListAPI } from "lib/api/category";
import { getProfileCateoryList as getProfileCateoryListAPI } from "lib/api/user";

import {
    GET_MAIN_PORTFOLIO_LIST,
    GET_MAIN_PROJECT_LIST,
    GET_MAIN_FEED_LIST,
    GET_CATEGORY_LIST,
    GET_USER_KEWORD_LIST
} from "stores/modules/main/action";

const getMainPortfolioListSaga = createRequestSaga(
    GET_MAIN_PORTFOLIO_LIST,
    getMainPortfolioListAPI
);
const getMainProjectListSaga = createRequestSaga(GET_MAIN_PROJECT_LIST, getMainProjectListAPI);
const getMainFeedListSaga = createRequestSaga(GET_MAIN_FEED_LIST, getMainFeedListAPI);
const getCategoryListSaga = createRequestSagaBase({
    type: GET_CATEGORY_LIST,
    api: getCategoryListAPI
});
const getUserKeywordListSaga = createRequestSagaBase({
    type: GET_USER_KEWORD_LIST,
    api: getProfileCateoryListAPI,
    progress: true
});

export function* mainSaga() {
    yield takeLatest(GET_MAIN_PORTFOLIO_LIST, getMainPortfolioListSaga);
    yield takeLatest(GET_MAIN_PROJECT_LIST, getMainProjectListSaga);
    yield takeLatest(GET_MAIN_FEED_LIST, getMainFeedListSaga);
    yield takeLatest(GET_CATEGORY_LIST, getCategoryListSaga);
    yield takeLatest(GET_USER_KEWORD_LIST, getUserKeywordListSaga);
}
