import { createAction, handleActions } from "redux-actions";
import { createRequestActionTypes } from "lib/createRequestSaga";
import produce from "immer";

export const SELECT_REVIEWER = "relation/SELECT_RELATION";
export const SET_CHANGE_CONDITION = "relation/SET_CHANGE_CONDITION";

export const [
    GET_RELATION_LIST,
    GET_RELATION_LIST_SUCCESS,
    GET_RELATION_LIST_FAILURE
] = createRequestActionTypes("relation/GET_RELATION_LIST");
export const [
    GET_RELATION_DETAIL,
    GET_RELATION_DETAIL_SUCCESS,
    GET_RELATION_DETAIL_FAILURE
] = createRequestActionTypes("relation/GET_RELATION_DETAIL");
export const [
    GET_RELATION_TAG_LIST,
    GET_RELATION_TAG_LIST_SUCCESS,
    GET_RELATION_TAG_LIST_FAILURE
] = createRequestActionTypes("relation/GET_RELATION_TAG_LIST");
export const [
    CHANGE_RELATION_STATUS,
    CHANGE_RELATION_STATUS_SUCCESS,
    CHANGE_RELATION_STATUS_FAILURE
] = createRequestActionTypes("relation/CHANGE_RELATION_STATUS");
export const [
    CHANGE_RELATION_STATUS_IN_LIST,
    CHANGE_RELATION_STATUS_IN_LIST_SUCCESS,
    CHANGE_RELATION_STATUS_IN_LIST_FAILURE
] = createRequestActionTypes("relation/CHANGE_RELATION_STATUS_IN_LIST");

export const selectReviewer = createAction(SELECT_REVIEWER);
export const getRelationList = createAction(GET_RELATION_LIST);
export const getRelationDetail = createAction(GET_RELATION_DETAIL);
export const getRelationTagList = createAction(GET_RELATION_TAG_LIST, options => ({
    ...options,
    limit: 9999
}));
export const changeRelationStatusInList = createAction(CHANGE_RELATION_STATUS_IN_LIST);
export const changeRelationStatus = createAction(CHANGE_RELATION_STATUS);
export const setChangeCondition = createAction(SET_CHANGE_CONDITION);

const initialState = {
    search: {
        counts: {
            result: 0,
            follow: 0,
            block: 0
        },
        list: []
    },
    detail: {
        summaryTarget: {},
        info: {}
    },
    tag: {
        counts: 0,
        list: []
    },
    changeCondition: {
        type: null, // ["none", "follow", "block"]
        status: null, // ["check", "complete", "fail"]
        userList: []
    }
};

const relation = handleActions(
    {
        [SELECT_REVIEWER]: (state, { payload }) => ({
            ...state,
            detail: {
                summaryTarget: payload || initialState.detail.summaryTarget,
                info:
                    state.detail.summaryTarget?._id === payload?._id
                        ? state.detail.info
                        : initialState.detail.info
            }
        }),
        [SET_CHANGE_CONDITION]: (state, { payload }) => {
            const newState = {
                ...initialState.changedConition,
                ...payload
            };

            return {
                ...state,
                changeCondition:
                    ["check", "complete", "fail"].indexOf(payload.status) > -1
                        ? newState
                        : initialState.changeCondition
            };
        },
        [GET_RELATION_LIST]: (state, { payload }) => ({
            ...state,
            search: {
                counts: state.search.counts,
                list: payload.options?.skip > 0 ? state.search.list : initialState.search.list
            }
        }),
        [GET_RELATION_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            search: {
                counts: {
                    result: payload.searchResultCount,
                    follow: payload.followingCount,
                    block: payload.blockCount
                },
                list: [...state.search.list, ...payload.list]
            }
        }),
        [GET_RELATION_DETAIL]: state => ({
            ...state,
            detail: {
                ...state.detail,
                info: initialState.detail.info
            }
        }),
        [GET_RELATION_DETAIL_SUCCESS]: (state, { payload }) => ({
            ...state,
            detail: {
                ...state.detail,
                info: payload
            }
        }),
        [GET_RELATION_TAG_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            tag: {
                counts: payload.totalCounts,
                list: payload.list
            }
        }),
        [CHANGE_RELATION_STATUS_IN_LIST_SUCCESS]: (state, { prev }) =>
            produce(state, draft => {
                const { status, userList } = prev;
                const userIdList = userList.map(item => item._id);

                if (["follow", "cancelFollow", "block"].indexOf(status) > -1) {
                    draft.search.counts.follow =
                        state.search.counts.follow +
                        (status === "follow" ? userIdList.length : -userIdList.length);
                }
                if (["block", "cancelBlock"].indexOf(status) > -1) {
                    draft.search.counts.block =
                        state.search.counts.block +
                        (status === "block" ? userIdList.length : -userIdList.length);
                }

                if (userIdList.indexOf(state.detail?.info?._id) > -1) {
                    draft.detail = initialState.detail;
                }

                draft.search.counts.result = state.search.counts.result - userIdList.length;
                draft.search.list = state.search.list.filter(
                    item => userIdList.indexOf(item.target._id) === -1
                );
            })
    },
    initialState
);

export default relation;
