import { takeLatest, takeLeading } from "redux-saga/effects";
import createRequestSaga, { createRequestSagaBase } from "lib/createRequestSaga";
import * as portfolioAPI from "lib/api/portfolio";
import { getTalentListByReviewerId as getTalentListByReviewerIdAPI } from "lib/api/talent";
import { getOpinions as getOpinionsAPI } from "lib/api/campaignRequest";
import { move404PageSaga } from "stores/modules/common/saga";

import {
    GET_PORTFOLIO_USER_INFO,
    GET_PORTFOLIO_USER_INFO_FAILURE,
    GET_PORTFOLIO_TALENT_LIST,
    GET_PORTFOLIO_ACTIVITY_INFO,
    GET_PORTFOLIO_OPINION_INFO,
    GET_ALL_PORTFOLIO_LIST,
    GET_RECENT_REVIEWER_LIST
} from "stores/modules/portfolio/action";

const getPortfolioUserInfoSaga = createRequestSagaBase({
    type: GET_PORTFOLIO_USER_INFO,
    api: portfolioAPI.getPortfolioUserInfo,
    progress: true
});

const getPortfolioTalentListSaga = createRequestSagaBase({
    type: GET_PORTFOLIO_TALENT_LIST,
    api: getTalentListByReviewerIdAPI,
    progress: true
});

const getPortfolioActivityInfoSaga = createRequestSagaBase({
    type: GET_PORTFOLIO_ACTIVITY_INFO,
    api: portfolioAPI.getPortfolioActivityInfo,
    progress: true
});

const getPortfolioOpinionInfoSaga = createRequestSagaBase({
    type: GET_PORTFOLIO_OPINION_INFO,
    api: getOpinionsAPI,
    progress: true
});

const getPortfolioListSaga = createRequestSagaBase({
    type: GET_ALL_PORTFOLIO_LIST,
    api: portfolioAPI.getPortfolioList,
    progress: true
});

const getRecentReviewerListSaga = createRequestSaga(
    GET_RECENT_REVIEWER_LIST,
    portfolioAPI.getRecentReviewerList
);

export function* portfolioSaga() {
    yield takeLatest(GET_PORTFOLIO_USER_INFO, getPortfolioUserInfoSaga);
    yield takeLeading(GET_PORTFOLIO_USER_INFO_FAILURE, move404PageSaga);
    yield takeLatest(GET_PORTFOLIO_TALENT_LIST, getPortfolioTalentListSaga);
    yield takeLatest(GET_PORTFOLIO_ACTIVITY_INFO, getPortfolioActivityInfoSaga);
    yield takeLatest(GET_PORTFOLIO_OPINION_INFO, getPortfolioOpinionInfoSaga);
    yield takeLatest(GET_ALL_PORTFOLIO_LIST, getPortfolioListSaga);
    yield takeLatest(GET_RECENT_REVIEWER_LIST, getRecentReviewerListSaga);
}
