import client from "./client";

export const createTalent = obj => client.post("/v3/talents", obj);
export const updateMyTalent = ({ talentId, obj }) => client.patch(`/v3/talents/${talentId}`, obj);
export const getTalentList = ({ category, keyword, sort, skip, limit }) =>
    client.get("/v3/talents", { params: { category, keyword, sort, skip, limit } });
export const getCustomTalentList = ({ type, limit }) =>
    client.get("/v3/talents/custom", { params: { type, limit } });
export const getMyTalent = talentId => client.get(`/v3/talents/${talentId}/me`);
export const getMyTalentList = ({ skip, limit } = {}) =>
    client.get("/v3/talents/me", { params: { skip, limit } });
export const getTalentDetail = talentId => client.get(`/v3/talents/${talentId}`);
export const getTalentCounts = () => client.get("/v3/talents/counts/me");
export const getTalentListByReviewerId = ({ reviewerId, skip, limit }) =>
    client.get(`/v3/talents/reviewer/${reviewerId}`, { params: { skip, limit } });
