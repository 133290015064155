import { createAction, handleActions } from "redux-actions";
import { takeLatest, takeLeading } from "redux-saga/effects";
import produce from "immer";
import {
    createRequestSagaWithAlert,
    createRequestSagaBase,
    createRequestActionTypes
} from "lib/createRequestSaga";
import {
    getNewProjectInfo as getProjectInfoAPI,
    getProjectStatsData as getProjectStatsDataAPI
} from "lib/api/report";
import { getBrandList as getBrandListAPI } from "lib/api/brand";
import { getProjectByBrandId as getProjectByBrandIdAPI } from "lib/api/enterprise";
import {
    getManagedProjectList as getManagedProjectListAPI,
    changeSubmitEndDay as changeSubmitEndDayAPI,
    cancelRereviewingProject as cancelRereviewingProjectAPI,
    deleteProject as deleteProjectAPI
} from "lib/api/project";

export const [
    GET_PROJECT_INFO,
    GET_PROJECT_INFO_SUCCESS,
    GET_PROJECT_INFO_FAILURE
] = createRequestActionTypes("reportCommon/GET_PROJECT_INFO");

export const [
    GET_MY_BRAND_LIST,
    GET_MY_BRAND_LIST_SUCCESS,
    GET_MY_BRAND_LIST_FAILURE
] = createRequestActionTypes("reportCommon/GET_MY_BRAND_LIST");

export const [
    GET_PROJECT_LIST_BY_BRAND,
    GET_PROJECT_LIST_BY_BRAND_SUCCESS,
    GET_PROJECT_LIST_BY_BRAND_FAILURE
] = createRequestActionTypes("reportCommon/GET_PROJECT_LIST_BY_BRAND");

export const [
    GET_MANAGE_PROJECT_LIST,
    GET_MANAGE_PROJECT_LIST_SUCCESS,
    GET_MANAGE_PROJECT_LIST_FAILURE
] = createRequestActionTypes("reportCommon/GET_MANAGE_PROJECT_LIST");

const [
    GET_PROJECT_STATISTICS,
    GET_PROJECT_STATISTICS_SUCCESS
    // GET_PROJECT_STATISTICS_FAILURE
] = createRequestActionTypes("reportCommon/GET_PROJECT_STATISTICS");

export const [
    CHANGE_SUBMIT_END_DAY_OF_PROJECT,
    CHANGE_SUBMIT_END_DAY_OF_PROJECT_SUCCESS,
    CHANGE_SUBMIT_END_DAY_OF_PROJECT_FAILURE
] = createRequestActionTypes("reportCommon/CHANGE_SUBMIT_END_DAY_OF_PROJECT");

export const [
    CANCEL_REREVIEWING_PROJECT,
    CANCEL_REREVIEWING_PROJECT_SUCCESS,
    CANCEL_REREVIEWING_PROJECT_FAILURE
] = createRequestActionTypes("reportCommon/CANCEL_REREVIEWING_PROJECT");

export const [
    DELETE_PROJECT,
    DELETE_PROJECT_SUCCESS,
    DELETE_PROJECT_FAILURE
] = createRequestActionTypes("reportCommon/DELETE_PROJECT");

export const getProjectInfo = createAction(GET_PROJECT_INFO, projectId => projectId);

export const getMyBrandList = createAction(GET_MY_BRAND_LIST, userId => userId);

export const getProjectListByBrand = createAction(GET_PROJECT_LIST_BY_BRAND, brandId => brandId);
export const getManagedProjectList = createAction(GET_MANAGE_PROJECT_LIST);

export const getProjectStats = createAction(GET_PROJECT_STATISTICS, ({ projectId, data }) => ({
    projectId,
    data
}));

export const changeSubmitEndDayOfProject = createAction(
    CHANGE_SUBMIT_END_DAY_OF_PROJECT,
    ({ projectId, newSubmitEndDay }) => ({ projectId, newSubmitEndDay })
);

export const cancelRereviewingProject = createAction(
    CANCEL_REREVIEWING_PROJECT,
    projectId => projectId
);
export const deleteProject = createAction(DELETE_PROJECT, projectId => projectId);

const getProjectInfoSaga = createRequestSagaWithAlert(
    GET_PROJECT_INFO,
    getProjectInfoAPI,
    "message.project.get.detail.fail",
    true
);

const getMyBrandListSaga = createRequestSagaWithAlert(
    GET_MY_BRAND_LIST,
    getBrandListAPI,
    "message.brand.get.fail",
    false
);

const getProjectListByBrandSaga = createRequestSagaWithAlert(
    GET_PROJECT_LIST_BY_BRAND,
    getProjectByBrandIdAPI,
    "message.project.get.list.fail",
    false
);

const getManageProjectListSaga = createRequestSagaBase({
    type: GET_MANAGE_PROJECT_LIST,
    api: getManagedProjectListAPI,
    progress: true
});

const getProjectStatsSaga = createRequestSagaWithAlert(
    GET_PROJECT_STATISTICS,
    getProjectStatsDataAPI,
    "message.report.statistics.fail",
    true
);

const changeSubmitEndDayOfProjectSaga = createRequestSagaBase({
    type: CHANGE_SUBMIT_END_DAY_OF_PROJECT,
    api: changeSubmitEndDayAPI,
    alertErrorMsg: "message.project.change.submit.endAt"
});

const cancelRereviewingSaga = createRequestSagaBase({
    type: CANCEL_REREVIEWING_PROJECT,
    api: cancelRereviewingProjectAPI,
    alertErrorMsg: "message.project.edit.cancel.fail"
});

const deleteProjectSaga = createRequestSagaBase({
    type: DELETE_PROJECT,
    api: deleteProjectAPI,
    alertSuccessMsg: "message.project.delete.success",
    alertErrorMsg: "message.project.delete.fail"
});

export function* reportCommonSaga() {
    yield takeLatest(GET_PROJECT_INFO, getProjectInfoSaga);
    yield takeLatest(GET_MY_BRAND_LIST, getMyBrandListSaga);
    yield takeLatest(GET_PROJECT_LIST_BY_BRAND, getProjectListByBrandSaga);
    yield takeLatest(GET_MANAGE_PROJECT_LIST, getManageProjectListSaga);
    yield takeLatest(GET_PROJECT_STATISTICS, getProjectStatsSaga);
    yield takeLeading(CHANGE_SUBMIT_END_DAY_OF_PROJECT, changeSubmitEndDayOfProjectSaga);
    yield takeLeading(CANCEL_REREVIEWING_PROJECT, cancelRereviewingSaga);
    yield takeLeading(DELETE_PROJECT, deleteProjectSaga);
}

const initialState = {
    projectInfo: null,
    brandList: [],
    projectList: [],
    statsData: null,

    manageProject: {
        counts: 0,
        list: []
    }
    // statsGraphData: {}
};

const reportCommon = handleActions(
    {
        [GET_PROJECT_INFO_SUCCESS]: (state, { payload }) => ({
            ...state,
            projectInfo: payload.projectInfo
        }),
        [GET_MY_BRAND_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            brandList: payload.brands
        }),
        [GET_PROJECT_LIST_BY_BRAND_SUCCESS]: (state, { payload }) => ({
            ...state,
            projectList: payload
        }),
        [GET_MANAGE_PROJECT_LIST]: state => ({
            ...state,
            manageProject: initialState.manageProject
        }),
        [GET_MANAGE_PROJECT_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            manageProject: {
                counts: payload.projectCount,
                list: payload.projectList
            }
        }),
        [GET_PROJECT_STATISTICS_SUCCESS]: (state, { payload }) => ({
            ...state,
            statsData: payload
        }),
        [CHANGE_SUBMIT_END_DAY_OF_PROJECT_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                if (state.projectInfo?._id) {
                    draft.projectInfo.submitEndAt = payload.newSubmitEndDay;
                    draft.projectInfo.daysUntilSubmitEndAt = payload.daysUntilSubmitEndAt;
                }

                if (state.manageProject.list.length > 0) {
                    draft.manageProject.list = state.manageProject.list.map(item => {
                        if (item._id === prev.projectId) {
                            return { ...item, submitEndAt: payload.newSubmitEndDay };
                        }
                        return item;
                    });
                }
            }),
        [CANCEL_REREVIEWING_PROJECT_SUCCESS]: (state, { prev: projectId }) =>
            produce(state, draft => {
                draft.manageProject.list = state.manageProject.list.map(item => {
                    if (item._id === projectId) {
                        const newItem = { ...item };
                        newItem.statusOfRequestRereviewingProject.status = "possible";
                        return newItem;
                    }
                    return item;
                });
            }),
        [DELETE_PROJECT_SUCCESS]: (state, { prev: projectId }) =>
            produce(state, draft => {
                draft.manageProject.counts = state.manageProject.counts - 1;
                draft.manageProject.list = state.manageProject.list.filter(item => {
                    if (item._id === projectId) {
                        return false;
                    }
                    return true;
                });
            })
    },
    initialState
);

export default reportCommon;
