import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { handleMessengerMainFunction } from "stores/modules/messenger/action";
import MessengerMainSideTemplate from "components/base/templates/MessengerMainSideTemplate";
import BrandHistory from "components/messenger/main/BrandHistory";
import SelectTo from "components/messenger/main/SelectTo";
import SelectFrom from "components/messenger/main/SelectFrom";
import GivePoints from "components/messenger/main/GivePoints";

const MainSideItems = () => {
    const dispatch = useDispatch();
    const mainFunction = useSelector(({ messenger }) => messenger.mainFunction);

    const onCloseMainSide = () => {
        dispatch(handleMessengerMainFunction("none"));
    };

    if (mainFunction === "none" || !mainFunction) return null;

    return (
        <MessengerMainSideTemplate mainFunction={mainFunction}>
            {(() => {
                switch (mainFunction) {
                    case "brand":
                        return <BrandHistory />;
                    case "selectTo":
                        return <SelectTo closeModal={onCloseMainSide} />;
                    case "selectFrom":
                        return <SelectFrom closeModal={onCloseMainSide} />;
                    case "point":
                        return <GivePoints />;
                    default:
                        return null;
                }
            })()}
        </MessengerMainSideTemplate>
    );
};
export default MainSideItems;
