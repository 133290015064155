import React from "react";
import { IconFont, StandardModal } from "reviewshare-common-component";
import { SERVICE_PDF } from "scheme/link";
import CommonLink from "components/common/CommonLink";

import style from "./BizIntroMobileMenu.module.scss";

const BizIntroMobileMenu = ({ type, closeModal }) => (
    <StandardModal id={style.modal} closeModal={closeModal}>
        <div className={style.menuItems}>
            {type === "self" && (
                <CommonLink className={style.menuItem} to={SERVICE_PDF} external>
                    <div>
                        <p className={style.menuSubTitle}>자세한 안내는</p>
                        <p className={style.menuTitle}>서비스 소개서 다운로드</p>
                    </div>
                    <IconFont icon="icon_download" className={style.menuIcon} />
                </CommonLink>
            )}
            <CommonLink className={style.menuItem} to="/">
                <div>
                    <p className={style.menuSubTitle}>리뷰쉐어가 궁금하다면</p>
                    <p className={style.menuTitle}>플랫폼 바로가기</p>
                </div>
                <IconFont icon="icon_reviewshare" className={style.menuIcon} />
            </CommonLink>
        </div>
    </StandardModal>
);

export default BizIntroMobileMenu;
