import client from "./client";

export const getRecommendList = ({ skip, limit }) =>
    client.get("/v3/tags/recommend/user-list", { params: { skip, limit } });
export const getRelationTagList = ({ skip, limit } = {}) =>
    client.get("/v3/tags/relation", { params: { skip, limit } });
export const getRelationListByTag = tagId => client.get(`/v3/relations/tags/${tagId}`);
export const setTagToUser = info => client.patch("/v3/relations/tag", info);
export const createTag = info => client.post("/v3/tags", info);
export const deleteTag = tagId => client.delete(`/v3/tags/${tagId}`);
