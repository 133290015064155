import React from "react";
import { useSelector } from "react-redux";
import { MessageBox } from "reviewshare-common-component";
import cx from "classnames";
import { numberWithCommas } from "lib/common";

import "./GivePointsDetail.scss";

const GivePointsDetail = ({ totalAmount, reviewerPoints }) => {
    const writeInfo = useSelector(({ messenger }) => messenger.writeInfo);

    return (
        <ul className="messenger-main-give-points-payment-detail">
            <li className="payment-item">
                <p className="payment-item-title">브랜드</p>
                <div className={cx("payment-item-content", { empty: !writeInfo.from.brandName })}>
                    <span className="ellipsis-text">{writeInfo.from.brandName || "-"}</span>
                </div>
            </li>
            <li className="payment-item">
                <p className="payment-item-title">받는 리뷰어</p>
                <div className={cx("payment-item-content", { empty: !writeInfo.toList.length })}>
                    <span className="ellipsis-text">
                        {writeInfo.toList.length > 0 ? writeInfo.toList[0].nickname : "-"}
                    </span>
                    {writeInfo.toList.length > 1 &&
                        `외 ${numberWithCommas(writeInfo.toList.length - 1)}명`}
                </div>
            </li>
            <li className="payment-item emphasis">
                <p className="payment-item-title">
                    <MessageBox title="실지급 포인트" direction="bottom">
                        리뷰어 1인 기준 포인트 선물 시 {"\n"}
                        <span className="blue-font">리뷰쉐어 수수료 20%</span>를 제외한 {"\n"}금액이
                        지급돼요.
                    </MessageBox>
                </p>
                <div className="payment-item-content">{numberWithCommas(reviewerPoints)}P</div>
            </li>
            <li className="payment-item emphasis">
                <p className="payment-item-title">총 결제 포인트</p>
                <div className="payment-item-content">{numberWithCommas(totalAmount)}P</div>
            </li>
        </ul>
    );
};
export default GivePointsDetail;
