import client from "./client";

export const getPortfolioUserInfo = id => client.get(`portfolios/${id}/summary-info`);
export const getPortfolioActivityInfo = id => client.get(`portfolios/${id}/activity-info`);
export const getMainPortfolioList = () => client.get("/portfolios/main");
export const getPortfolioList = ({ sort, skip, limit, ...options }) => {
    return client.get(`/portfolios`, {
        params: {
            ...options,
            sort: sort || "rsRecommend",
            skip,
            limit: limit || 20
        }
    });
};
export const getBestReviewerList = () => client.get("/portfolios/best");
export const getRecentReviewerList = ({ limit }) =>
    client.get("/reviewerStatus/updated-list", { params: { limit } });
