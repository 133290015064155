import { createAction, handleActions } from "redux-actions";
import { createRequestActionTypes } from "lib/createRequestSaga";
import produce from "immer";
import {
    PROJECT_STEP1_INITIAL_DATA,
    PROJECT_STEP2_INITIAL_DATA,
    PROJECT_STEP3_INITIAL_DATA,
    PROJECT_STEP4_INITIAL_DATA
} from "scheme/create";

export const RESET_ALL_STATE = "create/RESET_ALL_STATE";
export const RESET_READY_FOR_CREATE = "create/RESET_READY_FOR_CREATE";

export const SET_PROJECT_INFO = "create/SET_PROJECT_INFO";
export const SET_PREV_PROJECT_INFO = "create/SET_PREV_PROJECT_INFO";
export const SET_TEMP_PROJECT_INFO = "create/SET_TEMP_PROJECT_INFO";

export const [
    SET_SELECTED_BRAND_INFO,
    SET_SELECTED_BRAND_INFO_SUCCESS,
    SET_SELECTED_BRAND_INFO_FAILURE
] = createRequestActionTypes("create/SET_SELECTED_BRAND_INFO");

export const [
    GET_REREVIEWING_PROJECT,
    GET_REREVIEWING_PROJECT_SUCCESS,
    GET_REREVIEWING_PROJECT_FAILURE
] = createRequestActionTypes("create/GET_REREVIEWING_PROJECT");

export const [
    GET_TEMP_PROJECT_LIST,
    GET_TEMP_PROJECT_LIST_SUCCESS,
    GET_TEMP_PROJECT_LIST_FAILURE
] = createRequestActionTypes("create/GET_TEMP_PROJECT_LIST");

export const [
    DELETE_TEMP_PROJECT,
    DELETE_TEMP_PROJECT_SUCCESS,
    DELETE_TEMP_PROJECT_FAILURE
] = createRequestActionTypes("create/DELETE_TEMP_PROJECT");

export const [
    SAVE_TEMP_PROJECT,
    SAVE_TEMP_PROJECT_SUCCESS,
    SAVE_TEMP_PROJECT_FAILURE
] = createRequestActionTypes("create/SAVE_TEMP_PROJECT");

export const [
    CREATE_PROJECT,
    CREATE_PROJECT_SUCCESS,
    CREATE_PROJECT_FAILURE
] = createRequestActionTypes("create/CREATE_PROJECT");

export const [
    REQUEST_REREVIEWING_PROJECT,
    REQUEST_REREVIEWING_PROJECT_SUCCESS,
    REQUEST_REREVIEWING_PROJECT_FAILURE
] = createRequestActionTypes("create/REQUEST_REREVIEWING_PROJECT");

export const [
    EVALUATE_ITEMS,
    EVALUATE_ITEMS_SUCCESS,
    EVALUATE_ITEMS_FAILURE
] = createRequestActionTypes("create/EVALUATE_ITEMS");

export const SET_IMAGES = "create/SET_IMAGES";
export const SET_BLOB_ARRAY = "create/SET_BLOB_ARRAY";
export const SET_INICIS_DATA = "create/SET_INICIS_DATA";
export const RESET_INICIS_DATA = "create/RESET_INICIS_DATA";

export const resetAllState = createAction(RESET_ALL_STATE, isComplete => isComplete);
export const resetReadyForCreate = createAction(RESET_READY_FOR_CREATE);
export const getRereviewingProject = createAction(GET_REREVIEWING_PROJECT);
export const getTempProjectList = createAction(GET_TEMP_PROJECT_LIST);
export const setProjectInfo = createAction(SET_PROJECT_INFO, obj => obj);
export const setPrevProjectInfo = createAction(SET_PREV_PROJECT_INFO, info => info);
export const setTempProjectInfo = createAction(SET_TEMP_PROJECT_INFO, info => info);
export const setSelectedBrandInfo = createAction(SET_SELECTED_BRAND_INFO, brandId => brandId);
export const deleteTempProject = createAction(DELETE_TEMP_PROJECT, id => id);
export const saveTempProject = createAction(SAVE_TEMP_PROJECT, (obj, photos, successAlertObj) => ({
    obj,
    photos,
    successAlertObj
}));
export const setImages = createAction(SET_IMAGES, array => array);
export const setBlobArray = createAction(SET_BLOB_ARRAY, array => array);
export const createProject = createAction(CREATE_PROJECT, ({ info, photos, payment }) => ({
    info,
    photos,
    payment
}));
export const requestRereviewing = createAction(
    REQUEST_REREVIEWING_PROJECT,
    ({ info, photos, payment }) => ({
        info,
        photos,
        payment
    })
);
export const resetInicisData = createAction(RESET_INICIS_DATA, bool => bool);
export const evaluateItems = createAction(EVALUATE_ITEMS, obj => obj);

const initialState = {
    beforeChangeProjectInfo: {},
    afterChangedProjectId: null,
    projectInfo: {
        ...PROJECT_STEP1_INITIAL_DATA,
        ...PROJECT_STEP2_INITIAL_DATA,
        ...PROJECT_STEP3_INITIAL_DATA,
        ...PROJECT_STEP4_INITIAL_DATA,
        brand: "",
        brandName: "",
        country: "Korea"
    },
    selectedBrandInfo: {},
    tempCount: 0,
    tempProjectList: [],
    tempProjectInfo: {},
    prevProjetInfo: {},
    readyForCreate: false,
    images: [{}, {}, {}, {}],
    blobArray: ["", "", "", ""],
    inicisData: {},
    payment: {},
    checkEvaluate: false
};

const create = handleActions(
    {
        [RESET_ALL_STATE]: (state, { payload }) => {
            if (payload) {
                return {
                    ...initialState,
                    projectInfo: {
                        ...initialState.projectInfo,
                        recruitmentForm: state.projectInfo.recruitmentForm,
                        enterprise: state.projectInfo.enterprise,
                        brand: state.projectInfo.brand
                    },
                    selectedBrandInfo: state.selectedBrandInfo
                };
            }
            return { ...initialState };
        },
        [SET_PROJECT_INFO]: (state, { payload }) =>
            produce(state, draft => {
                draft.projectInfo = { ...state.projectInfo, ...payload };
            }),
        [SET_PREV_PROJECT_INFO]: (state, { payload }) =>
            produce(state, draft => {
                draft.projectInfo = {
                    ...initialState.projectInfo,
                    ...payload,
                    recruitmentForm: state.projectInfo.recruitmentForm
                };
                draft.prevProjectInfo = { ...payload };
                if (draft.tempProjectInfo && Object.keys(draft.tempProjectInfo).length > 0) {
                    draft.tempProjectInfo = {};
                }
                draft.images = initialState.images;
                draft.blobArray = initialState.blobArray;
            }),
        [SET_TEMP_PROJECT_INFO]: (state, { payload }) =>
            produce(state, draft => {
                draft.projectInfo = {
                    ...initialState.projectInfo,
                    ...payload,
                    recruitmentForm: state.projectInfo.recruitmentForm
                };
                draft.tempProjectInfo = payload;
                if (draft.prevProjectInfo && Object.keys(draft.prevProjectInfo).length > 0) {
                    draft.prevProjectInfo = {};
                }
                draft.images = initialState.images;
                draft.blobArray = initialState.blobArray;
            }),
        [SET_SELECTED_BRAND_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload._id && payload.name) {
                    draft.selectedBrandInfo = payload;
                    draft.projectInfo = {
                        ...state.projectInfo,
                        brand: payload._id,
                        brandName: payload.name,
                        enterprise: payload._id
                    };
                } else {
                    draft.selectedBrandInfo = initialState.selectedBrandInfo;
                    draft.projectInfo = {
                        ...state.projectInfo,
                        brand: null,
                        brandName: null,
                        enterprise: null
                    };
                }
                draft.prevProjectInfo = initialState.prevProjetInfo;
            }),
        [GET_REREVIEWING_PROJECT]: (state, { payload: projectId }) =>
            produce(state, draft => {
                draft.beforeChangeProjectInfo = { _id: projectId };
            }),
        [GET_REREVIEWING_PROJECT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                const { rereviewingCampaign } = payload;
                draft.beforeChangeProjectInfo = payload;

                const projectInfo = rereviewingCampaign || payload;
                projectInfo.photo_urls = projectInfo.photos;
                projectInfo.sns =
                    projectInfo.sns === "OtherSns" ? projectInfo.otherSns : projectInfo.sns;

                if (rereviewingCampaign) {
                    projectInfo._id = projectInfo.campaignId;
                }

                draft.projectInfo = projectInfo;
            }),
        [GET_TEMP_PROJECT_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.tempCount = payload.count;
                draft.tempProjectList = payload.projects;
            }),
        [DELETE_TEMP_PROJECT_SUCCESS]: (state, { prev: projecctId }) =>
            produce(state, draft => {
                draft.tempProjectList = state.tempProjectList.filter(
                    val => val.campaignId !== projecctId
                );
                draft.tempCount = state.tempCount - 1;
                if (projecctId === state.tempProjectInfo.campaignId) {
                    draft.tempProjectInfo = {};
                }
            }),
        [SAVE_TEMP_PROJECT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.projectInfo = { ...state.projectInfo, campaignId: payload._id };
            }),
        [CREATE_PROJECT_SUCCESS]: state =>
            produce(state, draft => {
                draft.readyForCreate = true;
                draft.checkEvaluate = false;
            }),
        [REQUEST_REREVIEWING_PROJECT_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                draft.readyForCreate = true;

                if (prev?.payment?.amountReal === 0) {
                    draft.afterChangedProjectId = payload._id;
                }
            }),
        [RESET_READY_FOR_CREATE]: state =>
            produce(state, draft => {
                draft.readyForCreate = false;
            }),
        [RESET_INICIS_DATA]: state =>
            produce(state, draft => {
                draft.readyForCreate = false;
                draft.inicisData = {};
            }),
        [SET_IMAGES]: (state, { payload }) =>
            produce(state, draft => {
                draft.images = payload;
            }),
        [SET_BLOB_ARRAY]: (state, { payload }) =>
            produce(state, draft => {
                draft.blobArray = payload;
            }),
        [SET_INICIS_DATA]: (state, { payload }) =>
            produce(state, draft => {
                draft.inicisData = payload;
            }),
        [EVALUATE_ITEMS_SUCCESS]: state =>
            produce(state, draft => {
                draft.checkEvaluate = true;
            })
    },
    initialState
);

export default create;
