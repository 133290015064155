import React from "react";
import cx from "classnames";
import autoLink from "auto-link";
import { encode } from "html-entities";
import { IconFont } from "reviewshare-common-component";
import { returnDateText } from "utils/messenger";

import "./MessageContent.scss";

const MessageContent = ({ data }) => (
    <>
        {(() => {
            switch (data.contents.type) {
                case "img":
                    return (
                        <div className={cx("message-item-content", data.contents.type)}>
                            <img src={data.contents.value} alt="이미지" />
                            <div className="image-info-box">
                                <p className="file-name">
                                    {data.contents.fileName.replace(
                                        `.${data.contents.fileFormat}`,
                                        ""
                                    )}
                                </p>
                                <p className="file-format">.{data.contents.fileFormat}</p>
                            </div>
                        </div>
                    );
                case "file":
                    return (
                        <a
                            className={cx("message-item-content", data.contents.type)}
                            href={data.contents.value}
                            target="_blank"
                            download
                            rel="noopener noreferrer nosublink"
                        >
                            <IconFont icon="icon_folder">파일</IconFont>
                            <p className="file-name">
                                {data.contents.fileName.replace(`.${data.contents.fileFormat}`, "")}
                            </p>
                            <p className="file-format">.{data.contents.fileFormat}</p>
                        </a>
                    );
                case "text":
                    return (
                        <div className={cx("message-item-content", data.contents.type)}>
                            {data.type === "date" ? (
                                returnDateText(data.contents.value)
                            ) : (
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: autoLink.link(encode(data.contents.value), {
                                            target: "_blank"
                                        })
                                    }}
                                />
                            )}
                        </div>
                    );
                default:
                    return null;
            }
        })()}
    </>
);
export default MessageContent;
