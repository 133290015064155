import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import {
    Button,
    Dropdown,
    DropdownItem,
    CautionList,
    StandardModal,
    InputWrapper,
    Input,
    Segmented,
    SegmentedItem,
    Spin,
    Switch,
    MessageBox
} from "reviewshare-common-component";
import { CATEGORY_SCHEME } from "scheme/feed";
import { CREATE_FEED_CAUTION_BY_TYPE } from "scheme/mypage";
import { getFeedForEdit as getFeedAPI } from "lib/api/feed";
import useTranslate from "lib/customHooks/useTranslate";
import useDirectApi from "lib/customHooks/useDirectApi";
import { useProgressItems } from "lib/customHooks/useProgress";
import { submitOtherFeed } from "stores/modules/feed/action";
import FormattedMessage from "components/common/FormattedMessage";
import UploadFileBox from "components/common/UploadFileBox";

import "./CreateFeedModal.scss";

const checkOtherUrl = url =>
    url &&
    !url.includes("blog.naver.com") &&
    !url.includes("instagram.com") &&
    !url.includes("youtu.be") &&
    !url.includes("youtube.com") &&
    !url.includes("tiktok");

const TYPE_LIST = [
    { label: "URL", value: "url" },
    { label: "사진/영상", value: "file" }
];

const CreateFeedModal = ({ onClose }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const urlInput = useRef();
    const { data, fetch: getFeedInfo, isFetching: isSearching } = useDirectApi({
        api: getFeedAPI
    });

    const mode = useSelector(({ feed }) => feed.selectedInfo?.mode);
    const selectedInfoId = useSelector(({ feed }) => feed.selectedInfo?.id);
    const isFetching = useProgressItems("feed/SUBMIT_OTHER_FEED");

    const [type, setType] = useState("url");
    const [feedInfo, setFeedInfo] = useState({
        url: "",
        fileList: [],
        deleteFileIdList: [],
        category: "",
        isHide: false
    });

    const possibleSubmit = useMemo(() => {
        if (!feedInfo.category) {
            return false;
        }
        if (type === "url" && !feedInfo.url) {
            return false;
        }
        if (type === "file" && !feedInfo.fileList?.length) {
            return false;
        }
        return true;
    }, [type, feedInfo.category, feedInfo.url, feedInfo.fileList]);

    const submitFeed = () => {
        const obj = { id: data?._id, category: feedInfo.category, isHide: feedInfo.isHide };
        if (type === "url") {
            obj.type = "review";
            obj.url = feedInfo.url;
        } else if (type === "file") {
            obj.type = "content";
            obj.fileList = feedInfo.fileList.filter(item => !item.id);
            obj.deleteFileIdList = feedInfo.deleteFileIdList;
        }
        dispatch(submitOtherFeed(obj));
    };

    const addFile = newFiles => {
        setFeedInfo(prev => ({
            ...prev,
            fileList: [...prev.fileList, ...newFiles]
        }));
    };

    const removeFile = index => {
        const file = feedInfo.fileList[index];

        if (file.id) {
            setFeedInfo(prev => ({
                ...prev,
                deleteFileIdList: [...prev.deleteFileIdList, file.id]
            }));
        }

        const _originalFileList = feedInfo.fileList.filter(
            (item, fileIndex) => index !== fileIndex
        );
        setFeedInfo(prev => ({ ...prev, fileList: _originalFileList }));
    };

    useEffect(() => {
        if (selectedInfoId) {
            getFeedInfo(selectedInfoId);
        }
        // eslint-disable-next-line
    }, [selectedInfoId]);

    useEffect(() => {
        if (data?._id) {
            const newFeedInfo = {
                category: data.category,
                isHide: data.isHide,
                url: "",
                fileList: [],
                deleteFileIdList: []
            };
            if (data.review_url) {
                setType("url");
                newFeedInfo.url = data.review_url;
            } else {
                setType("file");
                newFeedInfo.fileList = data.contents || [];
            }
            setFeedInfo(newFeedInfo);
        }
    }, [data]);

    return (
        <StandardModal
            id="create-feed-modal"
            className={cx({ fetching: isSearching })}
            modalTitle={translate(mode === "edit" ? "action.edit.target" : "action.create.target", {
                name: translate("item.content")
            })}
            modalFooter={
                <Button
                    className="theme1 size-l m-size-l"
                    onClick={submitFeed}
                    loading={isFetching}
                    disabled={!possibleSubmit}
                >
                    <FormattedMessage id={mode === "edit" ? "action.edit" : "action.create"} />
                </Button>
            }
            closeModal={onClose}
        >
            {isSearching ? (
                <Spin />
            ) : (
                <>
                    {mode === "create" && (
                        <Segmented current={type} className="size-m m-size-s">
                            {TYPE_LIST.map(item => (
                                <SegmentedItem
                                    key={item.value}
                                    value={item.value}
                                    onClick={() => setType(item.value)}
                                >
                                    {item.label}
                                </SegmentedItem>
                            ))}
                        </Segmented>
                    )}
                    {type === "url" && (
                        <InputWrapper remove={() => setFeedInfo(prev => ({ ...prev, url: "" }))}>
                            <Input
                                ref={urlInput}
                                id="submit-other-review-input"
                                className="size-l m-size-l"
                                value={feedInfo.url}
                                onChange={val => setFeedInfo(prev => ({ ...prev, url: val }))}
                                placeholder={translate("item.suggestion.url")}
                            />
                        </InputWrapper>
                    )}
                    {type === "file" && (
                        <UploadFileBox
                            elementId="create-feed-modal-file-box"
                            fileList={feedInfo.fileList}
                            acceptList={["image", "video"]}
                            add={addFile}
                            remove={removeFile}
                        />
                    )}
                    <Dropdown
                        showRadio
                        className="size-l m-size-l"
                        defaultValue={
                            <>
                                {translate("action.select.target", {
                                    name: translate("item.feed.category")
                                })}{" "}
                                <span className="blue-font">
                                    (<FormattedMessage id="item.required" />)
                                </span>
                            </>
                        }
                    >
                        {CATEGORY_SCHEME.map(item => (
                            <DropdownItem
                                key={item.value}
                                isActive={feedInfo.category === item.value}
                                onClick={() =>
                                    setFeedInfo(prev => ({ ...prev, category: item.value }))
                                }
                            >
                                <FormattedMessage id={item.label} />
                            </DropdownItem>
                        ))}
                    </Dropdown>
                    {type === "url" && checkOtherUrl(feedInfo.url) && (
                        <p className="error-message">
                            <FormattedMessage id="message.result.validate.url.regex" />
                        </p>
                    )}
                    <CautionList
                        title=""
                        items={[
                            ...CREATE_FEED_CAUTION_BY_TYPE[type],
                            ...CREATE_FEED_CAUTION_BY_TYPE.common
                        ]}
                    />
                    <div className="change-hide-status-area">
                        <MessageBox title="콘텐츠 공개" direction="rightBottom">
                            제출된 콘텐츠는 다른 유저들에게 자동 공개돼요. {"\n"}이를 원하지
                            않는다면 비공개로 설정해 주세요.
                        </MessageBox>
                        <Switch
                            status={!feedInfo.isHide}
                            onClick={() => setFeedInfo(prev => ({ ...prev, isHide: !prev.isHide }))}
                        />
                    </div>
                </>
            )}
        </StandardModal>
    );
};
export default CreateFeedModal;
