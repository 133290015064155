import React, { Suspense } from "react";
import { isMobile } from "lib/common";
import GlobalHeaderContainer from "containers/core/GlobalHeaderContainer";
import BottomTabBarPortal from "components/base/portal/BottomTabBarPortal";

import "./PrivatePageTemplate.scss";

const PrivatePageTemplate = ({ children, hasHeader, bottomTabBar }) => (
    <>
        <div className="private-page-template">
            {(!isMobile() || (isMobile() && hasHeader)) && <GlobalHeaderContainer />}
            <div className="page-body">
                <Suspense fallback={null}>{children}</Suspense>
            </div>
        </div>
        {bottomTabBar && <BottomTabBarPortal />}
    </>
);

export default PrivatePageTemplate;
