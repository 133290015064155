import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import cx from "classnames";

import "./Loading.scss";

const Loading = () => {
    const { progress, isBlack, progressItems } = useSelector(({ loading }) => loading);

    const Animation = keyframes`
        from {
            padding-right: 0;
        }
        to {
            padding-right: ${100 - progress}%;
        }
    `;

    const RestProgress = styled.div`
        animation: ${Animation} 600s linear;
    `;

    useEffect(() => {
        if (Object.keys(progressItems).length > 0) {
            document.querySelector("body").style.overflowY = "hidden";
        } else {
            document.querySelector("body").style.overflowY = "auto";
        }
    }, [progressItems]);

    return (
        <>
            <div
                className={cx("common-loading", {
                    active: Object.values(progressItems).indexOf(true) > -1,
                    black: isBlack
                })}
            >
                <div className="progress-bar">
                    <div
                        className={cx("progress", { rest: Object.keys(progressItems).length > 0 })}
                        style={{ width: `${progress}%` }}
                    />
                    <RestProgress
                        className="progress-rest progress"
                        style={{ width: `${progress}%` }}
                    />
                </div>
            </div>
        </>
    );
};
export default Loading;
