import React from "react";
import cx from "classnames";
import { Profile } from "reviewshare-common-component";
import { returnDateText, returnName } from "utils/messenger";
import FormattedMessage from "components/common/FormattedMessage";

import "./MessageBoxItem.scss";

const MessageBoxItem = ({ userType, data, active }) =>
    userType === "Seller" ? (
        <div
            className={cx("messenger-message-box-item biz", {
                active,
                disabled: data.isDeletedReviewer
            })}
        >
            <Profile className="size-s m-size-m" img={data.profileImg} noBand />
            <div className="text-area">
                <div className="info-area">
                    <p className="other-name">
                        {returnName(data.nickname, data.isDeletedReviewer)}
                    </p>
                    <span className="date">{returnDateText(data.lastMessagedAt, "time")}</span>
                </div>
                <div className="message-area">
                    <p className="message">{data.message}</p>
                    {data.isNew && <span className="new-mark">N</span>}
                </div>
            </div>
            <div className="brand-area">
                <span>
                    <FormattedMessage id="item.brand" />
                </span>
                <p>{returnName(data.brandName, data.isDeletedBrand)}</p>
            </div>
        </div>
    ) : userType === "Normal" ? (
        <div
            className={cx("messenger-message-box-item reviewer", {
                active,
                disabled: data.isDeletedBrand
            })}
        >
            <div className="text-area">
                <div className="info-area">
                    <p className="other-name">{returnName(data.brandName, data.isDeletedBrand)}</p>
                    <span className="date">{returnDateText(data.lastMessagedAt, "time")}</span>
                </div>
                <div className="message-area">
                    <p className="message">{data.message}</p>
                    {data.isNew && <span className="new-mark">N</span>}
                </div>
            </div>
        </div>
    ) : null;

export default MessageBoxItem;
