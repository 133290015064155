import React from "react";
import { withRouter } from "react-router-dom";
// import CsInfoModal from "components/popup/CsInfoModal";
import InterruptionModalContainer from "containers/subscription/InterruptionModalContainer";
import RefreshConfirm from "components/popup/RefreshConfirm";

const AllPopupList = ({ location }) =>
    location.pathname.indexOf("print") > -1 ? null : (
        <>
            {/* <CsInfoModal /> */}
            <InterruptionModalContainer />
            <RefreshConfirm />
        </>
    );

export default withRouter(AllPopupList);
