import { useState, useRef, useEffect } from "react";
import throttle from "lodash/throttle";

const useCheckMobile = () => {
    const resizeFn = useRef();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        resizeFn.current = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", throttle(resizeFn.current, 150));

        return () => {
            window.removeEventListener("resize", resizeFn.current);
        };
    }, []);

    return isMobile;
};

export default useCheckMobile;
