import React, { useState, useEffect } from "react";
import { IconFont } from "reviewshare-common-component";
import FormattedMessage from "components/common/FormattedMessage";
import ClickItem from "components/common/ClickItem";

import "./IEBanner.scss";

const IEBanner = () => {
    const [status, setStatus] = useState(false);

    useEffect(() => {
        if (document.cookie.indexOf("reviewshare_ie_banner_status=true") < 0) {
            setStatus(true);
        }
    }, []);

    const onCloseBanner = () => {
        document.cookie = "reviewshare_ie_banner_status=true; max-age=86400";
        setStatus(false);
    };

    return status ? (
        <>
            <div className="ie-browser-banner web">
                <div className="ie-browser-banner-inner">
                    <div className="caution-text">
                        <IconFont icon="icon_caution_fill">주의</IconFont>
                        <p>
                            <FormattedMessage id="message.caution.not_support_browser" />
                        </p>
                        <a
                            href="https://www.microsoft.com/ko-kr/edge?r=1"
                            target="_blank"
                            rel="noopener noreferrer nosublink"
                        >
                            MS Edge 다운로드
                        </a>
                    </div>
                    <ClickItem className="close-btn" onClick={onCloseBanner}>
                        <FormattedMessage id="action.dontLook.today" />
                        <IconFont icon="icon_close">
                            <FormattedMessage id="action.close" />
                        </IconFont>
                    </ClickItem>
                </div>
            </div>
        </>
    ) : null;
};
export default IEBanner;
