import React from "react";
import classnames from "classnames";
import { IconFont, Tag } from "reviewshare-common-component";
import FormattedMessage from "components/common/FormattedMessage";

import style from "./RelationStatusTag.module.scss";

const cx = classnames.bind(style);
const RelationStatusTag = ({ status, className }) => {
    if (!status) return null;
    return (
        <Tag className={cx(style.tag, className, "size-xs m-size-xs round")} color="gray-lighter">
            {status === "follow" && (
                <>
                    <IconFont icon="icon_check" /> <FormattedMessage id="status.relation.follow" />
                </>
            )}
            {status === "block" && (
                <>
                    <IconFont icon="icon_prohibition" />{" "}
                    <FormattedMessage id="status.relation.block" />
                </>
            )}
        </Tag>
    );
};

export default RelationStatusTag;
