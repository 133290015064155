import React, { useState, useRef, useEffect } from "react";
import { Profile, Spin, Segmented, SegmentedItem } from "reviewshare-common-component";
import { numberWithCommas } from "lib/common";
import { returnName } from "utils/messenger";
import useScrollEnd from "lib/customHooks/useScrollEnd";
import { useProgressItems } from "lib/customHooks/useProgress";
import { useUserType } from "lib/customHooks/useAuth";
import {
    useMessengerState,
    useBrandHistory,
    useMessengerAction
} from "lib/customHooks/useMessenger";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";
import EmptyContent from "components/messenger/side/EmptyContent";
import BrandHistoryItem from "components/messenger/main/BrandHistoryItem";
import RelationStatusBtn from "components/relation/common/RelationStatusBtn";

import "./BrandHistory.scss";

const BrandHistory = () => {
    const scrollElement = useRef();
    const isEndOfPage = useScrollEnd({ ref: scrollElement });

    const { getBrandHistory } = useMessengerAction();

    const userType = useUserType();
    const brandHistory = useBrandHistory();
    const selectedMessageBoxOptions = useMessengerState("selectedMessageBoxOptions");
    const isSearching = useProgressItems("messenger/GET_BRAND_HISTORY");

    const [category, setCategory] = useState("project");

    const {
        reviewer = {},
        brand = {},
        projectCounts,
        suggestionCounts,
        projectList = [],
        suggestionList = []
    } = brandHistory;

    const list = category === "suggestion" ? suggestionList : projectList;

    const onGetBrandHistory = skip => {
        getBrandHistory({
            reviewerId: selectedMessageBoxOptions.reviewerId,
            brandId: userType === "Seller" ? undefined : selectedMessageBoxOptions.brandId,
            category,
            skip
        });
    };

    useEffect(() => {
        onGetBrandHistory();
        // eslint-disable-next-line
    }, [category]);

    useEffect(() => {
        if (isEndOfPage) {
            if (category === "project" && projectCounts > list.length) {
                onGetBrandHistory(list.length);
            } else if (category === "suggestion" && suggestionCounts > list.length) {
                onGetBrandHistory(list.length);
            }
        }
        // eslint-disable-next-line
    }, [isEndOfPage]);

    return (
        <div className="messenger-main-brand-history" ref={scrollElement}>
            {reviewer.id || brand.id ? (
                <>
                    {userType === "Seller" ? (
                        <div className="reviewer-info">
                            <CommonLink to={`/portfolio/${reviewer.portfolioUrl}`}>
                                <Profile
                                    className="size-s m-size-l"
                                    noBand
                                    img={reviewer.profileImg}
                                    alt={`${returnName(
                                        reviewer.nickname,
                                        reviewer.isDeleted
                                    )}님의 프로필 사진`}
                                    disabled={!reviewer.portfolioUrl}
                                />
                            </CommonLink>
                            <div>
                                <CommonLink
                                    to={`/portfolio/${reviewer.portfolioUrl}`}
                                    className="reviewer-nickname"
                                    disabled={!reviewer.portfolioUrl}
                                >
                                    {returnName(reviewer.nickname, reviewer.isDeleted)}{" "}
                                    {reviewer.name && !reviewer.isDeleted && `(${reviewer.name})`}
                                </CommonLink>
                                <RelationStatusBtn
                                    className="size-xs m-size-xs"
                                    themeType="fill"
                                    relation={reviewer.relation}
                                    userId={reviewer.id}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="brand-info">
                            <p className="brand-name">{returnName(brand.name, brand.isDeleted)}</p>
                        </div>
                    )}
                    <div className="brand-history-counts">
                        <div className="brand-history-counts-item">
                            <p className="item-title">
                                <FormattedMessage id="item.project" />
                            </p>
                            <span className="item-counts-text">
                                <span className="item-counts">
                                    {numberWithCommas(projectCounts)}{" "}
                                </span>
                                회
                            </span>
                        </div>
                        <div className="brand-history-counts-item">
                            <p className="item-title">
                                <FormattedMessage id="item.suggestion" />
                            </p>
                            <span className="item-counts-text">
                                <span className="item-counts">
                                    {numberWithCommas(suggestionCounts)}{" "}
                                </span>
                                회
                            </span>
                        </div>
                    </div>

                    <Segmented
                        current={category}
                        className="brand-history-buttons round size-m m-size-m"
                    >
                        <SegmentedItem value="project" onClick={() => setCategory("project")}>
                            <FormattedMessage id="item.project" />
                        </SegmentedItem>
                        <SegmentedItem value="suggestion" onClick={() => setCategory("suggestion")}>
                            <FormattedMessage id="item.suggestion" />
                        </SegmentedItem>
                    </Segmented>
                    {isSearching && !list.length ? (
                        <Spin className="full" />
                    ) : list.length > 0 ? (
                        <ul className="brand-history-list">
                            {list.map((item, index) => (
                                <BrandHistoryItem key={index} data={item} category={category} />
                            ))}
                        </ul>
                    ) : !isSearching && !list.length ? (
                        <EmptyContent>참여 내역이 없어요.</EmptyContent>
                    ) : null}
                </>
            ) : (
                <Spin className="full" />
            )}
        </div>
    );
};
export default BrandHistory;
