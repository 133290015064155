import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { IconFont, Button, Profile } from "reviewshare-common-component";
import { MOBILE_MENU, MOBILE_QUICK_MENU } from "scheme/menu";
import { numberWithCommas } from "lib/common";
import useTranslate from "lib/customHooks/useTranslate";
import { useAuthAction } from "lib/customHooks/useAuth";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";
import GradeBadge from "components/common/GradeBadge";

import "./MobileUserMenu.scss";

const MobileUserMenu = ({
    user,
    isSeller,
    points,
    unread,
    burger,
    setBurger,
    history,
    location
}) => {
    const translate = useTranslate();
    const { logout } = useAuthAction();

    const [activeMenu, setActiveMenu] = useState(location.pathname);

    useEffect(() => {
        setActiveMenu(location.pathname);
    }, [location.pathname]);

    return (
        <div id="mobile-user-menu" className={cx({ active: burger })}>
            <div className="user-menu-background" onClick={() => setBurger(false)} />
            <div className="user-menu-content">
                <div className="top-area" />
                <div className="list header-quick-menu">
                    <div className="user-info">
                        {!isSeller && (
                            <Profile
                                className="m-size-m"
                                img={user.profile && user.profile.secure_url}
                                grade={user.score && user.score.grade}
                                blackUser={user.blackUser}
                                alt={translate("item.portfolio.profile.target", {
                                    name: user.nickname
                                })}
                                onClick={() => history.push("/mypage")}
                            />
                        )}
                        <div>
                            <p>
                                <FormattedMessage
                                    id={isSeller ? "target.biz" : "target.reviewer"}
                                    values={{ name: isSeller ? user?.name : user?.nickname }}
                                />
                            </p>
                            <ul className="menu-list">
                                <li>
                                    <CommonLink to={isSeller ? "/biz/myinfo" : "/myinfo"}>
                                        <FormattedMessage
                                            id={isSeller ? "menu.biz.info" : "menu.re.info"}
                                        />{" "}
                                        <IconFont icon="icon_arrow_right">
                                            <FormattedMessage id="action.view.more" />
                                        </IconFont>
                                    </CommonLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="etc-info-items">
                        {isSeller ? (
                            <div className="etc-info-item">
                                <span className="points">{numberWithCommas(points)}P</span>
                                <CommonLink
                                    to="/biz/payment"
                                    className="rs-button theme4 m-size-xxs round"
                                >
                                    <FormattedMessage id="action.view.list" />
                                </CommonLink>
                            </div>
                        ) : (
                            <>
                                <div className="etc-info-item column">
                                    <div className="user-grade">
                                        <GradeBadge
                                            grade={user.score?.grade}
                                            text
                                            className="size-l m-size-l"
                                        />
                                    </div>
                                    <CommonLink
                                        to="/score"
                                        className="rs-button theme4 m-size-xxs round"
                                    >
                                        <FormattedMessage id="menu.re.score+tier" />
                                    </CommonLink>
                                </div>
                                <div className="etc-info-item column">
                                    <CommonLink to="/mypoint" className="points">
                                        {numberWithCommas(points)}P
                                    </CommonLink>
                                    <CommonLink
                                        to="/mypoint"
                                        className="rs-button theme4 m-size-xxs round"
                                    >
                                        <FormattedMessage id="menu.re.points.list" />
                                    </CommonLink>
                                </div>
                            </>
                        )}
                    </div>
                    <ul className="quick-menu-list">
                        {MOBILE_QUICK_MENU[isSeller ? "business" : "reviewer"].map(
                            (item, index) => (
                                <li key={index} className={cx({ reviewer: !isSeller })}>
                                    <CommonLink
                                        to={
                                            item.external === "portfolio"
                                                ? `/portfolio/${user && user.portfolioUrl}`
                                                : item.path
                                        }
                                        rel="nosublink"
                                    >
                                        <div
                                            className={cx("image-wrapper", {
                                                "unread-mark":
                                                    (item.path === "/message" ||
                                                        item.path === "/biz/message") &&
                                                    !!unread
                                            })}
                                        >
                                            {item.icon && <img src={item.icon} alt={item.label} />}
                                            {item.iconFont && <IconFont icon={item.iconFont} />}
                                        </div>
                                        <p className="menu-title">
                                            <FormattedMessage id={item.label} />
                                        </p>
                                    </CommonLink>
                                </li>
                            )
                        )}
                    </ul>
                </div>
                <div className={`list menu-list ${isSeller ? "business" : ""}`}>
                    {MOBILE_MENU[isSeller ? "business" : "reviewer"].map((item, index) => {
                        const isActive = item.path.indexOf(activeMenu) > -1;

                        return (
                            <div
                                key={index}
                                className={cx("menu-item", { active: isActive })}
                                onClick={() => {
                                    if (!item.sub) {
                                        history.push(item.path[0]);
                                    } else if (isActive) {
                                        setActiveMenu("");
                                    } else {
                                        setActiveMenu(item.path[0]);
                                    }
                                }}
                            >
                                <div className="menu-title">
                                    <div className="flex align-center">
                                        <img
                                            src={(() => {
                                                if (isActive) {
                                                    return item.activeIcon;
                                                }
                                                return index === activeMenu
                                                    ? item.activeIcon
                                                    : item.icon;
                                            })()}
                                            alt={`${translate(item.label)} 아이콘`}
                                        />
                                        <FormattedMessage id={item.label} />
                                    </div>
                                    {item.sub && (
                                        <IconFont
                                            icon={isActive ? "icon_arrow_up" : "icon_arrow_down"}
                                        >
                                            <FormattedMessage id="action.view.more" />
                                        </IconFont>
                                    )}
                                </div>
                                {item.sub && (
                                    <div className="menu-content">
                                        {item.sub.map((val, itemIndex) => (
                                            <CommonLink to={val.path} key={itemIndex}>
                                                <FormattedMessage id={val.label} />
                                            </CommonLink>
                                        ))}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="list other-links">
                    <CommonLink className="link-title" to="/support">
                        <div>
                            <IconFont icon="icon_customerservice" />
                            <FormattedMessage id="menu.cs" />
                        </div>
                    </CommonLink>
                    <CommonLink className="link-title" to="/harm">
                        <div>
                            <IconFont icon="icon_declaration" />
                            <FormattedMessage id="menu.cs.harm" />
                        </div>
                    </CommonLink>
                    {isSeller ? (
                        <>
                            <CommonLink
                                className="link-title"
                                to="https://blog.naver.com/reviewshare"
                                external
                            >
                                <div>
                                    <IconFont icon="icon_question_circle" />
                                    <FormattedMessage id="menu.biz.guide" />
                                </div>
                            </CommonLink>
                            <CommonLink className="link-title" to="/bizintro">
                                <div>
                                    <IconFont icon="icon_intro" />
                                    <FormattedMessage id="menu.biz.intro" />
                                </div>
                            </CommonLink>
                        </>
                    ) : (
                        <CommonLink
                            className="link-title"
                            to="https://blog.naver.com/reviewshare"
                            external
                        >
                            <div>
                                <IconFont icon="icon_question_circle" />
                                <FormattedMessage id="menu.re.guide" />
                            </div>
                        </CommonLink>
                    )}
                </div>
                <div className="logout-area">
                    <Button className="theme6 m-size-m" onClick={logout}>
                        <IconFont icon="icon_unlock" />
                        <FormattedMessage id="action.signout" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default withRouter(MobileUserMenu);
