import { createAction, handleActions } from "redux-actions";

import { createRequestActionTypes } from "lib/createRequestSaga";

export const [
    GET_ACTIVITY_COUNTS,
    GET_ACTIVITY_COUNTS_SUCCESS,
    GET_ACTIVITY_COUNTS_FAILURE
] = createRequestActionTypes("mypage/GET_ACTIVITY_COUNTS");
export const [
    GET_TALENT_COUNTS,
    GET_TALENT_COUNTS_SUCCESS,
    GET_TALENT_COUNTS_FAILURE
] = createRequestActionTypes("mypage/GET_TALENT_COUNTS");

// activity
export const [
    GET_MY_ACTIVITY,
    GET_MY_ACTIVITY_SUCCESS,
    GET_MY_ACTIVITY_FAILURE
] = createRequestActionTypes("mypage/GET_MY_ACTIVITY");
export const [
    GET_RECOMMEND_HISTORY,
    GET_RECOMMEND_HISTORY_SUCCESS,
    GET_RECOMMEND_HISTORY_FAILURE
] = createRequestActionTypes("mypage/GET_RECOMMEND_HISTORY");
export const [
    GET_OPINION_LIST,
    GET_OPINION_LIST_SUCCESS,
    GET_OPINION_LIST_FAILURE
] = createRequestActionTypes("mypage/GET_OPINION_LIST");
export const [
    GET_PROJECT_COUNTS_BY_SNS,
    GET_PROJECT_COUNTS_BY_SNS_SUCCESS,
    GET_PROJECT_COUNTS_BY_SNS_FAILURE
] = createRequestActionTypes("mypage/GET_PROJECT_COUNTS_BY_SNS");

export const getActivityCounts = createAction(GET_ACTIVITY_COUNTS);
export const getTalentCounts = createAction(GET_TALENT_COUNTS);

// activity
export const getMyActivity = createAction(GET_MY_ACTIVITY);
export const getRecommendHistory = createAction(GET_RECOMMEND_HISTORY);
export const getOpinionList = createAction(GET_OPINION_LIST);
export const getProjectCountsBySns = createAction(GET_PROJECT_COUNTS_BY_SNS);

const initialState = {
    feedCounts: 0,
    activityCounts: {
        chosenProject: 0,
        suggestion: 0,
        review: 0,
        content: 0
    },
    talentCounts: {
        service: {
            counts: 0
        },
        request: {
            counts: 0
        },
        running: {
            counts: 0
        },
        complete: {
            counts: 0
        }
    },

    // activity
    activity: {
        totalScore: 0,
        totalProcessedProjectCounts: 0,
        opinions: {
            recommend: 0,
            message: 0
        },
        submissionRate: 0,
        projectCancelCounts: 0,
        unsubmitCounts: 0
    },
    opinionInfo: {
        totalProcessedProjectCounts: 0,
        totalRecommendCounts: 0,
        totalBizCounts: 0,
        totalMessageCounts: 0,
        recommendRate: 0,
        recommendList: [],
        messageList: []
    },
    projectCountsBySns: {
        totalCounts: 0,
        list: []
    }
};

const mypage = handleActions(
    {
        [GET_ACTIVITY_COUNTS_SUCCESS]: (state, { payload }) => ({
            ...state,
            activityCounts: payload
        }),
        [GET_TALENT_COUNTS_SUCCESS]: (state, { payload }) => ({
            ...state,
            talentCounts: payload
        }),
        [GET_MY_ACTIVITY_SUCCESS]: (state, { payload }) => ({
            ...state,
            activity: {
                totalScore: payload.totalScore,
                totalProcessedProjectCounts: payload.totalProcessedProjectCounts,
                opinions: payload.opinions,
                submissionRate: payload.submissionRate,
                projectCancelCounts: payload.projectCancelCounts,
                unsubmitCounts: payload.unsubmitCounts
            }
        }),
        [GET_RECOMMEND_HISTORY_SUCCESS]: (state, { payload }) => ({
            ...state,
            opinionInfo: {
                ...state.opinionInfo,
                totalProcessedProjectCounts: payload.totalProcessedProjectCounts,
                totalRecommendCounts: payload.totalCounts,
                recommendList: payload.list,
                recommendRate: payload.recommendRate
            }
        }),
        [GET_OPINION_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            opinionInfo: {
                ...state.opinionInfo,
                totalBizCounts: payload.totalBizCounts,
                totalMessageCounts: payload.totalCounts,
                messageList: payload.list
            }
        }),
        [GET_PROJECT_COUNTS_BY_SNS_SUCCESS]: (state, { payload }) => ({
            ...state,
            projectCountsBySns: {
                totalCounts: payload.totalCounts,
                list: payload.list
            }
        })
    },
    initialState
);

export default mypage;
