import moment from "moment";

export const returnReceiverType = userType => {
    if (userType === "Seller") {
        return "리뷰어";
    }
    return "브랜드";
};

export const returnDateText = (date, type = "date") => {
    if (moment().isSame(date, "day"))
        return type === "time"
            ? `${moment(date).format("A") === "PM" ? "오후" : "오전"} ${moment(date).format(
                  "hh:mm"
              )}`
            : "오늘";
    if (moment().isSame(date, "year")) return moment(date).format("MM월 DD일");

    return moment(date).format("YYYY년 MM월 DD일");
};

export const returnName = (name, isDeleted) => {
    const USER_NONE_TEXT = "알 수 없음";

    if (isDeleted || !name) return USER_NONE_TEXT;
    return name;
};

export const returnWriteInfo = ({ from, toList }) => {
    let _from = {};
    let _toList = [];
    let filteredFrom = {};
    let filteredToList = [];
    let isPossibleSend = false;

    if (from) {
        const {
            brandId,
            brandName,
            reviewerId,
            nickname,
            profileImg,
            isDeleted,
            isDeletedBrand
        } = from;

        if (brandId) {
            _from = { brandId, brandName, isDeleted: isDeleted || isDeletedBrand };
        } else if (reviewerId) {
            _from = {
                reviewerId,
                nickname,
                profileImg
            };
        }

        if (!_from.isDeleted) {
            filteredFrom = _from;
        }
    }

    if (toList && toList.length > 0) {
        _toList = toList.filter(to => {
            const { reviewerId, brandId } = to;

            return reviewerId || brandId;
        });

        if (_toList.length > 0) {
            filteredToList = _toList.filter(to => {
                const { isDeleted, isDeletedReviewer, isDeletedBrand } = to;
                if (isDeleted || isDeletedReviewer || isDeletedBrand) return false;
                return true;
            });
        }
    }

    if (Object.keys(filteredFrom).length > 0 && filteredToList.length > 0) {
        isPossibleSend = true;
    }

    return { from: _from, toList: _toList, filteredFrom, filteredToList, isPossibleSend };
};

export const addNewStatusToLastMessage = list => {
    if (!list || !list.length) return list;

    return list.map((item, index) => {
        if (index === list.length - 1) {
            return { ...item, isNew: true };
        } else {
            return item;
        }
    });
};
