import React from "react";
import { Link, useHistory } from "react-router-dom";
import { IconFont, Button, Tabs, TabItem } from "reviewshare-common-component";
import cx from "classnames";
import useSticky from "lib/customHooks/useSticky";
import { BUSINESS_CENTER_SIDE_MENU } from "scheme/menu";
import FormattedMessage from "components/common/FormattedMessage";
import ClickItem from "components/common/ClickItem";

import "./SideMenu.scss";

const SideMenu = ({ pathValue, subListState, handleSubListState, urlPathName }) => {
    useSticky("#global-header-start-position", ".biz-center-side-menu");
    const history = useHistory();
    const selectedMenu = BUSINESS_CENTER_SIDE_MENU[pathValue];

    const onMoveHistory = ({ link, value }) => {
        if (link) {
            history.push(link);
        } else if (value) {
            handleSubListState({
                ...subListState,
                [value]: !subListState[value]
            });
        }
    };

    return (
        <>
            <div className="biz-center-side-menu">
                <div className="top-button-area">
                    <Link
                        to="/biz/create/1"
                        className="rs-button theme1 size-l w-size-m create-project-btn"
                    >
                        <FormattedMessage id="menu.biz.project.create" />{" "}
                        <IconFont icon="icon_plus" />
                    </Link>
                </div>
                <ul className="main-menu-list">
                    {Object.keys(BUSINESS_CENTER_SIDE_MENU).map((menuKey, index) => {
                        const item = BUSINESS_CENTER_SIDE_MENU[menuKey];
                        const isActive = !!subListState[menuKey];
                        const isAcitveM = pathValue === menuKey;

                        return (
                            <li key={index} className={cx("main-menu-item", { active: isAcitveM })}>
                                <ClickItem
                                    id={item.id}
                                    className="main-menu-collapse"
                                    onClick={() =>
                                        onMoveHistory({
                                            link: item.link,
                                            value: menuKey
                                        })
                                    }
                                >
                                    <h3>
                                        <FormattedMessage id={item.title} />
                                        {item.new && (
                                            <IconFont icon="icon_new_mark_circle">new</IconFont>
                                        )}
                                    </h3>
                                    {item.subList && !item.subIsOnlyMobile && (
                                        <IconFont
                                            icon={isActive ? "icon_arrow_up" : "icon_arrow_down"}
                                        >
                                            <FormattedMessage id="action.view.more" />
                                        </IconFont>
                                    )}
                                </ClickItem>
                                <Button
                                    className={cx("main-menu-btn round m-size-s", {
                                        theme5: isAcitveM,
                                        theme4: !isAcitveM
                                    })}
                                    onClick={() =>
                                        onMoveHistory({
                                            link: item.link || item.subList[0].value,
                                            value: menuKey
                                        })
                                    }
                                >
                                    <FormattedMessage id={item.title} />
                                    {item.new && (
                                        <IconFont icon="icon_new_mark_circle">new</IconFont>
                                    )}
                                </Button>
                                {isActive && item.subList && !item.subIsOnlyMobile && (
                                    <ul className={cx("sub-menu-list", item.value)}>
                                        {item.subList.map((subList, subIndex) => (
                                            <li key={`sub_${subIndex}`}>
                                                <ClickItem
                                                    id={subList.id}
                                                    className={cx("sub-menu-item", {
                                                        active: urlPathName === subList.value
                                                    })}
                                                    onClick={() => {
                                                        subList.link
                                                            ? window.location.assign(
                                                                  process.env
                                                                      .REACT_APP_MAIN_SERVICE_URL +
                                                                      subList.link
                                                              )
                                                            : history.push(subList.value);
                                                    }}
                                                >
                                                    <FormattedMessage id={subList.label} />
                                                </ClickItem>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>

            {selectedMenu && selectedMenu.subList && (
                <div className="biz-center-side-sub-menu">
                    <Tabs current={urlPathName}>
                        {selectedMenu.subList.map((item, subIndex) => (
                            <TabItem key={subIndex} value={item.value}>
                                <Link to={item.value}>
                                    <span className={cx({ web: item.mobileLabel })}>
                                        <FormattedMessage id={item.label} />
                                    </span>
                                    {item.mobileLabel && (
                                        <span className="mobile">{item.mobileLabel}</span>
                                    )}
                                </Link>
                            </TabItem>
                        ))}
                    </Tabs>
                </div>
            )}
        </>
    );
};

export default SideMenu;
