import client from "./client";

export const getManagedProjectList = ({ projectStatus, type, brandId, limit, skip }) =>
    client.get("/campaign/managedProjectList", {
        params: {
            projectStatus,
            type,
            brandId,
            limit,
            skip
        }
    });

export const getManagedProjectCount = () => client.get(`/campaign/projectCount`);

export const getProjectsByBrand = brandId => client.get(`/v2/projects/brand/${brandId}`);

export const getRereviewingProject = projectId =>
    client.get(`/v3/campaigns/${projectId}/rereviewing`);
export const getRereviewingValueProject = projectId =>
    client.get(`/v3/campaigns/${projectId}/rereviewing-values`);
export const getTempProjectList = () => client.get("/v2/temp_campaigns");

export const saveTempProject = obj => client.post("/v2/temp_campaign", obj);

export const createProject = obj => client.post("/campaign", obj);
export const requestRereviewingProject = obj => client.post("/v3/campaigns/rereviewing", obj);

export const cancelRereviewingProject = projectId =>
    client.delete(`/v3/campaigns/${projectId}/rereviewing`);
export const deleteProject = projectId => client.delete(`/v3/campaigns/${projectId}`);
export const deleteTempProject = id =>
    client.delete("/v2/temp_campaign", { data: { campaignId: id } });

export const getProjectByMoid = moid => client.get(`/v2/projects/moid/${moid}`);

export const getRequestReviewer = ({ projectId, isChosen }) =>
    client.get(`/v2/projects/${projectId}/users?isChosen=${isChosen}`);

export const getBusinessProjectList = () => client.get(`/v2/users/projects`);

export const getMainProjectList = () => client.get("/projects/main");

export const getProjectList = ({
    keyword,
    type,
    ecategory,
    pcategory,
    sns,
    service,
    status = "true",
    sort,
    skip
}) =>
    client.get("/projects/search", {
        params: {
            keyword,
            type,
            ecategory,
            pcategory,
            sns,
            service,
            status,
            sort,
            skip
        }
    });

export const getRecommendProjectList = (query = {}) =>
    client.get("/v3/campaigns/pick", { params: query });

export const getProject = id => {
    return client.get(`/v2/projects/${id}`);
};

export const applyProject = obj => {
    return client.post("/campaign/participate", obj);
};

export const cancelProject = projectId =>
    client.post("/campaign/cancelParticipate", { campaign: projectId });

export const changeChoiseDay = ({ projectId, data }) =>
    client.patch(`/campaigns/choiseDay/${projectId}`, data);

export const changeSubmitEndDay = ({ projectId, newSubmitEndDay }) =>
    client.patch(`/campaigns/submitEndDay/${projectId}`, { newSubmitEndDay });

export const chosenComplete = projectId => client.patch(`/campaigns/${projectId}/chosenComplete`);
export const evaluateItems = obj => client.post("/v2/campaign/evaluateItems", obj);

export const getReportPdf = projectId => client.post(`/campaigns/print/${projectId}`);
