import client from "./client";

export const createSuggestion = obj => client.post("/v2/suggestions", obj);
export const getRecentSuggestion = () => client.get("/v2/suggestions/recent");
export const getSuggestion = suggestionId => client.get(`/v2/suggestions/info/${suggestionId}`);
export const getResult = suggestionId => client.get(`/v2/suggestions/${suggestionId}/results`);
export const getReviewerInfo = id => client.get(`/v2/suggestions/reviewer/${id}`);
export const getSuggestionListOfBusiness = ({ skip, limit, status, subStatus, sort }) =>
    client.get(
        `/v2/suggestions/list/business?skip=${skip || 0}&limit=${limit || 20}${
            status ? `&status=${status}` : ""
        }${subStatus ? `&subStatus=${subStatus}` : ""}${sort ? `&sort=${sort}` : ""}`
    );
export const getSuggestionListOfReviewer = ({ skip, limit, status, sort }) =>
    client.get(
        `/v2/suggestions/list/reviewer?skip=${skip || 0}&limit=${limit || 20}${
            status ? `&status=${status}` : ""
        }${sort ? `&sort=${sort}` : ""}`
    );
export const getPrevSuggestionList = ({ brandId }) =>
    client.get("/v2/suggestions/recent", { params: { brandId } });
export const cancelSuggestion = suggestionId =>
    client.delete(`/v2/suggestions/cancel/${suggestionId}`);
export const acceptSuggestion = ({ suggestionId, message }) =>
    client.put("/v2/suggestions/accept", { suggestionId, message });
export const rejectSuggestion = obj => client.put("/v2/suggestions/reject", obj);
export const submitResult = obj => client.put("/v2/suggestions/submit/review", obj);
export const downloadResult = suggestionId =>
    client.get(`/v2/suggestions/${suggestionId}/file-zip-url`);
export const changeSubmitEndDay = ({ suggestionId, submitEndAt }) =>
    client.patch(`/v3/suggestions/${suggestionId}/submitEndDay`, { submitEndAt });
export const changeStatusTocomplete = suggestionId =>
    client.patch(`/v3/suggestions/${suggestionId}/complete`);
