import { call, put } from "redux-saga/effects";
import { showLoading, hideLoading } from "stores/modules/loading";
import { handleProgressItem } from "stores/modules/progress";
import * as Sentry from "@sentry/browser";
import { OPEN_ALERT } from "stores/modules/alert";
import { returnAlertName } from "lib/common";
import { uploadResult as uploadResultAPI } from "lib/api/common";

export const createRequestActionTypes = type => {
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;
    return [type, SUCCESS, FAILURE];
};

export default function createRequestSaga(type, request, loading) {
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;

    return function*(action) {
        if (loading) {
            yield put(showLoading({ type: action.type }));
        }
        try {
            const response = yield call(request, action.payload);
            yield put({
                type: SUCCESS,
                payload: response.data,
                meta: response,
                prev: action.payload
            });
        } catch (e) {
            Sentry.withScope(scope => {
                scope.setExtra("Action Type", action.type);
                scope.setExtra("Data", e.response);
                Sentry.captureMessage(action.type);
            });
            yield put({
                type: FAILURE,
                payload: e,
                error: true
            });
        }

        if (loading) {
            yield put(hideLoading({ type: action.type }));
        }
    };
}

export const createRequestSagaWithAlert = (type, request, errorMsg, loading) => {
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;

    return function*(action) {
        if (loading) {
            yield put(showLoading({ type: action.type }));
        }
        try {
            const response = yield call(request, action.payload);
            yield put({
                type: SUCCESS,
                payload: response.data,
                meta: response,
                prev: action.payload
            });
        } catch (error) {
            yield put({
                type: OPEN_ALERT,
                payload: { text: errorMsg }
            });
            yield put({
                type: FAILURE,
                payload: { error: error.response }
            });
        }

        if (loading) {
            yield put(hideLoading({ type: action.type }));
        }
    };
};

export const createRequestSagaBase = ({
    type,
    api,
    alertSuccessMsg,
    alertSuccessPath,
    alertErrorMsg,
    alertErrorPath,
    loading,
    progress
}) => {
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;
    const alertName = returnAlertName(type);

    return function*(action) {
        try {
            if (loading) {
                yield put(showLoading({ type: action.type }));
            }
            if (progress) {
                yield put(handleProgressItem({ type: action.type, value: true }));
            }

            const response = yield call(api, action.payload);
            yield put({
                type: SUCCESS,
                payload: response.data,
                meta: response,
                prev: action.payload
            });
            if (alertSuccessMsg) {
                yield put({
                    type: OPEN_ALERT,
                    payload: {
                        name: `${alertName}-success`,
                        text: alertSuccessMsg,
                        path: alertSuccessPath
                    }
                });
            }
        } catch (error) {
            if (alertErrorMsg) {
                yield put({
                    type: OPEN_ALERT,
                    payload: {
                        name: `${alertName}-failure`,
                        text: alertErrorMsg,
                        path: alertErrorPath
                    }
                });
            }
            yield put({
                type: FAILURE,
                payload: { error: error.response },
                prev: action.payload
            });
        } finally {
            if (loading) {
                yield put(hideLoading({ type: action.type }));
            }
            if (progress) {
                yield put(handleProgressItem({ type: action.type, value: false }));
            }
        }
    };
};

export const uploadAndProcessFilesToS3Saga = ({ fileList, uploadAPI, isDownload }) => {
    let fileKeyList = null;

    return function*() {
        if (fileList?.length) {
            const { data: keyList } = yield call(uploadAPI, {
                fileList: fileList.map(file => ({ name: file.name, mimeType: file.mimeType }))
            });

            for (let index = 0; index < fileList.length; index++) {
                const item = keyList?.[index];
                const { file } = fileList[index];
                yield call(uploadResultAPI, {
                    url: item?.url,
                    file,
                    type: file.type,
                    ...(isDownload && {
                        contentDisposition: `attachment; filename*=UTF-8''${encodeURIComponent(
                            file.name
                        )}`
                    })
                });
            }
            fileKeyList = [...keyList];
        }

        return fileKeyList;
    };
};
