import { useDispatch, useSelector } from "react-redux";
import { removeStorage } from "lib/storage";
import { updateUserInfo } from "stores/modules/auth";

// state
export const useAuthState = value => useSelector(({ auth }) => auth[value]);
export const useUserInfo = value =>
    useSelector(({ auth }) =>
        value ? auth.userInfo.user && auth.userInfo.user[value] : auth.userInfo.user || {}
    );
export const useUserType = () => useSelector(({ auth }) => auth.userType);

// action
export const useAuthAction = () => {
    const dispatch = useDispatch();

    const onEditUserInfo = obj => {
        dispatch(updateUserInfo({ obj }));
    };

    const onLogout = () => {
        removeStorage("reviewshare-at");
        removeStorage("reviewshare-re-at");
        window.location.href = "/";
    };

    return { editUserInfo: onEditUserInfo, logout: onLogout };
};
