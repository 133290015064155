import React from "react";

const ClickItem = ({ onClick, onKeyDown, children, tag = "div", ...rest }) => (
    <>
        {tag === "span" ? (
            <span
                tabIndex={0}
                role="button"
                onClick={onClick}
                onKeyDown={e => e.keyCode === 13 && onClick()}
                style={{ cursor: "pointer" }}
                {...rest}
            >
                {children}
            </span>
        ) : (
            <div
                tabIndex={0}
                role="button"
                onClick={onClick}
                onKeyDown={e => e.keyCode === 13 && onClick()}
                style={{ cursor: "pointer" }}
                {...rest}
            >
                {children}
            </div>
        )}
    </>
);
export default ClickItem;
