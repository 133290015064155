import React, { Suspense } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { getStorage } from "lib/storage";
import BizPageTemplate from "components/base/templates/BizPageTemplate";

const BizPrivateRoute = ({ ...rest }) => {
    const location = useLocation();
    const { pathname } = location;
    const convertPathnameIntoArray = pathname.slice(1).split("/");
    const isLogin = getStorage("reviewshare-at");
    const { offTemplate, bottomTabBar } = rest;
    return (
        <>
            {isLogin ? (
                offTemplate ? (
                    <Suspense fallback={null}>
                        <Route {...rest} />
                    </Suspense>
                ) : (
                    <BizPageTemplate bottomTabBar={bottomTabBar}>
                        <Route {...rest} />
                    </BizPageTemplate>
                )
            ) : (
                <Redirect
                    to={{
                        pathname: `/login/Seller${
                            pathname ? `?returnTo=${convertPathnameIntoArray.join("_")}` : ""
                        }`
                    }}
                />
            )}
        </>
    );
};

export default BizPrivateRoute;
