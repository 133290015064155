import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import BizPrivateRoute from "containers/core/BizPrivateRoute";
import PrivateRoute from "containers/core/PrivateRoute";
import PublicRoute from "containers/core/PublicRoute";
import CheckLoginContainer from "containers/core/CheckLoginContainer";
import BizIntroPageTemplate from "components/base/templates/BizIntroPageTemplate";

// public

const GlobalMainPage = lazy(() => import("pages/GlobalMainPage"));
const ProjectPage = lazy(() => import("pages/ProjectPage"));
const ProjectListPage = lazy(() => import("pages/ProjectListPage"));
const PortfolioPage = lazy(() => import("pages/PortfolioPage"));
const PortfolioListPage = lazy(() => import("pages/PortfolioListPage"));
const TalentPage = lazy(() => import("pages/TalentPage"));
const TalentListPage = lazy(() => import("pages/TalentListPage"));
const FeedPage = lazy(() => import("pages/FeedPage"));
const NotFoundPage = lazy(() => import("pages/NotFoundPage"));

const RegisterPage = lazy(() => import("pages/RegisterPage"));
const RegisterCompletePage = lazy(() => import("pages/RegisterCompletePage"));
const SignInPage = lazy(() => import("pages/SignInPage"));
const SignInResultPage = lazy(() => import("pages/SignInResultPage"));
const FindAccountPage = lazy(() => import("pages/FindAccountPage"));
const PrivacyPolicyPage = lazy(() => import("pages/PrivacyPolicyPage"));
const TermsPage = lazy(() => import("pages/TermsPage"));
const HelpPage = lazy(() => import("pages/HelpPage"));
const ReviewerIntroPage = lazy(() => import("pages/ReviewerIntroPage"));
const BizIntroPage = lazy(() => import("pages/BizIntroPage"));
const BizIntroPartnerPage = lazy(() => import("pages/BizIntroPartnerPage"));
const BizSuggestListPage = lazy(() => import("pages/BizSuggestListPage"));

// reviewer + biz
const LeavePage = lazy(() => import("pages/LeavePage"));
const LeaveCompletePage = lazy(() => import("pages/LeaveCompletePage"));
const HarmReportPage = lazy(() => import("pages/HarmReportPage"));
const AlarmPage = lazy(() => import("pages/AlarmPage"));

// reviewer
const ReviewerMypage = lazy(() => import("pages/ReviewerMypage"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));
const MyInfoModifyPage = lazy(() => import("pages/MyInfoModifyPage"));
const MyActivityPage = lazy(() => import("pages/MyActivityPage"));
const ScoreHistoryPage = lazy(() => import("pages/ScoreHistoryPage"));
const PointHistoryPage = lazy(() => import("pages/PointHistoryPage"));
const MoneyWithdrawPage = lazy(() => import("pages/MoneyWithdrawPage"));
const ApplyPage = lazy(() => import("pages/ApplyPage"));
const CreateTalentPage = lazy(() => import("pages/CreateTalentPage"));
const CreateTalentCompletePage = lazy(() => import("pages/CreateTalentCompletePage"));
const MyTalentPage = lazy(() => import("pages/MyTalentPage"));
const ReviewerOrderDetailPage = lazy(() => import("pages/ReviewerOrderDetailPage"));
const AdditionInfoAfterRegisterPage = lazy(() => import("pages/AdditionInfoAfterRegisterPage"));

// biz
const BizMainPage = lazy(() => import("pages/BizMainPage"));
const BizMyInfoPage = lazy(() => import("pages/BizMyInfoPage"));
const BizSelectPlanPage = lazy(() => import("pages/BizSelectPlanPage"));
const BizManagePlanPage = lazy(() => import("pages/BizManagePlanPage"));
const PaymentHistoryPage = lazy(() => import("pages/PaymentHistoryPage"));
const ChargePointPage = lazy(() => import("pages/ChargePointPage"));
const RelationPage = lazy(() => import("pages/RelationPage"));
const CreateProjectPage = lazy(() => import("pages/CreateProjectPage"));
const EditProjectPage = lazy(() => import("pages/EditProjectPage"));
const PreviewPage = lazy(() => import("pages/PreviewPage"));
const ProjectManagePage = lazy(() => import("pages/ProjectManagePage"));
const NewReportPage = lazy(() => import("pages/NewReportPage"));
const ApplyTalentPage = lazy(() => import("pages/ApplyTalentPage"));
const ApplyTalentCompletePage = lazy(() => import("pages/ApplyTalentCompletePage"));
const BizManageOrderPage = lazy(() => import("pages/BizManageOrderPage"));
const BrandPage = lazy(() => import("pages/BrandPage"));
const PrintReportPage = lazy(() => import("pages/PrintReportPage"));
const PrintPricingPage = lazy(() => import("pages/PrintPricingPage"));

export default () => (
    <Switch>
        <PublicRoute exact path="/" bottomTabBar>
            <GlobalMainPage />
        </PublicRoute>
        <PublicRoute exact path="/404">
            <NotFoundPage />
        </PublicRoute>
        <PublicRoute exact path="/project" bottomTabBar>
            <ProjectListPage />
        </PublicRoute>
        <PublicRoute exact path="/project/:projectId" customMobileHeader>
            <ProjectPage />
        </PublicRoute>
        <PublicRoute exact path="/feed" bottomTabBar>
            <FeedPage />
        </PublicRoute>
        <PublicRoute exact path="/portfolio" bottomTabBar>
            <PortfolioListPage />
        </PublicRoute>
        <PublicRoute path="/portfolio/:id" customMobileHeader>
            <PortfolioPage />
        </PublicRoute>
        <PublicRoute exact path="/talent" bottomTabBar>
            <TalentListPage />
        </PublicRoute>
        <PublicRoute path="/talent/:talentId" customMobileHeader>
            <TalentPage />
        </PublicRoute>
        <PublicRoute exact path="/register">
            <RegisterPage />
        </PublicRoute>
        <PublicRoute exact path="/registerComplete">
            <RegisterCompletePage />
        </PublicRoute>
        <PublicRoute exact path="/signin">
            <SignInPage />
        </PublicRoute>
        <PublicRoute exact path="/signinResult">
            <SignInResultPage />
        </PublicRoute>
        <PublicRoute path="/myinfo/leaveComplete" customMobileHeader>
            <LeaveCompletePage />
        </PublicRoute>
        <PublicRoute path="/find/:type">
            <FindAccountPage />
        </PublicRoute>
        <PublicRoute exact path="/privacypolicy" customMobileHeader>
            <PrivacyPolicyPage />
        </PublicRoute>
        <PublicRoute exact path="/tos" customMobileHeader>
            <TermsPage />
        </PublicRoute>
        <PublicRoute path="/support" customMobileHeader bottomTabBar>
            <HelpPage />
        </PublicRoute>
        <PrivateRoute exact path="/mypage" hasHeader bottomTabBar>
            <ReviewerMypage />
        </PrivateRoute>
        <PrivateRoute exact path="/profile/:type">
            <ProfilePage />
        </PrivateRoute>
        <PrivateRoute exact path="/mypage/:type" bottomTabBar>
            <MyActivityPage />
        </PrivateRoute>
        <PrivateRoute exact path="/mypage/:type/:status" bottomTabBar>
            <MyActivityPage />
        </PrivateRoute>
        <PrivateRoute exact path="/myinfo">
            <MyInfoModifyPage />
        </PrivateRoute>
        <PrivateRoute exact path="/myinfo/leave" userType="All">
            <LeavePage />
        </PrivateRoute>
        <PrivateRoute exact path="/mypoint">
            <PointHistoryPage />
        </PrivateRoute>
        <PrivateRoute exact path="/withdraw">
            <MoneyWithdrawPage />
        </PrivateRoute>
        <PrivateRoute exact path="/harm" userType="All">
            <HarmReportPage />
        </PrivateRoute>
        <PrivateRoute exact path="/score">
            <ScoreHistoryPage />
        </PrivateRoute>
        <PrivateRoute exact path="/apply/project/:step">
            <ApplyPage />
        </PrivateRoute>
        <PrivateRoute exact path="/create/talent">
            <CreateTalentPage />
        </PrivateRoute>
        <PrivateRoute exact path="/create/talent/complete">
            <CreateTalentCompletePage />
        </PrivateRoute>
        <PrivateRoute exact path="/mytalent/:type">
            <MyTalentPage />
        </PrivateRoute>
        <PrivateRoute exact path="/mytalent/order/:orderId">
            <ReviewerOrderDetailPage />
        </PrivateRoute>
        <PrivateRoute exact path="/alarm" userType="All">
            <AlarmPage />
        </PrivateRoute>
        <PrivateRoute exact path="/apply/talent" userType="Seller">
            <ApplyTalentPage />
        </PrivateRoute>
        <PrivateRoute exact path="/apply/talent/complete" userType="Seller">
            <ApplyTalentCompletePage />
        </PrivateRoute>
        <PrivateRoute exact path="/registerComplete/:additionInfoStep" userType="Normal">
            <AdditionInfoAfterRegisterPage />
        </PrivateRoute>
        <BizPrivateRoute exact path="/biz/main" bottomTabBar>
            <BizMainPage />
        </BizPrivateRoute>
        <BizPrivateRoute exact path="/biz/create/:step">
            <CreateProjectPage />
        </BizPrivateRoute>
        <BizPrivateRoute exact path="/biz/project/edit/:step">
            <EditProjectPage />
        </BizPrivateRoute>
        <BizPrivateRoute exact path="/biz/manage/:projectStatus">
            <ProjectManagePage />
        </BizPrivateRoute>
        <BizPrivateRoute exact path="/biz/brand/:state">
            <BrandPage />
        </BizPrivateRoute>
        <BizPrivateRoute exact path="/biz/payment">
            <PaymentHistoryPage />
        </BizPrivateRoute>
        <BizPrivateRoute exact path="/biz/chargepoint">
            <ChargePointPage />
        </BizPrivateRoute>
        <BizPrivateRoute path="/biz/myinfo">
            <BizMyInfoPage />
        </BizPrivateRoute>
        <BizPrivateRoute path="/biz/suggestion/:status">
            <BizSuggestListPage />
        </BizPrivateRoute>
        <BizPrivateRoute path="/biz/plan/select">
            <BizSelectPlanPage />
        </BizPrivateRoute>
        <BizPrivateRoute path="/biz/plan/manage">
            <BizManagePlanPage />
        </BizPrivateRoute>
        <BizPrivateRoute path="/biz/order/:status">
            <BizManageOrderPage />
        </BizPrivateRoute>
        <BizPrivateRoute exact path="/biz/relation/:state">
            <RelationPage />
        </BizPrivateRoute>
        <BizPrivateRoute path="/report/:projectId" offTemplate>
            <NewReportPage />
        </BizPrivateRoute>
        <Route exact path={["/login", "/login/:userType"]}>
            <Suspense fallback={null}>
                <CheckLoginContainer />
            </Suspense>
        </Route>
        <Route exact path="/intro">
            <Suspense fallback={null}>
                <ReviewerIntroPage />
            </Suspense>
        </Route>
        <Route exact path="/biz/preview">
            <Suspense fallback={null}>
                <PreviewPage />
            </Suspense>
        </Route>
        <Route exact path="/bizintro">
            <BizIntroPageTemplate type="self">
                <BizIntroPage />
            </BizIntroPageTemplate>
        </Route>
        <Route exact path="/bizintro/partner">
            <BizIntroPageTemplate type="partner">
                <BizIntroPartnerPage />
            </BizIntroPageTemplate>
        </Route>
        <Route path="/print-report-page/:projectId">
            <Suspense fallback={null}>
                <PrintReportPage />
            </Suspense>
        </Route>
        <Route path="/print-pricing-page/:estimateToken">
            <Suspense fallback={null}>
                <PrintPricingPage />
            </Suspense>
        </Route>
        <Route path="*">
            <Suspense fallback={null}>
                <Redirect to={{ pathname: "/" }} />
            </Suspense>
        </Route>
    </Switch>
);
