import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import * as projectAPI from "lib/api/project";
import {
    getMyProjectByReviewer as getMyProjectByReviewerAPI,
    applyCancelRequest as applyCancelRequestAPI,
    getReview as getReviewAPI,
    submitReview,
    removeReview,
    submitEvaluateItems
} from "lib/api/campaignRequest";
import { createRequestSagaBase } from "lib/createRequestSaga";
import { numberWithCommas } from "lib/common";

import {
    GET_MY_PROJECT,
    GET_REVIEW,
    CANCEL_PROJECT,
    CANCEL_PROJECT_SUCCESS,
    CANCEL_PROJECT_FAILURE,
    APPLY_CANCEL_PROJECT,
    APPLY_CANCEL_PROJECT_SUCCESS,
    APPLY_CANCEL_PROJECT_FAILURE,
    SUBMIT_REVIEW,
    SUBMIT_REVIEW_SUCCESS,
    SUBMIT_REVIEW_FAILURE,
    REMOVE_REVIEW,
    REMOVE_REVIEW_SUCCESS,
    REMOVE_REVIEW_FAILURE,
    SUBMIT_ITEMS,
    SUBMIT_ITEMS_SUCCESS,
    SUBMIT_ITEMS_FAILURE
} from "stores/modules/myproject/action";
import { getMyWallet } from "stores/modules/wallet";

import { SHOW_LOADING, HIDE_LOADING } from "stores/modules/loading";
import { OPEN_ALERT } from "stores/modules/alert";

const getMyProjectSaga = createRequestSagaBase({
    type: GET_MY_PROJECT,
    api: getMyProjectByReviewerAPI,
    progress: true
});
const getReviewSaga = createRequestSagaBase({ type: GET_REVIEW, api: getReviewAPI });

function* cancelProjectSaga(action) {
    try {
        const { projectId, securityDepositPoints } = action.payload;
        yield call(projectAPI.cancelProject, projectId);
        yield put({
            type: CANCEL_PROJECT_SUCCESS,
            payload: projectId
        });
        if (securityDepositPoints > 0) {
            yield put({
                type: OPEN_ALERT,
                payload: {
                    text: `프로젝트 신청 취소 및 \n보증금 ${numberWithCommas(
                        securityDepositPoints
                    )}P가 환급됐어요.`
                }
            });
            yield put(getMyWallet());
        } else {
            yield put({
                type: OPEN_ALERT,
                payload: {
                    text: "프로젝트 신청 취소가 완료됐어요."
                }
            });
        }
    } catch (error) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "프로젝트 취소에 실패하였습니다." }
        });
        yield put({
            type: CANCEL_PROJECT_FAILURE,
            payload: { error: error.response }
        });
    }
}

function* applyCancelProjectSaga(action) {
    try {
        yield call(applyCancelRequestAPI, action.payload);
        yield put({
            type: APPLY_CANCEL_PROJECT_SUCCESS,
            payload: action.payload
        });
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.project.re.cancel.success" }
        });
    } catch (error) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.project.re.cancel.fail" }
        });
        yield put({
            type: APPLY_CANCEL_PROJECT_FAILURE,
            payload: { error: error.response }
        });
    }
}

function* submitReviewSaga(action) {
    try {
        yield put({ type: SHOW_LOADING, payload: { isBlack: true, type: action.type } });

        const { data } = yield call(submitReview, action.payload);
        yield put({
            type: SUBMIT_REVIEW_SUCCESS,
            payload: data.projectRequest
        });
    } catch (error) {
        if (error.response.data.indexOf("Already Existing Url") > -1) {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "다른 프로젝트에서 제출된 URL입니다." }
            });
        } else if (error.response.data === "validateError: url") {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "url을 확인해주세요." }
            });
        } else {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "제출에 실패하였습니다." }
            });
            yield put({
                type: SUBMIT_REVIEW_FAILURE,
                payload: { error: error.response }
            });
        }
    } finally {
        yield put({ type: HIDE_LOADING, payload: { type: action.type } });
    }
}

function* removeReviewSaga(action) {
    try {
        yield call(removeReview, action.payload);
        yield put({
            type: REMOVE_REVIEW_SUCCESS,
            payload: action.payload
        });
    } catch (error) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "삭제에 실패하였습니다." }
        });
        yield put({
            type: REMOVE_REVIEW_FAILURE,
            payload: { error: error.response }
        });
    }
}

function* submitItemsSaga(action) {
    try {
        const { data } = yield call(submitEvaluateItems, action.payload);
        yield put({
            type: SUBMIT_ITEMS_SUCCESS,
            payload: data._id
        });
    } catch (error) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "평가에 실패하였습니다." }
        });
        yield put({
            type: SUBMIT_ITEMS_FAILURE,
            payload: { error: error.response }
        });
    }
}

export function* myProjectSaga() {
    yield takeLatest(GET_MY_PROJECT, getMyProjectSaga);
    yield takeLatest(GET_REVIEW, getReviewSaga);
    yield takeLeading(APPLY_CANCEL_PROJECT, applyCancelProjectSaga);
    yield takeLeading(CANCEL_PROJECT, cancelProjectSaga);
    yield takeLeading(SUBMIT_REVIEW, submitReviewSaga);
    yield takeLeading(REMOVE_REVIEW, removeReviewSaga);
    yield takeLeading(SUBMIT_ITEMS, submitItemsSaga);
}
