import * as Sentry from "@sentry/browser";
import moment from "moment";

export const isMobile = () => {
    return window.innerWidth < 768;
};

export const numberWithCommas = value => {
    if (value) {
        if (typeof value === "string") {
            return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else if (typeof value === "number") {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
    return 0;
};
export const addHyphenToNumber = val =>
    val
        ? val
              .replace(/[^0-9*]/g, "")
              .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9*]+)?([0-9]{4})/, "$1-$2-$3")
              .replace("--", "-")
        : "";

export const escapeString = val => (val ? val.replace(/[\\^$*+?.():=!|{},\[\]]/g, "\\$&") : "");

export const returnIdLetters = number => String(number).padStart(5, "0");

export const convertSnsToEng = sns => {
    //legacy
    switch (sns) {
        case "네이버블로그":
            return "NaverBlog";
        case "네이버포스트":
            return "NaverPost";
        case "페이스북":
            return "Facebook";
        case "인스타그램":
            return "Instagram";
        case "카카오스토리":
            return "KakaoStory";
        case "유튜브":
            return "Youtube";
        case "틱톡":
            return "Tiktok";
        case "릴스":
            return "Reels";
        case "쇼츠":
            return "Shorts";
        case "배송형":
            return "Delivery";
        case "방문형":
            return "Visit";
        case "구매형":
            return "Buy";
        case "기자단":
            return "Reporters";
        case "자율형":
            return "None";
        default:
            return sns;
    }
};

export const convertAgeRangeToText = ageRange => {
    switch (ageRange) {
        case "10s":
            return "10대";
        case "20s":
            return "20대";
        case "30s":
            return "30대";
        case "40s":
            return "40대";
        case "50s":
            return "50대 이상";

        default:
            return ageRange;
    }
};

export const convertContact = type => {
    switch (type) {
        case "email":
            return "item.contact.email";
        case "company":
            return "item.contact.company";
        case "kakao":
            return "item.contact.kakao";
        case "messenger":
            return "item.contact.messenger";
        case "sms":
            return "item.contact.sms";
        case "call":
            return "item.contact.call";
        default:
            return type;
    }
};

export const callDirectApi = async (
    callFunction,
    params,
    sendSentry = true,
    returnError = true
) => {
    if (!callFunction) {
        return console.warn("api 함수를 넣어주세요.");
    }

    try {
        const res = await callFunction(params);
        return res;
    } catch (error) {
        if ([401, 403, 404].indexOf(error.response.status) === -1) {
            if (sendSentry) {
                Sentry.withScope(scope => {
                    scope.setExtra("Call Function", callFunction.name);
                    scope.setExtra("Data", error.response);
                    Sentry.captureMessage(callFunction.name);
                });
            }
        }
        if (returnError) {
            throw new Error(
                error.response.data.message ||
                    error.response.data.msg ||
                    error.response.data ||
                    "서버 에러입니다."
            );
        }
    }
};

export const caculateAge = age => {
    if (age < 21) {
        return "teenager";
    }
    if (age > 20 && age < 31) {
        return "twenties";
    }
    if (age > 30 && age < 41) {
        return "thirties";
    }
    if (age > 40) {
        return "overForty";
    }
};

export const returnManageProjectTitle = status => {
    switch (status) {
        case "standby":
            return "대기 프로젝트 목록";
        case "recruit":
            return "모집 프로젝트 목록";
        case "submit":
            return "제출 프로젝트 목록";
        case "complete":
            return "완료 프로젝트 목록";
        default:
            return "";
    }
};

export const convertCategory = val => {
    switch (val) {
        case "cosmetic":
            return "코스메틱";
        case "beauty":
            return "미용";
        case "fashion":
            return "패션/잡화";
        case "food":
            return "식품";
        case "supplies":
            return "생활용품";
        case "baby":
            return "출산/육아";
        case "digital":
            return "디지털/가전";
        case "sports":
            return "스포츠/건강";
        case "pet":
            return "반려동물";
        case "restaurant":
            return "맛집";
        case "travel":
            return "여행/숙박";
        case "area":
            return "지역/문화";
        case "etc":
            return "기타";
        default:
            return val;
    }
};

export const returnUrl = url => {
    if (!url.match(/^http?:\/\//i) && !url.match(/^https?:\/\//i)) {
        return "https://" + url;
    }
    return url;
};

export const getAppleCookieData = cookie => {
    if (cookie.indexOf("userData") < 0) {
        return false;
    }

    const userData = cookie.split("; ").find(item => item.indexOf("userData=") >= 0);
    let userDataValue = unescape(userData.split("userData=")[1]);

    if (userDataValue.indexOf("j:") === 0) {
        userDataValue = userDataValue.replace("j:", "");
    }

    return JSON.parse(userDataValue);
};

export const convertUnitOfTime = unit => {
    switch (unit) {
        case "years":
            return "date.year";
        case "months":
            return "date.month";
        case "weeks":
            return "date.week";
        case "days":
            return "date.day";
        case "hours":
            return "date.hour";
        case "minutes":
            return "date.minute";
        case "seconds":
            return "date.second";
        default:
            return unit;
    }
};

export const convertUnitBySeconds = seconds => {
    if (seconds < 60) {
        return "date.second";
    }
    if (seconds < 60 * 60) {
        return "date.minute";
    }
    if (seconds < 60 * 60 * 24) {
        return "date.hour";
    }
    return "date.day";
};

export const convertTimeBySeconds = seconds => {
    if (seconds < 1) {
        return 1;
    }
    if (seconds < 60) {
        return seconds;
    }
    if (seconds < 60 * 60) {
        return Math.floor(seconds / 60);
    }
    if (seconds < 60 * 60 * 24) {
        return Math.floor(seconds / (60 * 60));
    }
    return Math.floor(seconds / (60 * 60 * 24));
};

export const convertSecondsToTime = seconds => {
    if (seconds >= 60 * 60) {
        return new Date(seconds * 1000).toISOString().substr(11, 8);
    } else {
        return new Date(seconds * 1000).toISOString().substr(14, 5);
    }
};

export const addTime = date => {
    const localDate = new Date();

    const hour = moment(localDate).get("hour");
    const minute = moment(localDate).get("minute");
    const second = moment(localDate).get("second");
    const newDate = moment(date).set({ hour, minute, second });

    return newDate;
};

export const returnDiffSeconds = ({ today, target }) => {
    const addedTimeToday = addTime(today);
    const diffSeconds = moment(addedTimeToday).diff(target, "seconds");
    return diffSeconds;
};

export const convertGender = (gender, short) => {
    if (!gender) return "";
    switch (gender.toLowerCase()) {
        case "female":
            return short ? "item.gender.female_short" : "item.gender.female";
        case "male":
            return short ? "item.gender.male_short" : "item.gender.male";
        default:
            return gender;
    }
};

export const toCapitalFirstLetter = title => {
    if (!title) return "";
    return title.charAt(0).toUpperCase() + title.slice(1);
};

export const checkImage = type => {
    switch (type) {
        case "image/png":
        case "image/jpg":
        case "image/jpeg":
        case "image/svg+xml":
            return true;
        default:
            return false;
    }
};

export const returnAlertValue = ({ message, list }) => {
    let _message = "";
    let _path = "";

    if (message && list && list.length > 0) {
        for (let index = 0; index < list.length; index++) {
            const item = list[index];

            if (message.indexOf(item.keyword) > -1) {
                _message = item.message;
                _path = item.path;
                break;
            }
        }
    }

    return { message: _message, path: _path };
};

export const returnAlertName = type => {
    if (!type) return "";

    // eslint-disable-next-line no-useless-escape
    return `common-alert-${type
        .split("/")?.[1]
        .replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g, "-")
        ?.toLowerCase()}`;
};

export const convertIntForSort = (a, b) => {
    const newA = Number.isInteger(parseInt(a, 10)) ? parseInt(a, 10) : 0;
    const newB = Number.isInteger(parseInt(b, 10)) ? parseInt(b, 10) : 0;
    return newA - newB;
};
