import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const useSearchQuery = () => {
    const location = useLocation();
    // const [query, setQuery] = useState();

    // useEffect(() => {
    //     const newQuery = queryString.parse(location.search);

    //     if (!isEqual(query, newQuery)) {
    //         setQuery(newQuery);
    //     }
    //     // eslint-disable-next-line
    // }, [location.search]);

    return queryString.parse(location.search);
};

export const useSearchString = () => {
    const location = useLocation();

    return location.search;
};

export const useSearchQueryAction = () => {
    const onCreateSearchQuery = (options = {}) => queryString.stringify(options);

    return {
        createSearchQuery: onCreateSearchQuery
    };
};
