import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { createRequestSagaBase } from "lib/createRequestSaga";
import {
    getProjectsByBrand,
    getTempProjectList,
    saveTempProject,
    deleteTempProject,
    createProject as createProjectAPI,
    evaluateItems,
    getRereviewingProject as getRereviewingProjectAPI,
    requestRereviewingProject as requestRereviewingProjectAPI
} from "lib/api/project";
import { requestInicis } from "lib/api/payment";

import {
    SET_PROJECT_INFO,
    SET_SELECTED_BRAND_INFO,
    SET_SELECTED_BRAND_INFO_SUCCESS,
    SET_SELECTED_BRAND_INFO_FAILURE,
    GET_REREVIEWING_PROJECT,
    GET_REREVIEWING_PROJECT_FAILURE,
    GET_REREVIEWING_PROJECT_SUCCESS,
    GET_TEMP_PROJECT_LIST,
    DELETE_TEMP_PROJECT,
    SAVE_TEMP_PROJECT,
    SAVE_TEMP_PROJECT_SUCCESS,
    SAVE_TEMP_PROJECT_FAILURE,
    CREATE_PROJECT,
    CREATE_PROJECT_SUCCESS,
    CREATE_PROJECT_FAILURE,
    REQUEST_REREVIEWING_PROJECT,
    REQUEST_REREVIEWING_PROJECT_SUCCESS,
    REQUEST_REREVIEWING_PROJECT_FAILURE,
    SET_INICIS_DATA,
    EVALUATE_ITEMS,
    EVALUATE_ITEMS_SUCCESS,
    EVALUATE_ITEMS_FAILURE
} from "stores/modules/create/action";
import { handleProgressItem } from "stores/modules/progress";

import { SHOW_LOADING, HIDE_LOADING } from "stores/modules/loading";
import { OPEN_ALERT } from "stores/modules/alert";

const setSelectedBrandInfoSaga = function*(action) {
    try {
        yield put({ type: SHOW_LOADING, payload: { type: action.type } });
        const brandId = action.payload._id;
        const { data } = yield call(getProjectsByBrand, brandId);
        yield put({
            type: SET_SELECTED_BRAND_INFO_SUCCESS,
            payload: { ...action.payload, ...data.brand, projects: data.campaigns }
        });
        yield put({
            type: SET_PROJECT_INFO,
            payload: { brand: brandId }
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.brand.get.fail" }
        });
        yield put({
            type: SET_SELECTED_BRAND_INFO_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put({ type: HIDE_LOADING, payload: { type: action.type } });
    }
};

const getTempProjectListSaga = createRequestSagaBase({
    type: GET_TEMP_PROJECT_LIST,
    api: getTempProjectList,
    alertErrorMsg: "임시 저장 프로젝트 불러오기에 실패하였습니다."
});

const deleteTempProjectSaga = createRequestSagaBase({
    type: DELETE_TEMP_PROJECT,
    api: deleteTempProject,
    alertErrorMsg: "임시 저장 프로젝트 삭제에 실패하였습니다."
});

const saveTempProjectSaga = function*(action) {
    try {
        const { obj, photos, successAlertObj } = action.payload;
        yield put({ type: SHOW_LOADING, payload: { type: action.type } });
        let project = { ...obj };
        if (photos?.length > 0) {
            project = { ...project, photos };
        }

        const { data } = yield call(saveTempProject, project);
        yield put({
            type: SET_PROJECT_INFO,
            payload: obj
        });
        yield put({
            type: SAVE_TEMP_PROJECT_SUCCESS,
            payload: data.campaign
        });
        if (successAlertObj?.text) {
            yield put({
                type: OPEN_ALERT,
                payload: { text: successAlertObj?.text, path: successAlertObj.path || null }
            });
        } else {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "message.project.save.success" }
            });
        }
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.project.save.fail" }
        });
        yield put({
            type: SAVE_TEMP_PROJECT_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put({ type: HIDE_LOADING, payload: { type: action.type } });
    }
};

const getRereviewingProjectSaga = function*(action) {
    try {
        yield put(handleProgressItem({ type: action.type, value: true }));
        const { data } = yield call(getRereviewingProjectAPI, action.payload);
        yield put({
            type: GET_REREVIEWING_PROJECT_SUCCESS,
            payload: data
        });
        yield put({
            type: SET_SELECTED_BRAND_INFO,
            payload: { _id: data.enterprise }
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: {
                text: "message.project.get.detail.fail",
                path: "/biz/manage/standby"
            }
        });
        yield put({
            type: GET_REREVIEWING_PROJECT_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put(handleProgressItem({ type: action.type, value: false }));
    }
};

const createProjectSaga = function*(action) {
    const campaign = { ...action.payload.info, photos: action.payload.photos };
    try {
        yield put(handleProgressItem({ type: action.type, value: true }));
        const { data } = yield call(requestInicis, {
            ...action.payload.payment,
            campaign
        });
        yield put({
            type: SET_INICIS_DATA,
            payload: data.data
        });
        yield call(createProjectAPI, {
            ...campaign,
            payment: data.payment._id,
            moid: data.data.oid
        });
        yield put({
            type: CREATE_PROJECT_SUCCESS,
            payload: data
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.project.create.fail" }
        });
        yield put({
            type: CREATE_PROJECT_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put(handleProgressItem({ type: action.type, value: false }));
    }
};

const requestRereviewingProjectSaga = function*(action) {
    const campaign = { ...action.payload.info, photos: action.payload.photos };
    try {
        yield put(handleProgressItem({ type: action.type, value: true }));
        const { data } = yield call(requestInicis, {
            ...action.payload.payment,
            campaign
        });
        if (data) {
            yield put({
                type: SET_INICIS_DATA,
                payload: data.data
            });
        }
        yield call(requestRereviewingProjectAPI, {
            ...campaign,
            payment: data?.payment?._id,
            moid: data?.data?.oid
        });
        yield put({
            type: REQUEST_REREVIEWING_PROJECT_SUCCESS,
            payload: { _id: campaign._id },
            prev: action.payload
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.project.edit.fail" }
        });
        yield put({
            type: REQUEST_REREVIEWING_PROJECT_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put(handleProgressItem({ type: action.type, value: false }));
    }
};

const evaluateItemsSaga = function*(action) {
    try {
        yield call(evaluateItems, action.payload);
        yield put({
            type: OPEN_ALERT,
            payload: { text: "참여해주셔서 감사합니다." }
        });
        yield put({
            type: EVALUATE_ITEMS_SUCCESS
        });
    } catch (e) {
        if (e.response.data.message === "No campaign") {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "설문 항목을 등록할 수 없는 프로젝트입니다." }
            });
        } else {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "제출에 실패하였습니다." }
            });
            yield put({
                type: EVALUATE_ITEMS_FAILURE,
                payload: { error: e.response }
            });
        }
    }
};

export function* createSaga() {
    yield takeLeading(SET_SELECTED_BRAND_INFO, setSelectedBrandInfoSaga);
    yield takeLatest(GET_REREVIEWING_PROJECT, getRereviewingProjectSaga);
    yield takeLeading(GET_TEMP_PROJECT_LIST, getTempProjectListSaga);
    yield takeLeading(DELETE_TEMP_PROJECT, deleteTempProjectSaga);
    yield takeLeading(SAVE_TEMP_PROJECT, saveTempProjectSaga);
    yield takeLeading(CREATE_PROJECT, createProjectSaga);
    yield takeLeading(REQUEST_REREVIEWING_PROJECT, requestRereviewingProjectSaga);
    yield takeLeading(EVALUATE_ITEMS, evaluateItemsSaga);
}
