import React from "react";
import { createPortal } from "react-dom";
import useCheckMobile from "lib/customHooks/useCheckMobile";
import BottomTabBar from "components/base/BottomTabBar";
import BottomTabBarCustom from "components/base/BottomTabBarCustom";
import BottomTabBarMenu from "components/base/BottomTabBarMenu";

const BottomTabBarPortal = ({ type, children, ...rest }) => {
    const isMobile = useCheckMobile();
    if (!isMobile) return children || null;

    const el = document.getElementById("bottom-tab-bar");
    const tabBarChildren = children ? (
        <BottomTabBarCustom {...rest}>{children}</BottomTabBarCustom>
    ) : (
        <BottomTabBarMenu {...rest} />
    );

    return el ? createPortal(<BottomTabBar type={type}>{tabBarChildren}</BottomTabBar>, el) : null;
};

export default BottomTabBarPortal;
