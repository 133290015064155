import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InterruptionModal from "components/subscription/common/InterruptionModal";
import { changeStatusFromInterruption } from "stores/modules/subscription/action";

const InterruptionModalContainer = ({ history }) => {
    const dispatch = useDispatch();

    const user = useSelector(({ auth }) => auth.userInfo.user);
    const { planInfo } = useSelector(({ subscription }) => subscription);

    const [modalStatus, setModalStatus] = useState(false);

    const onCloseModal = () => {
        setModalStatus(false);
        dispatch(changeStatusFromInterruption());
    };

    useEffect(() => {
        if (modalStatus) {
            history.listen(() => onCloseModal());
        }
        // eslint-disable-next-line
    }, [modalStatus]);

    useEffect(() => {
        if (planInfo.plan !== "none" && planInfo.status === "interruption") {
            setModalStatus(true);
        }
        // eslint-disable-next-line
    }, [planInfo.plan, planInfo.status]);

    return modalStatus ? (
        <InterruptionModal user={user} plan={planInfo} closeModal={onCloseModal} />
    ) : null;
};

export default withRouter(InterruptionModalContainer);
