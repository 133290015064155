import React from "react";
import cx from "classnames";
import { Button, IconFont } from "reviewshare-common-component";
import { useAlarmState } from "lib/customHooks/useAlarm";
import { useUserInfo, useAuthAction } from "lib/customHooks/useAuth";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";

import "./BizCenterHeader.scss";

import reviewshareLogo from "assets/images/logo/reviewshare_logo.svg";

const BizCenterHeader = () => {
    const businessName = useUserInfo("name");
    const hasUnreadAlarm = useAlarmState("hasUnread");

    const { logout } = useAuthAction();

    return (
        <header className="business-center-header">
            <div className="logo-area">
                <a href="/" id="biz-header-logo" className="reviewshare-logo">
                    <img src={reviewshareLogo} alt="리뷰쉐어 로고" />
                </a>
                <CommonLink
                    to="/biz/main"
                    id="biz-header-bizcentertext"
                    className="reviewshare-business-text"
                >
                    <FormattedMessage id="menu.biz.center" />
                </CommonLink>
            </div>
            <div className="header-menu">
                <p className="business-name">
                    <FormattedMessage id="target.biz" values={{ name: businessName }} />
                </p>
                <CommonLink
                    to="/biz/myinfo"
                    id="biz-header-myinfobtn"
                    className="move-manage-my-info-btn"
                >
                    <FormattedMessage id="menu.biz.info" />
                </CommonLink>
                <Button id="biz-header-logoutbtn" className="logout-btn theme6" onClick={logout}>
                    <FormattedMessage id="action.signout" />
                </Button>
                <CommonLink
                    to="/support"
                    id="biz-header-help"
                    className="move-service-center-btn clear"
                >
                    <FormattedMessage id="menu.cs" />
                </CommonLink>
                <CommonLink to="/alarm" className={cx("alarm-link", { hasUnreadAlarm })}>
                    <IconFont icon="icon_alarm">
                        <FormattedMessage id="menu.alarm" />
                    </IconFont>
                </CommonLink>
            </div>
        </header>
    );
};

export default BizCenterHeader;
