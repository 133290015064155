import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { createRequestSagaBase } from "lib/createRequestSaga";
import * as alarmAPI from "lib/api/alarm";

import {
    GET_ALL_ALARM_LIST,
    READ_ALARM,
    REMOVE_ALARM,
    REMOVE_ALARM_SUCCESS,
    REMOVE_ALARM_FAILURE
} from "stores/modules/alarm/action";
import { OPEN_ALERT } from "stores/modules/alert";

const getAllAlarmListSaga = createRequestSagaBase({
    type: GET_ALL_ALARM_LIST,
    api: alarmAPI.getAllAlarmList,
    progress: true
});

const readAlarmSaga = createRequestSagaBase({
    type: READ_ALARM,
    api: alarmAPI.readAlarm
});

const removeAlarmSaga = function*(action) {
    try {
        const response = yield call(alarmAPI.removeAlarm, action.payload);
        yield put({
            type: REMOVE_ALARM_SUCCESS,
            payload: response.data,
            prev: action.payload
        });
        // 읽은 알림이 모두 삭제될때는 전체 개수를 카운트할 수 없어 다시 불러옴
        yield put({
            type: GET_ALL_ALARM_LIST,
            payload: { skip: action.payload?.skip }
        });
    } catch (error) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.alarm.delete.fail" }
        });
        yield put({
            type: REMOVE_ALARM_FAILURE,
            payload: { error: error.response },
            prev: action.payload
        });
    }
};

export function* alarmSaga() {
    yield takeLatest(GET_ALL_ALARM_LIST, getAllAlarmListSaga);
    yield takeLeading(READ_ALARM, readAlarmSaga);
    yield takeLeading(REMOVE_ALARM, removeAlarmSaga);
}
