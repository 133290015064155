import { call, put, takeLeading, takeLatest, takeEvery } from "redux-saga/effects";
import createRequestSaga, { createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as subscriptionAPI from "lib/api/subscription";

import {
    GET_PLAN_INFO,
    GET_PLAN_INFO_SUCCESS,
    GET_PLAN_TABLE,
    GET_PLAN_TABLE_SUCCESS,
    GET_PLAN_TABLE_FAILURE,
    CHANGE_STATUS_FROM_INTERRUPTION,
    CHANGE_PLAN,
    CHANGE_PLAN_SUCCESS,
    CHANGE_PLAN_FAILURE,
    UNSUBSCRIBE_PLAN,
    UNSUBSCRIBE_PLAN_SUCCESS,
    UNSUBSCRIBE_PLAN_FAILURE,
    REMOVE_SPARE_PAYMENT
} from "stores/modules/subscription/action";

import { OPEN_ALERT } from "stores/modules/alert";

const getPlanInfoSaga = createRequestSagaWithAlert(
    GET_PLAN_INFO,
    subscriptionAPI.getPlanInfo,
    "message.subscription.biz.plan.get.fail"
);

const getPlanTableSaga = function*(action) {
    try {
        const { data } = yield call(subscriptionAPI.getPlanTable, action.payload);
        yield put({
            type: GET_PLAN_INFO_SUCCESS,
            payload: { plan: data.plan, status: data.status, refundPrice: data.refundPrice }
        });
        yield put({
            type: GET_PLAN_TABLE_SUCCESS,
            payload: data.planList
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.subscription.biz.plan.get.fail" }
        });
        yield put({
            type: GET_PLAN_TABLE_FAILURE,
            payload: { error: e.response }
        });
    }
};

const changeStatusFromInterruptionSaga = createRequestSaga(
    CHANGE_STATUS_FROM_INTERRUPTION,
    subscriptionAPI.changeStatusFromInterruption
);

const changePlanSaga = function*(action) {
    try {
        const { data } = yield call(subscriptionAPI.changePlan, action.payload);
        yield put({
            type: CHANGE_PLAN_SUCCESS,
            payload: data
        });
    } catch (error) {
        if (error.response.data.indexOf("PaymentInfo status not a verified") > -1) {
            yield put({
                type: OPEN_ALERT,
                payload: {
                    text:
                        "등록된 카드 정보가 유효하지 않습니다.\n결제 정보 변경 후 다시 시도해주세요.",
                    path: "/biz/plan/manage"
                }
            });
        } else {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "message.subscription.biz.plan.change.fail" }
            });
        }
        yield put({
            type: CHANGE_PLAN_FAILURE,
            payload: { error: error.response }
        });
    }
};

const unsubcirbeSaga = function*(action) {
    try {
        yield call(subscriptionAPI.unsubscribePlan, action.payload);
        yield put({
            type: UNSUBSCRIBE_PLAN_SUCCESS,
            payload: { status: action.payload.cancel ? "running" : "readyForUnsubscribe" }
        });
    } catch (error) {
        yield put({
            type: OPEN_ALERT,
            payload: {
                text: action.payload.cancel
                    ? "message.subscription.biz.unsubscribe.cancel.fail"
                    : "message.subscription.biz.unsubscribe.fail"
            }
        });
        yield put({
            type: UNSUBSCRIBE_PLAN_FAILURE,
            payload: { error: error.response }
        });
    }
};

const removeSparePaymentSaga = createRequestSagaWithAlert(
    REMOVE_SPARE_PAYMENT,
    subscriptionAPI.removeSparePayment,
    "message.subscription.biz.payment.delete.additional.success"
);

export function* subscriptionSaga() {
    yield takeEvery(GET_PLAN_INFO, getPlanInfoSaga);
    yield takeLatest(GET_PLAN_TABLE, getPlanTableSaga);
    yield takeLeading(CHANGE_STATUS_FROM_INTERRUPTION, changeStatusFromInterruptionSaga);
    yield takeLeading(CHANGE_PLAN, changePlanSaga);
    yield takeLeading(UNSUBSCRIBE_PLAN, unsubcirbeSaga);
    yield takeLeading(REMOVE_SPARE_PAYMENT, removeSparePaymentSaga);
}
