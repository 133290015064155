import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import shuffle from "lodash/shuffle";
import cx from "classnames";
import { useUserType } from "lib/customHooks/useAuth";
import { getBannerList } from "stores/modules/banner/action";
import useCheckMobile from "lib/customHooks/useCheckMobile";
import CommonLink from "components/common/CommonLink";

import "./Banner.scss";

const Banner = ({ position }) => {
    const dispatch = useDispatch();
    const bannerList = useSelector(({ banner }) => banner[position] || []);
    const userType = useUserType();
    const isMobile = useCheckMobile();
    const item = useMemo(() => shuffle(bannerList)?.[0] || {}, [bannerList]);

    useEffect(() => {
        if (userType !== "None" && position) {
            dispatch(getBannerList({ position }));
        }
        // eslint-disable-next-line
    }, [userType]);

    if (!position || !bannerList?.length) return null;

    return (
        <CommonLink
            id={`${position}-banner-${item._id}`}
            className={cx("common-banner-item", position)}
            to={item.link}
            disabled={!item.link}
            title={item.alt}
            style={{
                backgroundImage: `url(${
                    isMobile ? item?.photos?.[1]?.secure_url : item?.photos?.[0]?.secure_url
                })`,
                backgroundColor: item?.backgroundColor
            }}
        />
    );
};
export default Banner;
