import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import uniq from "lodash/uniq";
import { Button, IconFont, Spin } from "reviewshare-common-component";
import useScrollEnd from "lib/customHooks/useScrollEnd";
import { useUserType } from "lib/customHooks/useAuth";
import { useProgressItems } from "lib/customHooks/useProgress";
import { getMessageList } from "stores/modules/messenger/action";
import MessageItem from "components/messenger/main/MessageItem";
import NewMessage from "components/messenger/main/NewMessage";

import "./MessageList.scss";

const LIMIT = 30;
const MessageList = () => {
    const dispatch = useDispatch();
    const scrollElement = useRef();

    const isStartOfPage = useScrollEnd({ ref: scrollElement, reverse: true });
    const isEndOfPage = useScrollEnd({ ref: scrollElement });
    const isSearching = useProgressItems("messenger/GET_MESSAGE_LIST");
    const userType = useUserType();
    const receiver = useSelector(({ messenger }) => messenger.writeInfo.from);
    const newMessage = useSelector(({ messenger }) => messenger.newMessage);
    const selectedMessageBox = useSelector(({ messenger }) => messenger.selectedMessageBox);
    const selectedMessageBoxOptions = useSelector(
        ({ messenger }) => messenger.selectedMessageBoxOptions
    );

    const [firstMessageId, setFirstMessageId] = useState(null);
    const [lastMessageId, setLastMessageId] = useState(null);
    const [lastScrollHeight, setLastScrollHeight] = useState(0);

    const { counterpart, list, hasNext, hasPrevious } = selectedMessageBox;

    const onChangeScrollPosition = height => {
        scrollElement.current.scrollTop = height;
    };

    const onLoadedImage = e => {
        const filteredList = uniq(list.map(item => item.messageId));
        const currentScrollHeight = scrollElement.current.scrollHeight;
        const lastMessageObj = list[list.length - 1];

        if ((filteredList.length <= LIMIT && !hasNext) || lastMessageObj.isNew) {
            onChangeScrollPosition(currentScrollHeight);
        }
        setLastScrollHeight(currentScrollHeight);
    };

    const onGetPrevMessageList = () => {
        dispatch(
            getMessageList({
                reviewerId: selectedMessageBoxOptions.reviewerId,
                brandId: selectedMessageBoxOptions.brandId,
                lastMessageId: selectedMessageBox.list[0].messageId
            })
        );
    };

    const onGetNextMessageList = () => {
        dispatch(
            getMessageList({
                reviewerId: selectedMessageBoxOptions.reviewerId,
                brandId: selectedMessageBoxOptions.brandId,
                lastMessageId:
                    selectedMessageBox.list[selectedMessageBox.list.length - 1].messageId,
                after: true
            })
        );
    };

    useEffect(() => {
        if (list.length > 0) {
            const currentScrollHeight = scrollElement.current.scrollHeight;
            const status = firstMessageId === list[0].messageId ? "next" : "prev";
            const lastMessageObj = list[list.length - 1];

            setLastScrollHeight(currentScrollHeight);
            setFirstMessageId(list[0].messageId);
            setLastMessageId(lastMessageObj.messageId);

            if (lastScrollHeight > 0) {
                if (status === "prev") {
                    return onChangeScrollPosition(currentScrollHeight - lastScrollHeight);
                } else if (lastMessageObj.messageId !== lastMessageId && lastMessageObj.isNew) {
                    //새 메시지 보냈을 때 or 새 메시지 도착했을 때
                    return onChangeScrollPosition(currentScrollHeight);
                }
                return;
            } else {
                if (hasNext) {
                    onChangeScrollPosition(currentScrollHeight);
                    return onGetNextMessageList();
                }
            }
            return onChangeScrollPosition(currentScrollHeight);
        } else {
            setFirstMessageId(null);
            setLastScrollHeight(0);
        }
        // eslint-disable-next-line
    }, [selectedMessageBox.list]);

    useEffect(() => {
        if (isStartOfPage && hasPrevious) {
            onGetPrevMessageList();
        }
        // eslint-disable-next-line
    }, [isStartOfPage]);

    useEffect(() => {
        if (isEndOfPage && hasNext) {
            onGetNextMessageList();
        }
        // eslint-disable-next-line
    }, [isEndOfPage]);

    useEffect(() => {
        if (selectedMessageBoxOptions.reviewerId && selectedMessageBoxOptions.brandId) {
            dispatch(
                getMessageList({
                    reviewerId: selectedMessageBoxOptions.reviewerId,
                    brandId: selectedMessageBoxOptions.brandId,
                    lastMessageId: selectedMessageBoxOptions.lastMessageId,
                    containLastMsg: !!selectedMessageBoxOptions.lastMessageId
                })
            );
        }
        // eslint-disable-next-line
    }, [
        selectedMessageBoxOptions.reviewerId,
        selectedMessageBoxOptions.brandId,
        selectedMessageBoxOptions.lastMessageId
    ]);

    return (
        <>
            {newMessage && userType === "Seller" && <NewMessage data={newMessage} />}
            <div id="messenger-main-message-list" ref={scrollElement} onLoad={onLoadedImage}>
                {isSearching && !list.length ? (
                    <Spin className="full" />
                ) : (
                    <>
                        {hasPrevious && (
                            <>
                                {isSearching || !isStartOfPage ? (
                                    <Spin />
                                ) : (
                                    <Button
                                        className="theme5 size-s m-size-xs get-list-btn prev-btn"
                                        onClick={onGetPrevMessageList}
                                    >
                                        <IconFont icon="icon_reset_left" />
                                        이전 내용 불러오기
                                    </Button>
                                )}
                            </>
                        )}
                        {list.map((item, index) => {
                            let hasNextItem = false;
                            let isNextItem = false;
                            let dateFirstItem = false;

                            if (index === 0) {
                                dateFirstItem = true;
                            }

                            if (index > 0) {
                                const prev = list[index - 1];
                                if (
                                    moment(item.createdAt).diff(
                                        moment(prev.createdAt),
                                        "minutes"
                                    ) === 0 &&
                                    prev.type === item.type
                                ) {
                                    isNextItem = true;
                                }

                                if (
                                    moment(item.createdAt)
                                        .endOf("day")
                                        .diff(moment(prev.createdAt).endOf("day"), "days") > 0
                                ) {
                                    dateFirstItem = true;
                                }
                            }

                            if (index < list.length - 1) {
                                const next = list[index + 1];
                                if (
                                    moment(item.createdAt).diff(
                                        moment(next.createdAt),
                                        "minutes"
                                    ) === 0 &&
                                    next.type === item.type
                                ) {
                                    hasNextItem = true;
                                }
                            }

                            return (
                                <>
                                    {dateFirstItem && (
                                        <MessageItem
                                            key={`${index}-date`}
                                            data={{
                                                type: "date",
                                                contents: {
                                                    type: "text",
                                                    value: moment(item.createdAt)
                                                },
                                                createdAt: moment(item.createdAt)
                                            }}
                                        />
                                    )}
                                    <MessageItem
                                        key={`${index}-content`}
                                        userType={userType}
                                        data={item}
                                        receiver={receiver}
                                        counterpart={counterpart}
                                        isNextItem={isNextItem}
                                        hasNextItem={hasNextItem}
                                    />
                                </>
                            );
                        })}
                        {hasNext && (
                            <>
                                {isSearching || !isEndOfPage ? (
                                    <Spin />
                                ) : (
                                    <Button
                                        className="theme5 size-s m-size-xs get-list-btn next-btn"
                                        onClick={onGetNextMessageList}
                                    >
                                        다음 내용 불러오기
                                        <IconFont icon="icon_reset_right" />
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
export default MessageList;
