import { useEffect } from "react";

const useSticky = (prevSelector, stickySelector) => {
    useEffect(() => {
        const prevElement = document.querySelector(prevSelector);
        const stickyElement = document.querySelector(stickySelector);

        if (prevElement && stickyElement) {
            const handler = entries => {
                if (!entries[0].isIntersecting) {
                    stickyElement.classList.add("isSticky");
                } else {
                    stickyElement.classList.remove("isSticky");
                }
            };

            const observer = new window.IntersectionObserver(handler);
            observer.observe(prevElement);

            return () => {
                observer.unobserve(prevElement);
            };
        }

        // eslint-disable-next-line
    }, []);

    return;
};

export default useSticky;
