import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import { createRequestSagaWithAlert, createRequestActionTypes } from "lib/createRequestSaga";
import { getScoreRecord as getScoreRecordAPI } from "lib/api/scoreRecord";

// ******************************
// TYPE
// ******************************

export const [
    GET_SCORE_RECORD_LIST,
    GET_SCORE_RECORD_LIST_SUCCESS,
    GET_SCORE_RECORD_LIST_LIST_FAILURE
] = createRequestActionTypes("scoreRecord/GET_SCORE_RECORD_LIST");

// ******************************
// CREATE_ACTION
// ******************************

export const getScoreRecordList = createAction(GET_SCORE_RECORD_LIST, ({ type, period, skip }) => ({
    type,
    period,
    skip
}));

// ******************************
// CREATE_REQUEST_SAGA
// ******************************

const getScoreRecordListSaga = createRequestSagaWithAlert(
    GET_SCORE_RECORD_LIST,
    getScoreRecordAPI,
    "활동 점수 내역을 가져오는 데 실패하였습니다.",
    true
);

export function* scoreRecordSaga() {
    yield takeLatest(GET_SCORE_RECORD_LIST, getScoreRecordListSaga);
}

const initialState = {
    totalCount: 0,
    list: [],
    totalScore: 0,
    grade: null
};

const scoreRecord = handleActions(
    {
        [GET_SCORE_RECORD_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            totalCount: payload.totalCount,
            list: payload.list,
            totalScore: state.totalScore || payload.totalScore,
            grade: state.grade || payload.grade
        })
    },
    initialState
);

export default scoreRecord;
