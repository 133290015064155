import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoginModal from "components/base/LoginModal";
import {
    handleLoginModal,
    login as tryToLogin,
    getUserInfoAsTokenInLocalStorage
} from "stores/modules/auth";
import { setStorage, getStorage, removeStorage } from "lib/storage";
import { AlertModal } from "reviewshare-common-component";
import FormattedMessage from "components/common/FormattedMessage";

const LoginModalContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { returnTo, brand_id, locale_id, timestamp, return_to } = queryString.parse(
        window.location.search
    );

    const { register, watch, handleSubmit, errors, getValues, setValue } = useForm();
    const [isReviewer, handleReviewerType] = useState(true);
    const [loginPageError, setLoginPageError] = useState(null);

    const loginModalInfo = useSelector(({ auth }) => auth.loginModalInfo);
    const loginError = useSelector(({ auth }) => auth.userInfoError);
    const { user, token } = useSelector(({ auth }) => ({
        user: auth.userInfo.user,
        token: auth.userInfo.token
    }));
    const identifierInput = watch("identifier");
    const passwordInput = watch("password");

    const hideLoginModal = () => {
        dispatch(handleLoginModal({ status: false }));
        if (returnTo) {
            history.push("/");
        }
    };

    // const lookAround = () => {
    //     sessionStorage.setItem("lookAround", true);
    //     hideLoginModal();
    //     if (returnTo) {
    //         history.push("/");
    //     }
    // };

    const closeModal = () => {
        setLoginPageError(null);
    };

    const onLogin = loginInfo => {
        let _loginInfo = {
            ...loginInfo,
            identifier: loginInfo.identifier ? loginInfo.identifier.trim() : ""
        };

        if (return_to) {
            _loginInfo = Object.assign({}, _loginInfo, {
                brand_id,
                locale_id,
                timestamp,
                return_to
            });
        }
        dispatch(tryToLogin(_loginInfo));
    };

    const onGoToSignIn = () => {
        history.push(isReviewer ? "/register" : "/signin");
    };

    const setStorageAfterLogin = ({ userType, userToken, loginFormValues }) => {
        // method: local, sns일때 실행됨
        if (userType === "Normal") {
            removeStorage("reviewshare-at");
            setStorage("reviewshare-re-at", userToken);
        } else {
            removeStorage("reviewshare-re-at");
            setStorage("reviewshare-at", userToken);
        }

        // method: local에서 실행됨
        if (loginFormValues.userType === "Normal") {
            if (loginFormValues.saveId) {
                setStorage("reviewshare-ri", loginFormValues.identifier);
            } else {
                removeStorage("reviewshare-ri");
            }
        } else if (loginFormValues.userType === "Seller") {
            if (loginFormValues.saveId) {
                setStorage("reviewshare-bi", loginFormValues.identifier);
            } else {
                removeStorage("reviewshare-bi");
            }
        }
    };

    const changeUserType = isReviewer => {
        if (isReviewer) {
            handleReviewerType(true);
        } else {
            handleReviewerType(false);
        }
    };

    const onResetValue = (key, id) => {
        setValue(key, "");
        document.getElementById(id).focus();
    };

    useEffect(() => {
        if (loginModalInfo.status) {
            const savedId = isReviewer
                ? getStorage("reviewshare-ri")
                : getStorage("reviewshare-bi");

            if (savedId) {
                setValue("identifier", savedId);
                setValue("password", "");
                setValue("saveId", true);
            }
        }
        // eslint-disable-next-line
    }, [loginModalInfo.status, isReviewer]);

    useEffect(() => {
        if (loginModalInfo.type === "Seller") {
            handleReviewerType(false);
            setValue("userType", "Seller");
        } else {
            handleReviewerType(true);
        }
        // eslint-disable-next-line
    }, [loginModalInfo.type]);

    useEffect(() => {
        if (loginError) {
            setLoginPageError("failLogin");
        }
        // eslint-disable-next-line
    }, [dispatch, loginError]);

    useEffect(() => {
        if (user && token) {
            const loginFormValues = getValues();
            setStorageAfterLogin({
                userType: user.category,
                userToken: token,
                loginFormValues
            });
        }
        // eslint-disable-next-line
    }, [dispatch, user, token]);

    useEffect(() => {
        if (token) {
            dispatch(getUserInfoAsTokenInLocalStorage({ init: true }));
            hideLoginModal();

            if (returnTo) {
                if (returnTo.indexOf("_") >= 0) {
                    const url = returnTo.split("_").reduce((text, val) => `${text}/${val}`);
                    history.push(`/${url}`);
                } else {
                    history.push(`/${returnTo}`);
                }
            }
        }
        // eslint-disable-next-line
    }, [dispatch, token]);

    return loginModalInfo.status ? (
        <>
            {loginPageError === "failLogin" && (
                <AlertModal handleModal={closeModal}>
                    <p>
                        <FormattedMessage id="message.signin.validate.inputs" />
                    </p>
                </AlertModal>
            )}
            <LoginModal
                closeModal={() => hideLoginModal()}
                loginForm={register}
                identifier={identifierInput}
                password={passwordInput}
                resetValue={onResetValue}
                isReviewer={isReviewer}
                errors={errors}
                onLogin={handleSubmit(onLogin)}
                changeUserType={changeUserType}
                goToSignIn={onGoToSignIn}
            />
        </>
    ) : null;
};

export default LoginModalContainer;
