import client from "./client";

export const uploadFile = file =>
    client.post("/file/create", file, {
        headers: { "Content-Type": "multipart/form-data" }
    });
export const getReviewFileZip = ({ projectId, reviewFileList }) =>
    client.post(`/file/download`, { projectId, reviewFileList });
export const getOriginFileZip = ({ projectId, target }) =>
    client.get(`/files/origin-zip-file/${projectId}${target ? `?target=${target}` : ""}`);
export const getCancelAttachments = requestId =>
    client.get(`/files/cancelAttachments/${requestId}`);
