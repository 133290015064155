import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";
import {
    Dropdown,
    DropdownItem,
    IconFont,
    InputWrapper,
    Input
} from "reviewshare-common-component";
import { numberWithCommas } from "lib/common";
import { returnReceiverType } from "utils/messenger";
import FormattedMessage from "components/common/FormattedMessage";
import { getMessageBoxList } from "stores/modules/messenger/action";

import "./SearchBox.scss";

const SearchBox = ({
    userType,
    searchStatus,
    setSearchStatus,
    searchOptions,
    searchedMessageBox,
    brandList,
    closeMessenger,
    changePrevStatus,
    setSearchKeyword,
    filterMessageBox
}) => {
    const dispatch = useDispatch();
    const [selectedBrandId, setSelectedBrandId] = useState(null);
    const [inputText, setInputText] = useState("");
    const [hasUnreadMessage, setHasUnreadMessage] = useState(false);

    const refreshMessageBoxList = () => {
        dispatch(
            getMessageBoxList(
                userType === "Seller" && selectedBrandId ? { brand: selectedBrandId } : undefined
            )
        );
    };

    const returnTitle = () => {
        if (searchStatus) {
            if (searchOptions.viewType === "counterpart") {
                return (
                    <>
                        <FormattedMessage id={returnReceiverType(userType)} />(
                        {numberWithCommas(searchedMessageBox.counterpart.counts)})
                    </>
                );
            } else if (searchOptions.viewType === "message") {
                return (
                    <>
                        <FormattedMessage id="item.message" />(
                        {numberWithCommas(searchedMessageBox.message.counts)})
                    </>
                );
            } else {
                return <FormattedMessage id="action.search" />;
            }
        } else {
            return <FormattedMessage id="item.message" />;
        }
    };

    useEffect(() => {
        filterMessageBox(selectedBrandId);
        // eslint-disable-next-line
    }, [selectedBrandId]);

    useEffect(() => {
        setInputText(searchOptions.keyword);
    }, [searchOptions.keyword]);

    useEffect(() => {
        if (brandList.length > 0) {
            const filteredHasUnreadBrands = brandList.filter(item => item.hasUnreadMessage);
            setHasUnreadMessage(filteredHasUnreadBrands.length > 0);
        }
    }, [brandList]);

    return (
        <div className="messenger-side-search-box">
            <div className="title-box">
                {searchStatus && (
                    <button type="button" className="back-btn" onClick={changePrevStatus}>
                        <IconFont icon="icon_line_arrow_left">뒤로가기</IconFont>
                    </button>
                )}
                <div className="title-area">
                    <h3>{returnTitle()}</h3>
                    {!searchStatus && (
                        <button
                            type="button"
                            className="refresh-btn web"
                            onClick={refreshMessageBoxList}
                        >
                            <IconFont icon="icon_reset_right">새로고침</IconFont>
                        </button>
                    )}
                </div>
                {!searchStatus && (
                    <button
                        type="button"
                        className="search-btn"
                        onClick={() => setSearchStatus("active")}
                    >
                        <IconFont icon="icon_search">
                            <FormattedMessage id="action.search" />
                        </IconFont>
                    </button>
                )}
                <button type="button" className="close-btn" onClick={closeMessenger}>
                    <IconFont icon="icon_close">
                        <FormattedMessage id="action.close" />
                    </IconFont>
                </button>
            </div>
            {searchStatus && searchOptions.viewType === "all" && (
                <div className="input-box">
                    <InputWrapper
                        search={() => setSearchKeyword(inputText)}
                        remove={() => setInputText("")}
                    >
                        <Input
                            value={inputText}
                            className="m-size-l"
                            placeholder="내 쪽지에서 검색"
                            onChange={val => setInputText(val)}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    setSearchKeyword(inputText);
                                }
                            }}
                        />
                    </InputWrapper>
                    {!searchOptions.keyword && (
                        <p>
                            {userType === "Seller"
                                ? "*리뷰어 닉네임, 쪽지 내용을 검색해 주세요."
                                : "*브랜드 이름, 쪽지 내용을 검색해 주세요."}
                        </p>
                    )}
                </div>
            )}
            {!searchStatus && userType === "Seller" && (
                <div className="filter-box">
                    <Dropdown id="messenger-search-box-brand-filter" className="m-size-l">
                        <DropdownItem onClick={() => setSelectedBrandId("")}>
                            <p className={cx("brand-name", { "has-unread": hasUnreadMessage })}>
                                <span>
                                    <FormattedMessage id="filter.brand.all" />
                                </span>
                            </p>
                        </DropdownItem>
                        {brandList.map((item, index) => (
                            <DropdownItem
                                key={index}
                                onClick={() => setSelectedBrandId(item._id)}
                                isActive={selectedBrandId === item._id}
                            >
                                <p
                                    className={cx("brand-name", {
                                        "has-unread": item.hasUnreadMessage
                                    })}
                                >
                                    <span>{item.name}</span>
                                </p>
                            </DropdownItem>
                        ))}
                    </Dropdown>
                </div>
            )}
        </div>
    );
};
export default SearchBox;
