import React from "react";
import { numberWithCommas } from "lib/common";
import { SUGGESTION_PRICE } from "scheme/price";
import CommonLink from "components/common/CommonLink";
import FormattedMessage from "components/common/FormattedMessage";

export const BIZ_SUGGESTION_LIST_PATH_MSG = (
    <FormattedMessage
        id="message.suggestion.biz.list.path"
        values={{
            path: (
                <CommonLink to="/biz/suggestion/waiting">
                    <FormattedMessage id="menu.biz.suggestion.manage" />
                </CommonLink>
            )
        }}
    />
);

export const BIZ_CANCEL_REFUND_POINTS_PATH_MSG = (
    <FormattedMessage
        id="message.suggestion.biz.cancel.refund.points.path"
        values={{
            path: (
                <CommonLink to="/biz/payment">
                    <FormattedMessage id="menu.biz.payment.list" />
                </CommonLink>
            )
        }}
    />
);

export const STEP1_CAUTION_TEXT_SCHEME = [
    `리워드 포인트 제공 시, 제안 수수료(${numberWithCommas(SUGGESTION_PRICE)}P)와 함께 차감됩니다.`,
    "리워드 포인트는 리뷰어가 결과물을 제출한 시점부터 지급할 수 있으며 제출일 기준 2주 이내로 미지급 시, 리뷰어에게 자동 지급됩니다. (이미 지급된 포인트는 회수가 불가할 수 있습니다.)",
    "리뷰어가 거절 또는 제출 마감일이 지난 경우 제안은 취소되며, 리워드 포인트 및 제안 수수료는 자동 환급됩니다."
];

export const STEP1_FEE_IS_FREE_CAUTION_TEXT_SCHEME = [
    "리워드 포인트는 리뷰어가 결과물을 제출한 시점부터 지급할 수 있으며 제출일 기준 2주 이내로 미지급 시, 리뷰어에게 자동 지급됩니다. (이미 지급된 포인트는 회수가 불가할 수 있습니다.)",
    "리뷰어가 거절 또는 제출 마감일이 지난 경우 제안은 취소되며, 리워드 포인트 및 제안 수수료는 자동 환급됩니다."
];

export const INITIAL_CHECK_VALUE_SCHEME = { info: false, guideLine: false };

export const STEP_TITLE_SCHEME = [
    "item.suggestion.item.name",
    "item.suggestion.guideline",
    "item.price.fee"
];

// business

// export const FILTER_WAITING_SUGGESTION = [
// { label: "filter.status.all", value: "All" },
// { label: "status.suggestion.waiting", value: "Waiting" }
// { label: "status.suggestion.reject", value: "Rejected" },
// { label: "status.suggestion.cancel", value: "Canceled" },
// { label: "status.payment.refund.after", value: "isRefunded" }
// ];

export const FILTER_RUNNING_SUGGESTION = [
    { label: "filter.status.all", value: "All" },
    { label: "status.suggestion.running", value: "Running" },
    { label: "status.suggestion.submit", value: "Submitted" },
    { label: "status.suggestion.complete", value: "Complete" }
];

export const FILTER_CANCELED_SUGGESTION = [
    { label: "filter.status.all", value: "All" },
    { label: "status.suggestion.reject", value: "Rejected" },
    { label: "status.suggestion.cancel", value: "Canceled" }
];

export const BIZ_SUGGESTION_STATUS_GUIDE = [
    {
        label: "status.suggestion.waiting",
        desc: [
            "리뷰어님의 응답을 기다리고 있는 상태에요.",
            "제안 수락 전까지 언제든지 취소가 가능해요."
        ]
    },
    {
        label: "status.suggestion.reject",
        desc: [
            "제안이 거절되어, 제안 수수료 및 제공 리워드 \n포인트가 환급된 상태에요.",
            "거절 사유를 참고하여 다시 제안해 보세요!"
        ]
    },
    {
        label: "status.suggestion.cancel",
        desc: [
            "제안이 취소되어, 제안 수수료 및 제공 리워드 \n포인트가 환급된 상태에요.",
            "취소 사유를 확인해 주세요!"
        ]
    }
];

export const BIZ_SUGGESTION_CAUTION_LIST = [
    "리뷰어가 거절 또는 제출 마감일이 지난 경우 제안은 취소되며, 리워드 포인트 및 제안 수수료는 자동 환급됩니다.",
    "리워드 포인트는 리뷰어가 결과물을 제출한 시점부터 지급할 수 있으며 제출일 기준 2주 이내로 미지급 시, 리뷰어에게 자동 지급됩니다. (이미 지급된 포인트는 회수가 불가할 수 있습니다.)"
];

export const BIZ_CANCEL_SUGGESTION_CAUTION_LIST = [BIZ_CANCEL_REFUND_POINTS_PATH_MSG];

//reviewer

export const REJECT_REASON_LIST = [
    {
        label: "item.suggestion.reject.type.itemName",
        value: "itemName",
        placeholder: "생각하시는 적정 제공 내역은 무엇인가요?"
    },
    {
        label: "item.suggestion.reject.type.points",
        value: "points",
        placeholder: "생각하시는 적정 리워드 포인트는 얼마인가요?"
    },
    {
        label: "item.suggestion.reject.type.guideline",
        value: "guideLine",
        placeholder: "어떤 가이드라인이 어려우셨나요?"
    },
    {
        label: "제출 기간이 짧음",
        value: "submitEndAt",
        placeholder: "생각하시는 적정 기간은 언제까지인가요?"
    },
    { label: "item.suggestion.reject.type.etc", value: "etc", placeholder: "내용을 입력해 주세요." }
];

export const REVIEWER_SUGGESTION_GUIDE = [
    {
        title: "message.suggestion.re.howToUse.step1",
        text: [
            "message.suggestion.re.howToUse.step1.detail1",
            "message.suggestion.re.howToUse.step1.detail2",
            "message.suggestion.re.howToUse.step1.detail3"
        ]
    },
    {
        title: "message.suggestion.re.howToUse.step2",
        text: ["message.suggestion.re.howToUse.step2.detail1"]
    },
    {
        title: "message.suggestion.re.howToUse.step3",
        text: [
            "message.suggestion.re.howToUse.step3.detail1",
            "message.suggestion.re.howToUse.points.payment.date"
        ]
    }
];
