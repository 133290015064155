import React from "react";
import { IconFont, Input, InputWrapper, InfoBox } from "reviewshare-common-component";
import useTranslate from "lib/customHooks/useTranslate";
import FormattedMessage from "components/common/FormattedMessage";

import commonStyle from "components/suggest/create/Common.module.scss";
import style from "./Info.module.scss";

const Info = ({ info, setInfo, invalidValue }) => {
    const translate = useTranslate();

    return (
        <div className={commonStyle.item}>
            <h3 className={commonStyle.itemTitle}>어떤 제품/서비스를 제공하시나요?</h3>
            <InfoBox
                color={invalidValue.info ? "red" : "blue"}
                className={`${commonStyle.infoBox} size-s m-size-s`}
            >
                <IconFont icon="icon_info" /> 제공 내역 / 리워드 포인트 중 1개 이상 입력 필수
            </InfoBox>
            <div className={commonStyle.itemContent}>
                <div className={style.field}>
                    <label htmlFor="suggest-modal-item-name" className={style.label}>
                        <FormattedMessage id="item.suggestion.item.name" />
                    </label>
                    <Input
                        id="suggest-modal-item-name"
                        value={info.itemName}
                        onChange={val => setInfo(prev => ({ ...prev, itemName: val }))}
                        placeholder={translate("message.suggestion.validate.itemName")}
                    />
                </div>
                <div className={style.field}>
                    <label htmlFor="suggest-modal-site-url" className={style.label}>
                        <FormattedMessage id="item.suggestion.url" /> (
                        <FormattedMessage id="item.optional" />)
                    </label>
                    <Input
                        id="suggest-modal-site-url"
                        value={info.siteUrl}
                        onChange={val => setInfo(prev => ({ ...prev, siteUrl: val }))}
                        placeholder={translate("message.suggestion.enter.url.induce")}
                    />
                </div>
                <div className={style.field}>
                    <label htmlFor="suggest-modal-points" className={style.label}>
                        <FormattedMessage id="item.suggestion.points" />
                    </label>
                    <InputWrapper suffix="P">
                        <Input
                            id="suggest-modal-points"
                            value={info.points}
                            onChange={val => setInfo(prev => ({ ...prev, points: val || 0 }))}
                            numberFormat
                        />
                    </InputWrapper>
                    {!!info.points && info.points % 1000 !== 0 && (
                        <p className={commonStyle.errorMsg}>
                            <IconFont icon="icon_caution_circle" />{" "}
                            <FormattedMessage id="message.suggestion.validate.point" />
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Info;
