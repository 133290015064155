import React from "react";
import { Link } from "react-router-dom";
import { StandardModal, Button } from "reviewshare-common-component";
import { toCapitalFirstLetter } from "lib/common";
import FormattedMessage from "components/common/FormattedMessage";
import Emoji from "components/common/Emoji";

import "./InterruptionModal.scss";

const InterruptionModal = ({ user, plan, closeModal }) => (
    <StandardModal id="interruption-modal" closeModal={closeModal}>
        <h3>
            <FormattedMessage id="message.subscription.biz.interruption.notice" />
        </h3>
        <div className="text-area">
            <FormattedMessage id="message.greeting" />{" "}
            <FormattedMessage id="target.biz" values={{ name: user?.name }} />!
            <Emoji emoji="🙂" /> {"\n"}
            <FormattedMessage
                id="message.subscription.biz.interruption.after"
                values={{
                    emphasis: (
                        <span className="blue-font">
                            <FormattedMessage id="message.subscription.biz.interruption.after.emphasis" />
                        </span>
                    )
                }}
            />
            {"\n"}
            <FormattedMessage id="message.subscription.biz.interruption.start.induce" />
        </div>
        <ul>
            <li className="reason-item">
                <p>
                    <FormattedMessage id="item.subscription.biz.plan.name" />
                </p>
                <span>{toCapitalFirstLetter(plan.plan)}</span>
            </li>
            <li className="reason-item">
                <p>
                    <FormattedMessage id="item.subscription.biz.unsubscribe.date" />
                </p>
                <span>{plan.endAt}</span>
            </li>
            <li className="reason-item">
                <p>
                    <FormattedMessage id="item.subscription.biz.unsubscribe.type" />
                </p>
                <span>
                    <FormattedMessage id="status.payment.fail" />
                </span>
            </li>
        </ul>
        <div className="button-area">
            <Link className="rs-button theme1 size-l m-size-l" to="/biz/plan/select">
                <FormattedMessage id="action.subscription.restart" />
            </Link>
            <Button className="theme6 size-l m-size-l" onClick={closeModal}>
                <FormattedMessage id="action.dontLook.again" />
            </Button>
        </div>
    </StandardModal>
);

export default InterruptionModal;
