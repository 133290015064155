import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { chatSocket } from "lib/api/socket";
import useScrollEnd from "lib/customHooks/useScrollEnd";
import { useUserType } from "lib/customHooks/useAuth";
import { useProgressItems } from "lib/customHooks/useProgress";
import { useMessengerState } from "lib/customHooks/useMessenger";
import {
    handleMessengerMainStatus,
    setSearchOptions,
    selectMessageBox,
    getMessageBoxList,
    addMessageBoxItem
} from "stores/modules/messenger/action";
import MessageBoxList from "components/messenger/side/MessageBoxList";
import WriteMessageBtn from "components/messenger/side/WriteMessageBtn";

const MessageBoxListContainer = () => {
    const dispatch = useDispatch();
    const isEndOfPage = useScrollEnd({ selector: "#messenger-side-message-box-list" });

    const userType = useUserType();
    const messageBox = useMessengerState("messageBox");
    const selectedMessageBoxOptions = useMessengerState("selectedMessageBoxOptions");

    const isSearching =
        useProgressItems("messenger/GET_MESSAGE_BOX_LIST") && !messageBox.list.length;

    const onSelectMessageBox = ({ reviewerId, brandId }) => {
        if (!reviewerId || !brandId) return;
        dispatch(selectMessageBox({ reviewerId, brandId }));
    };

    const onOpenWritingMessage = () => {
        dispatch(handleMessengerMainStatus("write"));
    };

    useEffect(() => {
        if (!messageBox.list.length) {
            dispatch(getMessageBoxList());
        }

        chatSocket.on("update_message_box_list", item => {
            dispatch(addMessageBoxItem(item));
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isEndOfPage && messageBox.counts > messageBox.list.length) {
            dispatch(setSearchOptions({ skip: messageBox.list.length }));
        }
        // eslint-disable-next-line
    }, [isEndOfPage]);

    return (
        <>
            <MessageBoxList
                userType={userType}
                isSearching={isSearching}
                list={messageBox.list}
                selectedReviewerId={selectedMessageBoxOptions.reviewerId}
                selectedBrandId={selectedMessageBoxOptions.brandId}
                selectMessageBox={onSelectMessageBox}
            />
            <WriteMessageBtn openWritingMessage={onOpenWritingMessage} />
        </>
    );
};

export default MessageBoxListContainer;
