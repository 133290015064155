import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactSwipe from "react-swipe";
import classnames from "classnames";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import {
    IconFont,
    MoreMenu,
    MoreMenuItem,
    Profile,
    StandardModal,
    NormalModal
} from "reviewshare-common-component";
import { convertSecondsToTime, numberWithCommas } from "lib/common";
import { convertSns } from "utils/sns";
import { convertProjectCategory } from "utils/project";
import { convertTalentCategory } from "utils/category";
import {
    getFeed,
    removeOtherFeed,
    handleHideFeed as handleHideFeedAction
} from "stores/modules/feed/action";
import useTranslate from "lib/customHooks/useTranslate";
import useCheckMobile from "lib/customHooks/useCheckMobile";
import { useSearchQuery, useSearchQueryAction } from "lib/customHooks/useSearchQuery";
import { useFeedAction } from "lib/customHooks/useFeed";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";
import ClickItem from "components/common/ClickItem";

import shorts_icon from "assets/images/snsIcon/icon_channel_shorts_transparent.svg";
import reels_icon from "assets/images/snsIcon/icon_channel_reels_transparent.svg";
import tiktok_icon from "assets/images/snsIcon/icon_channel_tiktok_transparent.svg";

import style from "./FeedDetailModal.module.scss";

const cx = classnames.bind(style);
const icons = {
    Shorts: shorts_icon,
    Reels: reels_icon,
    Tiktok: tiktok_icon
};

const ModalWrapper = ({ modalTitle, modalTitleBtn, className, closeModal, children, ...rest }) => {
    const translate = useTranslate();
    const isMobile = useCheckMobile();

    if (isMobile) {
        return (
            <StandardModal
                id="feed-detail-modal"
                modalTitle={translate("action.preview.target", { name: translate("item.content") })}
                className={cx(className, "full-modal")}
                align="center"
                modalTitleBtn={modalTitleBtn}
                closeModal={closeModal}
                {...rest}
            >
                {children}
            </StandardModal>
        );
    } else {
        return (
            <NormalModal className={cx(className, "full-modal")} {...rest}>
                <div className={style["action-items"]}>
                    {modalTitleBtn}
                    <button type="button" className={style["close-btn"]} onClick={closeModal}>
                        <IconFont icon="icon_close">닫기</IconFont>
                    </button>
                </div>
                {children}
            </NormalModal>
        );
    }
};

const FeedDetailModal = ({ onClose }) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const { editFeed } = useFeedAction();
    const history = useHistory();
    const location = useLocation();
    const { createSearchQuery } = useSearchQueryAction();
    const query = useSearchQuery();

    let reactSwipeEl;

    const userId = useSelector(({ auth }) => auth.userInfo.user?._id);
    const selectedInfo = useSelector(({ feed }) => feed.selectedInfo || {});
    const data = useSelector(({ feed }) => feed.detail || {});
    const isDataOwner = useMemo(
        () => !!userId && userId === data.owner?._id,
        // eslint-disable-next-line
        [userId || data.owner?._id]
    );
    const feedId = useMemo(
        () => data._id || data.id,
        // eslint-disable-next-line
        [data._id || data.id]
    );
    const service = useMemo(() => {
        if (data.project?._id) {
            return {
                _id: data.project._id,
                type: "project",
                title: data.project.title,
                category: convertProjectCategory(data.project.category),
                sns: data.sns,
                recruits: data.project.recruits,
                link: `/project/${data.project._id}`,
                thumbnail: data.project.photos?.[0]?.secure_url
            };
        }
        if (data.talent?._id) {
            return {
                _id: data.talent._id,
                type: "talent",
                title: data.talent.title,
                category: convertTalentCategory(data.talent.category.name),
                link: `/talent/${data.talent._id}`,
                thumbnail: data.talent.mainPhoto
            };
        }
        return null;
    }, [data.project?._id || data.talent?._id]);
    const [imageIndex, setImageIndex] = useState(0);

    const deleteFeed = () => {
        if (!data.isOther || !feedId) return;
        dispatch(removeOtherFeed(feedId));
    };

    const handleHideFeed = status => {
        dispatch(handleHideFeedAction({ feedId, isHide: status, showAlert: true }));
    };

    const onCloseModal = () => {
        if (query.feedId) {
            history.replace({
                pathname: location.pathname,
                search: createSearchQuery({
                    ...query,
                    feedId: undefined
                })
            });
        }
        onClose();
    };

    useEffect(() => {
        if (selectedInfo.id) {
            dispatch(getFeed(selectedInfo?.id));
        }
        // eslint-disable-next-line
    }, [selectedInfo.id]);

    return (
        <ModalWrapper
            id="feed-detail-modal"
            className={style.modal}
            closeModal={onCloseModal}
            modalTitleBtn={
                isDataOwner ? (
                    <MoreMenu direction="bottomRight">
                        <MoreMenuItem onClick={() => editFeed(data)}>
                            <FormattedMessage id="action.edit" />
                        </MoreMenuItem>
                        {data.isOther && (
                            <MoreMenuItem onClick={deleteFeed}>
                                <FormattedMessage id="action.delete" />
                            </MoreMenuItem>
                        )}
                        {data.isHide && data.hiddenBy === "Reviewer" && (
                            <MoreMenuItem onClick={() => handleHideFeed(false)}>
                                비공개 해제
                            </MoreMenuItem>
                        )}
                        {!data.isHide && (
                            <MoreMenuItem onClick={() => handleHideFeed(true)}>
                                콘텐츠 비공개
                            </MoreMenuItem>
                        )}
                    </MoreMenu>
                ) : null
            }
        >
            {Object.keys(data).length > 0 ? (
                <div className={style.wrapper}>
                    <div className={style["image-area"]}>
                        <div className={style["feed-image-wrapper"]}>
                            {data.contents.length > 1 ? (
                                <>
                                    <ReactSwipe
                                        ref={el => (reactSwipeEl = el)}
                                        className={style["feed-swiper"]}
                                        swipeOptions={{
                                            continuous: false,
                                            startSlide: imageIndex,
                                            transitionEnd: index => {
                                                setImageIndex(index);
                                            }
                                        }}
                                    >
                                        {[...data.contents].map((item, index) => (
                                            <div key={index}>
                                                {item.videoUrl ? (
                                                    <video
                                                        src={item.videoUrl}
                                                        autoPlay={index === 0}
                                                        controls
                                                        className={style["feed-image"]}
                                                    />
                                                ) : (
                                                    <img
                                                        className={style["feed-image"]}
                                                        src={item.photoUrl}
                                                        alt={translate("item.content")}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </ReactSwipe>
                                    <div className={style["slider-controls"]}>
                                        {imageIndex > 0 && (
                                            <button
                                                type="button"
                                                className={style["prev-btn"]}
                                                onClick={() => reactSwipeEl.prev()}
                                            >
                                                <IconFont icon="icon_arrow_left">
                                                    <FormattedMessage id="action.go.prev" />
                                                </IconFont>
                                            </button>
                                        )}
                                        {imageIndex < data.contents.length - 1 && (
                                            <button
                                                type="button"
                                                className={style["next-btn"]}
                                                onClick={() => reactSwipeEl.next()}
                                            >
                                                <IconFont icon="icon_arrow_right">
                                                    <FormattedMessage id="action.go.next" />
                                                </IconFont>
                                            </button>
                                        )}
                                    </div>
                                    <div className={style["pagination-box"]}>
                                        {imageIndex + 1}
                                        <span className={style["pagination-length"]}>
                                            /{data.contents.length}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                [...data.contents].map((item, index) => (
                                    <div key={index}>
                                        {item.videoUrl ? (
                                            <video
                                                src={item.videoUrl}
                                                controls
                                                autoPlay
                                                className={style["feed-image"]}
                                            />
                                        ) : (
                                            <img
                                                className={style["feed-image"]}
                                                src={item.photoUrl}
                                                alt={translate("item.content")}
                                            />
                                        )}
                                    </div>
                                ))
                            )}
                            {icons[data.sns] && (
                                <img
                                    className={style["sns-icon"]}
                                    src={icons[data.sns]}
                                    alt={data.sns}
                                />
                            )}
                        </div>
                        {data.review_url && (
                            <a
                                href={data.review_url}
                                className={cx(
                                    style["more-link"],
                                    "rs-button theme10 size-s m-size-m"
                                )}
                                target="_blank"
                                rel="noopener noreferrer nosublink"
                            >
                                <FormattedMessage id="action.read.more" />{" "}
                                <IconFont icon="icon_link" />
                            </a>
                        )}
                    </div>
                    <div className={style["info-area"]}>
                        <CommonLink
                            to={`/portfolio/${data.owner?.portfolioUrl}`}
                            className={style["profile-link"]}
                            disabled={!data.owner?.portfolioUrl}
                        >
                            <div className={style["user-profile"]}>
                                <Profile
                                    className="size-xs m-size-s"
                                    grade={data.owner?.grade}
                                    img={data.owner?.profile?.secure_url}
                                    alt={translate("item.portfolio.profile.target", {
                                        name: data.nickname
                                    })}
                                />
                                <p>
                                    {data.owner?.nickname || <FormattedMessage id="item.unknown" />}
                                </p>
                            </div>
                        </CommonLink>
                        <div className={style["content-area"]}>
                            {data.review_title && (
                                <p className={style["feed-title"]}>{data.review_title}</p>
                            )}
                            {data.type === "content" && data.contents?.length > 0 && (
                                <>
                                    <ul className={style["feed-preview-list"]}>
                                        {data.contents.map((item, index) => (
                                            <li
                                                key={index}
                                                className={cx(style["feed-preview-item"], {
                                                    [style.active]: index === imageIndex
                                                })}
                                            >
                                                <ClickItem onClick={() => setImageIndex(index)}>
                                                    {item.duration && (
                                                        <div
                                                            className={style["duration-background"]}
                                                        >
                                                            {convertSecondsToTime(item.duration)}
                                                        </div>
                                                    )}
                                                    <img
                                                        src={item.photoUrl}
                                                        alt={translate("item.content")}
                                                    />
                                                </ClickItem>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </div>
                        <div className={style["created-at"]}>
                            {data.isHide && (
                                <span className={style["lock-status"]}>
                                    <IconFont icon="icon_lock_fill" /> 비공개
                                </span>
                            )}
                            {moment(data.createdAt).format("YYYY.MM.DD")}에 게시한 콘텐츠입니다.
                        </div>
                        {service?._id && (
                            <div className={style["service-info"]}>
                                <p className={style["content-title"]}>
                                    <FormattedMessage id={`item.${service.type}`} /> 정보
                                </p>
                                <CommonLink
                                    className={style["service-link"]}
                                    to={service.link}
                                    disabled={!service._id}
                                >
                                    <img
                                        src={service.thumbnail}
                                        alt={translate("item.project.thumbnail")}
                                    />
                                    <div>
                                        <p className={style["service-title"]}>{service.title}</p>
                                        <div className={style["service-text"]}>
                                            {data.sns && (
                                                <span>
                                                    <FormattedMessage id={convertSns(data.sns)} />
                                                </span>
                                            )}
                                            <span>
                                                <FormattedMessage id={service.category} />
                                            </span>
                                            {service.recruits && (
                                                <span>
                                                    {translate(
                                                        "item.project.recruit.counts.target",
                                                        {
                                                            counts: numberWithCommas(
                                                                service?.recruits
                                                            )
                                                        }
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </CommonLink>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className={style.wrapper}>
                    <div className={style["image-area"]}>
                        <div className={style["feed-image-wrapper"]} />
                    </div>
                    <div className={style["info-area"]}>
                        <div className={style["user-profile"]}>
                            <Profile className="size-xs m-size-s" skeleton />
                            <p className={style.skeleton}>
                                <Skeleton />
                            </p>
                        </div>
                        <div className={style["content-area"]}>
                            <p className={style["feed-title"]}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </ModalWrapper>
    );
};
export default FeedDetailModal;
