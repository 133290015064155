import React from "react";
import { FormattedMessage } from "react-intl";

const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
const FormattedMessageComponent = ({ id, values, ...rest }) => {
    let message = null;

    if (!id) return message;
    if (typeof id !== "string") return id;
    if (korean.test(id)) return id;

    message = <FormattedMessage id={id} values={values} defaultMessage={id} {...rest} />;

    return message;
};

export default FormattedMessageComponent;
