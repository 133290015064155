import client from "./client";

export const getPoints = () => client.get(`/v2/wallets`);
export const getReviewerPointsHistory = ({ type, status, days, skip, limit }) =>
    client.get("/v2/points", {
        params: {
            type,
            status,
            days,
            skip,
            limit
        }
    });
