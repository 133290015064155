export const setStorage = (name, data) => {
    if (!name || !data) {
        console.warn("localStorage에 저장할 name 또는 data를 입력해주세요.");
        return null;
    }
    localStorage.setItem(name, data);
};

export const getStorage = name => {
    if (!name) {
        console.warn("localStorage에서 가져올 name을 입력해주세요.");
        return null;
    }

    const value = localStorage.getItem(name);
    try {
        return JSON.parse(value);
    } catch (error) {
        return value;
    }
};

export const removeStorage = name => {
    if (!name) {
        console.warn("localStorage에서 지울 name을 입력해주세요.");
        return null;
    }
    localStorage.removeItem(name);
};
