import React from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import { IconFont, Button, Bubble } from "reviewshare-common-component";
import { returnReceiverType } from "utils/messenger";
import {
    handleMessengerMainStatus,
    handleMessengerMainFunction,
    selectWriteInfo
} from "stores/modules/messenger/action";
import useCheckMobile from "lib/customHooks/useCheckMobile";
import { useUserType } from "lib/customHooks/useAuth";
import useTranslate from "lib/customHooks/useTranslate";
import WarningBox from "components/messenger/main/WarningBox";

import "./WriteInfo.scss";

const WriteInfo = () => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const isMobile = useCheckMobile();
    const userType = useUserType();
    const writeInfo = useSelector(({ messenger }) => messenger.writeInfo);

    const onResetMainStatus = () => {
        dispatch(handleMessengerMainStatus("none"));
    };

    const onSelectType = type => {
        dispatch(handleMessengerMainFunction(type));
    };

    const onRemoveTo = index => {
        const toList = [...writeInfo.toList];
        toList.splice(index, 1);
        dispatch(
            selectWriteInfo({
                from: writeInfo.from,
                toList
            })
        );
    };

    const onRemoveFrom = () => {
        dispatch(
            selectWriteInfo({
                from: {},
                toList: writeInfo.toList
            })
        );
    };

    return (
        <div className="messenger-main-write-info">
            <div className="title-box">
                <button type="button" className="back-btn" onClick={onResetMainStatus}>
                    <IconFont icon="icon_line_arrow_left">뒤로가기</IconFont>
                </button>
                <h3>새 쪽지 쓰기</h3>
            </div>
            {userType === "Seller" && (
                <div className={cx("select-item", { "has-remove-list": !!writeInfo.from.brandId })}>
                    <p className="select-item-title">브랜드</p>
                    <div
                        className={cx("select-item-content", { active: !!writeInfo.from.brandId })}
                    >
                        {writeInfo.from.brandId ? (
                            <div className="remove-btn-list">
                                <Button
                                    className="theme11 size-s m-size-xs remove-btn"
                                    onClick={onRemoveFrom}
                                >
                                    <p className="overflow-ellipse">{writeInfo.from.brandName}</p>
                                    <IconFont icon="icon_close">삭제</IconFont>
                                </Button>
                            </div>
                        ) : (
                            <div role="tooltip">
                                <Button
                                    className="theme4 size-s m-size-xs add-btn"
                                    onClick={() => onSelectType("selectFrom")}
                                >
                                    <span className="web">
                                        {translate("action.select.target", {
                                            name: translate("item.brand")
                                        })}
                                    </span>
                                    <IconFont icon="icon_plus_circle_fill">추가</IconFont>
                                </Button>
                                <Bubble
                                    direction={isMobile ? "left" : "right"}
                                    className="size-s m-size-s"
                                >
                                    브랜드를 선택해 주세요.
                                </Bubble>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className={cx("select-item", { "has-remove-list": writeInfo.toList.length > 0 })}>
                <p className="select-item-title">받는 {returnReceiverType(userType)}</p>
                <div className={cx("select-item-content", { active: writeInfo.toList.length > 0 })}>
                    {writeInfo.toList.length > 0 && (
                        <div className="remove-btn-list">
                            {writeInfo.toList.map((item, index) => (
                                <Button
                                    className="theme11 size-s m-size-xs remove-btn"
                                    onClick={() => onRemoveTo(index)}
                                    key={index}
                                >
                                    <p className="overflow-ellipse">
                                        {item.nickname || item.brandName}
                                    </p>
                                    <IconFont icon="icon_close">삭제</IconFont>
                                </Button>
                            ))}
                        </div>
                    )}
                    <Button
                        className="theme4 size-s m-size-xs add-btn"
                        onClick={() => onSelectType("selectTo")}
                    >
                        <span className="web">
                            {translate("action.select.target", {
                                name: translate(returnReceiverType(userType))
                            })}
                        </span>
                        <IconFont icon="icon_plus_circle_fill">
                            {translate("action.select.target", {
                                name: translate(returnReceiverType(userType))
                            })}
                        </IconFont>
                    </Button>
                </div>
            </div>
            <WarningBox position="write" />
        </div>
    );
};
export default WriteInfo;
