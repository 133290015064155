import React, { useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { IconFont } from "reviewshare-common-component";
import { DIRECT_WARNING_NOTICE } from "scheme/link";
import { useUserType } from "lib/customHooks/useAuth";
import { useSelectedMessageBox } from "lib/customHooks/useMessenger";
import useRelationStatus from "lib/customHooks/useRelationStatus";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";
import ClickItem from "components/common/ClickItem";

import "./WarningBox.scss";

const MESSAGE_LIST = {
    Normal: (
        <>
            해당 브랜드와 현재 진행중인 프로젝트/거래/제안이 없어요. 직거래에 응할 경우,
            리뷰어님에게 불이익이 발생할 수 있으니{" "}
            <CommonLink to="support/requests">고객센터</CommonLink>로 신고해 주세요. {"\n"}
        </>
    ),
    Seller: (
        <>
            프로젝트 선정, 제안 및 거래 절차 없이 쪽지(메일)로 결과물을 요청하는 경우 {"\n"}
            프로젝트 등록 제한 / 보유 포인트 환불 불가 / 계정 중지 등 서비스 이용이 제한될 수
            있습니다.
        </>
    )
};

// type = ["list", "write"]
const WarningBox = ({ position = "list" }) => {
    const userType = useUserType();
    const warningStatus = useSelectedMessageBox("warning");
    const target = useSelector(({ messenger }) => messenger.writeInfo?.toList?.[0] || {});
    const { relationStatus } = useRelationStatus({
        status: target.relation?.status,
        userId: target.reviewerId
    });
    const [moreStatus, setMoreStatus] = useState(false);

    if (relationStatus === "block") {
        return (
            <div className={cx("messenger-warning-box", position, { more: moreStatus })}>
                <div className="text-area">
                    차단한 리뷰어님과는 쪽지를 주고 받을 수 없어요. 다시 소통을 원하실 경우, 차단을
                    해제해 주세요.
                </div>
            </div>
        );
    }

    if (position === "list" && !warningStatus) return null;
    if (position === "write" && userType === "Normal") return null;
    return (
        <div className={cx("messenger-warning-box", position, { more: moreStatus })}>
            <div className="text-area">
                {position === "write" ? (
                    <p>
                        <IconFont icon="icon_caution_fill" /> 직접 거래 주의 안내
                    </p>
                ) : (
                    <span className="bold-font">[직접 거래 주의 안내] </span>
                )}
                {MESSAGE_LIST[userType]}
                {position === "write" ? (
                    <>
                        <br />
                        <CommonLink to={DIRECT_WARNING_NOTICE}>
                            직접 거래 페널티 <IconFont icon="icon_arrow_right">바로가기</IconFont>
                        </CommonLink>
                    </>
                ) : (
                    <>
                        {" "}
                        <CommonLink to={DIRECT_WARNING_NOTICE}>직접 거래 페널티</CommonLink>
                    </>
                )}
            </div>
            {position === "list" && (
                <ClickItem onClick={() => setMoreStatus(!moreStatus)} className="more-btn">
                    <IconFont icon={moreStatus ? "icon_arrow_up" : "icon_arrow_down"}>
                        <FormattedMessage id="action.view.more" />
                    </IconFont>
                </ClickItem>
            )}
        </div>
    );
};

export default WarningBox;
