export const DEFAULT_HEADER_MENU = {
    leftMenu: [
        {
            label: "menu.project",
            value: "project",
            link: "/project"
        },
        { label: "menu.talent", value: "talent", link: "/talent" },
        { label: "menu.suggestion", value: "portfolio", link: "/portfolio" },
        { label: "menu.content", value: "community", link: "/feed" }
    ],
    rightMenu: [
        {
            label: "menu.biz.center",
            value: "business_center",
            link: "/biz/main"
        },
        {
            label: "menu.cs",
            value: "service_none_member",
            link: `/support`
        },
        {
            label: "menu.biz.intro",
            value: "business_intro",
            link: `/bizintro`
        }
    ],
    subMenu: []
};

export const REVIEWER_HEADER_MENU = {
    leftMenu: DEFAULT_HEADER_MENU.leftMenu,
    rightMenu: [
        { label: "menu.re.project.submit", value: "submitReview", link: "/mypage/chosen" },
        {
            label: "menu.cs",
            value: "service",
            link: "/support"
        }
    ],
    subMenu: [
        { label: "menu.re.mypage", value: "myPage", link: "/mypage" },
        { label: "menu.re.content.list", value: "myContent", link: "/mypage/content" },
        { label: "menu.re.portfolio", value: "portfolio" },
        { label: "menu.re.score+tier", value: "score", link: "/score" },
        { label: "menu.re.activity", value: "myActivity", link: "/mypage/request" },
        { label: "menu.re.order+talent.manage", value: "mytalent", link: "/mytalent/order" }
    ]
};

export const BUSINESS_HEADER_MENU = {
    leftMenu: DEFAULT_HEADER_MENU.leftMenu,
    rightMenu: [
        { label: "menu.biz.project.create", value: "createProject", link: "/biz/create/1" },
        {
            label: "menu.cs",
            value: "service",
            link: "/support"
        },
        {
            label: "menu.biz.intro",
            value: "business_intro",
            link: `/bizintro`
        }
    ],
    subMenu: [
        { label: "menu.biz.center", value: "myPage", link: "/biz/main" },
        { label: "menu.biz.subscription", value: "subscription", link: "/biz/plan/manage" },
        { label: "menu.biz.relation.list", value: "relation", link: "/biz/relation/list" },
        {
            label: "menu.biz.project.manage",
            value: "ctrlProject",
            link: "/biz/manage/recruit"
        },
        { label: "menu.re.order.manage", value: "ctrlOrder", link: "/biz/order/request" },
        {
            label: "menu.biz.suggestion.manage",
            value: "ctrlSuggestion",
            link: "/biz/suggestion/waiting"
        }
    ]
};

export const BUSINESS_CENTER_SIDE_MENU = {
    main: { title: "menu.biz.center.home", id: "biz-left-home", link: "/biz/main" },
    project: {
        title: "menu.biz.project.manage",
        id: "biz-left-proj",
        link: "/biz/manage/standby",
        subList: [
            {
                label: "status.project.biz.standby",
                value: "/biz/manage/standby",
                id: "biz-left-proj-standby"
            },
            {
                label: "status.project.biz.recruit",
                value: "/biz/manage/recruit",
                id: "biz-left-proj-recruit"
            },
            {
                label: "status.project.biz.submit",
                value: "/biz/manage/submit",
                id: "biz-left-proj-submit"
            },
            {
                label: "status.project.biz.complete",
                value: "/biz/manage/complete",
                id: "biz-left-proj-complete"
            },
            {
                label: "menu.biz.project.create",
                value: "/biz/create/1",
                id: "biz-left-proj-create"
            }
        ],
        subIsOnlyMobile: true
    },
    order: {
        title: "menu.biz.order.manage",
        id: "biz-left-order",
        link: "/biz/order/request",
        subList: [
            {
                label: "status.order.biz.request",
                value: "/biz/order/request"
            },
            {
                label: "status.order.biz.running",
                value: "/biz/order/running"
            },
            {
                label: "status.order.biz.complete",
                value: "/biz/order/complete"
            },
            {
                label: "status.order.biz.cancel",
                value: "/biz/order/cancel"
            }
        ],
        subIsOnlyMobile: true
    },
    suggestion: {
        title: "menu.biz.suggestion.manage",
        id: "biz-left-prop",
        link: "/biz/suggestion/waiting"
    },
    plan: {
        title: "menu.biz.subscription",
        id: "biz-left-plan",
        subList: [
            {
                label: "menu.biz.subscription.mangage",
                value: "/biz/plan/manage",
                id: "biz-left-plan-manage"
            },
            {
                label: "menu.biz.subscription.select",
                value: "/biz/plan/select",
                id: "biz-left-plan-select"
            }
        ]
    },
    payment: {
        title: "menu.biz.payment.manage",
        id: "biz-left-payment",
        subList: [
            {
                label: "menu.biz.payment.list",
                value: "/biz/payment",
                id: "biz-left-payment-list"
            },
            {
                label: "menu.biz.payment.charge",
                value: "/biz/chargepoint",
                id: "biz-left-payment-chargepoint"
            }
        ]
    },
    brand: {
        title: "menu.biz.brand.manage",
        id: "biz-left-brand",
        subList: [
            {
                label: "menu.biz.brand.list",
                value: "/biz/brand/list",
                id: "biz-left-brand-total"
            },
            {
                label: "menu.biz.brand.create",
                value: "/biz/brand/create",
                id: "biz-left-brand-total-create"
            }
        ]
    },
    relation: {
        title: "menu.biz.relation.list",
        id: "biz-left-relation",
        link: "/biz/relation/list",
        subList: [
            {
                label: "menu.biz.relation.list",
                value: "/biz/relation/list",
                id: "biz-left-relation-list"
            },
            {
                label: "menu.biz.relation.recommend",
                value: "/biz/relation/recommend",
                id: "biz-left-relation-recommend"
            }
        ],
        subIsOnlyMobile: true
    }
};

export const MOBILE_QUICK_MENU = {
    reviewer: [
        {
            label: "menu.re.activity",
            path: "/mypage/request",
            iconFont: "icon_activity"
        },
        {
            label: "menu.re.order+talent.manage",
            path: "/mytalent/talent",
            iconFont: "icon_talent"
        },
        {
            label: "menu.re.portfolio",
            external: "portfolio",
            iconFont: "icon_portfolio"
        }
    ],
    business: [
        {
            label: "menu.biz.project.create",
            path: "/biz/create/1",
            iconFont: "icon_memo"
        },
        {
            label: "menu.biz.project.manage",
            path: "/biz/manage/recruit",
            iconFont: "icon_manage_project"
        },
        {
            label: "menu.biz.order.manage",
            path: "/biz/order/request",
            iconFont: "icon_talent"
        }
    ]
};

export const MOBILE_MENU = {
    none: [
        {
            label: "item.reviewer",
            list: [
                {
                    label: "menu.re.intro",
                    path: "/intro"
                },
                {
                    label: "menu.re.guide",
                    path: "https://blog.naver.com/reviewshare",
                    external: true
                }
            ]
        },
        {
            label: "item.biz",
            list: [
                {
                    label: "menu.biz.intro",
                    path: "/bizintro"
                },
                { label: "대행 소개", path: "/bizintro/partner" },
                {
                    label: "menu.biz.center",
                    path: "/biz/main"
                },
                {
                    label: "menu.biz.guide",
                    path: "https://blog.naver.com/reviewshare",
                    external: true
                }
            ]
        },
        {
            label: "menu.cs",
            list: [
                {
                    label: "menu.cs.request",
                    path: "/support/requests"
                },
                {
                    label: "menu.cs.faq",
                    path: "/support/faq"
                },
                {
                    label: "menu.cs.notice",
                    path: "/support/notice"
                }
            ]
        }
    ],
    reviewer: [
        {
            label: "menu.home",
            icon: "/images/header/icons/icon_hamburger_home.svg",
            activeIcon: "/images/header/icons/icon_hamburger_home_main_color.svg",
            path: ["/"]
        },
        {
            label: "menu.project",
            icon: "/images/header/icons/icon_hamburger_brandmanage.svg",
            activeIcon: "/images/header/icons/icon_hamburger_brandmanage_main_color.svg",
            path: ["/project"]
        },
        {
            label: "menu.content",
            icon: "/images/header/icons/icon_hamburger_message.svg",
            activeIcon: "/images/header/icons/icon_hamburger_message_main_color.svg",
            path: ["/feed"]
        },
        {
            label: "menu.re.mypage",
            icon: "/images/header/icons/icon_hamburger_projectmanage.svg",
            activeIcon: "/images/header/icons/icon_hamburger_projectmanage_main_color.svg",
            path: ["/mypage"]
        },
        {
            label: "menu.re.activity",
            icon: "/images/header/icons/icon_hamburger_activity.svg",
            activeIcon: "/images/header/icons/icon_hamburger_activity_main_color.svg",
            path: ["/mypage/request"]
        },
        {
            label: "menu.re.order+talent.manage",
            icon: "/images/header/icons/icon_hamburger_talent.svg",
            activeIcon: "/images/header/icons/icon_hamburger_talent_active.svg",
            path: ["/mytalent/order"]
        }
    ],
    business: [
        {
            label: "menu.home",
            icon: "/images/header/icons/icon_hamburger_home.svg",
            activeIcon: "/images/header/icons/icon_hamburger_home_main_color.svg",
            path: ["/"]
        },
        {
            label: "menu.biz.project.manage",
            icon: "/images/header/icons/icon_hamburger_projectmanage.svg",
            activeIcon: "/images/header/icons/icon_hamburger_projectmanage_active.svg",
            path: [
                "/biz/manage/standby",
                "/biz/manage/recruit",
                "/biz/manage/submit",
                "/biz/manage/complete"
            ]
        },
        {
            label: "menu.biz.order.manage",
            icon: "/images/header/icons/icon_hamburger_talent.svg",
            activeIcon: "/images/header/icons/icon_hamburger_talent_active.svg",
            path: [
                "/biz/order/request",
                "/biz/order/running",
                "/biz/order/complete",
                "/biz/order/cancel"
            ]
        },
        {
            label: "menu.biz.suggestion.manage",
            icon: "/images/header/icons/icon_hamburger_suggestion.svg",
            activeIcon: "/images/header/icons/icon_hamburger_suggestion_active.svg",
            path: ["/biz/suggestion/waiting", "/biz/suggestion/running"]
        },
        {
            label: "menu.biz.subscription",
            icon: "/images/header/icons/icon_hamburger_subscription.svg",
            activeIcon: "/images/header/icons/icon_hamburger_subscription_active.svg",
            path: ["/biz/plan/select", "/biz/plan/manage"],
            sub: [
                { label: "menu.biz.subscription.mangage", path: "/biz/plan/manage" },
                { label: "menu.biz.subscription.select", path: "/biz/plan/select" }
            ]
        },
        {
            label: "menu.biz.payment.manage",
            icon: "/images/header/icons/icon_hamburger_paymenthistory.svg",
            activeIcon: "/images/header/icons/icon_hamburger_paymenthistory_active.svg",
            path: ["/biz/payment", "/biz/chargepoint"],
            sub: [
                { label: "menu.biz.payment.list", path: "/biz/payment" },
                { label: "menu.biz.payment.charge", path: "/biz/chargepoint" }
            ]
        },
        {
            label: "menu.biz.brand.manage",
            icon: "/images/header/icons/icon_hamburger_brandmanage.svg",
            activeIcon: "/images/header/icons/icon_hamburger_brandmanage_active.svg",
            path: ["/biz/brand/list", "/biz/brand/create"],
            sub: [
                { label: "menu.biz.brand.list", path: "/biz/brand/list" },
                { label: "menu.biz.brand.create", path: "/biz/brand/create" }
            ]
        },
        {
            label: "menu.biz.relation.list",
            icon: "/images/header/icons/icon_hamburger_relation.svg",
            activeIcon: "/images/header/icons/icon_hamburger_relation_active.svg",
            path: ["/biz/relation/list"]
        }
    ]
};

export const FOOTER_SITE_MAP_LIST = [
    {
        title: "menu.reviewshare",
        target: ["Visitor", "Seller", "Normal"],
        menu: [
            { label: "menu.project", link: "/project" },
            { label: "menu.talent", link: "/talent" },
            { label: "menu.suggestion", link: "/portfolio" },
            { label: "menu.content", link: "/feed" }
        ]
    },
    {
        title: "item.biz",
        target: ["Visitor", "Seller"],
        menu: [
            { label: "menu.biz.intro", link: "/bizintro" },
            { label: "대행 소개", link: "/bizintro/partner" },
            { label: "menu.biz.guide", link: "https://blog.naver.com/reviewshare", external: true },
            { label: "menu.signup.biz", link: "/signin", target: ["Visitor"] },
            { label: "menu.biz.center", link: "/biz/main" }
        ]
    },
    {
        title: "item.reviewer",
        target: ["Visitor", "Normal"],
        menu: [
            { label: "menu.re.intro", link: "/intro" },
            { label: "menu.re.guide", link: "https://blog.naver.com/reviewshare", external: true },
            { label: "menu.signup.re", link: "/register", target: ["Visitor"] },
            { label: "menu.re.mypage", link: "/mypage" }
        ]
    },
    {
        title: "menu.cs",
        target: ["Visitor", "Seller", "Normal"],
        menu: [
            { label: "menu.cs.request", link: "/support/requests" },
            {
                label: "menu.cs.request.list",
                link: "/support/my-requests",
                target: ["Seller", "Normal"]
            },
            { label: "menu.cs.faq", link: "/support/faq" },
            { label: "menu.cs.notice", link: "/support/notice" }
        ]
    }
];

export const BOTTOM_TAB_MENU_SCHEME = [
    {
        label: "menu.home",
        path: "/",
        userType: "common",
        icon: "/images/header/icons/icon_tabbar_home.svg",
        activeIcon: "/images/header/icons/icon_tabbar_home_fill.svg"
    },
    {
        label: "menu.project",
        path: "/project",
        userType: "common",
        icon: "/images/header/icons/icon_tabbar_project.svg",
        activeIcon: "/images/header/icons/icon_tabbar_project_fill.svg"
    },
    {
        label: "menu.talent",
        path: "/talent",
        userType: "common",
        icon: "/images/header/icons/icon_tabbar_talent.svg",
        activeIcon: "/images/header/icons/icon_tabbar_talent_fill.svg"
    },
    {
        label: "menu.suggestion",
        path: "/portfolio",
        userType: "common",
        icon: "/images/header/icons/icon_tabbar_suggestion.svg",
        activeIcon: "/images/header/icons/icon_tabbar_suggestion_fill.svg"
    },
    {
        label: "menu.re.mypage",
        path: "/mypage",
        userType: "Normal",
        icon: "/images/header/icons/icon_tabbar_mypage.svg",
        activeIcon: "/images/header/icons/icon_tabbar_mypage_fill.svg"
    },
    {
        label: "menu.biz.center",
        path: "/biz/main",
        userType: "Seller",
        icon: "/images/header/icons/icon_tabbar_mypage.svg",
        activeIcon: "/images/header/icons/icon_tabbar_mypage_fill.svg"
    }
];
