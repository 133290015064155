import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { createRequestActionTypes } from "lib/createRequestSaga";

export const RESET_FEED = "feed/RESET_FEED";
export const SELECT_FEED = "feed/SELECT_FEED";

export const [GET_FEED, GET_FEED_SUCCESS, GET_FEED_FAILURE] = createRequestActionTypes(
    "feed/GET_FEED"
);

export const [
    GET_FEED_LIST,
    GET_FEED_LIST_SUCCESS,
    GET_FEED_LIST_FAILURE
] = createRequestActionTypes("feed/GET_FEED_LIST");

export const [
    GET_MY_FEED_LIST,
    GET_MY_FEED_LIST_SUCCESS,
    GET_MY_FEED_LIST_FAILURE
] = createRequestActionTypes("feed/GET_MY_FEED_LIST");

export const [
    GET_FEED_RECOMMEND_KEYWORD_LIST,
    GET_FEED_RECOMMEND_KEYWORD_LIST_SUCCESS,
    GET_FEED_RECOMMEND_KEYWORD_LIST_FAILURE
] = createRequestActionTypes("feed/GET_FEED_RECOMMEND_KEYWORD_LIST");

export const [
    GET_FEED_PICK_LIST,
    GET_FEED_PICK_LIST_SUCCESS,
    GET_FEED_PICK_LIST_FAILURE
] = createRequestActionTypes("feed/GET_FEED_PICK_LIST");

export const [
    GET_PORTFOLIO_CONTENT_LIST,
    GET_PORTFOLIO_CONTENT_LIST_SUCCESS,
    GET_PORTFOLIO_CONTENT_LIST_FAILURE
] = createRequestActionTypes("feed/GET_PORTFOLIO_CONTENT_LIST");

export const [
    GET_PORTFOLIO_SHORTFORM_LIST,
    GET_PORTFOLIO_SHORTFORM_LIST_SUCCESS,
    GET_PORTFOLIO_SHORTFORM_LIST_FAILURE
] = createRequestActionTypes("feed/GET_PORTFOLIO_SHORTFORM_LIST");

export const [
    GET_BIZ_FEED_LIST,
    GET_BIZ_FEED_LIST_SUCCESS,
    GET_BIZ_FEED_LIST_FAILURE
] = createRequestActionTypes("feed/GET_BIZ_FEED_LIST");

export const [
    SUBMIT_OTHER_FEED,
    SUBMIT_OTHER_FEED_SUCCESS,
    SUBMIT_OTHER_FEED_FAILURE
] = createRequestActionTypes("feed/SUBMIT_OTHER_FEED");

export const [
    REMOVE_OTHER_FEED,
    REMOVE_OTHER_FEED_SUCCESS,
    REMOVE_OTHER_FEED_FAILURE
] = createRequestActionTypes("feed/REMOVE_OTHER_FEED");

export const [
    HANDLE_HIDE_FEED,
    HANDLE_HIDE_FEED_SUCCESS,
    HANDLE_HIDE_FEED_FAILURE
] = createRequestActionTypes("feed/HANDLE_HIDE_FEED");

export const resetFeed = createAction(RESET_FEED, id => id);
export const selectFeed = createAction(SELECT_FEED, id => id);
export const getFeed = createAction(GET_FEED, id => id);
export const getFeedList = createAction(GET_FEED_LIST, options => options);
export const getMyFeedList = createAction(GET_MY_FEED_LIST, options => options);
export const getFeedPickList = createAction(GET_FEED_PICK_LIST, options => options);
export const getFeedRecommendKeywordList = createAction(
    GET_FEED_RECOMMEND_KEYWORD_LIST,
    options => options
);
export const getPortfolioContentList = createAction(GET_PORTFOLIO_CONTENT_LIST, options => options);
export const getPortfolioShortformList = createAction(
    GET_PORTFOLIO_SHORTFORM_LIST,
    option => option
);
export const getBizFeedList = createAction(GET_BIZ_FEED_LIST, query => query);
export const submitOtherFeed = createAction(SUBMIT_OTHER_FEED, obj => obj);
export const removeOtherFeed = createAction(REMOVE_OTHER_FEED, id => id);
export const handleHideFeed = createAction(HANDLE_HIDE_FEED);

const initialState = {
    selectedInfo: {},
    detail: {},
    all: {
        list: [],
        counts: 0
    },
    myFeed: {
        counts: 0,
        list: []
    },
    pick: {
        counts: 0,
        list: []
    },
    keywordList: [],
    portfolio: {
        content: {
            counts: 0,
            list: []
        },
        shortform: {
            counts: 0,
            list: []
        }
    },
    bizFeed: {
        counts: 0,
        list: []
    }
};

const excludeFeedInList = ({ draft, state, feedId }) => {
    if (state.all.list.find(item => item.id === feedId)) {
        draft.all.list = state.all.list.filter(item => item.id !== feedId);
        draft.all.counts = state.all.counts - 1;
    }
    if (state.portfolio.content.list.find(item => item.id === feedId)) {
        draft.portfolio.content.list = state.portfolio.content.list.filter(
            item => item.id !== feedId
        );
        draft.portfolio.content.counts = state.portfolio.content.counts - 1;
    }
    if (state.portfolio.shortform.list.find(item => item.id === feedId)) {
        draft.portfolio.shortform.list = state.portfolio.shortform.list.filter(
            item => item.id !== feedId
        );
        draft.portfolio.shortform.counts = state.portfolio.shortform.counts - 1;
    }
};

const feed = handleActions(
    {
        [RESET_FEED]: (state, { payload }) =>
            produce(state, draft => {
                if (payload?.key) {
                    draft[payload.key] = initialState[payload.key];
                } else {
                    draft = initialState;
                }
            }),
        [SELECT_FEED]: (state, { payload }) =>
            produce(state, draft => {
                if (payload?.mode) {
                    draft.selectedInfo.id = payload?.id;
                    draft.selectedInfo.mode = payload?.mode || "view";
                    draft.selectedInfo.link = payload?.link || null;
                } else {
                    draft.selectedInfo = initialState.selectedInfo;
                    draft.detail = initialState.detail;
                }
            }),
        [GET_FEED_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.detail = payload;
            }),
        [GET_FEED_FAILURE]: state =>
            produce(state, draft => {
                draft.selectedInfo = initialState.selectedInfo;
                draft.detail = initialState.detail;
            }),
        [GET_FEED_LIST]: (state, { payload }) =>
            produce(state, draft => {
                if (!payload.skip) {
                    draft.all = initialState.all;
                }
            }),
        [GET_FEED_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.all.counts = payload.totalCounts;
                draft.all.list = state.all.list.concat(payload.list);
            }),
        [GET_MY_FEED_LIST]: (state, { payload }) =>
            produce(state, draft => {
                if (!payload.skip) {
                    draft.myFeed = initialState.myFeed;
                }
            }),
        [GET_MY_FEED_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.myFeed.counts = payload.totalCounts;
                draft.myFeed.list = [...state.myFeed.list, ...payload.list];
            }),
        [GET_FEED_PICK_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.pick.counts = payload.totalCount;
                draft.pick.list = [...state.pick.list, ...payload.list];
            }),
        [GET_FEED_RECOMMEND_KEYWORD_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.keywordList = payload || [];
            }),
        [GET_PORTFOLIO_CONTENT_LIST_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                draft.portfolio.content = {
                    counts: payload.totalCounts,
                    list:
                        prev.skip > 0
                            ? [...state.portfolio.content.list, ...payload.list]
                            : payload.list
                };
            }),
        [GET_PORTFOLIO_SHORTFORM_LIST_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                draft.portfolio.shortform = {
                    counts: payload.totalCounts,
                    list:
                        prev.skip > 0
                            ? [...state.portfolio.shortform.list, ...payload.list]
                            : payload.list
                };
            }),
        [GET_BIZ_FEED_LIST]: state =>
            produce(state, draft => {
                draft.bizFeed.list = initialState.bizFeed.list;
            }),
        [GET_BIZ_FEED_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.bizFeed.counts = payload.totalCounts;
                draft.bizFeed.list = payload.list;
            }),
        [SUBMIT_OTHER_FEED_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                draft.selectedInfo = initialState.selectedInfo;
                if (payload?.[0]?.reviewPhoto !== "-") {
                    const isEdit = !!prev?.id;
                    draft.myFeed.counts = isEdit ? state.myFeed.counts : state.myFeed.counts + 1;
                    draft.myFeed.list = isEdit
                        ? state.myFeed.list.map(item => (item.id === prev.id ? payload[0] : item))
                        : [...payload, ...state.myFeed.list];
                }
            }),
        [REMOVE_OTHER_FEED_SUCCESS]: (state, { prev: feedId }) =>
            produce(state, draft => {
                draft.selectedInfo = initialState.selectedInfo;
                draft.detail = initialState.detail;
                if (state.myFeed.list.find(item => item.id === feedId)) {
                    draft.myFeed.list = state.myFeed.list.filter(item => item.id !== feedId);
                    draft.myFeed.counts = state.myFeed.counts - 1;
                }
                excludeFeedInList({ draft, state, feedId });
            }),
        [HANDLE_HIDE_FEED_SUCCESS]: (state, { prev = {} }) =>
            produce(state, draft => {
                if (state.detail?._id === prev.feedId) {
                    draft.detail.isHide = !!prev.isHide;
                }
                if (draft.myFeed?.list?.length > 0) {
                    draft.myFeed.list = state.myFeed.list.map(item => {
                        if (item.id === prev.feedId) {
                            return {
                                ...item,
                                isHide: !!prev.isHide,
                                hiddenBy: prev.isHide ? "Reviewer" : undefined
                            };
                        }
                        return item;
                    });
                }
                if (prev.isHide) {
                    draft.selectedInfo = initialState.selectedInfo;
                    excludeFeedInList({
                        draft,
                        state,
                        feedId: prev.feedId
                    });
                }
            })
    },
    initialState
);

export default feed;
