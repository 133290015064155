import { takeLatest } from "redux-saga/effects";
import { createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as postAPI from "lib/api/post";
import * as commentAPI from "lib/api/comment";

import {
    GET_FAQ_LIST,
    GET_NOTICE_LIST,
    GET_NOTICE_DETAIL,
    GET_REQUESTS_LIST,
    GET_REQUESTS
} from "stores/modules/helpCenter/action";

const getFaqListSaga = createRequestSagaWithAlert(GET_FAQ_LIST, postAPI.getFaqList);

const getNoticeListSaga = createRequestSagaWithAlert(GET_NOTICE_LIST, postAPI.getNoticeList);

const getNoticeDetailSaga = createRequestSagaWithAlert(GET_NOTICE_DETAIL, postAPI.getNoticeDetail);

const getRequestsListSaga = createRequestSagaWithAlert(
    GET_REQUESTS_LIST,
    commentAPI.getMyRequestsList
);

const getRequestsSaga = createRequestSagaWithAlert(GET_REQUESTS, commentAPI.getMyRequests);

export function* helpCenterSaga() {
    yield takeLatest(GET_FAQ_LIST, getFaqListSaga);
    yield takeLatest(GET_NOTICE_LIST, getNoticeListSaga);
    yield takeLatest(GET_NOTICE_DETAIL, getNoticeDetailSaga);
    yield takeLatest(GET_REQUESTS_LIST, getRequestsListSaga);
    yield takeLatest(GET_REQUESTS, getRequestsSaga);
}
