import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { createRequestSagaBase } from "lib/createRequestSaga";
import * as orderAPI from "lib/api/order";
import { uploadResult as uploadResultAPI } from "lib/api/common";

import {
    CREATE_ORDER,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAILURE,
    UPDATE_ORDER_STATUS,
    UPDATE_ORDER_STATUS_SUCCESS,
    UPDATE_ORDER_STATUS_FAILURE,
    SUBMIT_RESULT,
    SUBMIT_RESULT_SUCCESS,
    SUBMIT_RESULT_FAILURE,
    GET_REVIEWER_ORDER_LIST,
    GET_REVIEWER_ORDER_DETAIL,
    GET_BIZ_ORDER_COUNTS,
    GET_BIZ_ORDER_LIST
} from "stores/modules/order/action";

import { SHOW_LOADING, HIDE_LOADING } from "stores/modules/loading";
import { OPEN_ALERT } from "stores/modules/alert";
import { GET_MY_WALLET } from "stores/modules/wallet";

const createOrderSaga = function*(action) {
    try {
        yield put({ type: SHOW_LOADING, payload: { type: action.type } });
        const { data } = yield call(orderAPI.createOrder, action.payload);
        yield put({
            type: CREATE_ORDER_SUCCESS,
            prev: action.payload,
            payload: data
        });
        yield put({ type: GET_MY_WALLET });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.order.request.fail" }
        });
        yield put({
            type: CREATE_ORDER_FAILURE,
            payload: { error: e.response },
            prev: action.payload
        });
    } finally {
        yield put({ type: HIDE_LOADING, payload: { type: action.type } });
    }
};

const updateOrderStatusSaga = function*(action) {
    try {
        const { process } = action.payload;
        let result;
        let successMsg = "";
        let successPath = "";

        if (process === "cancel") {
            result = yield call(orderAPI.cancelOrder, action.payload);
            successMsg = "message.order.cancel.success";
            successPath = "/biz/order/cancel";
        } else if (process === "complete") {
            result = yield call(orderAPI.completeOrder, action.payload);
            successMsg = "message.order.complete.success";
            successPath = "/biz/order/complete";
        } else if (["complete", "reject", "confirm"].indexOf(process) > -1) {
            result = yield call(orderAPI.updateOrderStatus, action.payload);

            if (process === "reject") {
                successMsg = "message.order.reject.success";
            }
        } else {
            return;
        }

        yield put({
            type: UPDATE_ORDER_STATUS_SUCCESS,
            payload: result.data,
            prev: action.payload
        });

        if (successMsg) {
            yield put({
                type: OPEN_ALERT,
                payload: { text: successMsg, path: successPath }
            });
        }
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "거래 상태 변경에 실패하였습니다." }
        });
        yield put({
            type: UPDATE_ORDER_STATUS_FAILURE,
            payload: { error: e.response }
        });
    }
};

const submitResultSaga = function*(action) {
    const { orderId, fileList } = action.payload;
    try {
        yield put({ type: SHOW_LOADING, payload: { type: action.type } });

        const { data } = yield call(orderAPI.getSubmitResultUrl, {
            ...action.payload,
            fileList: fileList.map(item => ({
                name: `${item.name}.${item.mimeType}`,
                mimeType: item.mimeType
            }))
        });
        if (fileList.length > 0) {
            for (let index = 0; index < fileList.length; index++) {
                const item = data[index];
                const { file } = fileList[index];

                yield call(uploadResultAPI, {
                    url: item.url,
                    file,
                    type: file.type
                });
            }
        }

        yield put({
            type: SUBMIT_RESULT_SUCCESS,
            prev: action.payload
        });
        yield put({
            type: GET_REVIEWER_ORDER_DETAIL,
            payload: { orderId, isSubmit: true }
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.result.submit.fail" }
        });
        yield put({
            type: SUBMIT_RESULT_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put({ type: HIDE_LOADING, payload: { type: action.type } });
    }
};

const getReviewerOrderListSaga = createRequestSagaBase({
    type: GET_REVIEWER_ORDER_LIST,
    api: orderAPI.getReviewerOrderList,
    progress: true
});

const getReviewerOrderDetailSaga = createRequestSagaBase({
    type: GET_REVIEWER_ORDER_DETAIL,
    api: orderAPI.getReviewerOrderDetail,
    progress: true,
    alertErrorMsg: "message.talent.get.detail.fail"
});

const getBizOrderCountsSaga = createRequestSagaBase({
    type: GET_BIZ_ORDER_COUNTS,
    api: orderAPI.getBizOrderCounts
});

const getBizOrderListSaga = createRequestSagaBase({
    type: GET_BIZ_ORDER_LIST,
    api: orderAPI.getBizOrderList,
    progress: true
});

export function* orderSaga() {
    yield takeLeading(CREATE_ORDER, createOrderSaga);
    yield takeLeading(UPDATE_ORDER_STATUS, updateOrderStatusSaga);
    yield takeLeading(SUBMIT_RESULT, submitResultSaga);
    yield takeLatest(GET_REVIEWER_ORDER_LIST, getReviewerOrderListSaga);
    yield takeLatest(GET_REVIEWER_ORDER_DETAIL, getReviewerOrderDetailSaga);
    yield takeLatest(GET_BIZ_ORDER_COUNTS, getBizOrderCountsSaga);
    yield takeLatest(GET_BIZ_ORDER_LIST, getBizOrderListSaga);
}
