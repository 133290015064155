import React, { useState } from "react";
import cx from "classnames";
import { IconFont, Button, AlertModal } from "reviewshare-common-component";
import { convertFileType, returnAcceptText, returnFileType, changeFileType } from "utils/file";
import useTranslate from "lib/customHooks/useTranslate";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";
import ClickItem from "components/common/ClickItem";

import "./UploadFileBox.scss";

const IMAGE_SIZE_LIMIT = 1024 * 1024 * 10;
const VIDEO_SIZE_LIMIT = 1024 * 1024 * 1000 * 2;
const FILE_SIZE_LIMIT = 1024 * 1024 * 10;

const IMAGE_LENGTH_LIMIT = 20;
const VIDEO_LENGTH_LIMIT = 2;

const size = { image: IMAGE_SIZE_LIMIT, video: VIDEO_SIZE_LIMIT, file: FILE_SIZE_LIMIT };

const UploadFileBox = ({
    elementId,
    disabled,
    acceptList = ["file"],
    fileList,
    add,
    remove,
    length = { image: IMAGE_LENGTH_LIMIT, video: VIDEO_LENGTH_LIMIT }
}) => {
    const translate = useTranslate();

    const [alert, setAlert] = useState({
        status: false,
        text: ""
    });

    const onAddFile = e => {
        const files = [];
        const counts = {};
        if (!e.target?.files?.length) return files;

        Object.keys(length).forEach(limitKey => {
            counts[limitKey] = fileList.filter(
                item => returnFileType(item.format) === limitKey
            ).length;
        });

        let hadOverLength = false;
        let hadOverSize = false;

        for (let index = 0; index < e.target.files.length; index++) {
            const file = e.target.files[index];
            const fileFormat = changeFileType(file.name);
            const fileNameArray = file.name.split(".");
            fileNameArray.pop();
            const fileName = fileNameArray.join(".");
            const changedFile = new File([file], `${fileName}.${fileFormat}`, { type: file.type });
            const fileType = file.type.split("/")[0];
            const filteredFileType = ["image", "video"].indexOf(fileType) > -1 ? fileType : "file";

            const passLength = length[filteredFileType]
                ? counts[filteredFileType] < length[filteredFileType]
                : true;
            const passSize = file.size < size[filteredFileType];

            if (passLength && passSize) {
                counts[filteredFileType] += 1;
                files.push({
                    file: changedFile,
                    format: fileFormat,
                    name: fileName,
                    mimeType: fileFormat,
                    type: file.type
                });
            } else {
                hadOverLength = hadOverLength || !passLength;
                hadOverSize = hadOverSize || !passSize;
            }
        }

        add(files);

        if (hadOverLength) {
            setAlert({
                status: true,
                text: "message.upload_file.limit_num"
            });
        } else if (hadOverSize) {
            setAlert({
                status: true,
                text: "message.upload_file.limit_size"
            });
        }
    };

    return (
        <>
            <div className={cx("upload-multi-file-box", { disabled })}>
                {!disabled && (
                    <Button
                        className="theme4 size-l m-size-l"
                        onClick={() => {
                            document.getElementById(elementId).click();
                        }}
                    >
                        <IconFont icon="icon_plus_circle" />
                        <FormattedMessage
                            id="action.attach.target"
                            values={{
                                name: acceptList
                                    .map(item => translate(convertFileType(item)))
                                    .join("/")
                            }}
                        />
                    </Button>
                )}
                <input
                    id={elementId}
                    type="file"
                    accept={returnAcceptText(acceptList)}
                    multiple
                    onChange={onAddFile}
                />
                <div className="file-item-list">
                    <div className="file-item-head web">
                        <div className="file-item-name">
                            <FormattedMessage id="item.file.name" />
                        </div>
                        {!disabled && (
                            <div className="file-item-remove-btn">
                                <FormattedMessage id="action.delete" />
                            </div>
                        )}
                    </div>
                    <div
                        className={cx("file-item-box-wrapper", {
                            "empty-content": !fileList.length
                        })}
                    >
                        {fileList.length > 0 ? (
                            fileList.map((file, index) => (
                                <div className="file-item-box" key={index}>
                                    <CommonLink
                                        className={cx("file-download-link", {
                                            "has-link": file.url
                                        })}
                                        to={file.url}
                                        external
                                        disabled={!file.url}
                                    >
                                        <div className="file-item-name overflow-ellipse">
                                            {file.name?.replace(`.${file.format}`, "")}
                                        </div>
                                        <div className="file-item-type">.{file.format}</div>
                                    </CommonLink>
                                    {!disabled && (
                                        <ClickItem
                                            className="file-item-remove-btn"
                                            onClick={() => remove(index)}
                                        >
                                            <IconFont icon="icon_trash web">
                                                <FormattedMessage id="action.delete" />
                                            </IconFont>
                                            <IconFont icon="icon_close_circle_fill mobile">
                                                <FormattedMessage id="action.delete" />
                                            </IconFont>
                                        </ClickItem>
                                    )}
                                </div>
                            ))
                        ) : (
                            <p className="web">
                                <FormattedMessage id="message.file.validate" />
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {alert.status && (
                <AlertModal
                    handleModal={() => {
                        setAlert({ status: false, text: "" });
                    }}
                >
                    <FormattedMessage id={alert.text} />
                </AlertModal>
            )}
        </>
    );
};
export default UploadFileBox;
