import React from "react";
import cx from "classnames";
import { Button } from "reviewshare-common-component";
import { numberWithCommas } from "lib/common";
import { returnReceiverType } from "utils/messenger";
import FormattedMessage from "components/common/FormattedMessage";
import SearchedMessageBoxItem from "components/messenger/side/SearchedMessageBoxItem";

import "./SearchedMessageBoxList.scss";

const LIMIT = 4;
const SearchedMessageBoxList = ({
    userType,
    itemType,
    viewType,
    searchedKeyword,
    counts,
    list,
    selectMessageBox,
    changeViewType
}) =>
    list && list.length > 0 ? (
        <div className={cx("messenger-side-searched-message-box-list", viewType)}>
            {viewType === "all" && (
                <p className="searched-message-box-title">
                    <FormattedMessage
                        id={
                            itemType === "counterpart"
                                ? returnReceiverType(userType)
                                : "item.message"
                        }
                    />
                    ({numberWithCommas(counts)})
                </p>
            )}
            <div className="searched-message-box-list-background">
                <ul className="searched-message-box-list">
                    {list.map((item, index) => {
                        if (viewType === "all" && index > LIMIT) return null;
                        return (
                            <SearchedMessageBoxItem
                                userType={userType}
                                data={item}
                                type={itemType}
                                searchedKeyword={searchedKeyword}
                                key={index}
                                onClick={() =>
                                    selectMessageBox({
                                        reviewerId: item.reviewerId,
                                        brandId: item.brandId,
                                        lastMessageId: item.messageId
                                    })
                                }
                            />
                        );
                    })}
                </ul>
                {viewType === "all" && counts > LIMIT && (
                    <Button
                        className="clear size-l m-size-xl list-more-btn"
                        onClick={() => changeViewType(itemType)}
                    >
                        <FormattedMessage id="action.view.more" />
                    </Button>
                )}
            </div>
        </div>
    ) : null;

export default SearchedMessageBoxList;
