import { combineReducers } from "redux";
import { all, select, takeEvery } from "redux-saga/effects";
import * as Sentry from "@sentry/browser";
import common from "./modules/common/action";
import { commonSaga } from "./modules/common/saga";
import loading, { loadingSaga } from "./modules/loading";
import progress from "./modules/progress";
import alert from "./modules/alert";

import auth, { authSaga } from "./modules/auth";
import { secondAuthSaga } from "./modules/auth/saga";
import banner from "./modules/banner/action";
import { bannerSaga } from "./modules/banner/saga";
import alarm from "./modules/alarm/action";
import { alarmSaga } from "./modules/alarm/saga";
import wallet, { walletSaga } from "./modules/wallet";
import brand from "./modules/brand/action";
import { brandSaga } from "./modules/brand/saga";
import create from "./modules/create/action";
import { createSaga } from "./modules/create/saga";
import payment from "./modules/payment/action";
import { paymentSaga } from "./modules/payment/saga";
import messenger from "./modules/messenger/action";
import { messengerSaga } from "./modules/messenger/saga";
import relation from "./modules/relation/action";
import { relationSaga } from "./modules/relation/saga";
import feed from "./modules/feed/action";
import { feedSaga } from "./modules/feed/saga";
import myPoint, { myPointSaga } from "./modules/myPoint";
import mypage from "./modules/mypage/action";
import { mypageSaga } from "./modules/mypage/saga";
import myProject from "./modules/myproject/action";
import { myProjectSaga } from "./modules/myproject/saga";
import project from "./modules/project/action";
import { projectSaga } from "./modules/project/saga";
import reportCommon, { reportCommonSaga } from "./modules/report/common";
import reportRecruit, { reportRecruitSaga } from "./modules/report/recruit/recruit";
import reportSubmit from "./modules/report/submit/action";
import { reportSubmitSaga } from "./modules/report/submit/saga";
import portfolio from "./modules/portfolio/action";
import { portfolioSaga } from "./modules/portfolio/saga";
import suggest from "./modules/suggest/action";
import { suggestSaga } from "./modules/suggest/saga";
import scoreRecord, { scoreRecordSaga } from "./modules/scoreRecord";
import main from "./modules/main/action";
import { mainSaga } from "./modules/main/saga";
import helpCenter from "./modules/helpCenter/action";
import { helpCenterSaga } from "./modules/helpCenter/saga";
import subscription from "./modules/subscription/action";
import { subscriptionSaga } from "./modules/subscription/saga";
import talent from "./modules/talent/action";
import { talentSaga } from "./modules/talent/saga";
import order from "./modules/order/action";
import { orderSaga } from "./modules/order/saga";

function* sendMessageToSentry(action) {
    const status = action.payload?.error?.status;

    if ([401, 403, 404].indexOf(status) === -1) {
        const { auth } = yield select();
        const { userInfo, userType } = auth;
        const user = {
            userType,
            userInfo: ["Normal", "Seller"].indexOf(userType) > -1 && userInfo.user
        };

        yield Sentry.withScope(scope => {
            scope.setExtra("Action Type", action.type);
            scope.setExtra("User", user);
            scope.setExtra("Data", action.payload.error);
            Sentry.captureMessage(action.type);
        });
    }
}

const rootReducer = combineReducers({
    common,
    loading,
    progress,
    alert,
    banner,
    auth,
    alarm,
    wallet,
    brand,
    create,
    payment,
    messenger,
    relation,
    project,
    feed,
    myPoint,
    mypage,
    myProject,
    reportCommon,
    reportRecruit,
    reportSubmit,
    portfolio,
    suggest,
    scoreRecord,
    main,
    helpCenter,
    subscription,
    talent,
    order
});

export function* rootSaga() {
    yield all([
        commonSaga(),
        bannerSaga(),
        authSaga(),
        alarmSaga(),
        loadingSaga(),
        secondAuthSaga(),
        walletSaga(),
        brandSaga(),
        createSaga(),
        paymentSaga(),
        messengerSaga(),
        relationSaga(),
        projectSaga(),
        feedSaga(),
        myPointSaga(),
        mypageSaga(),
        myProjectSaga(),
        reportCommonSaga(),
        reportRecruitSaga(),
        reportSubmitSaga(),
        portfolioSaga(),
        suggestSaga(),
        scoreRecordSaga(),
        mainSaga(),
        helpCenterSaga(),
        subscriptionSaga(),
        talentSaga(),
        orderSaga(),
        takeEvery(action => action.type.endsWith("_FAILURE"), sendMessageToSentry)
    ]);
}

export default rootReducer;
