import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";
import { Spin } from "reviewshare-common-component";
import { selectMessageBox, setSearchOptions } from "stores/modules/messenger/action";
import { returnReceiverType } from "utils/messenger";
import { useUserType } from "lib/customHooks/useAuth";
import { useProgressItems } from "lib/customHooks/useProgress";
import { useMessengerState } from "lib/customHooks/useMessenger";
import useScrollEnd from "lib/customHooks/useScrollEnd";
import TipBanner from "components/messenger/side/TipBanner";
import EmptyContent from "components/messenger/side/EmptyContent";
import SearchedMessageBoxList from "components/messenger/side/SearchedMessageBoxList";

const VIEW_TYPE_ARRAY = ["counterpart", "message"];
const SearchedMessageBoxContainer = () => {
    const dispatch = useDispatch();
    const scrollElement = useRef();
    const isEndOfPage = useScrollEnd({ ref: scrollElement });

    const userType = useUserType();
    const isSearching = useProgressItems("messenger/SEARCH_MESSAGE_BOX");
    const searchOptions = useMessengerState("searchOptions");
    const searchedMessageBox = useMessengerState("searchedMessageBox");

    const onSelectMessageBox = ({ reviewerId, brandId, lastMessageId }) => {
        dispatch(selectMessageBox({ reviewerId, brandId, lastMessageId }));
    };

    const onChangeViewType = type => {
        dispatch(setSearchOptions({ viewType: type }));
    };

    useEffect(() => {
        if (isEndOfPage) {
            const isNotAll = VIEW_TYPE_ARRAY.indexOf(searchOptions.viewType) > -1;

            if (isNotAll) {
                const result = searchedMessageBox[searchOptions.viewType];
                const isHasNext = result.list.length < result.counts;

                if (isHasNext) {
                    dispatch(setSearchOptions({ skip: result.list.length }));
                }
            }
        }
        // eslint-disable-next-line
    }, [isEndOfPage, searchOptions.viewType]);

    useEffect(() => {
        if (scrollElement.current) {
            scrollElement.current.scrollTop = 0;
        }
    }, [searchOptions.viewType]);

    return (
        <div className="messenger-side-list-item-wrapper" ref={scrollElement}>
            {searchOptions.viewType === "all" && (
                <TipBanner>
                    쪽지를 주고받은 이력이 없는 새 {returnReceiverType(userType)} 검색은{"\n"}
                    [새 쪽지 쓰기] - [받는 {returnReceiverType(userType)}]에서 검색해 주세요.
                </TipBanner>
            )}
            {VIEW_TYPE_ARRAY.map((type, index) => {
                if (["all", type].indexOf(searchOptions.viewType) < 0) return null;
                return (
                    <SearchedMessageBoxList
                        key={index}
                        userType={userType}
                        itemType={type}
                        viewType={searchOptions.viewType}
                        searchedKeyword={searchOptions.keyword}
                        list={searchedMessageBox[type].list}
                        counts={searchedMessageBox[type].counts}
                        selectMessageBox={onSelectMessageBox}
                        changeViewType={onChangeViewType}
                    />
                );
            })}

            {isSearching ? (
                <Spin className={cx({ full: searchOptions.viewType === "all" })} />
            ) : (
                <>
                    {!!searchOptions.keyword &&
                        !searchedMessageBox.counterpart.counts &&
                        !searchedMessageBox.message.counts && (
                            <EmptyContent>검색 결과가 없어요</EmptyContent>
                        )}
                </>
            )}
        </div>
    );
};

export default SearchedMessageBoxContainer;
