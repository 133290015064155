import client from "./client";

export const getFaqList = ({ skip = 0, tag, topF, target, search }) =>
    client.get(
        `/posts/faq?skip=${skip}${tag ? `&tag=${tag}` : ""}${topF ? `&topF=${topF}` : ""}${
            search ? `&search=${search}` : ""
        }${target ? `&target=${target}` : ""}`
    );

export const getNoticeList = ({ skip = 0, limit = 10, home, target }) =>
    client.get(
        `/posts/notice?skip=${skip}${limit ? `&limit=${limit}` : ""}${
            target ? `&target=${target}` : ""
        }${home ? `&home=${home}` : ""}`
    );

export const getNoticeDetail = noticeId => client.get(`/posts/notice/${noticeId}`);
