import { useState, useEffect } from "react";
import throttle from "lodash/throttle";

const useScroll = isInactive => {
    const [scrollY, setValue] = useState(0);

    const onScroll = () => {
        setValue(window.scrollY);
    };

    useEffect(() => {
        if (!isInactive) {
            window.addEventListener("scroll", throttle(onScroll, 150));
        }
        return () => window.removeEventListener("scroll", onScroll);

        // eslint-disable-next-line
    }, []);

    return scrollY;
};

export default useScroll;
