import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { createRequestSagaBase } from "lib/createRequestSaga";
import * as talentAPI from "lib/api/talent";

import {
    CREATE_TALENT,
    CREATE_TALENT_SUCCESS,
    CREATE_TALENT_FAILURE,
    GET_MY_TALENT,
    GET_MY_TALENT_LIST,
    GET_ALL_TALENT_LIST,
    GET_RECOMMEND_TALENT_LIST,
    GET_POPULAR_TALENT_LIST,
    UPDATE_MY_TALENT,
    UPDATE_MY_TALENT_SUCCESS,
    UPDATE_MY_TALENT_FAILURE,
    REMOVE_MY_TALENT,
    REMOVE_MY_TALENT_SUCCESS,
    GET_TALENT_DETAIL
} from "stores/modules/talent/action";
import { OPEN_ALERT } from "stores/modules/alert";
import { SHOW_LOADING, HIDE_LOADING } from "stores/modules/loading";

const createTalentSaga = function*(action) {
    try {
        yield put({ type: SHOW_LOADING, payload: { type: action.type } });
        const { formData, isTemp } = action.payload;
        const { data } = yield call(talentAPI.createTalent, formData);
        yield put({
            type: CREATE_TALENT_SUCCESS,
            payload: isTemp ? {} : data
        });
        if (isTemp) {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "message.talent.save.success", path: "/mytalent/talent" }
            });
        }
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.talent.create.fail" }
        });
        yield put({
            type: CREATE_TALENT_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put({ type: HIDE_LOADING, payload: { type: action.type } });
    }
};

const getMyTalentSaga = createRequestSagaBase({
    type: GET_MY_TALENT,
    api: talentAPI.getMyTalent
});

const getMyTalentListSaga = createRequestSagaBase({
    type: GET_MY_TALENT_LIST,
    api: talentAPI.getMyTalentList,
    progress: true
});
const getAllTalentListSaga = createRequestSagaBase({
    type: GET_ALL_TALENT_LIST,
    api: talentAPI.getTalentList,
    progress: true
});
const getRecommendTalentListSaga = createRequestSagaBase({
    type: GET_RECOMMEND_TALENT_LIST,
    api: talentAPI.getCustomTalentList
});
const getPopularTalentListSaga = createRequestSagaBase({
    type: GET_POPULAR_TALENT_LIST,
    api: talentAPI.getCustomTalentList
});

const updateMyTalentSaga = function*(action) {
    const { talentId, process, formData, status, isTemp } = action.payload;
    const newPayload = process === "updateService" ? formData : action.payload;

    try {
        if (process === "updateService") {
            yield put({ type: SHOW_LOADING, payload: { type: action.type } });
        }

        const { data } = yield call(talentAPI.updateMyTalent, { talentId, obj: newPayload });
        yield put({
            type: UPDATE_MY_TALENT_SUCCESS,
            payload: data
        });
        if (process === "updateService") {
            if (status === "ready" && !isTemp) {
                yield put({
                    type: CREATE_TALENT_SUCCESS,
                    payload: data
                });
            } else {
                yield put({
                    type: OPEN_ALERT,
                    payload: { text: "message.talent.edit.success", path: "/mytalent/talent" }
                });
            }
        }
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.talent.edit.fail" }
        });
        yield put({
            type: UPDATE_MY_TALENT_FAILURE,
            payload: { error: e.response },
            prev: action.payload
        });
    } finally {
        if (process === "updateService") {
            yield put({ type: HIDE_LOADING, payload: { type: action.type } });
        }
    }
};

const removeMyTalentSaga = function*(action) {
    try {
        const { talentId, process } = action.payload;
        const { data } = yield call(talentAPI.updateMyTalent, { talentId, obj: { process } });
        yield put({
            type: REMOVE_MY_TALENT_SUCCESS,
            payload: data
        });
    } catch (e) {
        if (e.response.data.indexOf("This talent has order in progress") > -1) {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "message.talent.delete.impossible.order" }
            });
        } else {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "message.talent.delete.fail" }
            });
        }
        yield put({
            type: UPDATE_MY_TALENT_FAILURE,
            payload: { error: e.response },
            prev: action.payload
        });
    }
};

const getTalentDetailSaga = createRequestSagaBase({
    type: GET_TALENT_DETAIL,
    api: talentAPI.getTalentDetail,
    alertErrorMsg: "message.talent.delete.fail.pause",
    alertErrorPath: "/"
});

export function* talentSaga() {
    yield takeLeading(CREATE_TALENT, createTalentSaga);
    yield takeLatest(GET_MY_TALENT, getMyTalentSaga);
    yield takeLatest(GET_MY_TALENT_LIST, getMyTalentListSaga);
    yield takeLatest(GET_ALL_TALENT_LIST, getAllTalentListSaga);
    yield takeLatest(GET_RECOMMEND_TALENT_LIST, getRecommendTalentListSaga);
    yield takeLatest(GET_POPULAR_TALENT_LIST, getPopularTalentListSaga);
    yield takeLeading(UPDATE_MY_TALENT, updateMyTalentSaga);
    yield takeLeading(REMOVE_MY_TALENT, removeMyTalentSaga);
    yield takeLatest(GET_TALENT_DETAIL, getTalentDetailSaga);
}
