import React, { Suspense } from "react";
import { isMobile } from "lib/common";
import BizCenterHeader from "components/base/BizCenterHeader";
import GlobalHeaderContainer from "containers/core/GlobalHeaderContainer";
import SideMenuContainer from "containers/core/SideMenuContainer";
import BottomTabBarPortal from "components/base/portal/BottomTabBarPortal";

import "./BizPageTemplate.scss";

const BizPageTemplate = ({ children, bottomTabBar }) => (
    <>
        <div className="business-page-template">
            {isMobile() ? <GlobalHeaderContainer /> : <BizCenterHeader />}
            <div className="page-body">
                <SideMenuContainer />
                <div className="main-contents">
                    <div className="main-contents-body">
                        <Suspense fallback={null}>{children}</Suspense>
                    </div>
                </div>
            </div>
        </div>
        {bottomTabBar && <BottomTabBarPortal />}
    </>
);

export default BizPageTemplate;
