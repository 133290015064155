import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import { Button, InputWrapper, Input } from "reviewshare-common-component";
import { escapeString } from "lib/common";
import useTranslate from "lib/customHooks/useTranslate";
import { useAllBrand } from "lib/customHooks/useBrand";
import { selectWriteInfo } from "stores/modules/messenger/action";
import FormattedMessage from "components/common/FormattedMessage";

import "./SelectFrom.scss";

const SelectFrom = ({ closeModal }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const allBrands = useAllBrand();
    const writeInfo = useSelector(({ messenger }) => messenger.writeInfo);

    const [inputText, setInputText] = useState("");
    const [searchedKeyword, setSearchedKeyword] = useState("");

    const regex = new RegExp(escapeString(searchedKeyword), "g");

    const onSelectFrom = fromInfo => {
        if (!fromInfo) return;
        dispatch(
            selectWriteInfo({
                from: { brandId: fromInfo._id, brandName: fromInfo.name },
                toList: writeInfo.toList,
                noSearchMessageBox: true
            })
        );
        closeModal();
    };

    return (
        <div className="messenger-main-side-select-from">
            <InputWrapper search={() => setSearchedKeyword(inputText)}>
                <Input
                    className="m-size-l"
                    placeholder={translate("item.brand.name")}
                    value={inputText}
                    onChange={val => setInputText(val)}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            setSearchedKeyword(inputText);
                        }
                    }}
                />
            </InputWrapper>
            <ul className="from-list">
                {(searchedKeyword
                    ? allBrands.list.filter(item => item.name.indexOf(searchedKeyword) > -1)
                    : allBrands.list
                ).map((item, index) => {
                    const isActive = writeInfo.from.brandId === item._id;

                    return (
                        <li key={index} className={cx({ active: isActive })}>
                            <p
                                className="from-name"
                                dangerouslySetInnerHTML={{
                                    __html: searchedKeyword
                                        ? item.name.replace(
                                              regex,
                                              `<span class="blue-font">${searchedKeyword}</span>`
                                          )
                                        : item.name
                                }}
                            />
                            <Button
                                className="theme4 size-s m-size-xs"
                                disabled={isActive}
                                onClick={() => onSelectFrom(item)}
                            >
                                <FormattedMessage id="action.select" />
                            </Button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
export default SelectFrom;
