import { useSelector } from "react-redux";

// state
export const useBrandState = value => useSelector(({ brand }) => brand[value]);
export const useAllBrand = value =>
    useSelector(({ brand }) => (value ? brand.allBrands[value] : brand.allBrands));

// action
export const useBrandAction = () => {
    // const dispatch = useDispatch();

    return {};
};
