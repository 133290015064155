import client from "./client";

export const createOrder = obj => client.post("/v3/orders", obj);
export const cancelOrder = ({ orderId, cancelMessage }) =>
    client.patch(`/v3/orders/${orderId}/cancel`, { cancelMessage });
export const completeOrder = ({ orderId }) => client.patch(`/v3/orders/${orderId}/complete`);
export const updateOrderStatus = obj => client.patch(`/v3/orders/${obj.orderId}`, obj);
export const getResult = orderId => client.get(`/v3/orders/${orderId}/result`);
export const getReviewerOrderList = ({
    status,
    brandName,
    phoneNumber,
    orderId,
    skip,
    limit = 15
}) =>
    client.get(`/v3/orders`, {
        params: { status, brandName, phoneNumber, orderId, skip, limit }
    });
export const getReviewerOrderDetail = ({ orderId }) => client.get(`/v3/orders/${orderId}`);
export const getSubmitResultUrl = obj =>
    client.post(`${process.env.REACT_APP_SERVERLESS_URL}/orders/results`, obj);
export const downloadResult = orderId => client.get(`/v3/orders/${orderId}/file-zip-url`);
export const getBizOrderCounts = () => client.get("/v3/orders/counts/me");
export const getBizOrderList = ({ status, filter, skip, limit = 15 }) =>
    client.get(`/v3/orders/me/${status}`, {
        params: {
            filter,
            skip,
            limit
        }
    });
