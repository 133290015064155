import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Stickyfill from "stickyfilljs";
import { useDispatch } from "react-redux";
import { isMobile } from "lib/common";
import { useUserInfo, useUserType, useAuthState } from "lib/customHooks/useAuth";
import { useTotalPoints } from "lib/customHooks/useWallet";
import GlobalHeader from "components/base/GlobalHeader";
import MobileMenu from "components/base/MobileMenu";
import MobileUserMenu from "components/base/MobileUserMenu";
import { handleLoginModal } from "stores/modules/auth";
import { useAlarmState } from "lib/customHooks/useAlarm";
import { DEFAULT_HEADER_MENU, REVIEWER_HEADER_MENU, BUSINESS_HEADER_MENU } from "scheme/menu";

const GlobalHeaderContainer = ({ history }) => {
    const dispatch = useDispatch();

    const user = useUserInfo();
    const userType = useUserType();
    const isShowLoginModal = useAuthState("isShowLoginModal");
    const totalPoints = useTotalPoints();
    const hasUnreadAlarm = useAlarmState("hasUnread");

    const [headerSchema, handleHeaderSchema] = useState(DEFAULT_HEADER_MENU);

    const [burger, setBurger] = useState(false);

    const onShowLoginModal = () => {
        setBurger(false);
        dispatch(handleLoginModal({ status: true }));
    };

    useEffect(() => {
        const element = document.getElementsByTagName("header");
        Stickyfill.add(element);

        history.listen(info => {
            if (isMobile()) {
                setBurger(false);

                if (isShowLoginModal) {
                    dispatch(handleLoginModal({ status: false }));
                }

                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(info.pathname);
                }
            }
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        switch (userType) {
            case "Normal":
                handleHeaderSchema(REVIEWER_HEADER_MENU);
                break;
            case "Seller":
                handleHeaderSchema(BUSINESS_HEADER_MENU);
                break;

            default:
                break;
        }
    }, [userType]);

    useEffect(() => {
        if (burger) {
            document.querySelector("body").style.overflowY = "hidden";
        } else {
            document.querySelector("body").style.overflowY = "visible";
        }
        return () => {
            document.querySelector("body").style.overflowY = "visible";
        };
        // eslint-disable-next-line
    }, [burger]);

    return (
        <>
            <GlobalHeader
                userType={userType}
                points={totalPoints}
                schema={headerSchema}
                hasUnreadAlarm={hasUnreadAlarm}
                onClickLoginBtn={onShowLoginModal}
                setBurger={setBurger}
            />
            {userType === "Visitor" ? (
                <MobileMenu
                    burger={burger}
                    setBurger={setBurger}
                    showLoginModal={onShowLoginModal}
                />
            ) : (
                user._id && (
                    <MobileUserMenu
                        user={user}
                        isSeller={userType === "Seller"}
                        points={totalPoints}
                        burger={burger}
                        setBurger={setBurger}
                    />
                )
            )}
        </>
    );
};

export default withRouter(GlobalHeaderContainer);
