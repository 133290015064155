import { numberWithCommas } from "lib/common";

export const convertSuggestStatus = status => {
    switch (status) {
        case "Waiting":
            return "status.suggestion.waiting";
        case "Running":
            return "status.suggestion.running";
        case "Submitted":
            return "status.suggestion.submit";
        case "Rejected":
            return "status.suggestion.reject";
        case "NoResponse":
            return "status.suggestion.noresponse";
        case "Complete":
            return "status.suggestion.complete";
        case "Canceled":
            return "status.suggestion.cancel";
        case "Refunded":
            return "status.payment.refund.after";
        case "Issued":
            return "status.payment.pay.points";
        case "Issued before":
            return "status.payment.pay.points.before";
        default:
            return status;
    }
};

export const convertSuggestionReason = reason => {
    switch (reason) {
        case "itemName":
            return "item.suggestion.reject.type.itemName";
        case "points":
            return "item.suggestion.reject.type.points";
        case "guideLine":
            return "item.suggestion.reject.type.guideline";
        case "submitEndAt":
            return "제출 기간";
        case "etc":
            return "item.suggestion.reject.type.etc";
        default:
            return reason;
    }
};

export const convertSuggestionReasonTitle = reason => {
    switch (reason) {
        case "itemName":
            return "리뷰어님이 생각하는 적정 제공 내역";
        case "points":
            return "리뷰어님이 생각하는 적정 리워드 포인트";
        case "guideLine":
            return "리뷰어님이 생각하는 적정 가이드라인";
        case "submitEndAt":
            return "리뷰어님이 생각하는 적정 기간";
        default:
            return "";
    }
};

export const convertOfferDetail = ({ itemName, points }) => {
    const offerItems = [];

    if (itemName) {
        offerItems.push(itemName);
    }

    if (points > 0) {
        offerItems.push(`${numberWithCommas(points)}P`);
    }

    if (!offerItems.length) {
        return "";
    }
    return offerItems.join(" + ");
};
