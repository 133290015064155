import React from "react";
import cx from "classnames";
import { Profile, IconFont } from "reviewshare-common-component";
import { escapeString } from "lib/common";
import { returnDateText, returnName } from "utils/messenger";
import FormattedMessage from "components/common/FormattedMessage";

import "./SearchedMessageBoxItem.scss";

const SearchedMessageBoxItem = ({ userType, type, data, searchedKeyword, ...rest }) => {
    const regex = new RegExp(escapeString(searchedKeyword), "g");
    const otherName =
        userType === "Seller"
            ? returnName(data.nickname, data.isDeletedReviewer)
            : returnName(data.brandName, data.isDeletedBrand);
    const brandName = returnName(data.brandName, data.isDeletedBrand);

    return type === "counterpart" ? (
        <li className={cx("messenger-searched-message-box-item", type)} {...rest}>
            <div className="profile-text-area">
                {userType === "Seller" && (
                    <Profile className="size-s m-size-m" img={data.profileImg} noBand />
                )}
                <div className="text-area">
                    <div className="info-area">
                        <p
                            className="other-name"
                            dangerouslySetInnerHTML={{
                                __html: searchedKeyword
                                    ? otherName.replace(
                                          regex,
                                          `<span class="blue-font">${searchedKeyword}</span>`
                                      )
                                    : otherName
                            }}
                        />
                    </div>
                    {userType === "Seller" ? (
                        <div className="brand-text">
                            <IconFont icon="icon_message" />
                            {brandName}
                        </div>
                    ) : (
                        <div className="message-area">
                            <p className="message-text">{data.message}</p>
                        </div>
                    )}
                </div>
            </div>
        </li>
    ) : type === "message" ? (
        <li className={cx("messenger-searched-message-box-item", type)} {...rest}>
            <div className="profile-text-area">
                {userType === "Seller" && (
                    <Profile className="size-s m-size-m" img={data.profileImg} noBand />
                )}
                <div className="text-area">
                    <div className="info-area">
                        <p className="other-name">{otherName}</p>
                        <span className="date">{returnDateText(data.createdAt)}</span>
                    </div>
                    <div className="message-area">
                        <p
                            className="message"
                            dangerouslySetInnerHTML={{
                                __html: searchedKeyword
                                    ? data.message.replace(
                                          regex,
                                          `<span class="blue-font">${searchedKeyword}</span>`
                                      )
                                    : data.message
                            }}
                        />
                    </div>
                </div>
            </div>
            {userType === "Seller" && (
                <div className="brand-area">
                    <span>
                        <IconFont icon="icon_message" />
                        <FormattedMessage id="item.brand" />
                    </span>
                    <p>{brandName}</p>
                </div>
            )}
        </li>
    ) : null;
};
export default SearchedMessageBoxItem;
