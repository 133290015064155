import { createAction, handleActions } from "redux-actions";
import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import createRequestSaga, { createRequestActionTypes } from "lib/createRequestSaga";
import { getReviewerPointsHistory } from "lib/api/wallet";
import { getRequestWithdrawHistory, requestWithdraw } from "lib/api/points";
import { returnAlertValue } from "lib/common";
import { REVIEWER_WITHDRAW_POINTS } from "scheme/alert";
import { OPEN_ALERT } from "stores/modules/alert";
import { setUnsubmitCounts } from "stores/modules/auth";

const [
    GET_POINT_HISTORY,
    GET_POINT_HISTORY_SUCCESS,
    GET_POINT_HISTORY_FAILURE
] = createRequestActionTypes("myPoint/GET_POINT_HISTORY");

const [
    GET_REQUEST_HISTORY,
    GET_REQUEST_HISTORY_SUCCESS,
    GET_REQUEST_HISTORY_FAILURE
] = createRequestActionTypes("myPoint/GET_REQUEST_HISTORY");

const [
    WITHDRAW_POINTS,
    WITHDRAW_POINTS_SUCCESS,
    WITHDRAW_POINTS_FAILURE
] = createRequestActionTypes("myPoint/WITHDRAW_POINTS");

export const getPointHistory = createAction(GET_POINT_HISTORY);

export const getRequestHistory = createAction(GET_REQUEST_HISTORY);
export const withdrawPoints = createAction(WITHDRAW_POINTS);

const getPointHistorySaga = createRequestSaga(GET_POINT_HISTORY, getReviewerPointsHistory);
const getRequestHistorySaga = createRequestSaga(GET_REQUEST_HISTORY, getRequestWithdrawHistory);

const withdrawPointsSaga = function*(action) {
    try {
        const { data } = yield call(requestWithdraw, action.payload);
        yield put({
            type: WITHDRAW_POINTS_SUCCESS,
            payload: data
        });
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.points.withdrawal.success", path: "reload" }
        });
    } catch (e) {
        const failData = e.response.data;
        if (failData.unsubmitProjectCounts || failData.unsubmitSuggestionCounts) {
            yield put(setUnsubmitCounts(failData));
        } else {
            const { message, path } = returnAlertValue({
                message: failData,
                list: REVIEWER_WITHDRAW_POINTS
            });
            yield put({
                type: OPEN_ALERT,
                payload: { text: message || "message.points.withdrawal.fail", path }
            });
        }
        yield put({
            type: WITHDRAW_POINTS_FAILURE,
            payload: { error: e.response }
        });
    }
};

export function* myPointSaga() {
    yield takeLatest(GET_POINT_HISTORY, getPointHistorySaga);
    yield takeLatest(GET_REQUEST_HISTORY, getRequestHistorySaga);
    yield takeLeading(WITHDRAW_POINTS, withdrawPointsSaga);
}

const initialState = {
    pointRequests: [],
    count: 0,
    isLoading: true,
    myPointError: null,
    requestHistory: [],
    today: null,
    thisEarlyMonthAmount: 0,
    thisMonthAmount: 0,
    nextMonthAmount: 0
};

const myPoint = handleActions(
    {
        [GET_POINT_HISTORY_SUCCESS]: (state, { payload }) => ({
            ...state,
            ...payload,
            isLoading: false
        }),
        [GET_POINT_HISTORY_FAILURE]: (state, { payload: error }) => ({
            ...state,
            myPointError: error,
            isLoading: false
        }),
        [GET_REQUEST_HISTORY]: state => ({
            ...state,
            requestHistory: [],
            today: null,
            thisEarlyMonthAmount: 0,
            thisMonthAmount: 0,
            nextMonthAmount: 0
        }),
        [GET_REQUEST_HISTORY_SUCCESS]: (state, { payload }) => ({
            ...state,
            requestHistory: payload.list,
            today: payload.today,
            thisEarlyMonthAmount: payload.thisEarlyMonthAmount,
            thisMonthAmount: payload.thisMonthAmount,
            nextMonthAmount: payload.nextMonthAmount
        })
    },
    initialState
);

export default myPoint;
