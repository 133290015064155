import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { createRequestActionTypes } from "lib/createRequestSaga";

export const [GET_FAQ_LIST, GET_FAQ_LIST_SUCCESS, GET_FAQ_LIST_FAILURE] = createRequestActionTypes(
    "helpCenter/GET_FAQ_LIST"
);

export const [
    GET_NOTICE_LIST,
    GET_NOTICE_LIST_SUCCESS,
    GET_NOTICE_LIST_FAILURE
] = createRequestActionTypes("helpCenter/GET_NOTICE_LIST");

export const [
    GET_NOTICE_DETAIL,
    GET_NOTICE_DETAIL_SUCCESS,
    GET_NOTICE_DETAIL_FAILURE
] = createRequestActionTypes("helpCenter/GET_NOTICE_DETAIL");

export const RESET_NOTICE_DETAIL = "feed/RESET_NOTICE_DETAIL";

export const [
    GET_REQUESTS_LIST,
    GET_REQUESTS_LIST_SUCCESS,
    GET_REQUESTS_LIST_FAILURE
] = createRequestActionTypes("helpCenter/GET_REQUESTS_LIST");

export const [GET_REQUESTS, GET_REQUESTS_SUCCESS, GET_REQUESTS_FAILURE] = createRequestActionTypes(
    "helpCenter/GET_REQUESTS"
);

export const getFaqList = createAction(GET_FAQ_LIST);
export const getNoticeList = createAction(GET_NOTICE_LIST);
export const getNoticeDetail = createAction(GET_NOTICE_DETAIL);
export const resetNoticeDetail = createAction(RESET_NOTICE_DETAIL);
export const getRequestList = createAction(GET_REQUESTS_LIST, ({ lastRequestid, skip }) => ({
    lastRequestid,
    skip
}));
export const getRequest = createAction(GET_REQUESTS, requestId => requestId);

const initialState = {
    faqSearching: false,
    faqCounts: 0,
    faqList: [],
    noticeCounts: 0,
    noticeList: [],
    noticeDetail: {},
    requests: {
        totalCount: 0,
        list: [],
        detail: null
    }
};

const helpCenter = handleActions(
    {
        [GET_FAQ_LIST]: state =>
            produce(state, draft => {
                draft.faqSearching = true;
            }),
        [GET_FAQ_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.faqSearching = false;
                draft.faqList = payload.list;
                draft.faqCounts = payload.totalCount;
            }),
        [GET_FAQ_LIST_FAILURE]: (state, { payload }) =>
            produce(state, draft => {
                draft.faqSearching = false;
            }),
        [GET_NOTICE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.noticeList = payload.list;
                draft.noticeCounts = payload.totalCount;
            }),
        [RESET_NOTICE_DETAIL]: state =>
            produce(state, draft => {
                draft.noticeDetail = {};
            }),
        [GET_NOTICE_DETAIL_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.noticeDetail = payload;
            }),
        [GET_REQUESTS_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.requests.totalCount = payload.total;
                draft.requests.list = payload.list;
            }),
        [GET_REQUESTS]: (state, { payload }) =>
            produce(state, draft => {
                draft.requests.detail = {
                    requestId: payload
                };
            }),
        [GET_REQUESTS_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.requests.detail = payload;
            })
    },
    initialState
);

export default helpCenter;
