import React from "react";
import { Route } from "react-router-dom";
import PublicPageTemplate from "components/base/templates/PublicPageTemplate";

const PublicRoute = ({ ...rest }) => {
    const { customMobileHeader, bottomTabBar } = rest;

    return (
        <PublicPageTemplate customMobileHeader={customMobileHeader} bottomTabBar={bottomTabBar}>
            <Route {...rest} />
        </PublicPageTemplate>
    );
};

export default PublicRoute;
