import React, { useState, useMemo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, CautionList, Input } from "reviewshare-common-component";
import { numberWithCommas, isMobile as checkMobile } from "lib/common";
import { openAlert } from "stores/modules/alert";
import { getMyWallet } from "stores/modules/wallet";
import { sendGiftPoint } from "stores/modules/messenger/action";
import { POINT_GIFT_RATE } from "scheme/price";
import { returnSubtractFee } from "utils/price";
import CommonLink from "components/common/CommonLink";
import GivePointsDetail from "components/messenger/main/GivePointsDetail";

import "./GivePoints.scss";

const minAmount = 1000;
const GivePoints = () => {
    const dispatch = useDispatch();
    const amountInput = useRef();
    const isMobile = checkMobile();

    const totalPoints = useSelector(({ wallet }) => wallet.totalPoints);
    const writeInfo = useSelector(({ messenger }) => messenger.writeInfo);
    const isFetching = useSelector(({ progress }) => progress.progressItems)[
        "messenger/SEND_GIFT_POINT"
    ];

    const [amount, setAmount] = useState(null);
    const totalAmount = useMemo(() => (amount < minAmount ? 0 : amount * writeInfo.toList.length), [
        amount,
        writeInfo.toList.length
    ]);
    const reviewerPoints = useMemo(
        () =>
            amount < minAmount
                ? 0
                : returnSubtractFee({
                      value: amount,
                      rate: POINT_GIFT_RATE
                  }),
        [amount]
    );
    const possibleGivePoints = useMemo(
        () =>
            writeInfo.from.brandId &&
            writeInfo.toList.length > 0 &&
            amount >= minAmount &&
            totalAmount <= totalPoints,
        [writeInfo, amount, totalAmount, totalPoints]
    );

    const onCreateGiftPoint = () => {
        if (amount % 1000 !== 0) {
            return dispatch(openAlert({ text: "포인트는 1,000P 단위로 입력해 주세요." }));
        }
        const targetList = writeInfo.toList.map(to => to.reviewerId);
        dispatch(
            sendGiftPoint({ targetList, pointsPerTarget: amount, brandId: writeInfo.from.brandId })
        );
    };

    useEffect(() => {
        if (!isMobile) {
            amountInput.current.focus();
        }
        dispatch(getMyWallet());
        // eslint-disable-next-line
    }, []);

    return (
        <div className="messenger-main-give-points">
            <div className="scroll-area">
                <div className="total-points">
                    <p>보유 포인트</p>
                    <span>{numberWithCommas(totalPoints)}P</span>
                </div>
                <div className="input-area">
                    <Input
                        ref={amountInput}
                        className="m-size-l"
                        placeholder={`${numberWithCommas(minAmount)}P 이상 입력`}
                        value={amount}
                        onChange={val => setAmount(val)}
                        numberFormat
                    />
                    <input
                        type="number"
                        id="give-points-fee"
                        value={returnSubtractFee({
                            value: amount,
                            rate: 100 - POINT_GIFT_RATE
                        })}
                        hidden
                    />
                    {totalAmount > totalPoints && (
                        <p className="error-message">
                            보유 포인트가 부족합니다.{" "}
                            <CommonLink to="/biz/chargepoint">포인트 충전</CommonLink> 후 다시
                            시도해 주세요.
                        </p>
                    )}
                    <p className="caution-message">
                        *리뷰어 1인당 선물할 포인트 금액을 입력해 주세요.
                    </p>
                </div>
                <GivePointsDetail totalAmount={totalAmount} reviewerPoints={reviewerPoints} />
                <CautionList
                    items={[
                        "선물한 포인트는 환급이 어려워요. 받는 리뷰어를 신중히 선택하여 선물해 주세요.",
                        "선물 전 리뷰어와 충분히 소통해 주세요. 선물 후 발생되는 이슈는 리뷰쉐어 팀에서 책임지지 않습니다."
                    ]}
                />
            </div>
            <Button
                id="give-points-btn"
                className="theme5 size-m m-size-l give-points-btn"
                disabled={!possibleGivePoints || isFetching}
                onClick={onCreateGiftPoint}
            >
                포인트 선물
            </Button>
        </div>
    );
};
export default GivePoints;
