import React from "react";

import "./BottomTabBar.scss";

// type: ["none", "border", "shadow"]
const BottomTabBar = ({ type = "shadow", children }) => (
    <>
        <div id="bottom-tab-bar-wrapper" className={type}>
            {children}
        </div>
        <div id="bottom-tab-bar-padding-element" />
    </>
);

export default BottomTabBar;
