import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { IconFont, Button } from "reviewshare-common-component";
import FormattedMessage from "components/common/FormattedMessage";

import "./LauncherBtn.scss";

const EXCLUDE_PATH_ARRAY = ["/mytalent/order/", "/print", "/bizintro", "intro", "/profile/edit"];
const LauncherBtn = ({ hasUnreadMessage, openMessenger, location }) => {
    const [status, setStatus] = useState(true);

    useEffect(() => {
        if (location) {
            let isExclude = false;
            for (let index = 0; index < EXCLUDE_PATH_ARRAY.length; index++) {
                const path = EXCLUDE_PATH_ARRAY[index];
                isExclude = location.pathname.indexOf(path) > -1;
                if (isExclude) break;
            }

            setStatus(!isExclude);
        }
    }, [location]);

    return status ? (
        <div id="messenger-common-launcher-btn">
            <Button className="theme1 size-l m-size-xl" onClick={openMessenger}>
                <IconFont icon="icon_message_fill" />
                <span className="text">
                    <FormattedMessage id="menu.messenger" />
                </span>
                {hasUnreadMessage && <span className="new-mark" />}
            </Button>
        </div>
    ) : null;
};
export default withRouter(LauncherBtn);
