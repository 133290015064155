import React from "react";
import { useSelector } from "react-redux";
import { CautionList } from "reviewshare-common-component";
import { SUGGESTION_PRICE } from "scheme/price";
import { numberWithCommas, toCapitalFirstLetter } from "lib/common";
import { useTotalPoints } from "lib/customHooks/useWallet";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";
import InduceSubscribeAboutFee from "components/subscription/common/InduceSubscribeAboutFee";

import commonStyle from "components/suggest/create/Common.module.scss";
import style from "./Bill.module.scss";

const serviceFee = SUGGESTION_PRICE;
const Bill = ({ info, feeIsFree, amount }) => {
    const totalPoints = useTotalPoints();
    const planInfo = useSelector(({ subscription }) => subscription.planInfo);

    return (
        <div className={commonStyle.item}>
            <div className={style.pointArea}>
                <p className={style.pointsTitle}>
                    <FormattedMessage id="item.price.points.total" />
                </p>
                <span className={style.totalAmount}>{numberWithCommas(amount)}P</span>
                <p className={style.myPoints}>
                    <FormattedMessage id="item.points.total" />: {numberWithCommas(totalPoints)}P
                    <CommonLink
                        to="/biz/chargepoint"
                        className={`${style.chargeLink} rs-button theme4 size-s m-size-xxs round`}
                    >
                        <FormattedMessage id="action.charge" />
                    </CommonLink>
                </p>
            </div>
            <div className={style.pointInfoArea}>
                <ul>
                    {info.points > 0 && (
                        <li>
                            <p>
                                <FormattedMessage id="item.suggestion.points" />
                            </p>
                            <span>{numberWithCommas(info.points)}P</span>
                        </li>
                    )}
                    <li>
                        <p>
                            <FormattedMessage id="item.price.fee.suggestion" />
                        </p>
                        <span>
                            {feeIsFree ? (
                                <FormattedMessage id="item.price.fee.free" />
                            ) : (
                                `${numberWithCommas(serviceFee)}P`
                            )}
                        </span>
                    </li>
                </ul>
            </div>
            {feeIsFree ? (
                <CautionList
                    title=""
                    items={[
                        `담당자님은 현재 ${toCapitalFirstLetter(
                            planInfo.plan
                        )} 플랜 구독 중으로 제안 수수료가 무료입니다.`
                    ]}
                    className={style.cautionList}
                />
            ) : (
                <InduceSubscribeAboutFee type="suggestion" />
            )}
        </div>
    );
};
export default Bill;
