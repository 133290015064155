import React, { useState } from "react";
import classnames from "classnames";
import { Button, IconFont } from "reviewshare-common-component";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";

import error_illust from "assets/images/IllustImg/error.svg";
import style from "./Error.module.scss";

const cx = classnames.bind(style);
const Error = ({ error }) => {
    const [errorDetailStatus, setErrorDetailStatus] = useState(false);

    return (
        <div className={style.wrapper}>
            <div className={style.inner}>
                <img src={error_illust} className={style.img} alt="404" />
                <h2 className={style.title}>화면을 불러오지 못했어요.</h2>
                <p className={style.desc}>새로고침 버튼을 눌러 재시도 해보세요!</p>
                <Button
                    className={cx(style.link, "theme1 size-l m-size-l round")}
                    onClick={() => window.location.reload()}
                >
                    <IconFont icon="icon_reset_left" /> <FormattedMessage id="action.refresh" />
                </Button>
                <p className={style.cs}>
                    지속적으로 화면이 보이지 않을 경우 {"\n"}
                    아래 에러 코드 화면을 캡쳐하여{" "}
                    <CommonLink to={`${process.env.REACT_APP_MAIN_SERVICE_URL}/support/requests`}>
                        [1:1 문의]
                    </CommonLink>
                    에서 문의해주세요.
                </p>
                <div className={style.errorBox}>
                    <Button
                        className={cx(style.errorMoreBtn, "clear size-m m-size-m")}
                        onClick={() => setErrorDetailStatus(!errorDetailStatus)}
                    >
                        에러 코드 보기{" "}
                        <IconFont icon={errorDetailStatus ? "icon_arrow_up" : "icon_arrow_down"} />
                    </Button>
                    <div className={cx(style.errorCode, { [style.active]: errorDetailStatus })}>
                        {error?.message}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Error;
