import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import "styles/main.scss";
import "noto-sans-korean.css";
// import * as serviceWorker from './serviceWorker';

// const tagManagerArgs = {
//     gtmId: "GTM-WM24PH2"
// };

const isDev = process.env.NODE_ENV === "development";
if (!isDev) {
    import("@sentry/browser").then(Sentry => {
        Sentry.init({
            dsn: "https://c51ee47480f94859b9970b4c0986adaf@o339454.ingest.sentry.io/5272169"
        });
    });
}

ReactDOM.render(<Root />, document.getElementById("root"));
