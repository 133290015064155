import produce from "immer";
import { delay, put, takeLatest, select } from "redux-saga/effects";
import { createAction, handleActions } from "redux-actions";

export const SHOW_LOADING = "loading/SHOW_LOADING";
export const HIDE_LOADING = "loading/HIDE_LOADING";

export const RESET_PROGRESS_ITEMS = "loading/RESET_PROGRESS_ITEMS";

export const showLoading = createAction(SHOW_LOADING);
export const hideLoading = createAction(HIDE_LOADING);

const initialState = {
    progress: 0,
    progressItems: {},
    isBlack: false
};

const returnProgress = items => {
    const completeCounts = Object.values(items).filter(item => !item).length;
    const totalCounts = Object.keys(items).length;
    return (completeCounts / totalCounts) * 100;
};

const loading = handleActions(
    {
        [SHOW_LOADING]: (state, { payload }) =>
            produce(state, draft => {
                const _progressItems = { ...state.progressItems };
                _progressItems[payload.type] = true;
                draft.progressItems = _progressItems;
                draft.progress = returnProgress(_progressItems);
                draft.isBlack = payload.isBlack;
            }),
        [HIDE_LOADING]: (state, { payload }) =>
            produce(state, draft => {
                const _progressItems = { ...state.progressItems };
                _progressItems[payload.type] = false;
                draft.progressItems = _progressItems;
                draft.progress = returnProgress(_progressItems);
                draft.isBlack = false;
            }),
        [RESET_PROGRESS_ITEMS]: state =>
            produce(state, draft => {
                draft.progress = initialState.progress;
                draft.progressItems = initialState.progressItems;
            })
    },
    initialState
);

const handleLoadingSaga = function*() {
    const state = yield select();
    const itemsVer1 = { ...state.loading.progressItems };

    if (Object.values(itemsVer1).indexOf(true) < 0) {
        yield delay(500);
        const _state = yield select();
        const itemsVer2 = { ..._state.loading.progressItems };

        if (Object.values(itemsVer2).indexOf(true) < 0) {
            yield put({
                type: RESET_PROGRESS_ITEMS
            });
        }
    }
};
export function* loadingSaga() {
    yield takeLatest(HIDE_LOADING, handleLoadingSaga);
}

export default loading;
