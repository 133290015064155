import client from "./client";

export const getMyActivity = () => client.get("/users/activity-history-counts");
// export const getMyRepick = () => client.get("/v2/users/repick");
export const getMyProject = ({ category, skip, limit, sort, type }) =>
    client.get(
        `/v2/users/requests?category=${category}${
            skip && skip > 0 ? `&skip=${skip}` : ""
        }&limit=${limit}&sort=${sort || "1"}${type ? `&type=${type}` : ""}`
    );
export const getRecommendHistory = () => client.get("/campaignRequests/recommended-projects");
export const getProjectCountsBySns = () => client.get("/campaignRequests/counts-by-sns");
export const getActivityCounts = () => client.get(`/v3/users/me/reviewer-activity-count`);
