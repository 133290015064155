import React from "react";
import Emoji from "components/common/Emoji";

import "./NewMessage.scss";

const NewMessage = ({ data }) => (
    <div id="messenger-new-message">
        <div className="messenger-new-message-content web">
            <Emoji emoji="🔔" />
            <span className="brand-name">{data.brandName}</span> 브랜드에 {"\n"}
            <span className="red-font">새 쪽지</span>가 도착했어요!
        </div>
        <div className="messenger-new-message-content mobile">
            <p>
                <Emoji emoji="🔔" />
                <span className="brand-name">{data.brandName}</span> 브랜드에 {"\n"}
            </p>
            <p>
                <span className="red-font">새 쪽지</span>가 도착했어요!
            </p>
        </div>
    </div>
);
export default NewMessage;
