import React, { useState, useEffect } from "react";
import SideMenu from "components/base/SideMenu";

const subListInitState = {
    main: false,
    project: false,
    payment: false,
    brand: false,
    message: false,
    subscription: false,
    suggestion: false
};

const SideMenuContainer = () => {
    const urlPathName = window.location.pathname;

    const [pathValue, setPathValue] = useState(null);
    const [subListState, handleSubListState] = useState(subListInitState);

    const convertSubListState = pathName => {
        switch (pathName) {
            case "manage":
            case "create":
                return "project";
            default:
                return pathName;
        }
    };

    useEffect(() => {
        const mainPath = urlPathName.split("/")[2];
        if (mainPath === "chargepoint") {
            setPathValue("payment");
            handleSubListState({ ...subListInitState, payment: true });
        } else if (mainPath === "main") {
            setPathValue("main");
            handleSubListState({ ...subListInitState, main: true });
        } else {
            setPathValue(convertSubListState(mainPath));
            handleSubListState(prev => ({
                ...prev,
                [convertSubListState(mainPath)]: true
            }));
        }
    }, [urlPathName]);

    return (
        <SideMenu
            pathValue={pathValue}
            subListState={subListState}
            handleSubListState={handleSubListState}
            urlPathName={urlPathName}
        />
    );
};

export default SideMenuContainer;
