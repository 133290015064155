import client from "./client";

export const login = userObj => client.post("/login", userObj);
export const register = userInfo => client.post("/v2/users", userInfo);
export const editUserInfo = obj => client.put("/v2/users", obj);
export const getMyInfo = () => client.get("/me");
export const updateMyInfo = obj => client.put("/me", obj);
export const getNaverProfile = obj => client.post("/v2/auth/naver", obj);
export const checkEmail = email => client.get(`/checkEmail?email=${email}`);
export const checkNickname = nickname =>
    client.get(`/checkNickname?nickname=${encodeURIComponent(nickname)}`);
export const checkPortfolioUrl = url =>
    client.get(`/checkPortfolioUrl?url=${encodeURIComponent(url)}`);
export const checkPhoneNumber = ({ phoneNumber, category }) =>
    client.get(`/checkPhoneNum?phoneNumber=${phoneNumber}&category=${category}`);
export const certificatePhone = phoneNumber =>
    client.get(`/certificatePhone?phoneNumber=${phoneNumber}`);
export const checkCertification = ({ phoneNumber, certNumber, registerCategoryType }) =>
    client.get(
        `/certificationCheck?phoneNumber=${phoneNumber}&certNumber=${certNumber}${
            registerCategoryType ? `&register=${registerCategoryType}` : ""
        }`
    );
export const changePassword = obj => client.put("/changePassword", obj);
export const findAccount = ({ name, phoneNumber }) =>
    client.get(`/findAccounts?name=${encodeURIComponent(name)}&phoneNumber=${phoneNumber}`);
export const checkUserExist = obj => client.post("/checkUserExist", obj);
export const resetPassword = obj => client.post("/changePasswordForFind", obj);
export const leave = ({ reason, text }) => client.post("/v2/users/withdrawals", { reason, text });
export const passwordVerification = oldPassword =>
    client.post("/passwordVerification", { oldPassword });
export const combineAccounts = obj => client.post("/combineAccounts", obj);
