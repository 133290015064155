import { useDispatch, useSelector } from "react-redux";
import { getFeedList, selectFeed } from "stores/modules/feed/action";

// state
export const useFeedState = value => useSelector(({ feed }) => feed[value]);
export const useAllFeed = value => useSelector(({ feed }) => (value ? feed.all[value] : feed.all));

// action
export const useFeedAction = () => {
    const dispatch = useDispatch();

    const onGetAllFeedList = (options = {}) => {
        dispatch(getFeedList(options));
    };

    const editFeed = data => {
        const feedId = data?._id || data?.id;
        if (!feedId) return;

        if (data.isOther) {
            dispatch(selectFeed({ id: feedId, mode: "edit" }));
        } else if (data.projectId || data.project?._id) {
            dispatch(
                selectFeed({ id: feedId, mode: "edit-project-feed", link: "/mypage/complete" })
            );
        } else if (data.suggestionId || data.suggestion) {
            dispatch(
                selectFeed({
                    id: feedId,
                    mode: "edit-suggestion-feed",
                    link: "/mypage/suggestion/running"
                })
            );
        } else if (data.orderId || data.talent?._id) {
            dispatch(
                selectFeed({
                    id: feedId,
                    mode: "edit-order-feed",
                    link: `/mytalent/order/${data.orderId?._id || data.orderId}`
                })
            );
        }
    };

    return {
        getAllFeedList: onGetAllFeedList,
        editFeed
    };
};
