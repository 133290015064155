import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconFont } from "reviewshare-common-component";
import { returnName } from "utils/messenger";
import { useUserType } from "lib/customHooks/useAuth";
import { handleMessengerMainFunction } from "stores/modules/messenger/action";
import FormattedMessage from "components/common/FormattedMessage";
import ClickItem from "components/common/ClickItem";
import RelationStatusBtn from "components/relation/common/RelationStatusBtn";

import "./MessageBoxToList.scss";

const MessageBoxToList = () => {
    const dispatch = useDispatch();

    const userType = useUserType();
    const toList = useSelector(({ messenger }) => messenger.writeInfo.toList || []);
    const onlyOneTarget = useMemo(() => toList.length === 1, [toList.length]);

    const firstReceiver = useMemo(
        () =>
            userType === "Seller"
                ? returnName(toList?.[0]?.nickname, toList?.[0]?.isDeletedReviewer)
                : returnName(toList?.[0]?.brandName, toList?.[0]?.isDeletedBrand),
        [toList?.[0]]
    );

    const onOpenBrandHistory = () => {
        dispatch(handleMessengerMainFunction("brand"));
    };

    if (!toList.length) return <p>{returnName()}</p>;

    if (onlyOneTarget) {
        return (
            <div className="message-box-to-list-name">
                <ClickItem onClick={onOpenBrandHistory} className="open-brand-history-btn">
                    <h3>{firstReceiver}</h3>
                    <IconFont icon="icon_arrow_right">
                        <FormattedMessage id="action.view.more" />
                    </IconFont>
                </ClickItem>
                <RelationStatusBtn
                    themeType="line"
                    className="size-xxs m-size-xxs"
                    relation={toList[0].relation}
                    userId={toList[0].reviewerId}
                />
            </div>
        );
    }

    return (
        <div className="message-box-to-list-name" tabIndex={0}>
            <h3>
                {firstReceiver} 외 {toList.length - 1}명
            </h3>
            <span className="icon-wrapper">
                <IconFont icon="icon_arrow_up">
                    <FormattedMessage id="action.view.more" />
                </IconFont>
            </span>
        </div>
    );
};
export default MessageBoxToList;
