export const convertProjectType = type => {
    switch (type) {
        case "review":
            return "item.project.type.review";
        case "content":
            return "item.project.type.content";
        case "shortform":
            return "item.project.type.shortform";
        case "event":
            return "기타";
        case "survey":
            return "설문";
        case "interview":
            return "인터뷰";
        default:
            return type;
    }
};

export const convertProjectCategory = category => {
    switch (category) {
        case "Delivery":
            return "item.project.category.delivery";
        case "Visit":
            return "item.project.category.visit";
        case "Buy":
            return "item.project.category.buy";
        case "Reporters":
            return "item.project.category.reporters";
        case "None":
            return "item.project.category.none";
        default:
            return category;
    }
};

export const convertProjectResultType = type => {
    switch (type) {
        case "review":
            return "item.project.type.review_short";
        case "content":
            return "item.project.type.content_short";
        case "shortform":
            return "item.project.type.shortform_short";
        case "event":
            return "이벤트";
        case "survey":
            return "설문";
        case "interview":
            return "인터뷰";
        default:
            return type;
    }
};

export const convertProjectStatus = status => {
    switch (status) {
        case "standby":
            return "status.project.biz.standby";
        case "recruit":
            return "status.project.biz.recruit";
        case "submit":
            return "status.project.biz.submit";
        case "complete":
            return "status.project.biz.complete";
        default:
            return status;
    }
};

export const convertProjectKey = (key, category) => {
    switch (key) {
        case "type":
            return "item.project.type";
        case "category":
            return "item.project.category";
        case "sns":
            return "item.project.sns";
        case "points":
            return "item.project.points";
        case "recruits":
            return "item.project.recruit.counts";
        case "visitLocation":
            return "item.project.location";
        case "item":
            return "item.project.item";
        case "itemName":
            return category === "Visit" ? "item.project.item.name.visit" : "item.project.item.name";
        case "itemPrice":
            return category === "Visit"
                ? "item.project.item.price.visit"
                : category === "Buy"
                ? "item.project.item.price.buy"
                : "item.project.item.price";
        case "title":
            return "item.project.title";
        case "photos":
            return "item.project.image";
        case "mainPhoto":
            return "item.project.image.main";
        case "subPhoto":
            return "item.project.image.sub";
        case "keyword":
            return "item.project.keyword";
        case "hashTags":
            return "item.project.hashtag";
        case "confirm":
            return "item.project.requirement";
        case "itemOptions":
            return "item.project.option";
        case "writeWarning":
            return "item.project.caution";
        case "guideLine":
            return "item.project.guide";
        case "bizInfo":
            return "item.project.biz.info";
        case "phoneNumber":
            return "item.project.contact.phoneNumber";
        case "contact":
            return "item.project.contact";
        case "contactTime":
            return "item.project.contact.time";
        case "enterprise":
            return "item.brand";
        default:
            return key;
    }
};

export const returnAdMessage = ({ category, points } = {}) => {
    const items = [];

    switch (category) {
        case "배송형":
        case "Delivery":
        case "방문형":
        case "Visit":
            items.push("제품/서비스");
            break;
        default:
            items.push("원고료");
    }

    if (items.indexOf("제품/서비스") > -1 && points > 0) {
        items.push("원고료");
    }

    return `${items.join("/")}를 제공받았으나,\n주관적인 생각으로 작성하였습니다.`;
};

export const checkCancelReviewerFault = reason => {
    const reviewerFaultReasonList = [
        "noIntention",
        "lookWrong",
        "personalSchedule",
        "lostContact",
        "brokenPromise",
        "deadlinePassed",
        "reviewerFault",
        "etcReviewer"
    ];
    return reviewerFaultReasonList.indexOf(reason) > -1;
};

export const checkCancelNeedAttach = ({ reason, userType }) => {
    if (userType === "reviewer") {
        return ["accident", "bizFault", "etcBiz"].indexOf(reason) > -1;
    }
    if (userType === "biz") {
        return ["reviewerFault", "etcReviewer"].indexOf(reason) > -1;
    }
};

export const convertApplyCancelReason = reason => {
    switch (reason) {
        case "noIntention":
            return "프로젝트 진행의사 없어짐";
        case "lookWrong":
            return "프로젝트 정보 잘못 봄";
        case "personalSchedule":
            return "개인 스케줄로 프로젝트 진행 불가";
        case "accident":
            return "특수사항(상해,사고 등)";
        case "lostContact":
            return "연락이 잘 되지 않아요";
        case "brokenPromise":
            return "약속을 지키지 않았어요";
        case "deadlinePassed":
            return "마감기한이 많이 지났어요";
        case "deliveryDelay":
            return "배송 일정 지연";
        case "dissolution":
            return "사업을 더 이상 운영하지 않아요";
        case "productDefect":
            return "제품, 서비스에 문제가 있어요";
        case "unbookable":
            return "예약 불가";
        case "reviewerFault":
            return "리뷰어측 귀책";
        case "bizFault":
            return "브랜드측 귀책(매장 폐업, 배송 일정 지연, 제품 불량, 예약불가, 연락두절 등)";
        case "etcReviewer":
            return "기타 (리뷰어 개인사정)";
        case "etcBiz":
            return "기타 (비즈니스 개인사정)";
        default:
            return reason;
    }
};
