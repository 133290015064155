import React, { useState } from "react";
import classnames from "classnames";
import moment from "moment";
import DatePicker from "react-date-picker";
import { IconFont, Button, InfoBox } from "reviewshare-common-component";
import { useToday } from "lib/customHooks/useCommon";

import commonStyle from "components/suggest/create/Common.module.scss";
import style from "./Date.module.scss";

const cx = classnames.bind(commonStyle, style);
const Date = ({ info, setInfo, invalidValue }) => {
    const today = useToday();
    const [datePickerStatus, setDatePickerStatus] = useState(false);
    return (
        <div className={commonStyle.item}>
            <h3 className={commonStyle.itemTitle}>결과물 제출 기간을 설정해 주세요.</h3>
            <InfoBox
                color={invalidValue.date ? "red" : "blue"}
                className={`${commonStyle.infoBox} size-s m-size-s`}
            >
                <IconFont icon="icon_info" /> 결과물 제출 기간 설정 필수
            </InfoBox>
            <div className={commonStyle.itemContent}>
                <div className={style.dateBox}>
                    <div className={`${style.dateItem} ${style.disabled}`}>
                        <IconFont icon="icon_calendar" />
                        제안 수락일
                    </div>
                    <span>~</span>
                    <div className={style.dateItem}>
                        <IconFont icon="icon_calendar" />
                        {info.submitEndAt
                            ? moment(info.submitEndAt).format("YYYY.MM.DD")
                            : "제출 마감일"}
                        <DatePicker
                            isOpen={datePickerStatus}
                            clearIcon={null}
                            calendarIcon={null}
                            minDate={moment(today).toDate()}
                            value={moment(info.submitEndAt).toDate()}
                            onChange={val =>
                                setInfo(prev => ({
                                    ...prev,
                                    submitEndAt: moment(val).format("YYYY-MM-DD")
                                }))
                            }
                            onCalendarClose={() => setDatePickerStatus(false)}
                        />
                        {[true, false].map(boolean => (
                            <Button
                                className={cx(`${style.changeBtn} size-xs m-size-xxs round`, {
                                    theme2: !datePickerStatus,
                                    theme3: datePickerStatus
                                })}
                                onClick={() => setDatePickerStatus(!datePickerStatus)}
                                disabled={boolean !== datePickerStatus}
                            >
                                <span className="web">
                                    {info.submitEndAt ? "변경" : "선택"}
                                    {datePickerStatus ? " 취소" : ""}
                                </span>
                                <span className="mobile">
                                    {!datePickerStatus && (info.submitEndAt ? "변경" : "선택")}
                                    {datePickerStatus && "취소"}
                                </span>
                            </Button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Date;
