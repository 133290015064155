import React from "react";
import moment from "moment";
import { returnName } from "utils/messenger";
import useTranslate from "lib/customHooks/useTranslate";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";

import "./BrandHistoryItem.scss";

const BrandHistoryItem = ({ data, category }) => {
    const translate = useTranslate();

    const categoryInfo = (
        <>
            {data.titleImgUrl && (
                <img src={data.titleImgUrl} alt={translate("item.project.thumbnail")} />
            )}
            <div className="category-text">
                <p>
                    {data.title || (
                        <>
                            <FormattedMessage id="item.brand" /> :{" "}
                            {returnName(data.brandName, data.isDeletedBrand)}
                        </>
                    )}
                </p>
                <span>
                    {category === "suggestion" ? (
                        <>
                            <FormattedMessage id="item.suggestion.id" /> {data.suggestionId}
                        </>
                    ) : (
                        <>
                            <FormattedMessage id="item.project.id" /> {data.projectId}
                        </>
                    )}
                </span>
            </div>
        </>
    );
    return (
        <li className="messenger-main-brand-history-item">
            {category === "project" ? (
                <CommonLink to={`/project/${data.projectId}`} className="category-info">
                    {categoryInfo}
                </CommonLink>
            ) : (
                <div className="category-info">{categoryInfo}</div>
            )}
            <div className="category-sub-info">
                <div className="category-sub-info-item date">
                    <p>{category === "suggestion" ? "제안 수락일" : "선정일"}</p>
                    <span>{moment(data.choiseDay || data.acceptedAt).format("YYYY.MM.DD")}</span>
                </div>
            </div>
        </li>
    );
};
export default BrandHistoryItem;
