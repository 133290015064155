import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as brandAPI from "lib/api/brand";

import { handleProgressItem } from "stores/modules/progress";
import {
    CREATE_BRAND,
    CREATE_BRAND_SUCCESS,
    CREATE_BRAND_FAILURE,
    GET_BRAND_LIST,
    GET_BRAND_LIST_SUCCESS,
    GET_BRAND_LIST_FAILURE,
    GET_ALL_BRAND_LIST
} from "stores/modules/brand/action";

import { SET_SELECTED_BRAND_INFO } from "stores/modules/create/action";

import { OPEN_ALERT } from "stores/modules/alert";

const createBrandSaga = function*(action) {
    try {
        const { data } = yield call(brandAPI.createBrand, action.payload);
        yield put({
            type: CREATE_BRAND_SUCCESS,
            payload: data.enterprise
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.brand.create.fail" }
        });
        yield put({
            type: CREATE_BRAND_FAILURE,
            payload: { error: e.response }
        });
    }
};

const getBrandListSaga = function*(action) {
    try {
        yield put(handleProgressItem({ type: action.type, value: true }));
        const { data } = yield call(brandAPI.getBrandList, action.payload);
        yield put({
            type: GET_BRAND_LIST_SUCCESS,
            payload: data
        });
        if (action.payload?.isSelect) {
            yield put({
                type: SET_SELECTED_BRAND_INFO,
                payload: data.latestBrand || data.brands[0]
            });
        }
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.brand.get.list.fail" }
        });
        yield put({
            type: GET_BRAND_LIST_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put(handleProgressItem({ type: action.type, value: false }));
    }
};

const getAllBrandListSaga = createRequestSagaWithAlert(
    GET_ALL_BRAND_LIST,
    brandAPI.getBrandList,
    "message.brand.get.list.fail"
);

export function* brandSaga() {
    yield takeLeading(CREATE_BRAND, createBrandSaga);
    yield takeLatest(GET_BRAND_LIST, getBrandListSaga);
    yield takeLatest(GET_ALL_BRAND_LIST, getAllBrandListSaga);
}
