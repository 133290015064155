export const changeJsonData = values => {
    if (!values || values.length < 0) {
        return values;
    }

    const newValues = [...values];
    newValues.splice(0, 1);
    const newData = {};
    newValues.forEach(array => {
        const obj = {};
        values[0].forEach((key, index) => {
            obj[key] = array[index];
        });
        newData[obj.value] = obj;
    });

    return newData;
};
