import { takeLatest } from "redux-saga/effects";
import createRequestSaga, { createRequestSagaBase } from "lib/createRequestSaga";
import * as mypageAPI from "lib/api/mypage";
import { getOpinions as getOpinionsAPI } from "lib/api/campaignRequest";
import { getTalentCounts as getTalentCountsAPI } from "lib/api/talent";

import {
    GET_ACTIVITY_COUNTS,
    GET_TALENT_COUNTS,
    GET_MY_ACTIVITY,
    GET_RECOMMEND_HISTORY,
    GET_OPINION_LIST,
    GET_PROJECT_COUNTS_BY_SNS
} from "stores/modules/mypage/action";

const getTalentCountsSaga = createRequestSagaBase({
    type: GET_TALENT_COUNTS,
    api: getTalentCountsAPI
});

// activity
const getRecommendHistorySaga = createRequestSaga(
    GET_RECOMMEND_HISTORY,
    mypageAPI.getRecommendHistory
);
const getOpinionListSaga = createRequestSaga(GET_OPINION_LIST, getOpinionsAPI);
const getProjectCountsBySnsSaga = createRequestSaga(
    GET_PROJECT_COUNTS_BY_SNS,
    mypageAPI.getProjectCountsBySns
);
const getMyActivitySaga = createRequestSaga(GET_MY_ACTIVITY, mypageAPI.getMyActivity);
const getActivityCountsSaga = createRequestSagaBase({
    type: GET_ACTIVITY_COUNTS,
    api: mypageAPI.getActivityCounts
});

export function* mypageSaga() {
    yield takeLatest(GET_ACTIVITY_COUNTS, getActivityCountsSaga);
    yield takeLatest(GET_TALENT_COUNTS, getTalentCountsSaga);
    yield takeLatest(GET_MY_ACTIVITY, getMyActivitySaga);
    yield takeLatest(GET_RECOMMEND_HISTORY, getRecommendHistorySaga);
    yield takeLatest(GET_OPINION_LIST, getOpinionListSaga);
    yield takeLatest(GET_PROJECT_COUNTS_BY_SNS, getProjectCountsBySnsSaga);
}
