import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { ConfirmModal } from "reviewshare-common-component";
import { numberWithCommas } from "lib/common";
import { changeRelationStatus, setChangeCondition } from "stores/modules/relation/action";

import style from "./BeforeChangeRelation.module.scss";

const cx = classnames.bind(style);

const BeforeChangeRelation = () => {
    const dispatch = useDispatch();

    const type = useSelector(({ relation }) => relation.changeCondition.type);
    const status = useSelector(({ relation }) => relation.changeCondition.status);
    const userList = useSelector(({ relation }) => relation.changeCondition.userList);

    const onCloseModal = () => {
        dispatch(setChangeCondition({ status: null }));
    };

    const onSetBlcok = async () => {
        dispatch(changeRelationStatus({ status: "block", userList, showCompleteStatus: true }));
    };

    if (["follow", "block"].indexOf(type) === -1) return null;
    if (["check", "complete"].indexOf(status) === -1) return null;
    if (!userList?.length) return null;
    if (type === "block" && status === "check") {
        return (
            <ConfirmModal
                className={cx(style.checkModal)}
                onCancel={onCloseModal}
                onConfirm={onSetBlcok}
            >
                <h3 className={style.title}>
                    <p className={style.nickname}>
                        {userList[0].nickname}
                        {userList.length > 1 && `외 ${numberWithCommas(userList.length - 1)}명`}
                    </p>
                    차단하시겠어요?
                </h3>
                <ul className={style.functionItems}>
                    <li className={style.functionItem}>
                        <p className={style.functionTitle}>
                            차단 시 해당 리뷰어와 {"\n"}이런 서비스들을 이용할 수 없어요.
                        </p>
                        <div className={style.functionDesc}>
                            1. 쪽지를 수신할 수 없어요.{"\n"}
                            2. 제안/거래를 할 수 없어요.{"\n"}
                            3. 프로젝트 진행 시 모집 현황에서 해당 리뷰어를 볼 수 없어요.
                        </div>
                    </li>
                    <li className={style.functionItem}>
                        <p className={style.functionTitle}>
                            해당 리뷰어와 {"\n"}진행 중인 서비스가 있다면?
                        </p>
                        <div className={style.functionDesc}>
                            리워드 포인트가 있는 경우, 리뷰어가 결과물 제출할 때 자동 지급돼요.{" "}
                            <span className="blue-font">
                                진행중인 서비스 취소 및 포인트 환급을 원하신다면 고객센터 1:1 문의로
                                알려주세요.
                            </span>
                        </div>
                    </li>
                </ul>
            </ConfirmModal>
        );
    }

    return null;
};

export default BeforeChangeRelation;
