import { createAction, handleActions } from "redux-actions";
import { createRequestActionTypes } from "lib/createRequestSaga";
import { checkRunningPlan } from "utils/subscription";
import produce from "immer";

export const RESET_PLAN_INFO = "subscription/RESET_PLAN_INFO";
export const SET_PLAN_INFO = "subscription/SET_PLAN_INFO";

export const [
    GET_PLAN_INFO,
    GET_PLAN_INFO_SUCCESS,
    GET_PLAN_INFO_FAILURE
] = createRequestActionTypes("subscription/GET_PLAN_INFO");
export const [
    GET_PLAN_TABLE,
    GET_PLAN_TABLE_SUCCESS,
    GET_PLAN_TABLE_FAILURE
] = createRequestActionTypes("subscription/GET_PLAN_TABLE");
export const [
    CHANGE_STATUS_FROM_INTERRUPTION,
    CHANGE_STATUS_FROM_INTERRUPTION_SUCCESS,
    CHANGE_STATUS_FROM_INTERRUPTION_FAILURE
] = createRequestActionTypes("subscription/CHANGE_STATUS_FROM_INTERRUPTION");
export const [CHANGE_PLAN, CHANGE_PLAN_SUCCESS, CHANGE_PLAN_FAILURE] = createRequestActionTypes(
    "subscription/CHANGE_PLAN"
);
export const [
    UNSUBSCRIBE_PLAN,
    UNSUBSCRIBE_PLAN_SUCCESS,
    UNSUBSCRIBE_PLAN_FAILURE
] = createRequestActionTypes("subscription/UNSUBSCRIBE_PLAN");
export const [
    REMOVE_SPARE_PAYMENT,
    REMOVE_SPARE_PAYMENT_SUCCESS,
    REMOVE_SPARE_PAYMENT_FAILURE
] = createRequestActionTypes("subscription/REMOVE_SPARE_PAYMENT");

export const resetPlanInfo = createAction(RESET_PLAN_INFO);
export const setPlanInfo = createAction(SET_PLAN_INFO);
export const getPlanInfo = createAction(GET_PLAN_INFO);
export const getPlanTable = createAction(GET_PLAN_TABLE);
export const changeStatusFromInterruption = createAction(CHANGE_STATUS_FROM_INTERRUPTION);
export const changePlan = createAction(CHANGE_PLAN);
export const unsubscribePlan = createAction(UNSUBSCRIBE_PLAN);
export const removeSparePayment = createAction(REMOVE_SPARE_PAYMENT);

const initialState = {
    planIsRunning: false,
    planInfo: {
        plan: "none",
        brands: {
            list: [],
            totalCounts: 0
        },
        features: {},
        existAvaliablePromotion: false
    },

    planTable: [],
    history: []
};

const subscription = handleActions(
    {
        [RESET_PLAN_INFO]: state =>
            produce(state, draft => {
                draft.planInfo.paymentInfo = undefined;
                draft.planInfo.sparePaymentInfo = undefined;
            }),
        [SET_PLAN_INFO]: (state, { payload }) =>
            produce(state, draft => {
                draft.planInfo = { ...state.planInfo, ...payload };
                draft.planIsRunning = checkRunningPlan(payload);
            }),
        [GET_PLAN_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.planInfo = { ...state.planInfo, ...payload };
                draft.planIsRunning = checkRunningPlan(payload);
            }),
        [GET_PLAN_TABLE_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.planTable = payload || [];
            }),
        [CHANGE_STATUS_FROM_INTERRUPTION]: state =>
            produce(state, draft => {
                draft.planInfo = { ...state.planInfo, ...{ plan: "none", status: "none" } };
                draft.planIsRunning = false;
            }),
        [CHANGE_PLAN_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.planInfo = { ...state.planInfo, ...payload };
                draft.planIsRunning = checkRunningPlan(payload);
            }),
        [UNSUBSCRIBE_PLAN_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.planInfo = { ...state.planInfo, status: payload.status };
                draft.planIsRunning = checkRunningPlan({
                    ...state.planInfo,
                    status: payload.status
                });
            }),
        [REMOVE_SPARE_PAYMENT_SUCCESS]: state =>
            produce(state, draft => {
                draft.planInfo.sparePaymentInfo = undefined;
            })
    },
    initialState
);

export default subscription;
