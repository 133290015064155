import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmModal } from "reviewshare-common-component";
import useTranslate from "lib/customHooks/useTranslate";
import useDirectApi from "lib/customHooks/useDirectApi";
import { createSuggestion as createSuggestionAPI } from "lib/api/suggest";
import { openAlert } from "stores/modules/alert";
import { resetSuggestUserInfo } from "stores/modules/suggest/action";
import FormattedMessage from "components/common/FormattedMessage";
import SuggestModal from "components/suggest/common/modal/SuggestModal";
import SuggestCompleteModal from "components/suggest/common/modal/SuggestCompleteModal";

const SuggestContainer = ({ history }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();

    const [selectedBrandInfo, setSelectedBrandInfo] = useState({});
    const [confirmModalStatus, setConfirmModalStatus] = useState(false);
    const [completeSuggestion, setCompletedSuggestion] = useState(false);

    const {
        data: createdSuggestion,
        fetch: createSuggestion,
        error: createSuggestionError
    } = useDirectApi({
        api: createSuggestionAPI
    });

    const allBrands = useSelector(({ brand }) => brand.allBrands);
    const suggestUserInfo = useSelector(({ suggest }) => suggest.suggestUserInfo);

    const onCloseModal = () => {
        dispatch(resetSuggestUserInfo());
        setCompletedSuggestion(false);
    };

    const onSuggestAgain = () => {
        setCompletedSuggestion(false);
    };

    useEffect(() => {
        if (allBrands.list && allBrands.list.length && !selectedBrandInfo._id) {
            setSelectedBrandInfo(allBrands.list[0]);
        }
        // eslint-disable-next-line
    }, [allBrands]);

    useEffect(() => {
        if (createdSuggestion?.suggestion?._id) {
            setCompletedSuggestion(true);
        }
        // eslint-disable-next-line
    }, [createdSuggestion?.suggestion?._id]);

    useEffect(() => {
        if (createSuggestionError) {
            dispatch(openAlert({ text: "message.suggestion.create.fail" }));
        }
        // eslint-disable-next-line
    }, [createSuggestionError]);

    useEffect(() => {
        if (createdSuggestion?.suggestion?._id) {
            setCompletedSuggestion(true);
        }
        // eslint-disable-next-line
    }, [createdSuggestion?.suggestion?._id]);

    useEffect(() => {
        history.listen(() => onCloseModal());

        return () => {
            onCloseModal();
        };
        // eslint-disable-next-line
    }, []);

    if (!suggestUserInfo.userId) return null;
    return (
        <div>
            {completeSuggestion ? (
                <SuggestCompleteModal
                    closeModal={onCloseModal}
                    suggestAgain={onSuggestAgain}
                    createdSuggestionId={createdSuggestion?.suggestion?._id}
                />
            ) : (
                <SuggestModal createSuggestion={createSuggestion} closeModal={onCloseModal} />
            )}
            {confirmModalStatus && (
                <ConfirmModal
                    confirmText={translate("action.charge")}
                    cancelText={translate("action.cancel")}
                    onCancel={() => setConfirmModalStatus(false)}
                    onConfirm={() => {
                        setConfirmModalStatus(false);
                        history.push("/biz/chargepoint");
                    }}
                >
                    <FormattedMessage id="message.points.lack" />
                    {"\n"}
                    <FormattedMessage id="message.points.check.charge" />
                </ConfirmModal>
            )}
        </div>
    );
};

export default withRouter(SuggestContainer);
