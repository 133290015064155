import client from "./client";

export const uploadFiles = files => client.post(`/v3/feeds/get-upload-url`, files);
export const getFeed = id => client.get(`/v2/feeds/${id}`);
export const getFeedForEdit = id => client.get(`/v3/feeds/${id}/edit`);
export const getFeedList = options =>
    client.get("/feeds", {
        params: options
    });
export const getPortfolioFeedList = ({ userId, type, skip, limit = 20 }) =>
    client.get("/v3/feeds", { params: { userId, type, skip, limit } });
export const getFeedPickList = options => client.get("/v3/feeds/pick", { params: options });
export const getMyFeedList = ({ type, isOther, skip, limit = 20 }) =>
    client.get("/v2/myfeeds", {
        params: {
            type,
            isOther: isOther || undefined,
            skip,
            limit
        }
    });
export const getBizFeedList = ({ location, brand, project, skip = 0, limit = 12 }) =>
    client.get(`/feeds/generated-by-biz/${location}`, {
        params: { brand, project, skip, limit }
    });
export const getFeedRecommendKeywordList = options =>
    client.get("/v3/feeds/recommend-keywords", { params: options });
export const submitOtherFeed = obj => client.post("/v3/feeds/otherFeed", obj);
export const removeOtherFeed = feedId => client.post("/v2/remove/otherFeed", { id: feedId });
export const handleHideFeed = ({ feedId, isHide }) =>
    client.patch(`/v3/feeds/${feedId}/hide`, { isHide });
