import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { createRequestActionTypes } from "lib/createRequestSaga";

export const GET_PROJECT = "project/GET_PROJECT";
export const GET_PROJECT_SUCCESS = "project/GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILURE = "project/GET_PROJECT_FAILURE";

export const APPLY_PROJECT = "project/APPLY_PROJECT";
export const APPLY_PROJECT_SUCCESS = "project/APPLY_PROJECT_SUCCESS";
export const APPLY_PROJECT_FAILURE = "project/APPLY_PROJECT_FAILURE";

export const SET_APPLY_PROJECT = "project/SET_APPLY_PROJECT";

export const CANCEL_PROJECT = "project/CANCEL_PROJECT";
export const CANCEL_PROJECT_SUCCESS = "project/CANCEL_PROJECT_SUCCESS";
export const CANCEL_PROJECT_FAILURE = "project/CANCEL_PROJECT_FAILURE";

export const GET_PROJECT_LIST = "project/GET_PROJECT_LIST";
export const GET_PROJECT_LIST_SUCCESS = "project/GET_PROJECT_LIST_SUCCESS";
export const GET_PROJECT_LIST_FAILURE = "project/GET_PROJECT_LIST_FAILURE";

export const GET_RECOMMEND_PROJECT_LIST = "project/GET_RECOMMEND_PROJECT_LIST";
export const GET_RECOMMEND_PROJECT_LIST_SUCCESS = "project/GET_RECOMMEND_PROJECT_LIST_SUCCESS";
export const GET_RECOMMEND_PROJECT_LIST_FAILURE = "project/GET_RECOMMEND_PROJECT_LIST_FAILURE";

export const [
    GET_PROJECT_COUNTS,
    GET_PROJECT_COUNTS_SUCCESS,
    GET_PROJECT_COUNTS_FAILURE
] = createRequestActionTypes("project/GET_PROJECT_COUNTS");

export const RESET_PROJECT = "project/RESET_PROJECT";

export const getProject = createAction(GET_PROJECT, obj => obj);
export const applyProject = createAction(APPLY_PROJECT, obj => obj);
export const setApplyProject = createAction(SET_APPLY_PROJECT, obj => obj || {});
export const cancelProject = createAction(CANCEL_PROJECT, obj => obj);
export const getProjectList = createAction(GET_PROJECT_LIST, projectSearchObj => projectSearchObj);
export const getRecommendProjectList = createAction(GET_RECOMMEND_PROJECT_LIST);
export const getProjectCounts = createAction(GET_PROJECT_COUNTS);

export const resetProject = createAction(RESET_PROJECT);

const initialState = {
    isEnterprise: false,
    project: {},
    apply: {},
    projectListSearching: false,
    projectList: [],
    totalProjectCount: 0,
    projectListError: null,

    recommend: {
        list: []
    },

    //biz
    projectCounts: {}
};

const project = handleActions(
    {
        [RESET_PROJECT]: state =>
            produce(state, draft => {
                draft.project = {};
            }),
        [GET_PROJECT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.project = {
                    ...payload.project,
                    isChosen: payload.isChosen,
                    status: payload.status || payload.project.status,
                    securityDeposit: payload.securityDeposit
                };
                draft.isEnterprise = payload.isEnterprise;
            }),
        [SET_APPLY_PROJECT]: (state, { payload }) =>
            produce(state, draft => {
                draft.apply = payload;
            }),
        [CANCEL_PROJECT_SUCCESS]: state =>
            produce(state, draft => {
                draft.project.status = "Apply";
                draft.project.securityDeposit = undefined;
            }),
        [GET_PROJECT_LIST]: (state, { payload: searchQuery }) => ({
            ...state,
            projectListSearching: true,
            projectList: searchQuery?.skip > 0 ? state.projectList : [],
            totalProjectCount: searchQuery?.skip > 0 ? state.totalProjectCount : 0
        }),
        [GET_PROJECT_LIST_SUCCESS]: (state, { payload: projectArray, prev }) => ({
            ...state,
            projectListError: null,
            projectListSearching: false,
            projectList: state.projectList.concat(projectArray.projects),
            totalProjectCount: prev.skip > 0 ? state.totalProjectCount : projectArray.count
        }),
        [GET_PROJECT_LIST_FAILURE]: (state, { payload: error }) => ({
            ...state,
            projectListError: error,
            projectListSearching: false
        }),
        [GET_RECOMMEND_PROJECT_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            recommend: { list: payload }
        }),
        [GET_PROJECT_COUNTS_SUCCESS]: (state, { payload }) => ({
            ...state,
            projectCounts: payload
        })
    },
    initialState
);

export default project;
