import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { createRequestSagaWithAlert } from "lib/createRequestSaga";
import * as paymentAPI from "lib/api/payment";

import {
    GET_BILL,
    GET_BILL_SUCCESS,
    GET_BILL_FAILURE,
    GET_PAYMENT_HISTORY,
    REQUEST_SUBSCRIPTION,
    REQUEST_SUBSCRIPTION_PAYMENT
} from "stores/modules/payment/action";

import { OPEN_ALERT } from "stores/modules/alert";

const getBillSaga = function*(action) {
    try {
        const { data } = yield call(paymentAPI.getBill, action.payload);
        yield put({
            type: GET_BILL_SUCCESS,
            payload: data.payment
        });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "세금계산서 정보를 가져오지 못하였습니다." }
        });
        yield put({
            type: GET_BILL_FAILURE,
            payload: { error: e.response }
        });
    }
};

const getPaymentHistorySaga = createRequestSagaWithAlert(
    GET_PAYMENT_HISTORY,
    paymentAPI.getPaymentHistory,
    "결제 내역을 가져오지 못하였습니다."
);

const getInicisDataForSubscriptionSaga = createRequestSagaWithAlert(
    REQUEST_SUBSCRIPTION,
    paymentAPI.requestInicisForSubscription,
    "결제 정보 요청에 실패하였습니다."
);

const getInicisDataForSubscriptionPaymentSaga = createRequestSagaWithAlert(
    REQUEST_SUBSCRIPTION_PAYMENT,
    paymentAPI.requestInicisForSubscriptionPayment,
    "결제 정보 요청에 실패하였습니다."
);

export function* paymentSaga() {
    yield takeLatest(GET_BILL, getBillSaga);
    yield takeLatest(GET_PAYMENT_HISTORY, getPaymentHistorySaga);
    yield takeLeading(REQUEST_SUBSCRIPTION, getInicisDataForSubscriptionSaga);
    yield takeLeading(REQUEST_SUBSCRIPTION_PAYMENT, getInicisDataForSubscriptionPaymentSaga);
}
