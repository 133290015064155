import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { createRequestActionTypes } from "lib/createRequestSaga";

export const RESET_PORTFOLIO = "portfolio/RESET_PORTFOLIO";
export const CHANGE_RELATION_STATUS_IN_ALL_LIST = "portfolio/CHANGE_RELATION_STATUS_IN_ALL_LIST";

export const [
    GET_PORTFOLIO_USER_INFO,
    GET_PORTFOLIO_USER_INFO_SUCCESS,
    GET_PORTFOLIO_USER_INFO_FAILURE
] = createRequestActionTypes("portfolio/GET_PORTFOLIO_USER_INFO");

export const [
    GET_PORTFOLIO_TALENT_LIST,
    GET_PORTFOLIO_TALENT_LIST_SUCCESS,
    GET_PORTFOLIO_TALENT_LIST_FAILURE
] = createRequestActionTypes("portfolio/GET_PORTFOLIO_TALENT_LIST");

export const [
    GET_PORTFOLIO_ACTIVITY_INFO,
    GET_PORTFOLIO_ACTIVITY_INFO_SUCCESS,
    GET_PORTFOLIO_ACTIVITY_INFO_FAILURE
] = createRequestActionTypes("portfolio/GET_PORTFOLIO_ACTIVITY_INFO");

export const [
    GET_PORTFOLIO_OPINION_INFO,
    GET_PORTFOLIO_OPINION_INFO_SUCCESS,
    GET_PORTFOLIO_OPINION_INFO_FAILURE
] = createRequestActionTypes("portfolio/GET_PORTFOLIO_OPINION_INFO");

export const [
    GET_ALL_PORTFOLIO_LIST,
    GET_ALL_PORTFOLIO_LIST_SUCCESS,
    GET_ALL_PORTFOLIO_LIST_FAILURE
] = createRequestActionTypes("portfolio/GET_ALL_PORTFOLIO_LIST");

export const [
    GET_RECENT_REVIEWER_LIST,
    GET_RECENT_REVIEWER_LIST_SUCCESS,
    GET_RECENT_REVIEWER_LIST_FAILURE
] = createRequestActionTypes("portfolio/GET_RECENT_REVIEWER_LIST");

export const resetPortfolio = createAction(RESET_PORTFOLIO);
export const changeRelationStatusInAllList = createAction(CHANGE_RELATION_STATUS_IN_ALL_LIST);
export const getPortfolioUserInfo = createAction(GET_PORTFOLIO_USER_INFO, id => id);
export const getPortfolioTalentList = createAction(GET_PORTFOLIO_TALENT_LIST);
export const getPortfolioActivityInfo = createAction(GET_PORTFOLIO_ACTIVITY_INFO, id => id);
export const getPortfolioOpinionInfo = createAction(GET_PORTFOLIO_OPINION_INFO, obj => obj);

export const getAllPortfolioList = createAction(GET_ALL_PORTFOLIO_LIST, option => option);
export const getRecentReviewerList = createAction(GET_RECENT_REVIEWER_LIST);

const initialState = {
    user: {},
    activity: {},
    feed: {
        counts: 0,
        list: []
    },
    talent: {
        counts: 0,
        list: []
    },
    opinions: {},

    // portfolio list page
    bestReviewerStartAt: null,
    bestReviewerList: [],

    all: {
        counts: 0,
        list: []
    },

    recent: {
        list: []
    }
};

const portfolio = handleActions(
    {
        [RESET_PORTFOLIO]: state =>
            produce(state, draft => {
                draft.user = initialState.user;
                draft.activity = initialState.activity;
                draft.feed = initialState.feed;
                draft.talent = initialState.talent;
                draft.opinions = initialState.opinions;
            }),
        [CHANGE_RELATION_STATUS_IN_ALL_LIST]: (state, { payload }) =>
            produce(state, draft => {
                if (state.all.list.length > 0) {
                    draft.all.list = state.all.list.map(item => {
                        if (payload.userIdList.indexOf(item.userId) > -1) {
                            return {
                                ...item,
                                relation: { ...item.relation, status: payload.status }
                            };
                        }
                        return item;
                    });
                }
            }),
        [GET_PORTFOLIO_USER_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.user = { ...state.user, ...payload };
            }),
        [GET_PORTFOLIO_TALENT_LIST_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                draft.talent = {
                    counts: payload.totalCounts,
                    major: payload.major,
                    list: prev.skip > 0 ? [...state.talent.list, ...payload.list] : payload.list
                };
            }),
        [GET_PORTFOLIO_ACTIVITY_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.activity = payload;
            }),
        [GET_PORTFOLIO_OPINION_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.opinions = payload;
            }),
        [GET_ALL_PORTFOLIO_LIST]: (state, { payload }) =>
            produce(state, draft => {
                draft.all.list = payload.skip > 0 ? state.all.list : initialState.all.list;
                draft.all.counts = payload.skip > 0 ? state.all.counts : initialState.all.counts;
            }),
        [GET_ALL_PORTFOLIO_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.all.list = [...state.all.list, ...payload.list];
                draft.all.counts = payload.totalCounts;
            }),
        [GET_RECENT_REVIEWER_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.recent.list = payload;
            })
    },
    initialState
);

export default portfolio;
