import { createAction, handleActions } from "redux-actions";
import moment from "moment";
import produce from "immer";

export const SET_SERVER_VERSION = "common/SET_SERVER_VERSION";
export const SET_LANG = "common/SET_LANG";
export const SET_TODAY = "common/SET_TODAY";
export const MOVE_404_PAGE = "common/MOVE_404_PAGE";

export const setServerVersion = createAction(SET_SERVER_VERSION);
export const setLang = createAction(SET_LANG);
export const setToday = createAction(SET_TODAY);
export const move404Page = createAction(MOVE_404_PAGE);

const initialState = {
    clientVersion: process.env.REACT_APP_VERSION,
    serverVersion: process.env.REACT_APP_VERSION,
    lang: "kr",
    today: moment(new Date()).format("YYYY-MM-DD")
};

const common = handleActions(
    {
        [SET_SERVER_VERSION]: (state, { payload }) =>
            produce(state, draft => {
                draft.serverVersion = payload;
            }),
        [SET_LANG]: (state, { payload }) =>
            produce(state, draft => {
                draft.lang = payload;
            }),
        [SET_TODAY]: (state, { payload }) =>
            produce(state, draft => {
                draft.today = payload;
            })
    },
    initialState
);

export default common;
