import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeRelationStatus, setChangeCondition } from "stores/modules/relation/action";

const useRelationStatus = ({ status, userId, nickname, showCompleteStatus } = {}) => {
    const dispatch = useDispatch();

    const changeConditionType = useSelector(({ relation }) => relation.changeCondition.type);
    const changeConditionStatus = useSelector(({ relation }) => relation.changeCondition.status);
    const changeConditionUserList = useSelector(
        ({ relation }) => relation.changeCondition.userList
    );
    const changeConditionShowCompleteStatus = useSelector(
        ({ relation }) => relation.changeCondition.showCompleteStatus
    );

    const [isFetching, setIsFetching] = useState(false);
    const [localStatus, setLocalStatus] = useState(status || "none");
    const [localChangedUserIdList, setLocalChangedUserIdList] = useState([]);

    const onChangeStatus = async _status => {
        if (!userId) return;
        if (["follow", "cancelFollow", "cancelBlock"].indexOf(_status) > -1) {
            dispatch(
                changeRelationStatus({
                    status: _status,
                    userList: [{ _id: userId, nickname }],
                    showCompleteStatus
                })
            );
            setIsFetching(true);
        }
        if (_status === "checkBlock") {
            dispatch(
                setChangeCondition({
                    type: "block",
                    status: "check",
                    userList: [{ _id: userId, nickname }]
                })
            );
        }
    };

    const onSetStatusState = _status => {
        if (["complete", "fail"].indexOf(changeConditionStatus) === -1) return;
        if (changeConditionUserList.map(item => item._id).indexOf(userId) === -1) return;
        const newStatus = ["follow", "block"].indexOf(_status) > -1 ? _status : "none";

        setIsFetching(false);
        if (changeConditionStatus === "complete") {
            setLocalStatus(newStatus);
            setLocalChangedUserIdList(changeConditionUserList.map(item => item._id));
        }

        // 한 화면에서 팔로우 가능한 요소가 여러개 있고 & 동일한 유저인 경우를 방지해서 상태 변경이 일어난 요소에서만 업데이트해줌
        if (!changeConditionShowCompleteStatus && isFetching) {
            dispatch(setChangeCondition({ status: null }));
        }
    };

    useEffect(() => {
        setLocalStatus(status || "none");
        setLocalChangedUserIdList([]);
    }, [status]);

    useEffect(() => {
        onSetStatusState(changeConditionType);
    }, [changeConditionType, changeConditionStatus, changeConditionUserList]);

    return {
        relationStatus: localStatus,
        changeStatus: onChangeStatus,
        isChangedStatus: localChangedUserIdList.indexOf(userId) > -1,
        isFetching
    };
};

export default useRelationStatus;
