import React from "react";
import cx from "classnames";
import { convertGrade } from "utils/score";
import useTranslate from "lib/customHooks/useTranslate";

import "./GradeBadge.scss";

import grade_blacklist from "assets/images/grade/grade_blacklist.svg";
import grade_white from "assets/images/grade/grade_white.svg";
import grade_silver from "assets/images/grade/grade_silver.svg";
import grade_gold from "assets/images/grade/grade_gold.svg";
import grade_dia from "assets/images/grade/grade_dia.svg";

const GradeBadge = ({ grade, text, className }) => {
    const translate = useTranslate();
    const gradeText = translate(convertGrade(grade));

    return (
        <div className={cx("common-grade-badge", className, grade, { "has-text": text })}>
            {grade === "a" && <img src={grade_white} alt={gradeText} />}
            {grade === "b" && <img src={grade_silver} alt={gradeText} />}
            {grade === "c" && <img src={grade_gold} alt={gradeText} />}
            {grade === "d" && <img src={grade_dia} className="dia" alt={gradeText} />}
            {grade === "z" && <img src={grade_blacklist} className="black" alt={gradeText} />}
            {text && <p>{gradeText}</p>}
        </div>
    );
};

export default GradeBadge;
