import produce from "immer";
import { createAction, handleActions } from "redux-actions";

const HANDLE_PROGRESS_ITEM = "progress/HANDLE_PROGRESS_ITEM";

export const handleProgressItem = createAction(HANDLE_PROGRESS_ITEM);

const initialState = {
    progressItems: {}
};

const progress = handleActions(
    {
        [HANDLE_PROGRESS_ITEM]: (state, { payload }) =>
            produce(state, draft => {
                draft.progressItems[payload.type] = payload.value || undefined;
            })
    },
    initialState
);

export default progress;
