import { delay, put, select, takeLeading, takeLatest, call } from "redux-saga/effects";
import { createRequestSagaBase } from "lib/createRequestSaga";
import * as messengerAPI from "lib/api/messenger";
import { getBrandHistory } from "lib/api/brand";
import { returnWriteInfo } from "utils/messenger";
import { createGiftPoint as createGiftPointAPI } from "lib/api/giftPoint";
import { uploadAndProcessFilesToS3Saga } from "lib/createRequestSaga";

import {
    HANDLE_MESSENGER_MAIN_STATUS,
    HANDLE_NEW_MESSAGE,
    SEARCH_MESSAGE_BOX,
    SEARCH_TO,
    SELECT_WRITE_INFO,
    SELECT_MESSAGE_BOX,
    SET_WRITE_INFO,
    GET_MESSAGE_BOX_LIST,
    GET_MESSAGE_LIST,
    GET_BRAND_HISTORY,
    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESS,
    SEND_GIFT_POINT,
    SEND_GIFT_POINT_SUCCESS,
    SEND_GIFT_POINT_FAILURE,
    ADD_MESSAGE_BOX_ITEM
} from "stores/modules/messenger/action";
import { openAlert } from "stores/modules/alert";
import { handleProgressItem } from "stores/modules/progress";

const searchMessageBoxSaga = createRequestSagaBase({
    type: SEARCH_MESSAGE_BOX,
    api: messengerAPI.searchMessageBox,
    alertErrorMsg: "쪽지 리스트를 불러오는 데 실패하였습니다.",
    progress: true
});

const searchToSaga = createRequestSagaBase({
    type: SEARCH_TO,
    api: messengerAPI.searchTo,
    progress: true
});

const getMessageBoxListSaga = createRequestSagaBase({
    type: GET_MESSAGE_BOX_LIST,
    api: messengerAPI.getMessageBoxList,
    alertErrorMsg: "쪽지 리스트를 불러오는 데 실패하였습니다.",
    progress: true
});

const getMessageListSaga = createRequestSagaBase({
    type: GET_MESSAGE_LIST,
    api: messengerAPI.getMessageList,
    alertErrorMsg: "쪽지 리스트를 불러오는 데 실패하였습니다.",
    progress: true
});

const sendGiftPointSaga = createRequestSagaBase({
    type: SEND_GIFT_POINT,
    api: createGiftPointAPI,
    progress: true,
    alertErrorMsg: "포인트 선물에 실패하였습니다."
});

const sendMessageSaga = function*(action) {
    const attachments = action.payload.getAll("attachments");

    try {
        yield put(handleProgressItem({ type: action.type, value: true }));

        if (attachments?.length) {
            const uploadedFiles = yield call(
                uploadAndProcessFilesToS3Saga({
                    fileList: attachments.map(item => ({
                        file: item,
                        name: item.name,
                        mimeType: item.type,
                        type: item.type
                    })),
                    uploadAPI: messengerAPI.uploadFiles,
                    isDownload: true
                })
            );

            action.payload.delete("attachments");

            const convertedAttachments = uploadedFiles.map(uploadedFileItem => ({
                Key: uploadedFileItem.Key,
                fileName: uploadedFileItem.fileName,
                originalname: uploadedFileItem.originalname
            }));
            action.payload.append("attachments", JSON.stringify(convertedAttachments));
        }

        const { data } = yield call(messengerAPI.sendMessage, action.payload);

        yield put({
            type: SEND_MESSAGE_SUCCESS,
            payload: data,
            prev: action.payload
        });
    } catch (error) {
        yield put(openAlert({ text: "쪽지 발송에 실패하였습니다.", status: true }));
    }
};

const sendGiftPointSuccessSaga = function*(action) {
    yield put({
        type: SEND_MESSAGE_SUCCESS,
        payload: action.payload,
        prev: action.prev
    });
};

const sendGiftPointFailureSaga = function*(action) {
    if (action.payload?.error?.data.indexOf("Unable to proceed") > -1) {
        yield put(
            openAlert({ text: "차단한 리뷰어에게는 포인트 선물을 할 수 없어요.", status: true })
        );
    }
};

const getBrandHistorySaga = createRequestSagaBase({
    type: GET_BRAND_HISTORY,
    api: getBrandHistory,
    alertErrorMsg: "브랜드 참여내역을 불러오지 못했습니다.",
    progress: true
});

const selectWriteInfoSaga = function*(action) {
    const { auth } = yield select();

    if (auth.userType === "Visitor") return;

    let beforeFrom = action.payload.from;

    if (auth.userType === "Normal") {
        const { user } = auth.userInfo;
        beforeFrom = {
            reviewerId: user._id,
            nickname: user.nickname
        };
    }

    const { from, filteredFrom, toList, filteredToList, isPossibleSend } = returnWriteInfo({
        from: beforeFrom,
        toList: action.payload.toList
    });

    const noFrom = Object.keys(from).length < 1;
    const noPrivate = toList.length !== 1;

    if (noFrom || noPrivate) {
        // 1:1 대화가 아니거나, 받는 브랜드 선택이 안 되어있을 때
        yield put({
            type: HANDLE_MESSENGER_MAIN_STATUS,
            payload: "write"
        });
    }

    yield put({
        type: SET_WRITE_INFO,
        payload: { from: filteredFrom, toList: filteredToList, isPossibleSend }
    });

    if (action.payload.isSendSuccess) return;
    if (noFrom || noPrivate) return;

    if (!action.payload.noSearchMessageBox) {
        yield put({
            type: SELECT_MESSAGE_BOX,
            payload: {
                reviewerId: from.reviewerId || toList[0].reviewerId,
                brandId: from.brandId || toList[0].brandId
            }
        });
    }
};

const addMessageBoxItemSaga = function*(action) {
    const { auth } = yield select();
    const { messenger } = yield select();
    const item = action.payload[0];
    const filteredBrandId = messenger.searchOptions.brandId;
    const isPossibleNew = filteredBrandId && filteredBrandId !== item.brandId;

    if (auth.userType === "Seller" && item.isNew && isPossibleNew) {
        yield put({
            type: HANDLE_NEW_MESSAGE,
            payload: item
        });
        yield delay(1000 * 5);
        yield put({
            type: HANDLE_NEW_MESSAGE,
            payload: null
        });
    }
};

export function* messengerSaga() {
    yield takeLeading(SEARCH_MESSAGE_BOX, searchMessageBoxSaga);
    yield takeLeading(SEARCH_TO, searchToSaga);
    yield takeLatest(GET_MESSAGE_BOX_LIST, getMessageBoxListSaga);
    yield takeLatest(GET_MESSAGE_LIST, getMessageListSaga);
    yield takeLeading(GET_BRAND_HISTORY, getBrandHistorySaga);
    yield takeLeading(SEND_MESSAGE, sendMessageSaga);
    yield takeLeading(SEND_GIFT_POINT, sendGiftPointSaga);
    yield takeLeading(SEND_GIFT_POINT_SUCCESS, sendGiftPointSuccessSaga);
    yield takeLeading(SEND_GIFT_POINT_FAILURE, sendGiftPointFailureSaga);
    yield takeLeading(SELECT_WRITE_INFO, selectWriteInfoSaga);
    yield takeLeading(ADD_MESSAGE_BOX_ITEM, addMessageBoxItemSaga);
}
