import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import cx from "classnames";
import { IconFont } from "reviewshare-common-component";
import ClickItem from "components/common/ClickItem";

import "./InduceSubscribeAboutFee.scss";

const PROMOTION_MESSAGE_SCHEME = {
    available: {
        project:
            "구독하면 프로젝트 수수료 없이 이용할 수 있어요. 담당자님을 위한 프로모션도 진행하고 있으니 놓치지마세요!🔥",
        suggestion:
            "구독하면 제안 수수료 없이 이용할 수 있어요. 담당자님을 위한 프로모션도 진행하고 있으니 놓치지마세요!🔥"
    },
    unavailable: {
        project:
            "비용이 부담스러운 담당자님을 위한 구독 상품이 준비되어 있어요. 수수료 걱정 없이 프로젝트를 마음껏 등록해보세요!🥳",
        suggestion:
            "비용이 부담스러운 담당자님을 위한 구독 상품이 준비되어 있어요. 수수료 걱정 없이 마음껏 제안해보세요!🥳"
    }
};
const InduceSubscribeAboutFee = ({ type, clickSubscribe }) => {
    const history = useHistory();
    const existAvaliablePromotion = useSelector(
        ({ subscription }) => subscription.planInfo?.existAvaliablePromotion
    );
    const [moreStatus, setMoreStatus] = useState(false);

    const clickSubscribeBtn = () => {
        if (clickSubscribe) {
            clickSubscribe();
        } else {
            history.push("/biz/plan/select");
        }
    };

    if (!type) return null;

    return (
        <div className={cx("induce-subscribe-about-fee", type, { active: moreStatus })}>
            <ClickItem className="tip-title" onClick={() => setMoreStatus(!moreStatus)}>
                <IconFont icon="icon_tip">tip</IconFont> 수수료 무료로 이용하는 방법
                <IconFont icon={moreStatus ? "icon_arrow_up_circle" : "icon_arrow_down_circle"}>
                    더보기
                </IconFont>
            </ClickItem>
            <div className="tip-desc">
                <p>
                    {existAvaliablePromotion
                        ? PROMOTION_MESSAGE_SCHEME.available[type]
                        : PROMOTION_MESSAGE_SCHEME.unavailable[type]}
                </p>
                <button className="subscribe-btn" onClick={clickSubscribeBtn}>
                    구독 시작하기
                    <IconFont icon="icon_arrow_right" />
                </button>
            </div>
        </div>
    );
};

export default InduceSubscribeAboutFee;
