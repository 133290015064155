import React from "react";
import cx from "classnames";
import Skeleton from "react-loading-skeleton";

import "./Skeleton.scss";

const SkeletonComponent = ({ className, ...rest }) => (
    <div className="common-skeleton">
        <Skeleton className={cx("common-skeleton-item", className)} {...rest} />
    </div>
);

export default SkeletonComponent;
