export const countBrandsBySubsPlan = ({ planName, planTable }) => {
    if (!planName || !planTable || !planTable.length) return;
    const plan = planTable.find(item => item.name === planName);
    if (!plan) return 0;
    return plan.limitNumOfBrand || 0;
};

export const checkRunningPlan = planInfo => {
    if (!planInfo || !planInfo.plan || !planInfo.status) return false;
    if (planInfo.plan === "none") return false;
    if (["running", "readyForUnsubscribe", "preUnsubscribe"].indexOf(planInfo.status) < 0)
        return false;
    return true;
};

export const checkNoPlan = planInfo => {
    if (!planInfo || !planInfo.plan || !planInfo.status) return false;
    if (planInfo.plan !== "none") return false;
    if (["none", "interruption"].indexOf(planInfo.status) < 0) return false;
    return true;
};

export const checkSupportCustomBanner = features => {
    return features && features.customBanner;
};
