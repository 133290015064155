export const COMMON_GET_PROJECT = [
    {
        keyword: "unknown project",
        message: "message.project.get.detail.fail.unknown"
    },
    {
        keyword: "onlyOpenToReviewer",
        message: "message.project.get.detail.fail.private"
    }
];

export const REVIEWER_APPLY_PROJECT = [
    {
        keyword: "AlreadyParticipated",
        message: "message.project.apply.fail.duplicate",
        path: "/project"
    },
    {
        keyword: "PenaltyExceeded:blockProjectRequest",
        message: "message.project.apply.fail.penalty.block",
        path: "/project"
    },
    {
        keyword: "PenaltyExceeded:unsubmit",
        message: "message.project.apply.fail.penalty.unsubmit",
        path: "/mypage/unsubmit"
    }
];

export const REVIEWER_SUBMIT_OTHER_REVIEW = [
    {
        keyword: "OtherSNS Error",
        message: "message.review.other.sns.line_break"
    },
    {
        keyword: "Already Existing Url",
        message: "message.review.other.submit.fail.duplicate"
    }
];

export const REVIEWER_WITHDRAW_POINTS = [
    {
        keyword: "penalty",
        message: "message.points.withdrawal.fail.penalty.unsubmit",
        path: "/mypage/unsubmit"
    },
    {
        keyword: "request block user",
        message: "message.points.withdrawal.fail.penalty.block"
    },
    {
        keyword: "Id card",
        message: "주민등록번호를 확인해주세요."
    },
    {
        keyword: "minors",
        message: "미성년자일 경우 부모님의 계좌 정보와 주민등록번호를 입력해주세요."
    }
];
