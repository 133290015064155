import client from "./client";

export const getWelcomePointsInfo = userId =>
    client.get(
        `/payment/findOne?query={"where":{"owner":${userId},"category":"event","status":"paid"}}`
    );

export const getBill = () => client.get("/v2/payments/savedInfo");
export const getPaymentHistory = ({ keyword, skip, limit, startDate, endDate, category }) =>
    client.get("/payment/history", {
        params: { keyword, skip, limit, startDate, endDate, category }
    });
export const requestInicis = payment => client.post("/payment/requestInicis", payment);
export const requestInicisForSubscription = ({ plan, paymentMethod = "monthly", brandIdList }) =>
    client.get("/v3/payments/required-billing-data", {
        params: {
            plan,
            paymentMethod,
            brandIdList
        }
    });
export const requestInicisForSubscriptionPayment = ({ changePayment, addSparePayment }) =>
    client.get("/v3/payments/required-billing-data", {
        params: {
            changePayment,
            addSparePayment
        }
    });
// export const requestChargePoint = obj => client.post("/payment/points", obj);
export const refund = ({ projectId }) => client.post("payments/refund", { projectId }); //사용 안할 예정
export const adjust = projectObj => client.post("payments/adjust", projectObj);
export const getPayment = moid => client.get(`/v2/payment/moid?moid=${moid}`);
export const createReceipt = receipt => client.post("/v2/payments/receipt", receipt);
export const chargePoint = obj => client.post("/payment/points", obj);
