import React from "react";
import { useDispatch } from "react-redux";
import { Spin, IconFont } from "reviewshare-common-component";
import TipBanner from "components/messenger/side/TipBanner";
import EmptyContent from "components/messenger/side/EmptyContent";
import MessageBoxItem from "components/messenger/side/MessageBoxItem";
import { getMessageBoxList } from "stores/modules/messenger/action";

import "./MessageBoxList.scss";

const MessageBoxList = ({
    userType,
    isSearching,
    list,
    selectedReviewerId,
    selectedBrandId,
    selectMessageBox
}) => {
    const dispatch = useDispatch();

    const refreshMessageBoxList = () => {
        dispatch(
            getMessageBoxList(
                userType === "Seller" && selectedBrandId ? { brand: selectedBrandId } : undefined
            )
        );
    };

    return (
        <div id="messenger-side-message-box-list">
            {userType === "Seller" && (
                <TipBanner>
                    위에서 브랜드 선택 후, [새 쪽지 쓰기] 또는 [브랜드별 받은 쪽지]를 확인해 주세요.
                </TipBanner>
            )}
            {isSearching ? (
                <Spin className="full" />
            ) : list.length > 0 ? (
                <>
                    <button
                        type="button"
                        className="refresh-btn mobile"
                        onClick={refreshMessageBoxList}
                    >
                        <span className="refresh-icon-wrapper">
                            <IconFont icon="icon_reset_right">새로고침</IconFont>
                        </span>
                        <p>목록 새로고침</p>
                    </button>
                    <ul>
                        {list.map((item, index) => (
                            <li key={index} onClick={() => selectMessageBox(item)}>
                                <MessageBoxItem
                                    userType={userType}
                                    data={item}
                                    active={
                                        selectedReviewerId === item.reviewerId &&
                                        selectedBrandId === item.brandId
                                    }
                                />
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <EmptyContent icon="icon_message_fill">쪽지가 없어요</EmptyContent>
            )}
        </div>
    );
};

export default MessageBoxList;
