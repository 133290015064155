import React from "react";
import { withRouter } from "react-router-dom";
import { IconFont } from "reviewshare-common-component";
import { MOBILE_MENU } from "scheme/menu";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";

import icon_logo_symbol_gradient from "assets/images/logo/icon_logo_symbol_gradient.svg";

import "./MobileMenu.scss";

const MobileMenu = ({ burger, setBurger, showLoginModal }) => (
    <div id="mobile-menu" className={burger ? "active" : ""}>
        <div className="menu-background" onClick={() => setBurger(false)} />
        <div className="menu-content">
            <div className="top-area">
                <div className="logo-area" onClick={showLoginModal}>
                    <img src={icon_logo_symbol_gradient} alt="리뷰쉐어 로고" />
                </div>
                <p onClick={showLoginModal}>
                    <FormattedMessage id="action.signin" />/
                    <FormattedMessage id="action.signup" />
                    <IconFont icon="icon_arrow_right_circle" />
                </p>
            </div>
            <div className="list">
                {MOBILE_MENU["none"].map((item, index) => (
                    <div key={index} className="list-wrapper">
                        <p className="menu-item">
                            <FormattedMessage id={item.label} />
                        </p>
                        {item.list.map((list, listIndex) => (
                            <CommonLink to={list.path} external={list.external} key={listIndex}>
                                <FormattedMessage id={list.label} />
                            </CommonLink>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default withRouter(MobileMenu);
