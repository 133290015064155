import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { createRequestActionTypes } from "lib/createRequestSaga";

export const [
    GET_MAIN_PORTFOLIO_LIST,
    GET_MAIN_PORTFOLIO_LIST_SUCCESS,
    GET_MAIN_PORTFOLIO_LIST_FAILURE
] = createRequestActionTypes("main/GET_MAIN_PORTFOLIO_LIST");

export const [
    GET_MAIN_PROJECT_LIST,
    GET_MAIN_PROJECT_LIST_SUCCESS,
    GET_MAIN_PROJECT_LIST_FAILURE
] = createRequestActionTypes("main/GET_MAIN_PROJECT_LIST");

export const [
    GET_MAIN_FEED_LIST,
    GET_MAIN_FEED_LIST_SUCCESS,
    GET_MAIN_FEED_LIST_FAILURE
] = createRequestActionTypes("main/GET_MAIN_FEED_LIST");

export const [
    GET_CATEGORY_LIST,
    GET_CATEGORY_LIST_SUCCESS,
    GET_CATEGORY_LIST_FAILURE
] = createRequestActionTypes("main/GET_CATEGORY_LIST");

export const [
    GET_USER_KEWORD_LIST,
    GET_USER_KEWORD_LIST_SUCCESS,
    GET_USER_KEWORD_LIST_FAILURE
] = createRequestActionTypes("main/GET_USER_KEWORD_LIST");

export const getMainPortfolioList = createAction(GET_MAIN_PORTFOLIO_LIST);
export const getMainProjectList = createAction(GET_MAIN_PROJECT_LIST);
export const getMainFeedList = createAction(GET_MAIN_FEED_LIST);
export const getCategoryList = createAction(GET_CATEGORY_LIST);
export const getUserKeywordList = createAction(GET_USER_KEWORD_LIST);

const initialState = {
    portfolioList: [],
    newProjectList: [],
    popularProjectList: [],
    feedList: [],
    category: {},
    userKeywordList: []
};

const main = handleActions(
    {
        [GET_MAIN_PORTFOLIO_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.portfolioList = payload;
            }),
        [GET_MAIN_PROJECT_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.newProjectList = payload.newProjectList;
                draft.popularProjectList = payload.popularProjectList;
            }),
        [GET_MAIN_FEED_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.feedList = payload.list;
            }),
        [GET_CATEGORY_LIST_SUCCESS]: (state, { payload, prev = {} }) =>
            produce(state, draft => {
                if (prev.target) {
                    draft.category[prev.target] = payload;
                } else {
                    // all
                    const newCategoryObj = {};
                    [...payload].forEach(category => {
                        if (newCategoryObj[category.target]) {
                            newCategoryObj[category.target].push(category);
                        } else {
                            newCategoryObj[category.target] = [category];
                        }
                    });
                    draft.category = newCategoryObj;
                }
            }),
        [GET_USER_KEWORD_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.userKeywordList = payload;
            })
    },
    initialState
);

export default main;
