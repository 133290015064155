import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { createRequestSagaBase } from "lib/createRequestSaga";
import * as authAPI from "lib/api/auth";
import * as userAPI from "lib/api/user";

import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE,
    GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE_SUCCESS,
    GET_MY_PROFILE,
    EDIT_MY_PROFILE,
    EDIT_MY_PROFILE_SUCCESS,
    UPDATE_USER_INFO,
    // UPDATE_USER_INFO_SUCCESS,
    UPDATE_USER_INFO_FAILURE,
    EDIT_USER_INFO,
    EDIT_EMAIL
} from "stores/modules/auth";

import { SHOW_LOADING, HIDE_LOADING } from "stores/modules/loading";

import { getMyWallet } from "stores/modules/wallet";
import { getAllBrandList } from "stores/modules/brand/action";
import { getPlanInfo } from "stores/modules/subscription/action";
import { handleUnreadValue } from "stores/modules/messenger/action";
import { handleHasUnreadAlarm } from "stores/modules/alarm/action";

import { OPEN_ALERT } from "stores/modules/alert";

function* afterGetUserInfoSaga(action) {
    const isInit = action.prev?.init;

    if (isInit) {
        const data = action.payload;
        yield put(getMyWallet());
        yield put(handleUnreadValue(data.hasUnreadMessage));
        yield put(handleHasUnreadAlarm(data.hasUnreadInfo));
        if (data.user.category === "Seller") {
            yield put(getPlanInfo());
            yield put(getAllBrandList());
        }
    }
}

const getUserInfoAsTokenInLocalStorageSaga = createRequestSagaBase({
    type: GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE,
    api: authAPI.getMyInfo,
    progress: true
});

const getMyProfileSaga = createRequestSagaBase({
    type: GET_MY_PROFILE,
    api: userAPI.getMyProfile,
    progress: true
});

const editMyProfileSaga = createRequestSagaBase({
    type: EDIT_MY_PROFILE,
    api: userAPI.editMyProfile,
    progress: true,
    alertSuccessMsg: "프로필 수정이 완료되었습니다.",
    alertSuccessPath: "/profile/preview"
});

const editEmailSaga = createRequestSagaBase({
    type: EDIT_EMAIL,
    api: userAPI.changeEmail,
    progress: true,
    alertErrorMsg: "이메일 변경에 실패하였습니다."
});

function* updateUserInfoSaga(action) {
    try {
        const { obj, path } = action.payload;
        yield call(authAPI.updateMyInfo, obj);
        yield put({
            type: GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE
        });
        if (path) {
            yield put({
                type: OPEN_ALERT,
                payload: { text: "message.account.edit.success", path: "/biz/myinfo" }
            });
        }
    } catch (error) {
        yield put({
            type: OPEN_ALERT,
            payload: { text: "message.account.edit.fail" }
        });
        yield put({
            type: UPDATE_USER_INFO_FAILURE,
            payload: { error: error.response }
        });
    }
}

const editUserInfoSaga = createRequestSagaBase({
    type: EDIT_USER_INFO,
    api: authAPI.editUserInfo,
    alertFailMsg: "message.account.edit.fail",
    progress: true
});

function* loginSaga(action) {
    try {
        yield put({ type: SHOW_LOADING, payload: { type: action.type } });
        const { data } = yield call(authAPI.login, action.payload);
        if (data.user.category === action.payload.userType) {
            yield put({
                type: LOGIN_SUCCESS,
                payload: data
            });
        } else {
            yield put({
                type: OPEN_ALERT,
                payload: {
                    text: `${
                        action.payload.userType === "Seller" ? "비즈니스" : "리뷰어"
                    } 계정이 아닙니다.`
                }
            });
        }
    } catch (e) {
        yield put({
            type: LOGIN_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put({ type: HIDE_LOADING, payload: { type: action.type } });
    }
}

export function* secondAuthSaga() {
    yield takeLatest(GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE, getUserInfoAsTokenInLocalStorageSaga);
    yield takeLatest(GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE_SUCCESS, afterGetUserInfoSaga);
    yield takeLatest(GET_MY_PROFILE, getMyProfileSaga);
    yield takeLeading(EDIT_MY_PROFILE, editMyProfileSaga);
    yield takeLeading(EDIT_EMAIL, editEmailSaga);
    yield takeLatest(EDIT_MY_PROFILE_SUCCESS, getMyProfileSaga);
    yield takeLatest(EDIT_MY_PROFILE_SUCCESS, getUserInfoAsTokenInLocalStorageSaga);
    yield takeLeading(UPDATE_USER_INFO, updateUserInfoSaga);
    yield takeLeading(EDIT_USER_INFO, editUserInfoSaga);
    yield takeLeading(LOGIN, loginSaga);
}
