import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { IconFont, Profile, Bubble, Button } from "reviewshare-common-component";
import { numberWithCommas } from "lib/common";
import useTranslate from "lib/customHooks/useTranslate";
import { useUserType, useAuthAction } from "lib/customHooks/useAuth";
import { useTotalPoints } from "lib/customHooks/useWallet";

import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";

import style from "./GlobalHeaderMenu.module.scss";

const GlobalHeaderMenu = ({ schema }) => {
    const translate = useTranslate();
    const userType = useUserType();
    const userInfo = useSelector(({ auth }) => auth.userInfo.user || {});
    const isSeller = useMemo(() => userType === "Seller", [userType]);
    const totalPoints = useTotalPoints();
    const { logout } = useAuthAction();

    const existAvaliablePromotion = useSelector(
        ({ subscription }) => subscription.planInfo?.existAvaliablePromotion
    );

    if (["Normal", "Seller"].indexOf(userType) === -1) return null;
    return (
        <div className={style.wrapper}>
            <CommonLink
                role="tooltip"
                className={style.userBadge}
                to={isSeller ? "/biz/main" : "/mypage"}
            >
                <FormattedMessage
                    id={isSeller ? "target.biz" : "target.reviewer"}
                    values={{
                        name: userInfo.nickname || userInfo.name
                    }}
                />
                {isSeller ? (
                    <>
                        <IconFont
                            icon="icon_arrow_up"
                            className={`${style.arrowIcon} ${style.arrowUp}`}
                        >
                            <FormattedMessage id="action.view.fold" />
                        </IconFont>
                        <IconFont
                            icon="icon_arrow_down"
                            className={`${style.arrowIcon} ${style.arrowDown}`}
                        >
                            <FormattedMessage id="action.view.more" />
                        </IconFont>
                    </>
                ) : (
                    <Profile
                        className={`${style.profile} size-xs`}
                        grade={userInfo.score?.grade}
                        blackUser={userInfo.blackUser}
                        img={userInfo.profile?.secure_url}
                        alt={translate("item.portfolio.profile.target", {
                            name: userInfo.nickname
                        })}
                    />
                )}
                {existAvaliablePromotion && (
                    <Bubble className={`size-s ${style.promotionBubble}`} duration={6000}>
                        <FormattedMessage id="message.subscription.promotion.induce" />
                    </Bubble>
                )}
            </CommonLink>

            <div className={style.menuBox}>
                <div className={style.userArea}>
                    {!isSeller && (
                        <CommonLink to="/mypage" className={style.profile}>
                            <Profile
                                className="size-s"
                                grade={userInfo.score?.grade}
                                blackUser={userInfo.blackUser}
                                img={userInfo.profile?.secure_url}
                                alt={translate("item.portfolio.profile.target", {
                                    name: userInfo.nickname
                                })}
                            />
                        </CommonLink>
                    )}
                    <div className={style.userText}>
                        <p className={style.nameTitle}>
                            <span className={style.userName}>
                                {userInfo.nickname || userInfo.name}
                            </span>{" "}
                            {isSeller ? "담당자님" : "님"}
                        </p>
                        <ul className={style.userActions}>
                            {!isSeller && (
                                <li>
                                    <CommonLink
                                        className="rs-button theme4 size-s"
                                        to="/profile/preview"
                                    >
                                        <FormattedMessage
                                            id={
                                                userInfo.profile?.tags?.[0] === "DEFAULT_PROFILE" ||
                                                !userInfo.profile?.secure_url
                                                    ? "action.portfolio.create"
                                                    : "action.portfolio.edit"
                                            }
                                        />
                                    </CommonLink>
                                </li>
                            )}
                            <li>
                                <CommonLink
                                    className="rs-button theme4 size-s"
                                    to={isSeller ? "biz/myinfo" : "/myinfo"}
                                >
                                    <FormattedMessage
                                        id={isSeller ? "menu.biz.info" : "menu.re.info"}
                                    />
                                </CommonLink>
                            </li>
                            <li>
                                <Button className="theme4 size-s" onClick={logout}>
                                    <FormattedMessage id="action.signout" />
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={style.pointsBox}>
                    {isSeller && (
                        <CommonLink
                            to="/biz/chargepoint"
                            className={`rs-button theme5 size-s round ${style.chargeLink}`}
                        >
                            <FormattedMessage id="action.charge" />
                        </CommonLink>
                    )}
                    <CommonLink
                        to={isSeller ? "/biz/payment" : "/mypoint"}
                        className={style.pointsLink}
                    >
                        <span className={style.pointsTitle}>
                            <FormattedMessage id="item.points.total" />
                        </span>
                        <span className={style.pointsAmount}>
                            {numberWithCommas(totalPoints)}P<IconFont icon="icon_arrow_right" />
                        </span>
                    </CommonLink>
                </div>
                <ul className={style.menuList}>
                    {schema.subMenu.map((subMenu, index) => {
                        let { link } = subMenu;

                        if (subMenu.value === "portfolio") {
                            link = `/portfolio/${userInfo.portfolioUrl}`;
                        } else if (subMenu.value === "subscription") {
                            if (existAvaliablePromotion) {
                                link = "/biz/plan/select";
                            }
                        }

                        return (
                            <li className={`${style.menuItem}`} key={`sub_${index}`} role="tooltip">
                                <CommonLink
                                    to={link}
                                    external={subMenu.value === "portfolio"}
                                    role="tooltip"
                                >
                                    <FormattedMessage id={subMenu.label} />
                                    {subMenu.new && (
                                        <IconFont
                                            icon="icon_new_mark_circle"
                                            className={style.newMark}
                                        >
                                            new
                                        </IconFont>
                                    )}
                                </CommonLink>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default GlobalHeaderMenu;
