import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { createRequestActionTypes } from "lib/createRequestSaga";

export const RESET_SELECTED_BRAND = "brand/RESET_SELECTED_BRAND";
export const RESET_BRAND_LIST = "brand/RESET_BRAND_LIST";
export const SELECT_BRAND = "brand/SELECT_BRAND";
export const HANDLE_BRAND_UNREAD_STATUS = "brand/HANDLE_BRAND_UNREAD_STATUS";

export const [CREATE_BRAND, CREATE_BRAND_SUCCESS, CREATE_BRAND_FAILURE] = createRequestActionTypes(
    "brand/CREATE_BRAND"
);

export const [
    GET_BRAND_LIST,
    GET_BRAND_LIST_SUCCESS,
    GET_BRAND_LIST_FAILURE
] = createRequestActionTypes("brand/GET_BRAND_LIST");

export const [
    GET_ALL_BRAND_LIST,
    GET_ALL_BRAND_LIST_SUCCESS,
    GET_ALL_BRAND_LIST_FAILURE
] = createRequestActionTypes("brand/GET_ALL_BRAND_LIST");

export const SET_BRAND_FOR_PROJECT_LIST = "brand/SET_BRAND_FOR_PROJECT_LIST";

const HANDLE_BRAND_EDIT_STATUS = "brand/HANDLE_BRAND_EDIT_STATUS";

export const resetSelectedBrand = createAction(RESET_SELECTED_BRAND);
export const resetBrandList = createAction(RESET_BRAND_LIST);
export const selectBrand = createAction(SELECT_BRAND, obj => obj);
export const createBrand = createAction(CREATE_BRAND, obj => obj);
export const getAllBrandList = createAction(GET_ALL_BRAND_LIST, () => ({ allList: true }));
export const getBrandList = createAction(GET_BRAND_LIST, options => options);
export const setBrandForProjectList = createAction(SET_BRAND_FOR_PROJECT_LIST, brandId => brandId);
export const handleBrandUnreadStatus = createAction(HANDLE_BRAND_UNREAD_STATUS, obj => obj);
export const handleBrandEditStatus = createAction(
    HANDLE_BRAND_EDIT_STATUS,
    ({ brandId, status }) => ({ brandId, status })
);

const initialState = {
    list: [],
    totalCounts: 0,

    allBrands: {
        totalCounts: 0,
        list: []
    },
    brandIdForProjectList: null,
    selectedBrand: {}
};

const brand = handleActions(
    {
        [RESET_SELECTED_BRAND]: state =>
            produce(state, draft => {
                draft.selectedBrand = {};
            }),
        [RESET_BRAND_LIST]: state =>
            produce(state, draft => {
                draft.list = initialState.list;
                draft.totalCounts = initialState.totalCounts;
            }),
        [SELECT_BRAND]: (state, { payload }) =>
            produce(state, draft => {
                draft.selectedBrand = payload;
            }),
        [SET_BRAND_FOR_PROJECT_LIST]: (state, { payload: brandId }) => ({
            ...state,
            brandIdForProjectList: brandId
        }),
        [CREATE_BRAND_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.counts = state.counts + 1;
                draft.list = [payload].concat(state.list);
            }),
        [GET_BRAND_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.totalCounts = payload.totalCounts;
                draft.list = payload.brands
                    ? payload.brands.map(brandItem => {
                          brandItem.isEdit = false;
                          return brandItem;
                      })
                    : [];
            }),
        [GET_ALL_BRAND_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.allBrands.totalCounts = payload.totalCounts;
                draft.allBrands.list = payload.brands;
            }),
        [HANDLE_BRAND_EDIT_STATUS]: (state, { payload }) =>
            produce(state, draft => {
                draft.list.forEach(brandItem => {
                    if (brandItem._id === payload.brandId) {
                        brandItem.isEdit = payload.status;
                    }
                });
            }),
        [HANDLE_BRAND_UNREAD_STATUS]: (state, { payload }) =>
            produce(state, draft => {
                if (state.allBrands.list.length > 0) {
                    draft.allBrands.list = state.allBrands.list.map(item => {
                        if (item._id === payload.brandId) {
                            return { ...item, hasUnreadMessage: payload.hasUnreadMessage };
                        }
                        return item;
                    });
                }
            })
    },
    initialState
);

export default brand;
