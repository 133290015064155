import client from "./client";

export const createBrand = obj => client.post("/enterprise", obj);
export const getBrandList = ({
    keyword = "",
    skip = 0,
    limit = 10,
    type,
    customBanner,
    allList
} = {}) =>
    client.get("/v2/brands", {
        params: {
            keyword: keyword || undefined,
            skip,
            limit,
            type: type === "all" || !type ? undefined : type,
            customBanner,
            allList,
            newMsgInfo: allList,
            sort: allList ? "name" : undefined
        }
    });
export const updateBrand = brandObj => client.put("/enterprise", brandObj);
export const removeBrand = brandObj => client.delete("/enterprise", { params: brandObj });
export const getAllBrandCounts = () => client.get("/v2/brands/counts");
export const addBanner = ({ file, brandId }) =>
    client.post(`/v2/brands/${brandId}/custom-banner`, file);
export const getBrandHistory = ({ reviewerId, brandId, category, skip }) =>
    client.get(`/v3/brands/participation-reviewers/${reviewerId}`, {
        params: {
            brandId,
            source: category,
            skip,
            limit: 10
        }
    });
