import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AlertModal } from "reviewshare-common-component";
import { closeAlert } from "stores/modules/alert";
import FormattedMessage from "components/common/FormattedMessage";

const AlertContainer = ({ history }) => {
    const dispatch = useDispatch();
    const { alert } = useSelector(({ alert }) => alert);

    const onClose = () => {
        if (alert.path) {
            if (history.location.pathname === alert.path || alert.path === "reload") {
                history.go(0);
            } else {
                history.push(alert.path);
            }
        }
        return dispatch(closeAlert());
    };

    return (
        alert.status && (
            <AlertModal id={alert.name || undefined} handleModal={onClose}>
                <FormattedMessage id={alert.text} defaultMessage={alert.text} />
            </AlertModal>
        )
    );
};

export default withRouter(AlertContainer);
