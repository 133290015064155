import { createAction, handleActions } from "redux-actions";
import { createRequestActionTypes } from "lib/createRequestSaga";
import produce from "immer";

export const RESET_TALENT_DETAIL = "talent/RESET_TALENT_DETAIL";
export const RESET_CREATED_INFO = "talent/RESET_CREATED_INFO";
export const RESET_CREATING_INFO = "talent/RESET_CREATING_INFO";
export const RESET_APPLYING_INFO = "talent/RESET_APPLYING_INFO";
export const SET_APPLYING_INFO = "talent/SET_APPLYING_INFO";

export const [
    CREATE_TALENT,
    CREATE_TALENT_SUCCESS,
    CREATE_TALENT_FAILURE
] = createRequestActionTypes("talent/CREATE_TALENT");

export const [
    GET_ALL_TALENT_LIST,
    GET_ALL_TALENT_LIST_SUCCESS,
    GET_ALL_TALENT_LIST_FAILURE
] = createRequestActionTypes("talent/GET_ALL_TALENT_LIST");

export const [
    GET_RECOMMEND_TALENT_LIST,
    GET_RECOMMEND_TALENT_LIST_SUCCESS,
    GET_RECOMMEND_TALENT_LIST_FAILURE
] = createRequestActionTypes("talent/GET_RECOMMEND_TALENT_LIST");

export const [
    GET_POPULAR_TALENT_LIST,
    GET_POPULAR_TALENT_LIST_SUCCESS,
    GET_POPULAR_TALENT_LIST_FAILURE
] = createRequestActionTypes("talent/GET_POPULAR_TALENT_LIST");

export const [
    GET_MY_TALENT,
    GET_MY_TALENT_SUCCESS,
    GET_MY_TALENT_FAILURE
] = createRequestActionTypes("talent/GET_MY_TALENT");

export const [
    GET_MY_TALENT_LIST,
    GET_MY_TALENT_LIST_SUCCESS,
    GET_MY_TALENT_LIST_FAILURE
] = createRequestActionTypes("talent/GET_MY_TALENT_LIST");

export const [
    GET_TALENT_DETAIL,
    GET_TALENT_DETAIL_SUCCESS,
    GET_TALENT_DETAIL_FAILURE
] = createRequestActionTypes("talent/GET_TALENT_DETAIL");

export const [
    UPDATE_MY_TALENT,
    UPDATE_MY_TALENT_SUCCESS,
    UPDATE_MY_TALENT_FAILURE
] = createRequestActionTypes("talent/UPDATE_MY_TALENT");

export const [
    REMOVE_MY_TALENT,
    REMOVE_MY_TALENT_SUCCESS,
    REMOVE_MY_TALENT_FAILURE
] = createRequestActionTypes("talent/REMOVE_MY_TALENT");

export const resetCreatedInfo = createAction(RESET_CREATED_INFO);
export const resetCreatingInfo = createAction(RESET_CREATING_INFO);
export const resetTalent = createAction(RESET_TALENT_DETAIL);
export const resetApplyingInfo = createAction(RESET_APPLYING_INFO);
export const setApplyingInfo = createAction(SET_APPLYING_INFO);

export const getAllTalentList = createAction(GET_ALL_TALENT_LIST);
export const getRecommendTalentList = createAction(GET_RECOMMEND_TALENT_LIST);
export const getPopularTalentList = createAction(GET_POPULAR_TALENT_LIST);
export const getMyTalent = createAction(GET_MY_TALENT);
export const getMyTalentList = createAction(GET_MY_TALENT_LIST);
export const getTalentDetail = createAction(GET_TALENT_DETAIL);

export const createTalent = createAction(CREATE_TALENT, obj => obj);
export const updateMyTalent = createAction(UPDATE_MY_TALENT, obj => obj);
export const removeMyTalent = createAction(REMOVE_MY_TALENT, obj => obj);

const initialState = {
    creatingInfo: {},
    createdInfo: {},
    applyingInfo: {},
    myTalent: {
        counts: 0,
        list: []
    },
    talentDetail: {},

    all: {
        counts: 0,
        list: []
    },
    recommend: {
        counts: 0,
        list: []
    },
    popular: {
        counts: 0,
        list: []
    }
};

const talent = handleActions(
    {
        [RESET_TALENT_DETAIL]: state =>
            produce(state, draft => {
                draft.talentDetail = initialState.talentDetail;
            }),
        [RESET_CREATED_INFO]: state =>
            produce(state, draft => {
                draft.createdInfo = initialState.createdInfo;
            }),
        [RESET_CREATING_INFO]: state =>
            produce(state, draft => {
                draft.creatingInfo = initialState.creatingInfo;
            }),
        [RESET_APPLYING_INFO]: state =>
            produce(state, draft => {
                draft.applyingInfo = initialState.applyingInfo;
            }),
        [SET_APPLYING_INFO]: state =>
            produce(state, draft => {
                draft.applyingInfo = state.talentDetail;
            }),
        [CREATE_TALENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.createdInfo = payload;
            }),
        [GET_MY_TALENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.creatingInfo = payload;
            }),
        [GET_MY_TALENT_LIST_SUCCESS]: (state, { payload, prev = {} }) =>
            produce(state, draft => {
                draft.myTalent.counts = payload.totalCounts;
                draft.myTalent.list = prev.skip
                    ? [...state.myTalent.list, ...payload.list]
                    : payload.list;
            }),
        [GET_ALL_TALENT_LIST]: (state, { payload }) =>
            produce(state, draft => {
                if (!payload.skip) {
                    draft.all = initialState.all;
                }
            }),
        [GET_ALL_TALENT_LIST_SUCCESS]: (state, { payload, prev = {} }) =>
            produce(state, draft => {
                draft.all = {
                    counts: payload.totalCounts,
                    list: prev.skip > 0 ? [...state.all.list, ...payload.list] : payload.list
                };
            }),
        [GET_RECOMMEND_TALENT_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.recommend = {
                    counts: payload.totalCounts,
                    list: payload.list
                };
            }),
        [GET_POPULAR_TALENT_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.popular = {
                    counts: payload.totalCounts,
                    list: payload.list
                };
            }),
        [GET_TALENT_DETAIL_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.talentDetail = payload;
            }),
        [UPDATE_MY_TALENT]: (state, { payload }) =>
            produce(state, draft => {
                draft.myTalent.list = state.myTalent.list.map(item => {
                    if (item.talentId === payload.talentId) {
                        return {
                            ...item,
                            ...(payload.process === "status" && { status: payload.status }),
                            ...(payload.process === "major" && { isMajor: payload.isMajor })
                        };
                    }
                    return {
                        ...item,
                        ...(payload.process === "major" &&
                            payload.isMajor && { isMajor: !payload.isMajor })
                    };
                });
            }),
        [UPDATE_MY_TALENT_FAILURE]: (state, { prev }) =>
            produce(state, draft => {
                draft.myTalent.list = state.myTalent.list.map(item => {
                    if (item.talentId === prev.talentId) {
                        return {
                            ...item,
                            ...(prev.process === "status" && {
                                status: prev.status === "pause" ? "running" : "pause"
                            }),
                            ...(prev.process === "major" && { isMajor: !prev.isMajor })
                        };
                    }
                    return item;
                });
            }),
        [REMOVE_MY_TALENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.myTalent.counts = state.myTalent.counts - 1;
                draft.myTalent.list = state.myTalent.list.filter(item => {
                    if (item.talentId === payload.talentId) return false;
                    return true;
                });
            })
    },
    initialState
);

export default talent;
