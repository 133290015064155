import axios from "axios";

const client = axios.create();

client.defaults.baseURL = process.env.REACT_APP_API_URL;
export const getSpreadSheetValues = spreadId =>
    client.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadId}/values/Sheet1?key=AIzaSyB2TIpKltwcXYNO1FR_kFAD_dUWoC9rlYM`
    );

export const getReviewerIntroJson = () =>
    client.get("https://s3.ap-northeast-2.amazonaws.com/json.reviewshare.io/reviewer_intro.json");
