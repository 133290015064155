import { createAction, handleActions } from "redux-actions";
import { takeLeading } from "redux-saga/effects";
import createRequestSaga, { createRequestActionTypes } from "lib/createRequestSaga";
import * as walletAPI from "lib/api/wallet";

export const [
    GET_MY_WALLET,
    GET_MY_WALLET_SUCCESS,
    GET_MY_WALLET_FAILURE
] = createRequestActionTypes("wallet/GET_MY_WALLET");

export const getMyWallet = createAction(GET_MY_WALLET, userId => userId);

const getMyWalletSaga = createRequestSaga(GET_MY_WALLET, walletAPI.getPoints);

export function* walletSaga() {
    yield takeLeading(GET_MY_WALLET, getMyWalletSaga);
}

const initialState = {
    totalPoints: null,
    welcomePoints: {},
    readTotalPointsError: null
};

const wallet = handleActions(
    {
        [GET_MY_WALLET_SUCCESS]: (state, { payload }) => ({
            ...state,
            totalPoints: payload.wallet.total,
            welcomePoints: payload.wallet.welcomePoints || {}
        }),
        [GET_MY_WALLET_FAILURE]: (state, { payload: error }) => ({
            ...state,
            readTotalPointsError: error
        })
    },
    initialState
);

export default wallet;
