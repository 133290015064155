import React from "react";
import { IconFont } from "reviewshare-common-component";

import "./EmptyContent.scss";

const EmptyContent = ({ children, icon }) => (
    <div className="messenger-side-empty-content">
        <IconFont icon={icon || "icon_caution_fill"}>결과 없음</IconFont>
        <p>{children}</p>
    </div>
);
export default EmptyContent;
