import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import moment from "moment";
import { IconFont, Dropdown, DropdownItem } from "reviewshare-common-component";
import useDirectApi from "lib/customHooks/useDirectApi";
import { getPrevSuggestionList as getPrevSuggestionListAPI } from "lib/api/suggest";
import { convertOfferDetail } from "utils/suggestion";
import useTranslate from "lib/customHooks/useTranslate";

import commonStyle from "components/suggest/create/Common.module.scss";
import style from "./SelectBrand.module.scss";

const cx = classnames.bind(style, commonStyle);
const SelectBrand = ({ setFeeIsFree, prevSuggestion, setPrevSuggestion, setInfo }) => {
    const translate = useTranslate();
    const brandList = useSelector(({ brand }) => brand.allBrands?.list || []);
    const planInfo = useSelector(({ subscription }) => subscription.planInfo);
    const planIsRunning = useSelector(({ subscription }) => subscription.planIsRunning);
    const [selectedBrandInfo, setSelectedBrandInfo] = useState({});
    const { data: prevSuggestionList, fetch: getPrevSuggestionList } = useDirectApi({
        api: getPrevSuggestionListAPI
    });

    const onSelectSuggestion = obj => {
        setPrevSuggestion(obj);
        setInfo(prev => ({
            ...prev,
            itemName: obj.itemName,
            siteUrl: obj.siteUrl,
            points: obj.points,
            guideLine: obj.guideLine
        }));
    };

    useEffect(() => {
        if (planIsRunning && planInfo.features.unlimitedSuggestion) {
            setFeeIsFree(true);
        } else {
            setFeeIsFree(false);
        }
        // eslint-disable-next-line
    }, [planIsRunning, selectedBrandInfo]);

    useEffect(() => {
        if (selectedBrandInfo._id) {
            getPrevSuggestionList({ brandId: selectedBrandInfo._id });
            setInfo(prev => ({ ...prev, enterprise: selectedBrandInfo._id }));
        } else {
            setInfo(prev => ({ ...prev, enterprise: null }));
        }
        // eslint-disable-next-line
    }, [selectedBrandInfo._id]);

    useEffect(() => {
        if (brandList.length > 0 && !selectedBrandInfo._id) {
            setSelectedBrandInfo(brandList[0]);
        }
        // eslint-disable-next-line
    }, [brandList]);

    return (
        <div className={commonStyle.item}>
            <h3 className={cx(commonStyle.itemTitle, style.itemTitle)}>
                어떤 브랜드로 제안하시겠어요?
            </h3>
            <p className={style.cautionText}>
                <IconFont icon="icon_info_fill" /> 리뷰어에게 선택한 브랜드가 표시됩니다.
            </p>
            <div className={commonStyle.itemContent}>
                <Dropdown
                    className={`${style.dropdown} ${style.brand}`}
                    defaultValue={translate("action.select")}
                >
                    {brandList.map((item, index) => (
                        <DropdownItem
                            key={index}
                            isActive={item._id === selectedBrandInfo._id}
                            onClick={() => setSelectedBrandInfo(item)}
                        >
                            <p className="brand-name">{item.name}</p>
                        </DropdownItem>
                    ))}
                </Dropdown>
                {prevSuggestionList?.suggestion?.length > 0 && (
                    <Dropdown
                        className={`${style.dropdown} ${style.prev}`}
                        value={
                            prevSuggestion?._id
                                ? `${convertOfferDetail({
                                      itemName: prevSuggestion.itemName,
                                      points: prevSuggestion.points
                                  })} 제공`
                                : "이전 제안 불러오기"
                        }
                    >
                        {prevSuggestionList.suggestion.map((item, index) => (
                            <DropdownItem
                                key={index}
                                isActive={item._id === prevSuggestion?._id}
                                onClick={() => onSelectSuggestion(item)}
                            >
                                <p className={style.itemName}>
                                    {convertOfferDetail({
                                        itemName: item.itemName,
                                        points: item.points
                                    })}{" "}
                                    제공
                                </p>
                                <p className={style.createdAt}>
                                    {moment(item.createdAt).format("YYYY.MM.DD")}
                                </p>
                            </DropdownItem>
                        ))}
                    </Dropdown>
                )}
            </div>
        </div>
    );
};
export default SelectBrand;
