import React, { useEffect, useState } from "react";
import cx from "classnames";
import { IconFont } from "reviewshare-common-component";
import useTranslate from "lib/customHooks/useTranslate";
import FormattedMessage from "components/common/FormattedMessage";

import "./AttachedFileList.scss";

const FILE_UNIT_LIMIT = 4;
const FILE_UNIT_LIMIT_MOBILE = 1;
const AttachedFileList = ({ fileItemList, removeFile }) => {
    const translate = useTranslate();

    const [fileItemListIndex, setFileItemListIndex] = useState(0);
    const [scrollPercent, setScrollPercent] = useState(0);

    const onSetScrollPercent = () => {
        if (fileItemList.length > FILE_UNIT_LIMIT * (fileItemListIndex + 1)) {
            setScrollPercent(100 * fileItemListIndex);
        } else {
            const percent =
                (fileItemList.length - FILE_UNIT_LIMIT * fileItemListIndex) *
                    (100 / FILE_UNIT_LIMIT) +
                100 * (fileItemListIndex - 1);
            setScrollPercent(percent);
        }
    };
    useEffect(() => {
        const index =
            fileItemList.length === FILE_UNIT_LIMIT
                ? 0
                : Math.floor(fileItemList.length / FILE_UNIT_LIMIT);

        setFileItemListIndex(index);
        if (!(fileItemList.length / FILE_UNIT_LIMIT > fileItemListIndex + 1)) {
            onSetScrollPercent();
        }
        // eslint-disable-next-line
    }, [fileItemList]);

    useEffect(() => {
        onSetScrollPercent();
        // eslint-disable-next-line
    }, [fileItemListIndex]);

    return fileItemList.length > 0 ? (
        <div className="messenger-main-attached-file-list">
            {fileItemListIndex > 0 && (
                <button
                    type="button"
                    className="prev-btn web"
                    onClick={() => setFileItemListIndex(fileItemListIndex - 1)}
                >
                    <IconFont icon="icon_arrow_left_circle_fill">
                        <FormattedMessage id="action.go.prev" />
                    </IconFont>
                </button>
            )}
            {fileItemList.length > FILE_UNIT_LIMIT_MOBILE && (
                <button type="button" className="prev-btn mobile" disabled />
            )}
            <div
                className="file-item-list-wrapper"
                style={{
                    transform: `translateX(-${scrollPercent}%)`
                }}
            >
                <ul
                    className={cx({
                        "has-index": fileItemListIndex > 0,
                        "end-point":
                            fileItemList.length <= FILE_UNIT_LIMIT * (fileItemListIndex + 1)
                    })}
                >
                    {fileItemList.map((item, index) => (
                        <li key={index}>
                            {item.preview ? (
                                <img
                                    src={item.preview}
                                    alt={translate("action.preview")}
                                    className="file-image"
                                />
                            ) : (
                                <IconFont icon="icon_folder" className="file-icon">
                                    <FormattedMessage id="item.file" />
                                </IconFont>
                            )}
                            <p className="file-name">{item.name}</p>
                            <p className="file-format">.{item.format}</p>
                            <button
                                type="button"
                                className="remove-btn"
                                onClick={() => removeFile(index)}
                            >
                                <IconFont icon="icon_close">
                                    <FormattedMessage id="action.close" />
                                </IconFont>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {fileItemList.length / FILE_UNIT_LIMIT > fileItemListIndex + 1 && (
                <button
                    type="button"
                    className="next-btn web"
                    onClick={() => setFileItemListIndex(fileItemListIndex + 1)}
                >
                    <IconFont icon="icon_arrow_right_circle_fill">
                        <FormattedMessage id="action.go.prev" />
                    </IconFont>
                </button>
            )}
            {fileItemList.length > FILE_UNIT_LIMIT_MOBILE && (
                <button type="button" className="next-btn mobile" disabled />
            )}
        </div>
    ) : null;
};
export default AttachedFileList;
