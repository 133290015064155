export const convertFileType = type => {
    switch (type) {
        case "photo":
        case "image":
            return "item.file.type.image";
        case "video":
            return "item.file.type.video";
        case "file":
            return "item.file.type.file";

        default:
            return type;
    }
};

export const changeFileType = fileName => {
    const lastDotPosition = fileName.lastIndexOf(".");

    if (lastDotPosition === -1) {
        return "";
    }

    return fileName.substring(lastDotPosition + 1);
};

export const returnFileType = format => {
    switch (format.toLowerCase()) {
        case "avi":
        case "mp4":
        case "mov":
        case "quicktime":
            return "video";

        case "jpg":
        case "jpeg":
        case "png":
        case "svg":
        case "gif":
            return "image";

        default:
            return "file";
    }
};

export const returnAcceptText = formatList => {
    const acceptList = [];

    if (!formatList || !formatList.length || formatList.indexOf("file") > -1) {
        return "*";
    }

    if (formatList.indexOf("image") > -1) {
        acceptList.push("image/*");
    }

    if (formatList.indexOf("video") > -1) {
        acceptList.push("video/*");
    }

    return acceptList.join(", ");
};
