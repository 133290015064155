import React from "react";
import { IconFont } from "reviewshare-common-component";

import "./TipBanner.scss";

const TipBanner = ({ children }) => (
    <div className="messenger-side-tip-banner">
        <IconFont icon="icon_check" />
        {children}
    </div>
);
export default TipBanner;
