import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { createRequestSagaWithAlert, createRequestSagaBase } from "lib/createRequestSaga";
import {
    getProjectEvaluateItems as getProjectEvaluateItemsAPI,
    getReviewerListForSubmission as getReviewerListAPI,
    payProjectPoints as issuePointsAPI
} from "lib/api/report";

import {
    setOpinions as setOpinionsAPI,
    changeSubmitEndDay as changeSubmitEndDayAPI,
    updateDeliveryInfo as updateDeliveryInfoAPI,
    getContentFileList as getContentFileListAPI
} from "lib/api/campaignRequest";

import { getOriginFileZip as getOriginFileZipAPI } from "lib/api/file";

import { GET_RECRUITMENT_LIST_SUCCESS } from "stores/modules/report/recruit/recruit";
import {
    GET_REVIEWER_LIST,
    GET_CONTENT_FILE_LIST,
    ISSUE_POINTS,
    CHANGE_SUBMIT_END_DAY_OF_REQUEST,
    SET_OPINIONS,
    SET_OPINIONS_SUCCESS,
    SET_OPINIONS_FAILURE,
    UPDATE_DELIVERY_INFO,
    GET_PROJECT_EVALUATE_ITEMS,
    DOWNLOAD_ORIGIN_FILE,
    DOWNLOAD_ORIGIN_FILE_SUCCESS,
    DOWNLOAD_ORIGIN_FILE_FAILURE
} from "stores/modules/report/submit/action";
import { OPEN_ALERT } from "stores/modules/alert";
import { SHOW_LOADING, HIDE_LOADING } from "stores/modules/loading";
import { GET_REVIEWER_LIST_SUCCESS } from "./action";

const getReviewerListSaga = createRequestSagaBase({
    type: GET_REVIEWER_LIST,
    api: getReviewerListAPI,
    alertErrorMsg: "리뷰어 목록을 가져오는데 실패하였습니다.",
    progress: true
});

const getReviewerListSuccessSaga = function*(action) {
    if (action.prev.status === "request") {
        yield put({ type: GET_RECRUITMENT_LIST_SUCCESS, payload: action.payload });
    }
};

const getContentFileListSaga = createRequestSagaBase({
    type: GET_CONTENT_FILE_LIST,
    api: getContentFileListAPI
});

const issuePointsSaga = createRequestSagaWithAlert(
    ISSUE_POINTS,
    issuePointsAPI,
    "message.points.pay.fail"
);

const changeSubmitEndDayOfRequestSaga = createRequestSagaWithAlert(
    CHANGE_SUBMIT_END_DAY_OF_REQUEST,
    changeSubmitEndDayAPI,
    "message.project.change.submit.endAt"
);

const setOpinionsSaga = function*(action) {
    try {
        yield put({ type: SHOW_LOADING, payload: { type: action.type } });
        const { data } = yield call(setOpinionsAPI, action.payload);
        yield put({ type: SET_OPINIONS_SUCCESS, payload: data });
        // yield put({
        //     type: OPEN_ALERT,
        //     payload: {
        //         text: "평가를 완료하였습니다."
        //     }
        // });
    } catch (e) {
        yield put({
            type: OPEN_ALERT,
            payload: {
                text: "평가를 반영하는데 실패하였습니다."
            }
        });
        yield put({
            type: SET_OPINIONS_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put({ type: HIDE_LOADING, payload: { type: action.type } });
    }
};

const updateDeliveryInfoSaga = createRequestSagaWithAlert(
    UPDATE_DELIVERY_INFO,
    updateDeliveryInfoAPI,
    "송장번호 업데이트에 실패하였습니다.",
    true
);

const getProjectEvaluateItemSaga = createRequestSagaWithAlert(
    GET_PROJECT_EVALUATE_ITEMS,
    getProjectEvaluateItemsAPI,
    "설문 정보를 가져오는 데 실패하였습니다.",
    true
);

const downloadOriginFileSaga = function*(action) {
    try {
        yield put({ type: SHOW_LOADING, payload: { isBlack: true, type: action.type } });
        const { data } = yield call(getOriginFileZipAPI, action.payload);
        yield put({ type: DOWNLOAD_ORIGIN_FILE_SUCCESS, payload: data });
    } catch (e) {
        if (e.response && e.response.data === "No original files to download.") {
            yield put({
                type: OPEN_ALERT,
                payload: {
                    text: "다운로드할 원본 파일이 없습니다."
                }
            });
        } else {
            yield put({
                type: OPEN_ALERT,
                payload: {
                    text: "message.file.download.fail"
                }
            });
            yield put({
                type: DOWNLOAD_ORIGIN_FILE_FAILURE,
                payload: { error: e.response }
            });
        }
    } finally {
        yield put({ type: HIDE_LOADING, payload: { type: action.type } });
    }
};
export function* reportSubmitSaga() {
    yield takeLatest(GET_REVIEWER_LIST, getReviewerListSaga);
    yield takeLatest(GET_REVIEWER_LIST_SUCCESS, getReviewerListSuccessSaga);
    yield takeLatest(GET_CONTENT_FILE_LIST, getContentFileListSaga);
    yield takeLeading(ISSUE_POINTS, issuePointsSaga);
    yield takeLeading(CHANGE_SUBMIT_END_DAY_OF_REQUEST, changeSubmitEndDayOfRequestSaga);
    yield takeLeading(SET_OPINIONS, setOpinionsSaga);
    yield takeLeading(UPDATE_DELIVERY_INFO, updateDeliveryInfoSaga);
    yield takeLatest(GET_PROJECT_EVALUATE_ITEMS, getProjectEvaluateItemSaga);
    yield takeLeading(DOWNLOAD_ORIGIN_FILE, downloadOriginFileSaga);
}
