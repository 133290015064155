import React from "react";
import { Button, IconFont } from "reviewshare-common-component";

import "./WriteMessageBtn.scss";

const WriteMessageBtn = ({ openWritingMessage }) => (
    <div className="messenger-side-write-message-btn">
        <Button className="theme7 size-m m-size-xl" onClick={openWritingMessage}>
            <span className="web">새 쪽지 쓰기</span>
            <span className="mobile">
                <IconFont icon="icon_plus">새 쪽지 쓰기</IconFont>
            </span>
        </Button>
    </div>
);

export default WriteMessageBtn;
