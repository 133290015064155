import React, { useState } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { AlertModal } from "reviewshare-common-component";
import { getStorage } from "lib/storage";
import FormattedMessage from "components/common/FormattedMessage";
import PrivatePageTemplate from "components/base/templates/PrivatePageTemplate";

const PrivateRoute = ({ ...rest }) => {
    const location = useLocation();
    const history = useHistory();
    const { pathname } = location;
    const convertPathnameIntoArray = pathname.slice(1).split("/");
    const { userType = "Normal", hasHeader, bottomTabBar } = rest;
    const [alertModalStatus, setModal] = useState(true);

    if (["Normal", "Seller", "All"].indexOf(userType) === -1) {
        throw new Error("Invalid userType");
    }

    const isLogin = (() => {
        const reviewerToken = getStorage("reviewshare-re-at");
        const bizToken = getStorage("reviewshare-at");

        if (userType === "Normal") {
            return reviewerToken ? "pass" : bizToken ? "isNeeedReivewerLogin" : false;
        }

        if (userType === "Seller") {
            return bizToken ? "pass" : reviewerToken ? "isNeeedBizLogin" : false;
        }

        if (userType === "All") {
            return reviewerToken || bizToken ? "pass" : false;
        }

        return false;
    })();

    const goToMain = () => {
        setModal(false);
        history.replace("/");
    };

    return (
        <>
            {isLogin === "pass" && (
                <PrivatePageTemplate hasHeader={hasHeader} bottomTabBar={bottomTabBar}>
                    <Route {...rest} />
                </PrivatePageTemplate>
            )}
            {(isLogin === "isNeeedReivewerLogin" || isLogin === "isNeeedBizLogin") && (
                <>
                    {alertModalStatus && (
                        <AlertModal handleModal={goToMain}>
                            <p>
                                <FormattedMessage
                                    id={
                                        isLogin === "isNeeedReivewerLogin"
                                            ? "message.signin.validate.reviewer"
                                            : "message.signin.validate.biz"
                                    }
                                />
                            </p>
                        </AlertModal>
                    )}
                </>
            )}
            {!isLogin && (
                <Redirect
                    to={{
                        pathname: `/login/${userType}${
                            pathname ? `?returnTo=${convertPathnameIntoArray.join("_")}` : ""
                        }`
                    }}
                />
            )}
        </>
    );
};

export default PrivateRoute;
