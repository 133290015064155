import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconFont, Notification } from "reviewshare-common-component";
import FormattedMessage from "components/common/FormattedMessage";
import ClickItem from "components/common/ClickItem";

import "./RefreshConfirm.scss";

const RefreshConfirm = ({ history }) => {
    const { clientVersion, serverVersion } = useSelector(({ common }) => common);

    const [status, setStatus] = useState(true);

    if (clientVersion === serverVersion) return null;
    if (!status) return null;

    return (
        <Notification id="refresh-confirm-notification" close={() => setStatus(false)}>
            <FormattedMessage id="message.refresh.induce" />
            <ClickItem className="refresh-btn" onClick={() => history.go(0)}>
                <p>
                    <IconFont icon="icon_reset_right" /> <FormattedMessage id="action.refresh" />
                </p>
            </ClickItem>
        </Notification>
    );
};

export default withRouter(RefreshConfirm);
