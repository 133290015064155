import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import {
    Button,
    Profile,
    InputWrapper,
    Input,
    Spin,
    Tag,
    IconFont
} from "reviewshare-common-component";
import { escapeString } from "lib/common";
import useTranslate from "lib/customHooks/useTranslate";
import { useUserType } from "lib/customHooks/useAuth";
import { useProgressItems } from "lib/customHooks/useProgress";
import useScrollEnd from "lib/customHooks/useScrollEnd";
import { searchTo, selectWriteInfo } from "stores/modules/messenger/action";
import FormattedMessage from "components/common/FormattedMessage";
import EmptyContent from "components/messenger/side/EmptyContent";

import "./SelectTo.scss";

const SelectTo = ({ closeModal }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const userType = useUserType();
    const scrollElement = useRef();
    const isEndOfPage = useScrollEnd({ ref: scrollElement });

    const searchedToList = useSelector(({ messenger }) => messenger.searchedTo.list);
    const searchedToCounts = useSelector(({ messenger }) => messenger.searchedTo.counts);
    const writeInfo = useSelector(({ messenger }) => messenger.writeInfo);
    const isSearching = useProgressItems("messenger/SEARCH_TO") && !searchedToList.length;

    const [inputText, setInputText] = useState("");
    const [searchedKeyword, setSearchedKeyword] = useState("");

    const regex = new RegExp(escapeString(searchedKeyword), "g");

    const onSearchTo = skip => {
        setSearchedKeyword(inputText);
        const keyword = escapeString(inputText);
        if (!keyword) return;
        if (userType === "Seller") {
            dispatch(searchTo({ nickname: keyword, skip }));
        } else {
            dispatch(searchTo({ brandName: keyword, skip }));
        }
    };

    const onSelectTo = target => {
        dispatch(
            selectWriteInfo({
                from: writeInfo.from,
                toList: [...writeInfo.toList, target],
                noSearchMessageBox: true
            })
        );
    };

    const onRemoveTo = index => {
        const toList = [...writeInfo.toList];
        toList.splice(index, 1);
        dispatch(
            selectWriteInfo({
                from: writeInfo.from,
                toList,
                noSearchMessageBox: true
            })
        );
    };

    useEffect(() => {
        if (isEndOfPage && searchedToList.length < searchedToCounts) {
            onSearchTo(searchedToList.length);
        }
        // eslint-disable-next-line
    }, [isEndOfPage]);

    return (
        <div className="messenger-main-select-to">
            <div className="scroll-area" ref={scrollElement}>
                <InputWrapper search={() => onSearchTo()}>
                    <Input
                        value={inputText}
                        placeholder={translate("action.search")}
                        onChange={val => setInputText(val)}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearchTo();
                            }
                        }}
                    />
                </InputWrapper>
                {searchedKeyword ? (
                    <ul className="to-list">
                        {searchedToList.map((item, index) => {
                            let findIndex = -1;
                            if (userType === "Seller") {
                                findIndex = writeInfo.toList.findIndex(
                                    toItem => toItem.reviewerId === item.reviewerId
                                );
                            } else {
                                findIndex = writeInfo.toList.findIndex(
                                    toItem => toItem.brandId === item.brandId
                                );
                            }
                            const isActive = findIndex > -1;

                            return (
                                <li key={index} className={cx({ active: isActive })}>
                                    {userType === "Seller" && (
                                        <Profile
                                            className="size-xxs m-size-s"
                                            img={item.profileImg}
                                            noBand
                                            alt={translate("item.portfolio.profile.target", {
                                                name: item.nickname
                                            })}
                                        />
                                    )}
                                    <div className="nickname-area">
                                        <p
                                            className="to-name"
                                            dangerouslySetInnerHTML={{
                                                __html: searchedKeyword
                                                    ? (item.nickname || item.brandName).replace(
                                                          regex,
                                                          `<span class="blue-font">${searchedKeyword}</span>`
                                                      )
                                                    : item.nickname || item.brandName
                                            }}
                                        />
                                        {item.relation?.status === "follow" && (
                                            <Tag
                                                color="gray-lightest"
                                                className="relation-status-tag round size-xs m-size-xs"
                                            >
                                                <IconFont icon="icon_check" />{" "}
                                                <FormattedMessage id="status.relation.follow" />
                                            </Tag>
                                        )}
                                    </div>
                                    <Button
                                        className="theme4 size-s m-size-xs"
                                        onClick={() => {
                                            if (isActive) {
                                                onRemoveTo(findIndex);
                                            } else {
                                                onSelectTo(item);
                                            }
                                        }}
                                    >
                                        <FormattedMessage
                                            id={isActive ? "action.deselect" : "action.select"}
                                        />
                                    </Button>
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <p className="caution-text">
                        {userType === "Seller"
                            ? "*리뷰어 닉네임을 검색해 주세요."
                            : "*브랜드 이름을 검색해 주세요."}
                    </p>
                )}
                {isSearching ? (
                    <Spin className="full" />
                ) : (
                    searchedKeyword &&
                    !searchedToList.length && (
                        <EmptyContent>
                            <FormattedMessage id="message.search.result.no" />
                        </EmptyContent>
                    )
                )}
            </div>
            {searchedKeyword && (
                <div className="button-area">
                    <Button
                        className="theme5 m-size-l"
                        disabled={!writeInfo.toList.length}
                        onClick={closeModal}
                    >
                        선택 완료
                    </Button>
                </div>
            )}
        </div>
    );
};
export default SelectTo;
