import { useSelector, useDispatch } from "react-redux";
import {
    handleMessengerOpenStatus,
    selectWriteInfo,
    getBrandHistory
} from "stores/modules/messenger/action";

// state
export const useMessengerState = value => useSelector(({ messenger }) => messenger[value]);
export const useSelectedMessageBox = value =>
    useSelector(({ messenger }) =>
        value ? messenger.selectedMessageBox[value] : messenger.selectedMessageBox
    );
export const useBrandHistory = () => useSelector(({ messenger }) => messenger.brandHistory);

// action
export const useMessengerAction = () => {
    const dispatch = useDispatch();

    const onCloseMessenger = () => {
        dispatch(handleMessengerOpenStatus(false));
    };

    const onSendMessage = ({ from, toList }) => {
        dispatch(
            selectWriteInfo({
                from,
                toList
            })
        );
    };

    const onGetBrandHistory = ({ reviewerId, brandId, skip, category } = {}) => {
        dispatch(
            getBrandHistory({
                reviewerId,
                brandId,
                category,
                skip
            })
        );
    };

    return {
        closeMessenger: onCloseMessenger,
        sendMessage: onSendMessage,
        getBrandHistory: onGetBrandHistory
    };
};
