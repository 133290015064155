export const TALENT_REVIEWER_GUIDE = "https://blog.naver.com/reviewshare/222726122487";
export const TALENT_BIZ_GUIDE = "https://blog.naver.com/reviewshare/222726217541";
export const TALENT_ALL_NOTICE = "/support/notice/103855";

export const DIRECT_WARNING_NOTICE = "/support/notice/103867";

export const POINT_GIFT_HISTORY_RE = "/mypoint?type=pointGift";
export const POINT_GIFT_HISTORY_BIZ = "/biz/payment?category=pointGift";
export const POINT_GIFT_BIZ_NOTICE = "/support/notice/103876";
export const SHORT_FORM_BIZ_NOTICE = "/support/notice/103898";

export const PARTER_NOTICE = "/support/notice/103873";
export const SNS_YOUTUBE_CHANNEL_GUIDE = "https://www.youtube.com/account_advanced";
export const SERVICE_PDF =
    "https://drive.google.com/file/d/1wNhLp2J8CHTJdbjFtQB3IAnTp1KGTGsT/view?usp=sharing";
