import React, { useState, useEffect } from "react";
import cx from "classnames";
import { ModalBackground, IconFont } from "reviewshare-common-component";
import FormattedMessage from "components/common/FormattedMessage";
import ModalWrapper from "components/base/portal/ModalWrapper";

import "./MessengerTemplate.scss";

const MessengerTemplate = ({ status, closeMessenger, children }) => {
    const [localStatus, setLocalStatus] = useState(status);

    useEffect(() => {
        if (status) {
            setTimeout(() => setLocalStatus(status), 0);
        } else {
            setTimeout(() => setLocalStatus(status), 300);
        }
    }, [status]);

    return (
        <ModalWrapper>
            <ModalBackground onClick={closeMessenger} status={status || localStatus}>
                <div id="messenger" className={cx({ active: status && localStatus })}>
                    <div className="messenger-template">
                        <button
                            type="button"
                            className="messenger-close-btn"
                            onClick={closeMessenger}
                        >
                            <IconFont icon="icon_close">
                                <FormattedMessage id="action.close" />
                            </IconFont>
                        </button>
                        {children}
                    </div>
                </div>
            </ModalBackground>
        </ModalWrapper>
    );
};

export default MessengerTemplate;
