import React from "react";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { Button, IconFont, Tag } from "reviewshare-common-component";
import { isMobile as checkMobile } from "lib/common";

import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";
import GlobalHeaderMenu from "components/base/GlobalHeaderMenu";

import "./GlobalHeader.scss";

import reviewshareLogo from "assets/images/logo/reviewshare_logo.svg";
import beta_tag from "assets/images/icons/beta_tag.svg";

const GlobalHeader = ({
    userType,
    schema,
    hasUnreadAlarm,
    onClickLoginBtn,
    setBurger,
    location
}) => {
    const isMobile = checkMobile();

    return (
        <>
            <div id="global-header-start-position" />
            <header className="global-header">
                <div className="top-menu">
                    <div className="header-wrapper">
                        <div className="left-side">
                            <div className="logo-area">
                                <a href="/" className="reviewshare-logo">
                                    <img src={reviewshareLogo} alt="리뷰쉐어" />
                                </a>
                                {userType === "Seller" && (
                                    <Tag
                                        color="blue-dark"
                                        className="user-type-badge round"
                                        type={isMobile ? "line" : "fill"}
                                    >
                                        <FormattedMessage id="item.biz" />
                                    </Tag>
                                )}
                                {userType === "Normal" && (
                                    <Tag
                                        color="blue-dark"
                                        className="user-type-badge round"
                                        type={isMobile ? "line" : "fill"}
                                    >
                                        <FormattedMessage id="item.reviewer" />
                                    </Tag>
                                )}
                            </div>
                        </div>
                        <div className="right-side">
                            <ul className="right-side-menu">
                                {schema.rightMenu.map((rightMenu, index) => (
                                    <li
                                        className={cx("right-menu-item", {
                                            "blue-font":
                                                rightMenu.value === "submitReview" ||
                                                rightMenu.value === "createProject"
                                        })}
                                        key={`right_${index}`}
                                    >
                                        <>
                                            {rightMenu.external ? (
                                                <a
                                                    href={rightMenu.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {rightMenu.label}
                                                </a>
                                            ) : (
                                                <CommonLink to={rightMenu.link}>
                                                    <FormattedMessage id={rightMenu.label} />
                                                </CommonLink>
                                            )}
                                            {rightMenu.new && <span className="new-mark"> N</span>}
                                        </>
                                    </li>
                                ))}
                                {["Normal", "Seller"].indexOf(userType) > -1 && (
                                    <CommonLink
                                        to="/alarm"
                                        className={cx("alarm-link", { hasUnreadAlarm })}
                                    >
                                        <IconFont icon="icon_alarm">
                                            <FormattedMessage id="menu.alarm" />
                                        </IconFont>
                                    </CommonLink>
                                )}
                            </ul>
                            {userType === "Visitor" && (
                                <div className="btn-area">
                                    <Button
                                        className="login-btn theme1 size-m"
                                        onClick={onClickLoginBtn}
                                    >
                                        <FormattedMessage id="action.signin" /> /{" "}
                                        <FormattedMessage id="action.signup" />
                                    </Button>
                                </div>
                            )}
                            <GlobalHeaderMenu schema={schema} />
                        </div>
                    </div>
                    <div className="hamburger-menu-btn mobile" onClick={() => setBurger(true)}>
                        <IconFont icon="icon_hamburger">메뉴</IconFont>
                    </div>
                    {["Normal", "Seller"].indexOf(userType) > -1 && (
                        <CommonLink
                            to="/alarm"
                            className={cx("alarm-link mobile", { hasUnreadAlarm })}
                        >
                            <IconFont icon="icon_alarm">
                                <FormattedMessage id="menu.alarm" />
                            </IconFont>
                        </CommonLink>
                    )}
                </div>
                <div className="bottom-menu">
                    <div className="header-wrapper">
                        <ul className="bottom-menu-list">
                            {schema.leftMenu.map((leftMenu, index) => (
                                <li
                                    className={cx("bottom-menu-item", {
                                        "blue-font": location.pathname.indexOf(leftMenu.link) > -1
                                    })}
                                    key={`left_${index}`}
                                >
                                    <CommonLink
                                        to={leftMenu.link}
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: "smooth" })
                                        }
                                    >
                                        <FormattedMessage id={leftMenu.label} />
                                        {leftMenu.beta && (
                                            <img src={beta_tag} alt="beta" className="beta-tag" />
                                        )}
                                    </CommonLink>
                                    {leftMenu.sub && (
                                        <div className="sub-menu">
                                            {leftMenu.sub.map((sub, subIndex) => (
                                                <div key={subIndex}>
                                                    <CommonLink to={sub.link} className="sub-link">
                                                        <FormattedMessage id={sub.label} />
                                                        {sub.new && (
                                                            <IconFont icon="icon_new_mark_circle">
                                                                new
                                                            </IconFont>
                                                        )}
                                                    </CommonLink>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </header>
        </>
    );
};

export default withRouter(GlobalHeader);
