import { takeLeading, getContext } from "redux-saga/effects";

import { MOVE_404_PAGE } from "stores/modules/common/action";

export const move404PageSaga = function*(action) {
    const history = yield getContext("history");
    history.replace("/404");
};

export function* commonSaga() {
    yield takeLeading(MOVE_404_PAGE, move404PageSaga);
}
