import React from "react";
import classnames from "classnames";
import { Button, IconFont, Spin } from "reviewshare-common-component";
import useTranslate from "lib/customHooks/useTranslate";
import useRelationStatus from "lib/customHooks/useRelationStatus";
import FormattedMessage from "components/common/FormattedMessage";

import style from "./RelationStatusBtn.module.scss";

const cx = classnames.bind(style);
const RelationStatusBtn = ({
    themeType = "clear",
    userId,
    relation,
    showCompleteStatus,
    className,
    disabled
}) => {
    const translate = useTranslate();
    const { relationStatus, changeStatus, isFetching } = useRelationStatus({
        status: relation?.status,
        userId,
        showCompleteStatus
    });

    if (!userId || !relationStatus) return null;
    return (() => {
        const commonClassName = `common-relation-status-btn ${relationStatus} ${style.btn} ${style[themeType]} ${className}`;
        switch (relationStatus) {
            case "none":
                return (
                    <Button
                        className={cx(commonClassName, {
                            theme2: themeType === "line",
                            theme9: themeType === "fill",
                            clear: themeType === "clear"
                        })}
                        onClick={() => changeStatus("follow")}
                        disabled={disabled}
                    >
                        {isFetching && <Spin className={style.spin} />}
                        <span>
                            <FormattedMessage id="action.relation.follow" />
                        </span>
                    </Button>
                );
            case "follow":
                return (
                    <Button
                        className={cx(commonClassName, {
                            theme3: themeType === "line",
                            theme9: themeType === "fill",
                            clear: themeType === "clear"
                        })}
                        onClick={() => changeStatus("cancelFollow")}
                        disabled={disabled}
                    >
                        {isFetching && <Spin className={style.spin} />}
                        <span>
                            <IconFont className={`${style.icon}`} icon="icon_check" />
                            {` ${translate("status.relation.follow")}`}
                        </span>
                    </Button>
                );
            case "block":
                return (
                    <Button
                        className={cx(commonClassName, {
                            theme3: themeType === "line",
                            theme6: themeType === "fill",
                            clear: themeType === "clear"
                        })}
                        onClick={() => changeStatus("cancelBlock")}
                        disabled={disabled}
                    >
                        {isFetching && <Spin className={style.spin} />}
                        <span>
                            <IconFont className={`${style.icon}`} icon="icon_prohibition" />
                            {` ${translate("status.relation.block")}`}
                        </span>
                    </Button>
                );
            default:
                return null;
        }
    })();
};

export default RelationStatusBtn;
