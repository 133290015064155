import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import MessageBoxDetailContainer from "containers/messenger/MessageBoxDetailContainer";
import MainSideItems from "components/messenger/main/MainSideItems";
import WriteInfo from "components/messenger/main/WriteInfo";
import WriteMessage from "components/messenger/main/WriteMessage";

import "./MessengerMainTemplate.scss";

const MessengerMainTemplate = () => {
    const mainStatus = useSelector(({ messenger }) => messenger.mainStatus);

    return (
        <div id="messenger-main-content" className={cx({ active: mainStatus !== "none" })}>
            <div className="messenger-main-content-wrapper">
                {mainStatus === "write" ? (
                    <>
                        <WriteInfo />
                        <WriteMessage />
                    </>
                ) : mainStatus === "messagebox" ? (
                    <MessageBoxDetailContainer />
                ) : (
                    <div className="empty-content">왼쪽 목록에서 쪽지를 선택하세요.</div>
                )}
            </div>
            <div id="messenger-main-side-content">
                <MainSideItems />
            </div>
        </div>
    );
};

export default MessengerMainTemplate;
