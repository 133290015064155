import React from "react";
import { IconFont, InfoBox } from "reviewshare-common-component";
import useTranslate from "lib/customHooks/useTranslate";

import commonStyle from "components/suggest/create/Common.module.scss";
import style from "./GuideLine.module.scss";

const GuideLine = ({ info, setInfo, invalidValue }) => {
    const translate = useTranslate();

    return (
        <div className={`${commonStyle.item} ${style.wrapper}`}>
            <InfoBox
                color={invalidValue.guideLine ? "red" : "blue"}
                className={`${commonStyle.infoBox} size-s m-size-s`}
            >
                <IconFont icon="icon_info" /> 가이드라인 내용 입력 필수
            </InfoBox>
            <div className={`${commonStyle.itemContent} ${style.content}`}>
                <textarea
                    className={style.textarea}
                    value={info.guideLine}
                    onChange={e => {
                        const { value } = e.target;
                        setInfo(prev => ({ ...prev, guideLine: value }));
                    }}
                    placeholder={translate("message.suggestion.create.detail.guide")}
                />
            </div>
        </div>
    );
};
export default GuideLine;
