import client from "./client";

export const getProjectInfo = projectId => client.get(`/reports/${projectId}/project`);

export const getRequestReviewerList = ({ projectId, chosen }) =>
    client.get(`/reports/${projectId}/requests${chosen ? "?chosen=true" : ""}`);

export const getProgressReviewerList = projectId => client.get(`/reports/${projectId}/submits`);

export const changeCampaignRequestMark = ({ id, mark }) =>
    client.put("/campaignRequest/updateMark", { id, mark });

export const handleReportData = ({ projectId, requestDataParam }) =>
    client.get(`/reports/${projectId}/reviewers/stats?data=${requestDataParam}`);

export const getProjectStatsData = ({ projectId, data }) =>
    client.get(`/reports/${projectId}/reviewers/stats?data=${data}`);

export const getReviewerInfo = ({ projectId, reviewerId }) =>
    client.get(`/reports/${projectId}/users/${reviewerId}`);

export const payProjectPoints = ({ projectId, idArray }) =>
    client.put(`/reports/${projectId}/rewards`, { ids: idArray });

// new API

export const getNewProjectInfo = projectId =>
    client.get(`/campaigns/${projectId}/reportProjectInfo`);
export const getRecruitmentList = ({ projectId, status, viewType }) =>
    client.get(`/reports/${projectId}/recruitList/${status}`, {
        params: {
            "view-type": viewType
        }
    });

export const getProjectEvaluateItems = projectId => client.get(`/reports/${projectId}/evaluate`);

// submit
export const getReviewerListForSubmission = ({ projectId, status }) =>
    client.get(`/reports/${projectId}/userList${status ? `?status=${status}` : ""}`);
