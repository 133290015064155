import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmModal } from "reviewshare-common-component";
import { selectFeed } from "stores/modules/feed/action";
import FeedDetailModal from "components/feed/FeedDetailModal";
import CreateFeedModal from "components/mypage/reviewer/Modal/CreateFeedModal";

const FeedModalContainer = ({ history }) => {
    const dispatch = useDispatch();
    const selectedInfo = useSelector(({ feed }) => feed.selectedInfo || {});

    const closeModal = () => {
        dispatch(selectFeed(null));
    };

    useEffect(() => {
        if (selectedInfo.id) {
            closeModal();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    if (!selectedInfo.mode) return null;
    return (() => {
        switch (selectedInfo.mode) {
            case "edit-project-feed":
                return (
                    <ConfirmModal
                        onCancel={closeModal}
                        onConfirm={() => history.push(selectedInfo.link)}
                    >
                        <span className="medium-font">
                            프로젝트 콘텐츠는 [내 프로젝트 활동]에서 수정 가능해요.
                        </span>
                        {"\n"}수정하러 이동하시겠어요?
                    </ConfirmModal>
                );
            case "edit-suggestion-feed":
                return (
                    <ConfirmModal
                        onCancel={closeModal}
                        onConfirm={() => history.push(selectedInfo.link)}
                    >
                        <span className="medium-font">
                            제안 콘텐츠는 [1:1 제안]에서 수정 가능해요.
                        </span>
                        {"\n"}
                        수정하러 이동하시겠어요?
                    </ConfirmModal>
                );
            case "edit-order-feed":
                return (
                    <ConfirmModal
                        onCancel={closeModal}
                        onConfirm={() => history.push(selectedInfo.link)}
                    >
                        <span className="medium-font">
                            거래 콘텐츠는 [거래/서비스 관리]에서 수정 가능해요.
                        </span>
                        {"\n"}
                        수정하러 이동하시겠어요?
                    </ConfirmModal>
                );
            case "edit":
            case "create":
                return <CreateFeedModal onClose={closeModal} />;
            default:
                return <FeedDetailModal onClose={closeModal} />;
        }
    })();
};

export default withRouter(FeedModalContainer);
