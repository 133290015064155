import React from "react";
import { withRouter } from "react-router-dom";
import { IconFont } from "reviewshare-common-component";
import { useUserType } from "lib/customHooks/useAuth";
import { FOOTER_SITE_MAP_LIST } from "scheme/menu";
import CommonLink from "components/common/CommonLink";
import FormattedMessage from "components/common/FormattedMessage";

import "./GlobalFooter.scss";

const GlobalFooter = () => {
    const userType = useUserType();

    return (
        <footer className="global-footer">
            <div className="service-info web">
                <div className="footer-wrapper">
                    <div className="cs-center-info">
                        <p className="cs-center-title">1588-8781</p>
                        <p className="cs-center-date">
                            평일 10:00 ~ 17:00 / 점심 시간 12:00 ~ 13:30
                            <span>주말,공휴일 제외</span>
                        </p>
                        <div className="cs-center-menu-itmes">
                            <CommonLink className="rs-button theme4 size-s" to="/support/faq">
                                <FormattedMessage id="menu.cs.faq" />
                                <IconFont icon="icon_arrow_right" />
                            </CommonLink>
                            <CommonLink className="rs-button theme4 size-s" to="/support/requests">
                                <FormattedMessage id="menu.cs.request" />
                                <IconFont icon="icon_arrow_right" />
                            </CommonLink>
                            <CommonLink
                                className="rs-button theme4 size-s"
                                to="/support/call-requests"
                            >
                                전화 상담 예약
                                <IconFont icon="icon_arrow_right" />
                            </CommonLink>
                        </div>
                    </div>
                    <ul className="site-menu-list">
                        {FOOTER_SITE_MAP_LIST.map(
                            (item, itemIndex) =>
                                item.target.indexOf(userType) > -1 && (
                                    <li key={itemIndex}>
                                        <p>
                                            <FormattedMessage id={item.title} />
                                        </p>
                                        {item.menu.map(
                                            (menu, menuIndex) =>
                                                (!menu.target ||
                                                    (menu.target &&
                                                        menu.target.indexOf(userType) > -1)) && (
                                                    <CommonLink
                                                        to={menu.link}
                                                        key={menuIndex}
                                                        external={menu.external}
                                                    >
                                                        <FormattedMessage id={menu.label} />
                                                    </CommonLink>
                                                )
                                        )}
                                    </li>
                                )
                        )}
                    </ul>
                </div>
            </div>
            <div className="business-info">
                <div className="footer-wrapper">
                    <div className="text-area">
                        <ul className="menu-items">
                            <li>
                                <CommonLink to="/privacypolicy">
                                    <FormattedMessage id="menu.policy" />
                                </CommonLink>
                            </li>
                            <li>
                                <CommonLink to="/tos">
                                    <FormattedMessage id="menu.tos" />
                                </CommonLink>
                            </li>
                        </ul>
                        <ul className="company-info">
                            <li>상호명: (주)컨슈머인텔리전스</li>
                            <li>
                                <FormattedMessage id="item.address" />: 서울시 서초구 서초대로48길
                                101, 3층 (주)컨슈머인텔리전스
                            </li>
                            <li>사업자등록번호: 618-87-00253</li>
                            <li>통신판매업 신고: 제2018-서울강남-02053호</li>
                            <li>고객센터: 1588-8781</li>
                            <li>
                                <FormattedMessage id="item.email" />:{" "}
                                <span>info@reviewshare.io</span>
                            </li>
                            <li>대표: 박계환</li>
                            <li>개인정보관리책임자: 이병훈</li>
                        </ul>
                        <p className="copyright">
                            Copyright ⓒ 2023 All rights reserved by Consumer Intelligence Inc.
                        </p>
                    </div>
                    <ul className="outside-link-list">
                        <li>
                            <a
                                href="https://www.instagram.com/reviewshare.io/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <IconFont icon="icon_instagram">
                                    <FormattedMessage id="item.sns.instagram" />
                                </IconFont>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://blog.naver.com/reviewshare"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <IconFont icon="icon_blog">블로그</IconFont>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://play.google.com/store/apps/details?id=io.reviewshare.reviewerapp&hl=ko"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <IconFont icon="icon_play_store">android 앱</IconFont>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://apps.apple.com/kr/app/%EB%A6%AC%EB%B7%B0%EC%89%90%EC%96%B4/id1511895257"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <IconFont icon="icon_apple">ios 앱</IconFont>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default withRouter(GlobalFooter);
