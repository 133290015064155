import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import cx from "classnames";
import { BOTTOM_TAB_MENU_SCHEME } from "scheme/menu";
import FormattedMessage from "components/common/FormattedMessage";

import "./BottomTabBarMenu.scss";

const BottomTabBarMenu = ({ history, location }) => {
    const userType = useSelector(({ auth }) => auth.userType);

    return (
        <div id="bottom-tab-bar-tab-menu-items">
            {BOTTOM_TAB_MENU_SCHEME.map((menu, index) => {
                if (menu.userType !== "common" && userType !== menu.userType) return null;
                const isActive = location.pathname === menu.path;

                return (
                    <div
                        key={index}
                        className={cx("tab-menu-item", { active: isActive })}
                        onClick={() => {
                            if (isActive) {
                                return window.scrollTo({ top: 0, behavior: "smooth" });
                            } else {
                                history.push(menu.path);
                            }
                        }}
                    >
                        <img
                            src={isActive ? menu.activeIcon : menu.icon}
                            alt={`${menu.label} 아이콘`}
                        />
                        <span className="tab-menu-label">
                            <FormattedMessage id={menu.label} />
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

export default withRouter(BottomTabBarMenu);
