// 부가서비스
export const ADDITION_RECOMMEND_USE = 200000;
export const ADDITION_PRIVATE_USE = 200000;
export const ADDITION_EMERGENCY_USE = 300000;
export const ADDITION_SECONDARY_USE = 30000;

//셀프운영
export const SELF_BASIC = 5000;
export const SELF_STANDARD = 15000;
export const SELF_PREMIUM = 20000;

// 대행운영
export const PARTNER_BASIC = 30000;
export const PARTNER_LIGHT = 60000;
export const PARTNER_STANDARD = 90000;
export const PARTNER_PREMIUM = 120000;
// export const PARTNER_PREMIUM_PLUS = "";

// 리뷰어
export const REWARD_SECONDARY_USE = 10000;
export const REVIEWER_RECOMMEND = 3000;

// 구독형 플랜
export const SUBSCRIPTION_STARTER = 99000;
export const SUBSCRIPTION_GROWTH = 199000;
export const SUBSCRIPTION_PROFESSIONAL = 499000;
export const SUBSCRIPTION_ENTERPRISE = 999000;

// 제안
export const SUGGESTION_PRICE = 5000;

export const ORDER_RATE = 30;
export const POINT_GIFT_RATE = 20;
