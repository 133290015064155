import client from "./client";

export const searchMessageBox = ({ keyword, viewType = "all", limit, skip } = {}) =>
    client.get("/v3/messageBoxes/integration", {
        params: {
            keyword,
            where: viewType,
            limit: viewType === "all" ? 5 : limit || 30,
            skip
        }
    });

export const searchTo = ({ nickname, brandName, limit, skip } = {}) =>
    client.get("/v3/messages/receivers", {
        params: {
            nickname,
            brandName,
            limit: limit || 30,
            skip
        }
    });
export const getMessageBoxList = ({ brand, limit, skip } = {}) =>
    client.get("/v3/messageBoxes", {
        params: {
            brand,
            limit: limit || 30,
            skip
        }
    });

export const getMessageList = ({
    reviewerId,
    brandId,
    lastMessageId,
    containLastMsg,
    after,
    limit
} = {}) =>
    client.get("/v3/messageBoxes/messages", {
        params: {
            reviewer: reviewerId,
            brand: brandId,
            lastMessageId,
            containLastMsg,
            after,
            limit: limit || 30
        }
    });

export const sendMessage = formData => client.post("/v3/messages", formData);

export const uploadFiles = files => client.post("/v3/messages/get-upload-url", files);
