import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import SearchMessageBoxContainer from "containers/messenger/SearchMessageBoxContainer";
import SearchedMessageBoxContainer from "containers/messenger/SearchedMessageBoxContainer";
import MessageBoxListContainer from "containers/messenger/MessageBoxListContainer";

import "./MessengerSideTemplate.scss";

const MessengerSideTemplate = () => {
    const [searchStatus, setSearchStatus] = useState("ready");

    const isOpen = useSelector(({ messenger }) => messenger.isOpen);

    useEffect(() => {
        if (searchStatus === "inactive") {
            setSearchStatus("ready");
        }
    }, [isOpen]);

    return (
        <div id="messenger-side-content">
            <div className="messenger-side-content-wrapper">
                <SearchMessageBoxContainer
                    searchStatus={searchStatus}
                    setSearchStatus={setSearchStatus}
                />
                <div
                    className={cx("messenger-side-list-items", {
                        active: searchStatus === "active",
                        inactive: searchStatus === "inactive"
                    })}
                >
                    <div className="messenger-side-list-item">
                        <MessageBoxListContainer />
                    </div>
                    <div className="messenger-side-list-item">
                        <SearchedMessageBoxContainer />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessengerSideTemplate;
