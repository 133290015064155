import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, StandardModal, ConfirmModal, CautionList } from "reviewshare-common-component";
import {
    STEP_TITLE_SCHEME,
    STEP1_CAUTION_TEXT_SCHEME,
    STEP1_FEE_IS_FREE_CAUTION_TEXT_SCHEME,
    INITIAL_CHECK_VALUE_SCHEME
} from "scheme/suggest";
import { SUGGESTION_PRICE } from "scheme/price";
import { getMyWallet } from "stores/modules/wallet";
import { getPlanInfo } from "stores/modules/subscription/action";
import { getAllBrandList } from "stores/modules/brand/action";
import useTranslate from "lib/customHooks/useTranslate";
import { useTotalPoints } from "lib/customHooks/useWallet";
import { openAlert } from "stores/modules/alert";
import FormattedMessage from "components/common/FormattedMessage";
import SelectBrand from "components/suggest/create/SelectBrand";
import Info from "components/suggest/create/Info";
import Date from "components/suggest/create/Date";
import GuideLine from "components/suggest/create/GuideLine";
import Bill from "components/suggest/create/Bill";

import style from "components/suggest/create/Common.module.scss";

const initialValue = {
    itemName: "",
    siteUrl: "",
    points: 0,
    guideLine: ""
};

const serviceFee = SUGGESTION_PRICE;
const SuggestModal = ({ createSuggestion, closeModal }) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const totalPoints = useTotalPoints();
    const targetInfo = useSelector(({ suggest }) => suggest.suggestUserInfo);

    const [index, setIndex] = useState(0);
    const [invalidValue, setInvalidValue] = useState(INITIAL_CHECK_VALUE_SCHEME);
    const [info, setInfo] = useState(initialValue);
    const [prevSuggestion, setPrevSuggestion] = useState(null);
    const [confirmModalStatus, setConfirmModalStatus] = useState(false);
    const [feeIsFree, setFeeIsFree] = useState(false);

    const amount = (info.points || 0) + (feeIsFree ? 0 : serviceFee);

    const onSuggest = async () => {
        if (!info.enterprise) return;
        if (!info.itemName && !info.points) return;
        if (!info.guideLine) return;
        if (info.points && info.points % 1000 !== 0) return;

        if (totalPoints >= amount) {
            createSuggestion({
                ...info,
                user: targetInfo.userId,
                points: info.points
            });
        } else {
            dispatch(
                openAlert({
                    status: true,
                    text: translate("message.points.lack")
                })
            );
        }
    };

    const onPrevStep = () => {
        setInvalidValue(INITIAL_CHECK_VALUE_SCHEME);
        setIndex(index - 1);
    };

    const onNextStep = () => {
        const newInvalidValue = { ...INITIAL_CHECK_VALUE_SCHEME };
        if (index === 0) {
            if (!info.enterprise) {
                return;
            }
            if ((!info.itemName && !info.points) || info.points < 0) {
                newInvalidValue.info = true;
            } else if (info.points && info.points % 1000 !== 0) return;

            if (!info.submitEndAt) {
                newInvalidValue.date = true;
            }
        }

        if (index === 1) {
            if (!info.guideLine) {
                newInvalidValue.guideLine = true;
            }
        }

        setInvalidValue(newInvalidValue);
        if (Object.values(newInvalidValue).indexOf(true) > -1) {
            return;
        }

        if (index === 2) {
            return onSuggest();
        }

        setIndex(index + 1);
    };

    useEffect(() => {
        dispatch(getPlanInfo());
        dispatch(getAllBrandList());
        dispatch(getMyWallet());
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <StandardModal
                align="center"
                id={style.modal}
                className={`full-modal ${style[`step-${index + 1}`]}`}
                modalTitle={
                    <FormattedMessage
                        id="action.biz.suggestion.create.target"
                        values={{ name: targetInfo.nickname }}
                    />
                }
                modalFooter={
                    <>
                        {index !== 0 && (
                            <Button className="theme8 size-xl m-size-l" onClick={onPrevStep}>
                                <FormattedMessage id="action.go.prev.step" />
                            </Button>
                        )}
                        <Button className="theme1 size-xl m-size-l" onClick={onNextStep}>
                            <FormattedMessage
                                id={
                                    index === 2
                                        ? amount > 0
                                            ? "action.checkout"
                                            : "action.biz.suggestion.create"
                                        : "action.go.next.step"
                                }
                            />
                        </Button>
                    </>
                }
                autoClose={false}
                closeModal={closeModal}
            >
                <div className={style.modalInner}>
                    <h2 className={style.stepTitle}>
                        STEP{index + 1} <FormattedMessage id={STEP_TITLE_SCHEME[index]} />
                    </h2>
                    {(() => {
                        switch (index) {
                            case 0:
                                return (
                                    <>
                                        <SelectBrand
                                            setFeeIsFree={setFeeIsFree}
                                            prevSuggestion={prevSuggestion}
                                            setPrevSuggestion={setPrevSuggestion}
                                            info={info}
                                            setInfo={setInfo}
                                        />
                                        <Info
                                            info={info}
                                            setInfo={setInfo}
                                            invalidValue={invalidValue}
                                        />
                                        <Date
                                            info={info}
                                            setInfo={setInfo}
                                            invalidValue={invalidValue}
                                        />
                                        <CautionList
                                            className={style.cautionList}
                                            items={
                                                feeIsFree
                                                    ? STEP1_FEE_IS_FREE_CAUTION_TEXT_SCHEME
                                                    : STEP1_CAUTION_TEXT_SCHEME
                                            }
                                        />
                                    </>
                                );
                            case 1:
                                return (
                                    <GuideLine
                                        info={info}
                                        setInfo={setInfo}
                                        invalidValue={invalidValue}
                                    />
                                );
                            case 2:
                                return <Bill info={info} feeIsFree={feeIsFree} amount={amount} />;
                            default:
                                return null;
                        }
                    })()}
                </div>
            </StandardModal>
            {confirmModalStatus && (
                <ConfirmModal onCancel={() => setConfirmModalStatus(false)} onConfirm={closeModal}>
                    <FormattedMessage id="message.suggestion.check.cancel" />
                    {"\n"}
                    <FormattedMessage id="message.suggestion.create.detail.reset" />
                </ConfirmModal>
            )}
        </>
    );
};
export default SuggestModal;
