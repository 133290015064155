import React, { useState } from "react";
import cx from "classnames";
import { IconFont, Button, StandardModal, ScrollUpModal } from "reviewshare-common-component";
import { isMobile } from "lib/common";
import useTranslate from "lib/customHooks/useTranslate";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";

import "./LoginModal.scss";

import icon_logo_symbol_gradient from "assets/images/logo/icon_logo_symbol_gradient.svg";
import icon_search_delete from "assets/images/icons/icon_delete.svg";

const LoginModal = ({
    closeModal,
    isReviewer,
    changeUserType,
    loginForm,
    identifier,
    password,
    resetValue,
    onLogin,
    errors
}) => {
    const translate = useTranslate();

    const [inputStatus, setInputStatus] = useState(false);
    const modalContent = (
        <div className="login-modal-inner">
            <div className={cx("login-modal-step-wrapper", { last: inputStatus })}>
                <div className="login-modal-step">
                    <div className="logo-wrapper">
                        <img src={icon_logo_symbol_gradient} alt="리뷰쉐어 로고" />
                    </div>
                    <div className="select-user-type">
                        <ul>
                            <li>
                                <input
                                    type="radio"
                                    id="user-type-reviewer"
                                    defaultChecked={isReviewer}
                                    name="userType"
                                    value="Normal"
                                    ref={loginForm}
                                />
                                <label
                                    htmlFor="user-type-reviewer"
                                    onClick={() => changeUserType(true)}
                                >
                                    <IconFont icon="icon_radio_off">
                                        <FormattedMessage id="status.deselected.radio" />
                                    </IconFont>
                                    <IconFont icon="icon_radio_on" className="blue-font">
                                        <FormattedMessage id="status.selected.radio" />
                                    </IconFont>
                                    <FormattedMessage id="item.reviewer" />
                                </label>
                            </li>
                            <li>
                                <input
                                    type="radio"
                                    id="user-type-business"
                                    name="userType"
                                    defaultChecked={!isReviewer}
                                    value="Seller"
                                    ref={loginForm}
                                />
                                <label
                                    htmlFor="user-type-business"
                                    onClick={() => changeUserType(false)}
                                >
                                    <IconFont icon="icon_radio_off">
                                        <FormattedMessage id="status.deselected.radio" />
                                    </IconFont>
                                    <IconFont icon="icon_radio_on" className="blue-font">
                                        <FormattedMessage id="status.selected.radio" />
                                    </IconFont>
                                    <FormattedMessage id="item.biz" />
                                </label>
                            </li>
                        </ul>
                        {isReviewer ? (
                            <p className="user-type-tip">
                                <FormattedMessage
                                    id="message.re.role"
                                    values={{
                                        emphasis: (
                                            <span>
                                                <FormattedMessage id="message.re.role.emphasis" />
                                            </span>
                                        )
                                    }}
                                />
                            </p>
                        ) : (
                            <p className="user-type-tip">
                                <FormattedMessage
                                    id="message.biz.role"
                                    values={{
                                        emphasis: (
                                            <span>
                                                <FormattedMessage id="message.biz.role.emphasis" />
                                            </span>
                                        )
                                    }}
                                />
                            </p>
                        )}
                    </div>

                    <div className="sns-login">
                        <Button
                            id="login-emailbtn"
                            className="theme1 size-l m-size-l login-btn"
                            onClick={() => setInputStatus(true)}
                        >
                            <IconFont icon="icon_mail_fill" />
                            <FormattedMessage id="action.signin.email" />
                            (<FormattedMessage id={isReviewer ? "item.reviewer" : "item.biz"} />)
                        </Button>
                        <div className="text-with-horizon-line">
                            <div className="horizon-line" />
                            <p>
                                <FormattedMessage id="action.signin.sns" />
                            </p>
                        </div>
                        <div className="sns-login-list">
                            <a
                                id="login-applebtn"
                                href={`${process.env.REACT_APP_API_URL}/appleLogin?category=${
                                    isReviewer ? "Normal" : "Seller"
                                }`}
                                className="apple-login-btn"
                                rel="nosublink"
                            >
                                <IconFont icon="icon_apple">애플</IconFont>
                            </a>
                            <a
                                id="login-naverbtn"
                                href={`${process.env.REACT_APP_API_URL}/v2/signin/naver?category=${
                                    isReviewer ? "Normal" : "Seller"
                                }`}
                                className="naver-login-btn"
                                rel="nosublink"
                            >
                                <IconFont icon="icon_naver">네이버</IconFont>
                            </a>
                        </div>
                    </div>
                    <div className="review-share-visitor">
                        {isReviewer ? (
                            <>
                                <CommonLink to="/register">
                                    <FormattedMessage id="menu.signup.re" />
                                </CommonLink>
                                <CommonLink to="https://blog.naver.com/reviewshare" external>
                                    <FormattedMessage id="menu.re.guide" />
                                </CommonLink>
                            </>
                        ) : (
                            <>
                                <CommonLink id="login-biz-bizsignup" to="/signin">
                                    <FormattedMessage id="menu.signup.biz" />
                                </CommonLink>
                                <CommonLink to="https://blog.naver.com/reviewshare" external>
                                    <FormattedMessage id="menu.biz.guide" />
                                </CommonLink>
                            </>
                        )}
                    </div>
                </div>
                <div className="login-modal-step">
                    <div
                        id="login-email-back"
                        className="prev-step-title"
                        onClick={() => setInputStatus(false)}
                    >
                        <IconFont icon="icon_line_arrow_left">
                            <FormattedMessage id="action.go.back" />
                        </IconFont>
                        <p>
                            <FormattedMessage id="action.signin.email" />
                            (<FormattedMessage id={isReviewer ? "item.reviewer" : "item.biz"} />)
                        </p>
                    </div>
                    <form className="login-form">
                        <div>
                            <input
                                type="text"
                                id="login-email-idinput"
                                placeholder={translate("item.email")}
                                name="identifier"
                                onKeyDown={e => e.keyCode === 13 && onLogin()}
                                disabled={!inputStatus}
                                ref={loginForm({ required: true })}
                            />
                            {identifier && (
                                <img
                                    src={icon_search_delete}
                                    className="mobile"
                                    alt="텍스트 제거"
                                    onClick={() => resetValue("identifier", "login-email-idinput")}
                                />
                            )}
                        </div>
                        <div>
                            <input
                                type="password"
                                id="login-email-pwinput"
                                placeholder={translate("item.password")}
                                name="password"
                                disabled={!inputStatus}
                                onKeyDown={e => e.keyCode === 13 && onLogin()}
                                ref={loginForm({ required: true })}
                            />
                            {password && (
                                <img
                                    src={icon_search_delete}
                                    className="mobile"
                                    alt="텍스트 제거"
                                    onClick={() => resetValue("password", "login-email-pwinput")}
                                />
                            )}
                        </div>
                        <div className="error-message">
                            {errors.identifier?.type === "required" && (
                                <p>
                                    <FormattedMessage id="message.account.validate.email" />
                                </p>
                            )}
                            {!errors.identifier && errors.password?.type === "required" && (
                                <p>
                                    <FormattedMessage id="message.account.validate.password" />
                                </p>
                            )}
                        </div>
                        <Button
                            id="login-email-login"
                            className="theme1 size-l m-size-l login-btn"
                            onClick={onLogin}
                        >
                            <FormattedMessage id={isReviewer ? "item.reviewer" : "item.biz"} />{" "}
                            <FormattedMessage id="action.signin" />
                        </Button>
                        <div className="login-options">
                            <div className="save-id">
                                <input
                                    type="checkbox"
                                    id="login-email-saveid"
                                    name="saveId"
                                    ref={loginForm}
                                    defaultChecked
                                />
                                <label htmlFor="login-email-saveid">
                                    <IconFont icon="icon_checkbox_off">
                                        <FormattedMessage id="action.select.checkbox" />
                                    </IconFont>
                                    <IconFont icon="icon_checkbox_on">
                                        <FormattedMessage id="action.deselect.checkbox" />
                                    </IconFont>
                                    <p>
                                        {translate("action.save.target", {
                                            name: translate("item.email")
                                        })}
                                    </p>
                                </label>
                            </div>
                            <div className="find-btns">
                                <CommonLink id="login-email-findid" to="/find/id" rel="nosublink">
                                    <FormattedMessage id="menu.findID" />
                                </CommonLink>
                                <CommonLink
                                    id="login-email-findpw"
                                    to="/find/password"
                                    className="move-find-pwd-btn"
                                    rel="nosublink"
                                >
                                    <FormattedMessage id="menu.findPW" />
                                </CommonLink>
                            </div>
                        </div>
                    </form>
                    <div className="signin-btn-area">
                        <CommonLink
                            id="login-email-signup"
                            to={isReviewer ? "/register" : "/signin"}
                            rel="nosublink"
                        >
                            <FormattedMessage
                                id={isReviewer ? "menu.signup.re" : "menu.signup.biz"}
                            />
                        </CommonLink>
                    </div>
                </div>
            </div>
        </div>
    );
    return isMobile() ? (
        <ScrollUpModal closeModal={closeModal} id="login-modal">
            {modalContent}
        </ScrollUpModal>
    ) : (
        <StandardModal id="login-modal" closeModal={closeModal}>
            {modalContent}
        </StandardModal>
    );
};

export default LoginModal;
