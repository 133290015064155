import React, { useEffect, useState } from "react";
import Routes from "Routes";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import { IntlProvider } from "react-intl";
import { AlertModal } from "reviewshare-common-component";
import ErrorBoundary from "components/core/ErrorBoundary";
import LocationEvent from "components/core/LocationEvent";
import FormattedMessage from "components/common/FormattedMessage";
import Loading from "components/base/Loading";
import IEBanner from "components/base/IEBanner";
import AllPopupList from "components/popup/AllPopupList";
import AlertContainer from "containers/core/AlertContainer";
import LoginModalContainer from "containers/core/LoginModalContainer";
import MessengerContainer from "containers/messenger/MessengerContainer";
import FeedModalContainer from "containers/feed/FeedModalContainer";
import SuggestContainer from "containers/suggest/SuggestContainer";
import BeforeChangeRelation from "components/relation/common/BeforeChangeRelation";
import AfterChangeRelation from "components/relation/common/AfterChangeRelation";
import { getStorage, removeStorage } from "lib/storage";
import { getCategoryList, getUserKeywordList } from "stores/modules/main/action";

import en from "locale/en";
import kr from "locale/kr";
import vn from "locale/vn";

const langList = {
    en,
    kr,
    vn
};

const App = () => {
    const [authErrorModal, setErrorModal] = useState(false);
    const [preventPage, setPreventPage] = useState(false);
    const dispatch = useDispatch();
    const authError = useSelector(({ auth }) => auth.authError);
    const userId = useSelector(({ auth }) => auth.userInfo.user && auth.userInfo.user._id);
    const lang = useSelector(({ common }) => common.lang);

    const moveToLoginPage = () => {
        setErrorModal(false);
        localStorage.removeItem("reviewshare-at");
        localStorage.removeItem("reviewshare-re-at");
        window.location.href = "/";
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getCategoryList());
        dispatch(getUserKeywordList());
        const referrer = document.referrer;

        if (referrer) {
            sessionStorage.setItem("referrer", referrer);
        }
    }, []);

    useEffect(() => {
        if (getStorage("reviewshare-at") || getStorage("reviewshare-re-at")) {
            setPreventPage(true);

            if (userId) {
                setPreventPage(false);
            }
        }
    }, [userId]);

    useEffect(() => {
        if (authError) {
            removeStorage("reviewshare-at");
            removeStorage("reviewshare-re-at");
            setErrorModal(true);
        }
    }, [authError]);

    useEffect(() => {
        document.querySelector("body").classList.remove("lang-kr", "lang-en", "lang-vr");
        document.querySelector("body").classList.add(`lang-${lang}`);
    }, [lang]);

    return (
        <IntlProvider locale={lang} messages={langList[lang]}>
            <ErrorBoundary>
                <LocationEvent />
                <AlertContainer />
                <Loading />
                <AllPopupList />
                <BeforeChangeRelation />
                <AfterChangeRelation />
                <LoginModalContainer />
                <MessengerContainer />
                <FeedModalContainer />
                <SuggestContainer />
                {!!authErrorModal && (
                    <AlertModal handleModal={moveToLoginPage}>
                        <p>
                            <FormattedMessage id="message.signin.validate" />
                        </p>
                    </AlertModal>
                )}
                <div className={cx("root-wrapper", { preventPage })}>
                    <IEBanner />
                    <Routes />
                </div>
            </ErrorBoundary>
        </IntlProvider>
    );
};
export default App;
