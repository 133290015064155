import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { createRequestSagaWithAlert, createRequestSagaBase } from "lib/createRequestSaga";
import * as projectAPI from "lib/api/project";
import { openAlert } from "stores/modules/alert";
import { REVIEWER_APPLY_PROJECT } from "scheme/alert";
import { returnAlertValue, returnAlertName, numberWithCommas } from "lib/common";
import { getMyWallet } from "stores/modules/wallet";
import { handleProgressItem } from "stores/modules/progress";
import { setUnsubmitCounts } from "stores/modules/auth";

import { move404Page } from "stores/modules/common/action";
import {
    GET_PROJECT,
    GET_PROJECT_FAILURE,
    APPLY_PROJECT,
    APPLY_PROJECT_SUCCESS,
    APPLY_PROJECT_FAILURE,
    CANCEL_PROJECT,
    CANCEL_PROJECT_SUCCESS,
    CANCEL_PROJECT_FAILURE,
    GET_PROJECT_LIST,
    GET_RECOMMEND_PROJECT_LIST,
    GET_PROJECT_COUNTS
} from "stores/modules/project/action";

const getProjectSaga = createRequestSagaBase({
    api: projectAPI.getProject,
    type: GET_PROJECT
});

const getProjectFailureSaga = function*(action) {
    if (action.payload.error.data.indexOf("onlyOpenToReviewer") > -1) {
        yield put(
            openAlert({ text: "비공개 프로젝트입니다.\n리뷰어 계정으로 로그인이 필요합니다." })
        );
    } else if (action.payload.error.data.indexOf("onlyOpenToChosenReviewer") > -1) {
        yield put(openAlert({ text: "비공개 프로젝트입니다.\n선정된 리뷰어님만 볼 수 있습니다." }));
    } else {
        yield put(move404Page());
    }
};

const applyProjectSaga = function*(action) {
    yield put(handleProgressItem({ type: action.type, value: true }));
    const alertName = returnAlertName(action.type);
    try {
        yield call(projectAPI.applyProject, action.payload);
        yield put({ type: APPLY_PROJECT_SUCCESS });
        yield put(
            openAlert({
                name: `${alertName}-success`,
                text: "message.project.apply.complete",
                path: `/project/${action.payload.campaign}`
            })
        );
        if (action.payload?.securityDepositPoints > 0) {
            yield put(getMyWallet());
        }
    } catch (e) {
        const failData = e.response.data;
        if (failData.unsubmitProjectCounts || failData.unsubmitSuggestionCounts) {
            yield put(setUnsubmitCounts(failData));
        } else {
            const { message, path } = returnAlertValue({
                message: failData.message,
                list: REVIEWER_APPLY_PROJECT
            });
            yield put(
                openAlert({
                    name: `${alertName}-failure`,
                    text: message || "message.project.apply.fail",
                    path
                })
            );
        }
        yield put({
            type: APPLY_PROJECT_FAILURE,
            payload: { error: e.response }
        });
    }
    yield put(handleProgressItem({ type: action.type, value: false }));
};

const cancelProjectSaga = function*(action) {
    try {
        const { projectId, securityDepositPoints } = action.payload;
        const { data } = yield call(projectAPI.cancelProject, projectId);
        yield put({
            type: CANCEL_PROJECT_SUCCESS,
            payload: data
        });
        if (securityDepositPoints > 0) {
            yield put(
                openAlert({
                    text: `프로젝트 신청 취소 및 \n보증금 ${numberWithCommas(
                        securityDepositPoints
                    )}P가 환급됐어요.`
                })
            );
            yield put(getMyWallet());
        } else {
            yield put(
                openAlert({
                    text: "프로젝트 신청 취소가 완료됐어요."
                })
            );
        }
    } catch (e) {
        yield put({
            type: CANCEL_PROJECT_FAILURE,
            payload: { error: e.response }
        });
        yield put(
            openAlert({
                text: "message.project.apply.fail_request_cancel"
            })
        );
    }
};

const getProjectListSaga = createRequestSagaWithAlert(
    GET_PROJECT_LIST,
    projectAPI.getProjectList,
    "message.project.get.list.fail"
);

const getRecommendProjectListSaga = createRequestSagaWithAlert(
    GET_RECOMMEND_PROJECT_LIST,
    projectAPI.getRecommendProjectList
);

const getProjectCountsSaga = createRequestSagaBase({
    type: GET_PROJECT_COUNTS,
    api: projectAPI.getManagedProjectCount
});

export function* projectSaga() {
    yield takeLatest(GET_PROJECT, getProjectSaga);
    yield takeLeading(GET_PROJECT_FAILURE, getProjectFailureSaga);
    yield takeLeading(APPLY_PROJECT, applyProjectSaga);
    yield takeLeading(CANCEL_PROJECT, cancelProjectSaga);
    yield takeLatest(GET_PROJECT_LIST, getProjectListSaga);
    yield takeLatest(GET_RECOMMEND_PROJECT_LIST, getRecommendProjectListSaga);
    yield takeLatest(GET_PROJECT_COUNTS, getProjectCountsSaga);
}
