import React from "react";
import { useSelector } from "react-redux";
import { Button, Profile, StandardModal, CautionList } from "reviewshare-common-component";
import { SUGGESTION_PRICE } from "scheme/price";
import { BIZ_SUGGESTION_LIST_PATH_MSG } from "scheme/suggest";
import useTranslate from "lib/customHooks/useTranslate";
import FormattedMessage from "components/common/FormattedMessage";
import CommonLink from "components/common/CommonLink";

import style from "./SuggestCompleteModal.module.scss";

const serviceFee = SUGGESTION_PRICE;
const SuggestCompleteModal = ({ feeIsFree, closeModal, suggestAgain, createdSuggestionId }) => {
    const translate = useTranslate();
    const targetInfo = useSelector(({ suggest }) => suggest.suggestUserInfo || {});
    const planIsRunning = useSelector(({ subscription }) => subscription.planIsRunning);

    return (
        <StandardModal
            align="left"
            autoClose={false}
            closeModal={closeModal}
            id={planIsRunning ? "suggest-done-modal" : "suggest-complete-modal"}
            className={style.suggestCompleteModal}
        >
            <div className={style.modalInner}>
                <Profile
                    className="size-m m-size-m"
                    grade={targetInfo.grade}
                    blackUser={targetInfo.blackUser}
                    img={
                        typeof targetInfo.profile === "object"
                            ? targetInfo.profile?.secure_url
                            : targetInfo.profile
                    }
                    alt={translate("item.portfolio.profile.target", {
                        name: targetInfo.nickname
                    })}
                />
                <p className={style.successMsg}>
                    <FormattedMessage
                        id="message.suggestion.create.success"
                        values={{ name: <span>{targetInfo.nickname}</span> }}
                    />
                </p>
                <p className={style.desc}>
                    리뷰어님이 제안을 수락하시면 {"\n"}알림톡으로 알려드려요!
                </p>
                <div className={style.buttonItems}>
                    <CommonLink
                        className={`${style.buttonItem} rs-button theme4 size-l m-size-l`}
                        to="/biz/suggestion/waiting"
                    >
                        <FormattedMessage id="menu.biz.suggestion.manage" />
                    </CommonLink>
                    <Button
                        className={`${style.buttonItem} theme4 size-l m-size-m`}
                        onClick={suggestAgain}
                    >
                        <FormattedMessage id="action.biz.suggestion.create.another" />
                    </Button>
                </div>
                <input
                    type="number"
                    id="suggest-complete-fee"
                    value={feeIsFree ? 0 : serviceFee}
                    hidden
                />
                <input type="number" id="suggest-complete-id" value={createdSuggestionId} hidden />

                <CautionList className={style.cautionList} items={[BIZ_SUGGESTION_LIST_PATH_MSG]} />
            </div>
        </StandardModal>
    );
};
export default SuggestCompleteModal;
