import client from "./client";

export const createComment = commentForm => client.post("/comments", commentForm);

export const getMyRequestsList = ({ lastRequestid, skip = 0 }) =>
    client.get(`/comments?skip=${skip}${lastRequestid ? `&lastRequestid=${lastRequestid}` : ""}`);

export const getMyRequests = requestId => client.get(`/comments/${requestId}`);

export const cancelRequests = requestId => client.patch(`/comments/${requestId}/cancel`);
