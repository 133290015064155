export const FILTER_ITEM_LIST = [
    { label: "유형", value: "type", type: "checkbox" },
    { label: "카테고리", value: "category", type: "checkbox" },
    { label: "SNS", value: "sns", type: "radio" },
    { label: "성별", value: "gender", type: "radio" },
    { label: "연령", value: "ageGroup", type: "checkbox" }
];

export const CATEGORY_SCHEME = [
    { label: "item.feed.category.cosmetic", value: "cosmetic", name: "cosmetic" },
    { label: "item.feed.category.beauty", value: "beauty", name: "beauty" },
    { label: "item.feed.category.fashion", value: "fashion", name: "fashion" },
    { label: "item.feed.category.food", value: "food", name: "food" },
    { label: "item.feed.category.supplies", value: "supplies", name: "supplies" },
    { label: "item.feed.category.baby", value: "baby", name: "baby" },
    { label: "item.feed.category.digital", value: "digital", name: "digital" },
    { label: "item.feed.category.sports", value: "sports", name: "sports" },
    { label: "item.feed.category.pet", value: "pet", name: "pet" },
    {
        label: "item.feed.category.restaurant",
        value: "restaurant",
        name: "restaurant"
    },
    { label: "item.feed.category.travel", value: "travel", name: "travel" },
    { label: "item.feed.category.area", value: "area", name: "area" },
    { label: "item.feed.category.etc", value: "etc", name: "etc" }
];

export const FEED_TYPE_FILTER = [
    { label: "item.project.type.review", value: "review" },
    { label: "item.project.type.content", value: "content" },
    { label: "item.project.type.shortform", value: "shortform" }
];

export const FEED_SNS_FILTER = [
    { label: "item.sns.naver.blog", value: "NaverBlog" },
    { label: "item.sns.instagram", value: "Instagram" },
    { label: "item.sns.reels", value: "Reels" },
    { label: "item.sns.youtube", value: "Youtube" },
    { label: "item.sns.shorts", value: "Shorts" },
    { label: "item.sns.tiktok", value: "Tiktok" }
];
