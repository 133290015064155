import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import Error from "components/base/Error";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { errorInfo: false };
    }

    static getDerivedStateFromError(error) {
        return { errorInfo: error };
    }

    componentDidCatch(error, errorInfo) {
        if (error?.message?.indexOf("chunk") > -1) {
            window.location.reload();
        }

        Sentry.captureException(error);
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });

            Sentry.captureException(error);
        });

        // super.componentDidCatch(error, errorInfo);
    }
    render() {
        return this.state.errorInfo ? <Error error={this.state.errorInfo} /> : this.props.children;
    }
}
export default ErrorBoundary;
