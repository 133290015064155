import { createAction, handleActions } from "redux-actions";
import { createRequestActionTypes } from "lib/createRequestSaga";
import produce from "immer";

export const RESET_CREATED_INFO = "order/RESET_CREATING_INFO";
export const RESET_REVIEWER_ORDER_DETAIL = "order/RESET_REVIEWER_ORDER_DETAIL";
export const RESET_SUBMIT_RESULT_STATUS = "order/RESET_SUBMIT_RESULT_STATUS";

export const [CREATE_ORDER, CREATE_ORDER_SUCCESS, CREATE_ORDER_FAILURE] = createRequestActionTypes(
    "order/CREATE_ORDER"
);

export const [
    UPDATE_ORDER_STATUS,
    UPDATE_ORDER_STATUS_SUCCESS,
    UPDATE_ORDER_STATUS_FAILURE
] = createRequestActionTypes("order/UPDATE_ORDER_STATUS");

export const [
    SUBMIT_RESULT,
    SUBMIT_RESULT_SUCCESS,
    SUBMIT_RESULT_FAILURE
] = createRequestActionTypes("order/SUBMIT_RESULT");

export const [
    GET_REVIEWER_ORDER_LIST,
    GET_REVIEWER_ORDER_LIST_SUCCESS,
    GET_REVIEWER_ORDER_LIST_FAILURE
] = createRequestActionTypes("order/GET_REVIEWER_ORDER_LIST");

export const [
    GET_REVIEWER_ORDER_DETAIL,
    GET_REVIEWER_ORDER_DETAIL_SUCCESS,
    GET_REVIEWER_ORDER_DETAIL_FAILURE
] = createRequestActionTypes("order/GET_REVIEWER_ORDER_DETAIL");

export const [
    GET_BIZ_ORDER_LIST,
    GET_BIZ_ORDER_LIST_SUCCESS,
    GET_BIZ_ORDER_LIST_FAILURE
] = createRequestActionTypes("order/GET_BIZ_ORDER_LIST");

export const [
    GET_BIZ_ORDER_COUNTS,
    GET_BIZ_ORDER_COUNTS_SUCCESS,
    GET_BIZ_ORDER_COUNTS_FAILURE
] = createRequestActionTypes("order/GET_BIZ_ORDER_COUNTS");

export const resetCreatedInfo = createAction(RESET_CREATED_INFO);
export const resetReviewerOrderDetail = createAction(RESET_REVIEWER_ORDER_DETAIL);
export const resetSubmitResultStatus = createAction(RESET_SUBMIT_RESULT_STATUS);
export const createOrder = createAction(CREATE_ORDER, obj => obj);
export const updateOrderStatus = createAction(UPDATE_ORDER_STATUS);
export const submitResult = createAction(SUBMIT_RESULT);
export const getReviewerOrderList = createAction(GET_REVIEWER_ORDER_LIST);
export const getReviewerOrderDetail = createAction(GET_REVIEWER_ORDER_DETAIL);
export const getBizOrderCounts = createAction(GET_BIZ_ORDER_COUNTS);
export const getBizOrderList = createAction(GET_BIZ_ORDER_LIST);

const initialState = {
    createdInfo: {},

    submitResultSuccess: false,
    orderResult: {
        orderId: null,
        url: null,
        fileList: []
    },

    //reviewer
    reviewerOrderList: {
        counts: 0,
        list: []
    },
    reviewerOrderDetail: {},

    //biz
    bizOrderAllCounts: {},
    bizOrderList: {
        counts: {},
        list: []
    }
};

const order = handleActions(
    {
        [RESET_CREATED_INFO]: state =>
            produce(state, draft => {
                draft.createdInfo = initialState.createdInfo;
            }),
        [RESET_SUBMIT_RESULT_STATUS]: state =>
            produce(state, draft => {
                draft.submitResultSuccess = initialState.submitResultSuccess;
            }),
        [RESET_REVIEWER_ORDER_DETAIL]: state =>
            produce(state, draft => {
                draft.submitResultSuccess = initialState.submitResultSuccess;
                draft.reviewerOrderDetail = initialState.reviewerOrderDetail;
                draft.orderResult = initialState.orderResult;
            }),
        [CREATE_ORDER_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.createdInfo = payload;
            }),
        [UPDATE_ORDER_STATUS_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                if (["cancel", "complete"].indexOf(prev.process) > -1) {
                    const { counts } = state.bizOrderList;
                    draft.bizOrderList.counts = {
                        ...counts,
                        totalCounts: counts.totalCounts - 1
                    };
                    draft.bizOrderList.list = state.bizOrderList.list.filter(
                        item => item.orderId !== prev.orderId
                    );
                } else if (["confirm", "reject"].indexOf(prev.process) > -1) {
                    draft.reviewerOrderDetail = {
                        ...state.reviewerOrderDetail,
                        rejectMessage: prev.rejectMessage,
                        paymentStatus: prev.process === "reject" ? "refund" : state.paymentStatus,
                        ...payload
                    };
                }
            }),
        [SUBMIT_RESULT_SUCCESS]: state =>
            produce(state, draft => {
                draft.submitResultSuccess = true;
                draft.reviewerOrderDetail = {
                    ...state.reviewerOrderDetail,
                    status: "submit",
                    prevStatus: state.reviewerOrderDetail.status
                };
            }),
        [GET_REVIEWER_ORDER_LIST]: state =>
            produce(state, draft => {
                draft.reviewerOrderList.list = initialState.reviewerOrderList.list;
            }),
        [GET_REVIEWER_ORDER_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.reviewerOrderList = {
                    counts: payload.totalCounts,
                    list: payload.list
                };
            }),
        [GET_REVIEWER_ORDER_DETAIL_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                if (prev.isSubmit) {
                    draft.reviewerOrderDetail = {
                        ...payload,
                        status: state.reviewerOrderDetail.status,
                        prevStatus: state.reviewerOrderDetail.prevStatus,
                        paymentStatus: "paid"
                    };
                } else {
                    draft.reviewerOrderDetail = payload;
                }
            }),
        [GET_BIZ_ORDER_COUNTS_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.bizOrderAllCounts = payload;
            }),
        [GET_BIZ_ORDER_LIST]: state =>
            produce(state, draft => {
                draft.bizOrderList.list = initialState.bizOrderList.list;
            }),
        [GET_BIZ_ORDER_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.bizOrderList.list = payload.list;
                draft.bizOrderList.counts = {
                    ...payload,
                    list: undefined
                };
            })
    },
    initialState
);

export default order;
