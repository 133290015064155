import { createAction, handleActions } from "redux-actions";
import { takeLeading, takeLatest } from "redux-saga/effects";
import produce from "immer";
import { createRequestSagaBase, createRequestActionTypes } from "lib/createRequestSaga";
import {
    getRecruitmentList as getRecruitmentListAPI,
    changeCampaignRequestMark as changeCampaignRequestMarkAPI
} from "lib/api/report";
import { getReviewerProjectList as getReviewerProjectListAPI } from "lib/api/user";

// ******************************
// TYPE
// ******************************

export const [
    GET_RECRUITMENT_LIST,
    GET_RECRUITMENT_LIST_SUCCESS,
    GET_RECRUITMENT_LIST_FAILURE
] = createRequestActionTypes("reportRecruit/GET_RECRUITMENT_LIST");

export const [
    CHANGE_CAMPAIGN_REQUEST_MARK,
    CHANGE_CAMPAIGN_REQUEST_MARK_SUCCESS,
    CHANGE_CAMPAIGN_REQUEST_MARK_FAILURE
] = createRequestActionTypes("reportRecruit/CHANGE_CAMPAIGN_REQUEST_MARK");

export const [
    GET_REVIEWER_RECRUITMENT_PROJECTS,
    GET_REVIEWER_RECRUITMENT_PROJECTS_SUCCESS,
    GET_REVIEWER_RECRUITMENT_PROJECTS_FAILURE
] = createRequestActionTypes("reportRecruit/GET_REVIEWER_RECRUITMENT_PROJECTS");

export const HANDLE_RECRUIT_MODALS = "reportRecruit/HANDLE_RECRUIT_MODALS";
export const HANDLE_FILTER = "reportRecruit/HANDLE_FILTER";
export const HANDEL_FILTERED_LIST = "reportRecruit/HANDEL_FILTERED_LIST";
export const HANDEL_EXCLUDE_MY_BRAND = "reportRecruit/HANDEL_EXCLUDE_MY_BRAND";
export const CHANGE_TO_BTN_SEEN = "reportRecruit/CHANGE_TO_BTN_SEEN";
export const UPDATE_MEMO_STATUS = "reportRecruit/UPDATE_MEMO_STATUS";

// ******************************
// CREATE_ACTION
// ******************************

export const getRecruitmentList = createAction(
    GET_RECRUITMENT_LIST,
    ({ projectId, status, viewType, reset }) => ({
        projectId,
        status,
        viewType,
        reset
    })
);

export const changeCampaignRequestMark = createAction(
    CHANGE_CAMPAIGN_REQUEST_MARK,
    ({ id, mark }) => ({ id, mark })
);

export const handleRecruitModals = createAction(
    HANDLE_RECRUIT_MODALS,
    ({ status, name, data }) => ({
        status,
        name,
        data
    })
);

export const handleFilter = createAction(HANDLE_FILTER, filterObj => filterObj);

export const handleExcludeMyBrand = createAction(HANDEL_EXCLUDE_MY_BRAND, isExclude => isExclude);

export const handleFilteredList = createAction(HANDEL_FILTERED_LIST, list => list);

export const getReviewerRecruitmentProjects = createAction(
    GET_REVIEWER_RECRUITMENT_PROJECTS,
    ({ reviewerId, targetData, brandId }) => ({ reviewerId, targetData, brandId })
);

export const changeToBtnSeen = createAction(CHANGE_TO_BTN_SEEN, ({ reviewerId, type }) => ({
    reviewerId,
    type
}));
export const updateMemoStatus = createAction(UPDATE_MEMO_STATUS);

// ******************************
// CREATE_REQUEST_SAGA
// ******************************

const getRecruitmentListSaga = createRequestSagaBase({
    type: GET_RECRUITMENT_LIST,
    api: getRecruitmentListAPI,
    alertErrorMsg: "리뷰어 목록을 가져오는 데 실패하였습니다.",
    progress: true
});

const changeCampaignRequestMarkSaga = createRequestSagaBase({
    type: CHANGE_CAMPAIGN_REQUEST_MARK,
    api: changeCampaignRequestMarkAPI,
    alertErrorMsg: "별표 설정에 실패하였습니다."
});

const getReviewerRecruitmentProjectsSaga = createRequestSagaBase({
    type: GET_REVIEWER_RECRUITMENT_PROJECTS,
    api: getReviewerProjectListAPI,
    alertErrorMsg: "리뷰어 프로젝트 참여내역을 불러오지 못했습니다.",
    loading: true
});

export function* reportRecruitSaga() {
    yield takeLatest(GET_RECRUITMENT_LIST, getRecruitmentListSaga);
    yield takeLeading(CHANGE_CAMPAIGN_REQUEST_MARK, changeCampaignRequestMarkSaga);
    yield takeLatest(GET_REVIEWER_RECRUITMENT_PROJECTS, getReviewerRecruitmentProjectsSaga);
}

const initialState = {
    recruitNum: 0,
    chosenNum: 0,
    blockNum: 0,
    status: "recruit",
    viewType: "table",
    reviewerList: [],
    filteredReviewerList: [],
    modal: {
        status: false,
        name: null,
        data: null
    },
    filter: {
        isFiltered: false,
        mark: 0,
        age: "all",
        gender: "all",
        region: "all",
        message: "all"
    },
    excludeMyBrand: false,
    reviewerRecruitmentProjects: {
        count: 0,
        list: []
    }
};

const reportRecruit = handleActions(
    {
        [GET_RECRUITMENT_LIST]: (state, { payload }) => ({
            ...state,
            status: payload.status,
            viewType: payload.viewType || state.viewType,
            reviewerList: payload.reset ? initialState.reviewerList : state.reviewerList,
            filteredReviewerList:
                payload.status !== state.status
                    ? initialState.filteredReviewerList
                    : state.filteredReviewerList,
            filter: payload.status !== state.status ? initialState.filter : state.filter
        }),
        [GET_RECRUITMENT_LIST_SUCCESS]: (state, { payload }) => ({
            ...state,
            filteredReviewerList: [],
            recruitNum: payload.recruitNum,
            chosenNum: payload.chosenNum,
            blockNum: payload.blockNum,
            reviewerList:
                payload.list?.length > 0
                    ? payload.list.map(reviewer => ({ ...reviewer, isChecked: false }))
                    : []
        }),
        [HANDLE_RECRUIT_MODALS]: (state, { payload }) => ({
            ...state,
            modal: {
                status: payload.status,
                name: payload.name,
                data: payload.data
            }
        }),
        [HANDLE_FILTER]: (state, { payload }) => ({
            ...state,
            filter: {
                ...state.filter,
                ...payload
            }
        }),
        [HANDEL_FILTERED_LIST]: (state, { payload }) => ({
            ...state,
            filteredReviewerList: payload
        }),
        [HANDEL_EXCLUDE_MY_BRAND]: (state, { payload }) => ({
            ...state,
            excludeMyBrand: payload
        }),
        [CHANGE_CAMPAIGN_REQUEST_MARK]: (state, { payload }) =>
            produce(state, draft => {
                if (state.filteredReviewerList) {
                    draft.filteredReviewerList.forEach(item => {
                        if (item.id === payload.id) {
                            item.mark = payload.mark;
                        }
                    });
                }

                draft.reviewerList.forEach(item => {
                    if (item.id === payload.id) {
                        item.mark = payload.mark;
                    }
                });
            }),
        [GET_REVIEWER_RECRUITMENT_PROJECTS]: (state, { payload }) =>
            produce(state, draft => {
                draft.reviewerRecruitmentProjects = initialState.reviewerRecruitmentProjects;

                if (state.filteredReviewerList) {
                    draft.filteredReviewerList.forEach(item => {
                        if (item.ownerId === payload.reviewerId) {
                            if (payload.brandId) {
                                item.isReadBrandActivity = true;
                            } else {
                                item.isReadRecentActivity = true;
                            }
                        }
                    });
                }

                draft.reviewerList.forEach(item => {
                    if (item.ownerId === payload.reviewerId) {
                        if (payload.brandId) {
                            item.isReadBrandActivity = true;
                        } else {
                            item.isReadRecentActivity = true;
                        }
                    }
                });
            }),
        [GET_REVIEWER_RECRUITMENT_PROJECTS_SUCCESS]: (state, { payload }) => ({
            ...state,
            reviewerRecruitmentProjects: {
                count: payload.counts,
                list: payload.reviews
            }
        }),
        [CHANGE_TO_BTN_SEEN]: (state, { payload }) =>
            produce(state, draft => {
                if (state.filteredReviewerList) {
                    draft.filteredReviewerList.forEach(item => {
                        if (item.ownerId === payload.reviewerId) {
                            if (payload.type === "portfolio") {
                                item.isReadPortfolio = true;
                            } else {
                                item.isReadActivityChannel = true;
                            }
                        }
                    });
                }

                draft.reviewerList.forEach(item => {
                    if (item.ownerId === payload.reviewerId) {
                        if (payload.type === "portfolio") {
                            item.isReadPortfolio = true;
                        } else {
                            item.isReadActivityChannel = true;
                        }
                    }
                });
            }),
        [UPDATE_MEMO_STATUS]: (state, { payload }) =>
            produce(state, draft => {
                draft.reviewerList = state.reviewerList.map(item => {
                    if (item.id === payload.requestId) {
                        return { ...item, memo: payload.status };
                    } else {
                        return item;
                    }
                });
            })
    },
    initialState
);

export default reportRecruit;
