import produce from "immer";
import { createAction, handleActions } from "redux-actions";
import { createRequestActionTypes } from "lib/createRequestSaga";

const RESET_SELECTED_REQUEST = "reportSubmit/RESET_SELECTED_REQUEST";
const SELECT_REQUEST = "reportSubmit/SELECT_REQUEST";
const SELECT_REQUEST_BY_INDEX = "reportSubmit/SELECT_REQUEST_BY_INDEX";
const UPDATE_MEMO_STATUS = "reportSubmit/UPDATE_MEMO_STATUS";

export const [
    GET_REVIEWER_LIST,
    GET_REVIEWER_LIST_SUCCESS,
    GET_REVIEWER_LIST_FAILURE
] = createRequestActionTypes("reportSubmit/GET_REVIEWER_LIST");
export const [
    GET_CONTENT_FILE_LIST,
    GET_CONTENT_FILE_LIST_SUCCESS,
    GET_CONTENT_FILE_LIST_FAILURE
] = createRequestActionTypes("reportSubmit/GET_CONTENT_FILE_LIST");

export const [ISSUE_POINTS, ISSUE_POINTS_SUCCESS, ISSUE_POINTS_FAILURE] = createRequestActionTypes(
    "reportSubmit/ISSUE_POINTS"
);

export const [
    CHANGE_SUBMIT_END_DAY_OF_REQUEST,
    CHANGE_SUBMIT_END_DAY_OF_REQUEST_SUCCESS,
    CHANGE_SUBMIT_END_DAY_OF_REQUEST_FAILURE
] = createRequestActionTypes("reportSubmit/CHANGE_SUBMIT_END_DAY_OF_REQUEST");

export const [SET_OPINIONS, SET_OPINIONS_SUCCESS, SET_OPINIONS_FAILURE] = createRequestActionTypes(
    "reportSubmit/SET_OPINIONS"
);

export const [
    DOWNLOAD_ORIGIN_FILE,
    DOWNLOAD_ORIGIN_FILE_SUCCESS,
    DOWNLOAD_ORIGIN_FILE_FAILURE
] = createRequestActionTypes("reportSubmit/DOWNLOAD_ORIGIN_FILE");
const SET_DOWNLOAD_ORIGIN_FILE = "reportSubmit/SET_DOWNLOAD_ORIGIN_FILE";

const SET_APPLY_CANCEL_STATUS = "reportSubmit/SET_APPLY_CANCEL_STATUS";

export const [
    UPDATE_DELIVERY_INFO,
    UPDATE_DELIVERY_INFO_SUCCESS,
    UPDATE_DELIVERY_INFO_FAILURE
] = createRequestActionTypes("reportSubmit/UPDATE_DELIVERY_INFO");

export const [
    GET_PROJECT_EVALUATE_ITEMS,
    GET_PROJECT_EVALUATE_ITEMS_SUCCESS,
    GET_PROJECT_EVALUATE_ITEMS_FAILURE
] = createRequestActionTypes("reportSubmit/GET_PROJECT_EVALUATE_ITEMS");
const RESET_PROJECT_EVALUATE_ITEMS = "reportSubmit/RESET_PROJECT_EVALUATE_ITEMS";

export const resetSelectedRequest = createAction(RESET_SELECTED_REQUEST);
export const selectRequest = createAction(SELECT_REQUEST);
export const selectRequestByIndex = createAction(SELECT_REQUEST_BY_INDEX);

export const getReviewerList = createAction(GET_REVIEWER_LIST, ({ projectId, status, reset }) => ({
    projectId,
    status,
    reset
}));
export const getContentFileList = createAction(GET_CONTENT_FILE_LIST, requestId => requestId);

export const issuePoints = createAction(ISSUE_POINTS, ({ projectId, idArray }) => ({
    projectId,
    idArray
}));

export const changeSubmitEndDayOfRequest = createAction(
    CHANGE_SUBMIT_END_DAY_OF_REQUEST,
    ({ requestId, newSubmitEndDay }) => ({ requestId, newSubmitEndDay })
);

export const setOpinions = createAction(SET_OPINIONS, ({ requestId, category, message }) => ({
    requestId,
    category,
    message
}));

export const setApplyCancelStatus = createAction(SET_APPLY_CANCEL_STATUS);

export const updateDeliveryInfo = createAction(UPDATE_DELIVERY_INFO);
export const updateMemoStatus = createAction(UPDATE_MEMO_STATUS);

export const getProjectEvaluateItems = createAction(
    GET_PROJECT_EVALUATE_ITEMS,
    projectId => projectId
);
export const resetProjectEvaluateItems = createAction(RESET_PROJECT_EVALUATE_ITEMS);
export const downloadOriginFile = createAction(DOWNLOAD_ORIGIN_FILE);
export const setDownloadOriginFile = createAction(SET_DOWNLOAD_ORIGIN_FILE);

const initialState = {
    counts: {
        all: 0,
        applyCancel: 0,
        canceled: 0,
        submit: 0,
        unsubmit: 0
    },
    reviewerList: [],
    evaluateReport: {},
    selectedRequest: {
        type: "",
        data: {}
    },
    originFile: null
};
const reportSubmit = handleActions(
    {
        [RESET_SELECTED_REQUEST]: state =>
            produce(state, draft => {
                draft.selectedRequest = initialState.selectedRequest;
            }),
        [SELECT_REQUEST]: (state, { payload }) =>
            produce(state, draft => {
                // draft.selectedRequest.index = payload.index;
                draft.selectedRequest.type = payload.type;
                draft.selectedRequest.data = payload.data;
            }),
        [SELECT_REQUEST_BY_INDEX]: (state, { payload: index }) =>
            produce(state, draft => {
                const request = state.reviewerList[index];
                if (request) {
                    draft.selectedRequest = {
                        ...state.selectedRequest,
                        index,
                        data: request
                    };
                }
            }),
        [GET_REVIEWER_LIST]: (state, { payload }) =>
            produce(state, draft => {
                if (payload.reset) {
                    draft.reviewerList = initialState.reviewerList;
                }
            }),
        [GET_REVIEWER_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.counts = payload.count;
                draft.reviewerList = payload.list || [];
            }),
        [GET_CONTENT_FILE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.selectedRequest.data.contentFile = payload;
            }),
        [CHANGE_SUBMIT_END_DAY_OF_REQUEST_SUCCESS]: (state, { prev }) =>
            produce(state, draft => {
                draft.reviewerList = state.reviewerList.map(request => {
                    if (request.requestId === prev.requestId) {
                        return { ...request, submitEndAt: prev.newSubmitEndDay };
                    }
                    return request;
                });
            }),
        [ISSUE_POINTS_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.reviewerList = state.reviewerList.map(request => {
                    if (payload.requestIdList.indexOf(request.requestId) > -1) {
                        return { ...request, pointIssuedStatus: "issued" };
                    }
                    return request;
                });
                if (payload.requestIdList[0] === state.selectedRequest.data.requestId) {
                    draft.selectedRequest.data.pointIssuedStatus = "issued";
                }
            }),
        [SET_OPINIONS_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.reviewerList = state.reviewerList.map(request => {
                    if (request.requestId === payload.requestId) {
                        return { ...request, opinions: payload.opinions };
                    }
                    return request;
                });
                draft.selectedRequest.data = {
                    ...state.selectedRequest.data,
                    opinions: payload.opinions
                };
            }),
        [SET_APPLY_CANCEL_STATUS]: (state, { payload }) =>
            produce(state, draft => {
                draft.reviewerList = state.reviewerList.map(request => {
                    if (request.requestId === payload.requestId) {
                        return { ...request, status: payload.action };
                    }
                    return request;
                });
            }),
        [RESET_PROJECT_EVALUATE_ITEMS]: state => ({
            ...state,
            evaluateReport: {}
        }),
        [UPDATE_DELIVERY_INFO_SUCCESS]: (state, { prev }) =>
            produce(state, draft => {
                draft.reviewerList = state.reviewerList.map(request => {
                    const { requestId, deliveryCompany, invoiceNumber } = prev;
                    if (request.requestId === requestId) {
                        return { ...request, requestId, deliveryCompany, invoiceNumber };
                    }
                    return request;
                });
            }),
        [UPDATE_MEMO_STATUS]: (state, { payload }) =>
            produce(state, draft => {
                draft.reviewerList = state.reviewerList.map(item => {
                    if (item.requestId === payload.requestId) {
                        return { ...item, memo: payload.status };
                    } else {
                        return item;
                    }
                });
            }),
        [GET_PROJECT_EVALUATE_ITEMS_SUCCESS]: (state, { payload }) => ({
            ...state,
            evaluateReport: payload
        }),
        [GET_PROJECT_EVALUATE_ITEMS_FAILURE]: state => ({
            ...state,
            evaluateReport: {}
        }),
        [DOWNLOAD_ORIGIN_FILE_SUCCESS]: (state, { payload }) => ({
            ...state,
            originFile: payload.zipFileUrl
        }),
        [SET_DOWNLOAD_ORIGIN_FILE]: (state, { payload }) => ({
            ...state,
            originFile: payload
        })
    },
    initialState
);

export default reportSubmit;
