import React from "react";
import cx from "classnames";
import moment from "moment";
import { Profile, IconFont } from "reviewshare-common-component";
import FormattedMessage from "components/common/FormattedMessage";
import MessageContent from "components/messenger/main/MessageContent";
import MessageAlarmContent from "components/messenger/main/MessageAlarmContent";

import "./MessageItem.scss";

const USER_NONE_TEXT = "알 수 없음";

const MessageItem = ({ userType, data, receiver, counterpart, isNextItem, hasNextItem }) => (
    <div
        className={cx("messenger-message-item", data.type, {
            "is-next": isNextItem,
            "has-next": hasNextItem
        })}
    >
        {userType === "Seller" && data.type === "mine" && (
            <p className="user-name">
                <IconFont icon="icon_message" />
                <span className="user-name-text">{receiver.brandName || USER_NONE_TEXT}</span>
            </p>
        )}
        <div className="message-content">
            {userType === "Seller" && data.type === "other" && (
                <Profile className="size-xxs m-size-s" img={counterpart.profileImg} noBand />
            )}
            <div className={cx("message-speech-bubble", data.contents.type)}>
                {(() => {
                    switch (data.contents.type) {
                        case "img":
                        case "file":
                        case "text":
                            return <MessageContent data={data} />;
                        case "gift":
                            return <MessageAlarmContent data={data} />;
                        default:
                            return null;
                    }
                })()}
            </div>
            <div className="message-sub-info">
                {data.contents.fileName && (
                    <div className="download-area">
                        <a
                            className="rs-button theme4 size-s m-size-xs download-btn"
                            href={data.contents.value}
                            target="_blank"
                            download
                            rel="noopener noreferrer nosublink"
                        >
                            <IconFont icon="icon_download">
                                <FormattedMessage id="action.download.file" />
                            </IconFont>
                        </a>
                    </div>
                )}
                <span className="send-at">{moment(data.createdAt).format("HH:mm")}</span>
            </div>
        </div>
    </div>
);
export default MessageItem;
