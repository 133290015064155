import { createAction, handleActions } from "redux-actions";
import { takeLeading } from "redux-saga/effects";
import * as authAPI from "lib/api/auth";
import produce from "immer";
import createRequestSaga, {
    createRequestSagaBase,
    createRequestActionTypes,
    createRequestSagaWithAlert
} from "lib/createRequestSaga";

// ******
// TYPE
// ******
export const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes("auth/LOGIN");

export const [
    GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE,
    GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE_SUCCESS,
    GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE_FAILURE
] = createRequestActionTypes("auth/GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE");

export const [
    GET_MY_PROFILE,
    GET_MY_PROFILE_SUCCESS,
    GET_MY_PROFILE_FAILURE
] = createRequestActionTypes("auth/GET_MY_PROFILE");

const [
    CHECK_PHONENUMBER,
    CHECK_PHONENUMBER_SUCCESS,
    CHECK_PHONENUMBER_FAILURE
] = createRequestActionTypes("auth/CHECK_PHONENUMBER");
const [
    CERTIFICATE_PHONE,
    CERTIFICATE_PHONE_SUCCESS,
    CERTIFICATE_PHONE_FAILURE
] = createRequestActionTypes("auth/CERTIFICATE_PHONE");
const [
    CHECK_CERTIFICATION,
    CHECK_CERTIFICATION_SUCCESS,
    CHECK_CERTIFICATION_FAILURE
] = createRequestActionTypes("auth/CHECK_CERTIFICATION");
const [
    GET_NAVER_PROFILE,
    GET_NAVER_PROFILE_SUCCESS,
    GET_NAVER_PROFILE_FAILURE
] = createRequestActionTypes("auth/GET_NAVER_PROFILE");
const [REGISTER, REGISTER_SUCCESS, REGISTER_FAILURE] = createRequestActionTypes("auth/REGISTER");

export const [
    UPDATE_USER_INFO,
    UPDATE_USER_INFO_SUCCESS,
    UPDATE_USER_INFO_FAILURE
] = createRequestActionTypes("auth/UPDATE_USER_INFO");

export const [EDIT_EMAIL, EDIT_EMAIL_SUCCESS, EDIT_EMAIL_FAILURE] = createRequestActionTypes(
    "auth/EDIT_EMAIL"
);

export const [
    EDIT_MY_PROFILE,
    EDIT_MY_PROFILE_SUCCESS,
    EDIT_MY_PROFILE_FAILURE
] = createRequestActionTypes("auth/EDIT_MY_PROFILE");

export const [
    EDIT_USER_INFO,
    EDIT_USER_INFO_SUCCESS,
    EDIT_USER_INFO_FAILURE
] = createRequestActionTypes("auth/EDIT_USER_INFO");

const [
    COMEBINE_ACCOUNTS,
    COMEBINE_ACCOUNTS_SUCCESS
    // COMEBINE_ACCOUNTS_FAILURE
] = createRequestActionTypes("auth/COMEBINE_ACCOUNTS");

export const SET_USER_INFO = "auth/SET_USER_INFO";
const SET_CHECK_PHONENUMBER = "auth/SET_CHECK_PHONENUMBER";
const SET_UNCERTIFIED = "auth/SET_UNCERTIFIED";
const HANDLE_LOGIN_MODAL = "auth/HANDLE_LOGIN_MODAL";
const SET_VISITOR = "auth/SET_VISITOR";
export const SET_UNSUBMIT_COUNTS = "auth/SET_UNSUBMIT_COUNTS";

// ******
// ACTION
// ******
export const login = createAction(LOGIN, userObj => userObj);
export const getUserInfoAsTokenInLocalStorage = createAction(
    GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE
);
export const getMyProfile = createAction(GET_MY_PROFILE);
export const checkPhoneNumber = createAction(CHECK_PHONENUMBER, (phoneNumber, category) => ({
    phoneNumber,
    category
}));
export const certificatePhone = createAction(CERTIFICATE_PHONE, phoneNumber => phoneNumber);
export const checkCertification = createAction(
    CHECK_CERTIFICATION,
    (phoneNumber, certNumber, registerCategoryType) => ({
        phoneNumber,
        certNumber,
        registerCategoryType
    })
);
export const setCheckPhoneNumber = createAction(SET_CHECK_PHONENUMBER);
export const setUncertified = createAction(SET_UNCERTIFIED);
export const register = createAction(REGISTER, userInfo => userInfo);
export const getNaverProfile = createAction(GET_NAVER_PROFILE, obj => obj);
export const handleLoginModal = createAction(HANDLE_LOGIN_MODAL);
export const updateUserInfo = createAction(UPDATE_USER_INFO, obj => obj);
export const editEmail = createAction(EDIT_EMAIL);
export const editMyProfile = createAction(EDIT_MY_PROFILE);
export const editUserInfo = createAction(EDIT_USER_INFO, obj => obj);
export const combineAccounts = createAction(COMEBINE_ACCOUNTS, obj => obj);
export const setVisitor = createAction(SET_VISITOR);
export const setUnsubmitCounts = createAction(SET_UNSUBMIT_COUNTS);

// ****
// SAGA
// ****
const checkPhoneNumberSaga = createRequestSaga(CHECK_PHONENUMBER, authAPI.checkPhoneNumber);
const certificatePhoneSaga = createRequestSaga(CERTIFICATE_PHONE, authAPI.certificatePhone);
const checkCertificationSaga = createRequestSaga(CHECK_CERTIFICATION, authAPI.checkCertification);
const registerSaga = createRequestSagaBase({ type: REGISTER, api: authAPI.register });
const getNaverProfileSaga = createRequestSaga(GET_NAVER_PROFILE, authAPI.getNaverProfile);
const combineAccountsSaga = createRequestSagaWithAlert(
    COMEBINE_ACCOUNTS,
    authAPI.combineAccounts,
    "연동하기에 실패하였습니다.",
    true
);

export function* authSaga() {
    yield takeLeading(CHECK_PHONENUMBER, checkPhoneNumberSaga);
    yield takeLeading(CERTIFICATE_PHONE, certificatePhoneSaga);
    yield takeLeading(CHECK_CERTIFICATION, checkCertificationSaga);
    yield takeLeading(GET_NAVER_PROFILE, getNaverProfileSaga);
    yield takeLeading(REGISTER, registerSaga);
    yield takeLeading(COMEBINE_ACCOUNTS, combineAccountsSaga);
}

const initialState = {
    userType: "None", // ["Visitor", "Normal", "Seller"]
    loginModalInfo: {
        status: false,
        type: null
    },
    userInfo: {
        user: null,
        token: null,
        wallet: null,
        manager: {}
    },
    profile: {},
    userId: null,
    userInfoError: null,
    signInError: null,
    naverProfile: null,
    naverProfileError: null,
    isAlreadyUser: false,
    isRegister: false,
    authError: null,
    checkPhone: {
        phoneNumber: false,
        phoneNumberError: false,
        sendCertNumber: false,
        sendCertNumberError: false,
        isCertified: false,
        isCertifiedError: false
    },
    unsubmitCounts: {}
};

const auth = handleActions(
    {
        [SET_VISITOR]: state => ({
            ...state,
            userType: "Visitor"
        }),
        [LOGIN]: state => ({
            ...state,
            userInfoError: null
        }),
        [LOGIN_SUCCESS]: (state, { payload: userInfo }) => ({
            ...state,
            userType: userInfo.user.category,
            userInfo,
            userId: userInfo.user._id
        }),
        [LOGIN_FAILURE]: (state, { payload: error }) => ({
            ...state,
            userInfoError: error
        }),
        [REGISTER_SUCCESS]: (state, { payload: userInfo }) => ({
            ...state,
            userInfo,
            naverProfile: null,
            userId: userInfo.user._id,
            isRegister: true
        }),
        [REGISTER_FAILURE]: (state, { payload: error }) => ({
            ...state,
            signInError: error
        }),
        [EDIT_USER_INFO_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                if (prev) {
                    Object.keys(prev).forEach(key => {
                        draft.userInfo.user[key] = payload.user[key];
                    });
                }
            }),
        [EDIT_EMAIL_SUCCESS]: (state, { prev }) =>
            produce(state, draft => {
                draft.userInfo.user.email = prev.email;
            }),
        [GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE_SUCCESS]: (state, { payload: auth }) =>
            produce(state, draft => {
                draft.userType = auth.user.category;
                draft.isShowLoginModal = false;
                draft.userInfo.user = auth.user;
                draft.userId = auth.user._id;
                draft.userInfo.manager = auth.manager;
            }),
        [GET_USER_INFO_AS_TOKEN_IN_LOCAL_STORAGE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            authError: error
        }),
        [GET_MY_PROFILE_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.profile = payload;
            }),
        [SET_CHECK_PHONENUMBER]: state => ({
            ...state,
            checkPhone: {
                ...state.checkPhone,
                phoneNumber: true,
                phoneNumberError: false
            }
        }),
        [CHECK_PHONENUMBER_SUCCESS]: (state, { payload: data }) => {
            if (data.validate) {
                return {
                    ...state,
                    checkPhone: {
                        ...state.checkPhone,
                        phoneNumber: data.validate,
                        phoneNumberError: false
                    }
                };
            }
            return {
                ...state,
                checkPhone: {
                    ...state.checkPhone,
                    phoneNumber: false,
                    phoneNumberError: true
                }
            };
        },
        [CHECK_PHONENUMBER_FAILURE]: (state, { payload: error }) => ({
            ...state,
            checkPhone: {
                ...state.checkPhone,
                phoneNumber: false,
                phoneNumberError: error
            }
        }),
        [CERTIFICATE_PHONE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            checkPhone: {
                phoneNumber: false,
                phoneNumberError: false,
                sendCertNumber: data,
                sendCertNumberError: false,
                isCertified: false,
                isCertifiedError: false
            }
        }),
        [CERTIFICATE_PHONE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            checkPhone: {
                ...state.checkPhone,
                sendCertNumberError: error
            }
        }),
        [CHECK_CERTIFICATION_SUCCESS]: (state, { payload: data }) => {
            if (data) {
                return {
                    ...state,
                    checkPhone: {
                        ...state.checkPhone,
                        isCertifiedError: false,
                        ...(data.user
                            ? { isCertified: true, alreadyUserData: data.user }
                            : { isCertified: data })
                    }
                };
            }
            return {
                ...state,
                checkPhone: {
                    ...state.checkPhone,
                    isCertified: false,
                    isCertifiedError: true
                }
            };
        },
        [CHECK_CERTIFICATION_FAILURE]: (state, { payload: error }) => ({
            ...state,
            checkPhone: {
                ...state.checkPhone,
                isCertified: false,
                isCertifiedError: error
            }
        }),
        [SET_UNCERTIFIED]: state => ({
            ...state,
            checkPhone: {
                phoneNumber: false,
                phoneNumberError: false,
                sendCertNumber: false,
                sendCertNumberError: false,
                isCertified: false,
                isCertifiedError: false
            }
        }),
        [GET_NAVER_PROFILE_SUCCESS]: (state, { payload: data }) => {
            if (data.naverProfile) {
                return {
                    ...state,
                    naverProfile: data.naverProfile.response
                };
            }
            return {
                ...state,
                isAlreadyUser: true,
                userInfo: data,
                userId: data.user._id
            };
        },
        [GET_NAVER_PROFILE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            naverProfileError: error
        }),
        [HANDLE_LOGIN_MODAL]: (state, { payload }) => ({
            ...state,
            loginModalInfo: { status: payload.status, type: payload.type || null }
        }),
        [SET_USER_INFO]: (state, { payload }) =>
            produce(state, draft => {
                draft.userInfo.user = { ...state.userInfo.user, ...payload };
            }),
        [COMEBINE_ACCOUNTS_SUCCESS]: (state, { payload: userInfo }) => ({
            ...state,
            userInfo,
            naverProfile: null,
            userId: userInfo.user._id,
            isAlreadyUser: true
        }),
        [SET_UNSUBMIT_COUNTS]: (state, { payload }) => ({
            ...state,
            unsubmitCounts: payload || initialState.unsubmitCounts
        })
    },
    initialState
);

export default auth;
