import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { createRequestActionTypes } from "lib/createRequestSaga";

export const [
    GET_BANNER_LIST,
    GET_BANNER_LIST_SUCCESS,
    GET_BANNER_LIST_FAILURE
] = createRequestActionTypes("banner/GET_BANNER_LIST");

export const getBannerList = createAction(GET_BANNER_LIST);

const initialState = {
    mainTop: [],
    mainBottom: [],
    reviewerMypage: [],
    portfolioList: [],
    bizIntro: []
};

const banner = handleActions(
    {
        [GET_BANNER_LIST_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                draft[prev.position] = payload;
            })
    },
    initialState
);

export default banner;
