import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { chatSocket } from "lib/api/socket";
import { useUserInfo, useUserType } from "lib/customHooks/useAuth";
import { useMessengerAction } from "lib/customHooks/useMessenger";
import { handleLoginModal } from "stores/modules/auth";
import { getAllBrandList } from "stores/modules/brand/action";
import { handleMessengerOpenStatus, handleUnreadValue } from "stores/modules/messenger/action";
import MessengerTemplate from "components/base/templates/MessengerTemplate";
import MessengerSideTemplate from "components/base/templates/MessengerSideTemplate";
import MessengerMainTemplate from "components/base/templates/MessengerMainTemplate";
import LauncherBtn from "components/messenger/common/LauncherBtn";

const MessengerContainer = ({ history }) => {
    const dispatch = useDispatch();
    const { closeMessenger } = useMessengerAction();

    const user = useUserInfo();
    const userType = useUserType();
    const isOpen = useSelector(({ messenger }) => messenger.isOpen);
    const hasUnreadMessage = useSelector(({ messenger }) => messenger.hasUnreadMessage);

    const onOpenMessenger = () => {
        if (["Normal", "Seller"].indexOf(userType) > -1) {
            dispatch(handleMessengerOpenStatus(true));
        } else {
            dispatch(handleLoginModal({ status: true }));
        }
    };

    const onCloseMessenger = () => {
        closeMessenger();
    };

    useEffect(() => {
        if (user._id) {
            chatSocket.emit("login", user._id);
            chatSocket.on("connect_error", err => {
                console.log(err.message);
                chatSocket.connect();
            });
        }
    }, [user._id]);

    useEffect(() => {
        if (isOpen) {
            chatSocket.emit("position_update", "messageModalOn");

            if (userType === "Seller") {
                dispatch(getAllBrandList());
            }

            history.listen(() => onCloseMessenger());
        } else {
            chatSocket.emit("position_update", "main");
        }
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        chatSocket.on("check_unread_message", value => {
            dispatch(handleUnreadValue(value));
        });
        // eslint-disable-next-line
    }, [isOpen]);

    return (
        <>
            {["Normal", "Seller"].indexOf(userType) > -1 && (
                <MessengerTemplate status={isOpen} closeMessenger={onCloseMessenger}>
                    <MessengerSideTemplate />
                    <MessengerMainTemplate />
                </MessengerTemplate>
            )}
            {!isOpen && (
                <LauncherBtn hasUnreadMessage={hasUnreadMessage} openMessenger={onOpenMessenger} />
            )}
        </>
    );
};

export default withRouter(MessengerContainer);
