import { useDispatch, useSelector } from "react-redux";
import { getMyWallet } from "stores/modules/wallet";

// state
export const useWalletState = value => useSelector(({ wallet }) => wallet[value]);
export const useTotalPoints = () => useSelector(({ wallet }) => wallet.totalPoints);

// action
export const useWalletAction = () => {
    const dispatch = useDispatch();

    const onGetMyWallet = () => {
        dispatch(getMyWallet());
    };

    return { getMyWallet: onGetMyWallet };
};
