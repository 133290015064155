export const convertGrade = grade => {
    switch (grade) {
        case "b":
            return "item.tier.type.b";
        case "c":
            return "item.tier.type.c";
        case "d":
            return "item.tier.type.d";
        case "z":
            return "item.tier.type.z";
        default:
            return "item.tier.type.a";
    }
};

export const convertGradeAtNum = gradeText => {
    switch (gradeText) {
        case "z":
            return "0";
        case "b":
            return "2";
        case "c":
            return "3";
        case "d":
            return "4";
        default:
            return "1";
    }
};

export const convertScoreType = type => {
    switch (type) {
        case "register":
            return "item.score.type.register";
        case "login":
            return "item.score.type.login";
        case "correctSubmit":
            return "item.score.type.submit.project";
        case "evaluate":
            return "item.score.type.evaluate";
        case "recommend":
            return "item.score.type.recommend";
        case "noneRecommend":
            return "item.score.type.notRecommend";
        case "cancel":
            return "item.score.type.cancel.project";
        case "unsubmit":
            return "item.score.type.unsubmit.project";
        case "notFollow":
            return "item.score.type.notFollow";
        case "noShow":
            return "item.score.type.noShow";
        case "cheat":
            return "item.score.type.cheat";

        default:
            return "-";
    }
};

export const convertReliability = reliability => {
    switch (reliability) {
        case "low":
            return "낮음";
        case "high":
            return "높음";

        default:
            return "보통";
    }
};
