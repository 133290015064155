import React from "react";
import { Link } from "react-router-dom";

const CommonLink = ({ to, external, disabled, children, ...rest }) =>
    external ? (
        <a
            href={disabled ? undefined : to}
            rel="nosublink noopener noreferrer"
            target="_blank"
            disabled={disabled}
            {...rest}
        >
            {children}
        </a>
    ) : disabled ? (
        <a href={disabled ? undefined : to} rel="nosublink" disabled={disabled} {...rest}>
            {children}
        </a>
    ) : typeof to === "object" || (to?.length > 0 && to.charAt(0) === "/") ? (
        <Link to={to} disabled={disabled} rel="nosublink" {...rest}>
            {children}
        </Link>
    ) : (
        <a href={to} disabled={disabled} rel="nosublink" {...rest}>
            {children}
        </a>
    );

export default CommonLink;
