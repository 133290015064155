import client from "./client";

export const getRelationList = ({ status, options }) =>
    client.get(`/v3/relations/${status}`, {
        params: options
    });

export const getRelationDetail = reviewerId => client.get(`/v3/relations/users/${reviewerId}`);
export const getRecommendFollowList = ({ skip, limit } = {}) =>
    client.get("/v3/relations/following/recommend", { params: { skip, limit } });
export const changeFollowStatus = ({ follow, userIdList }) =>
    client.put("/v3/relations/follow", { follow, userIdList });
export const changeBlockStatus = ({ block, userIdList }) =>
    client.put("/v3/relations/block", { block, userIdList });
