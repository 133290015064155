import React, { Suspense, useEffect, useState } from "react";
import { BIZ_INTRO } from "scheme/spreadsheet";
import { callDirectApi } from "lib/common";
import { changeJsonData } from "utils/spreadsheet";
import { getSpreadSheetValues as getSpreadSheetValuesAPI } from "lib/api/spreadsheet";
import BizIntroHeader from "components/base/BizIntroHeader";
import GlobalFooter from "components/base/GlobalFooter";
import Banner from "components/common/Banner";

import "./BizIntroPageTemplate.scss";

const BizIntroPageTemplate = ({ type, children }) => {
    const [jsonData, setJsonData] = useState({});

    const getJsonData = async () => {
        try {
            const spreadSheet = await callDirectApi(
                getSpreadSheetValuesAPI,
                BIZ_INTRO[process.env.REACT_APP_ENV_NAME],
                true,
                false
            );

            if (spreadSheet && spreadSheet.data.values) {
                const newData = changeJsonData(spreadSheet.data.values);
                setJsonData(newData || {});
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getJsonData();
    }, []);

    return (
        <>
            {type === "self" && <Banner position="bizIntro" />}
            <BizIntroHeader type={type} />
            <BizIntroHeader type={type} fixed />
            <div className="biz-intro-page-template">
                <div className="page-body">
                    <Suspense fallback={null}>
                        {React.cloneElement(children, { jsonData })}
                    </Suspense>
                </div>
            </div>
            <GlobalFooter />
        </>
    );
};

export default BizIntroPageTemplate;
