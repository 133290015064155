import { call, put, takeLeading, takeLatest } from "redux-saga/effects";
import { createRequestSagaBase } from "lib/createRequestSaga";
import * as feedAPI from "lib/api/feed";
import { REVIEWER_SUBMIT_OTHER_REVIEW } from "scheme/alert";
import { returnAlertValue } from "lib/common";
import { openAlert } from "stores/modules/alert";
import { uploadAndProcessFilesToS3Saga } from "lib/createRequestSaga";

import { handleProgressItem } from "stores/modules/progress";
import {
    GET_FEED,
    GET_FEED_LIST,
    GET_MY_FEED_LIST,
    GET_FEED_PICK_LIST,
    GET_FEED_RECOMMEND_KEYWORD_LIST,
    GET_PORTFOLIO_CONTENT_LIST,
    GET_PORTFOLIO_SHORTFORM_LIST,
    GET_BIZ_FEED_LIST,
    SUBMIT_OTHER_FEED,
    SUBMIT_OTHER_FEED_SUCCESS,
    SUBMIT_OTHER_FEED_FAILURE,
    REMOVE_OTHER_FEED,
    HANDLE_HIDE_FEED,
    HANDLE_HIDE_FEED_SUCCESS,
    HANDLE_HIDE_FEED_FAILURE
} from "stores/modules/feed/action";

const getFeedSaga = createRequestSagaBase({
    type: GET_FEED,
    api: feedAPI.getFeed
});

const getFeedListSaga = createRequestSagaBase({
    type: GET_FEED_LIST,
    api: feedAPI.getFeedList,
    progress: true
});

const getMyFeedListSaga = createRequestSagaBase({
    type: GET_MY_FEED_LIST,
    api: feedAPI.getMyFeedList,
    progress: true
});

const getFeedPickListSaga = createRequestSagaBase({
    type: GET_FEED_PICK_LIST,
    api: feedAPI.getFeedPickList,
    progress: true
});

const getFeedRecommendKeywordSaga = createRequestSagaBase({
    type: GET_FEED_RECOMMEND_KEYWORD_LIST,
    api: feedAPI.getFeedRecommendKeywordList
});

const getPortfolioContentListSaga = createRequestSagaBase({
    type: GET_PORTFOLIO_CONTENT_LIST,
    api: feedAPI.getPortfolioFeedList,
    progress: true
});

const getPortfolioShortformListSaga = createRequestSagaBase({
    type: GET_PORTFOLIO_SHORTFORM_LIST,
    api: feedAPI.getPortfolioFeedList,
    progress: true
});

const getBizFeedListSaga = createRequestSagaBase({
    type: GET_BIZ_FEED_LIST,
    api: feedAPI.getBizFeedList,
    progress: true
});

const submitOtherFeedSaga = function*(action) {
    const { fileList } = action.payload;

    try {
        yield put(handleProgressItem({ type: action.type, value: true }));
        let fileKeyList;
        if (fileList?.length > 0) {
            fileKeyList = yield call(
                uploadAndProcessFilesToS3Saga({
                    fileList: fileList.map(fileItem => ({
                        ...fileItem,
                        name: `${fileItem.name}.${fileItem.mimeType}`,
                        mimeType: fileItem.type
                    })),
                    uploadAPI: feedAPI.uploadFiles
                })
            );
        }

        const { data } = yield call(feedAPI.submitOtherFeed, {
            ...action.payload,
            fileList: fileKeyList || undefined
        });
        yield put({
            type: SUBMIT_OTHER_FEED_SUCCESS,
            payload: data,
            prev: action.payload
        });
    } catch (e) {
        const { message } = returnAlertValue({
            message: e.response.data.message,
            list: REVIEWER_SUBMIT_OTHER_REVIEW
        });
        yield put(openAlert({ text: message || "message.review.other.submit.fail" }));
        yield put({
            type: SUBMIT_OTHER_FEED_FAILURE,
            payload: { error: e.response }
        });
    } finally {
        yield put(handleProgressItem({ type: action.type, value: false }));
    }
};

const removeOtherFeedSaga = createRequestSagaBase({
    type: REMOVE_OTHER_FEED,
    api: feedAPI.removeOtherFeed,
    alertSuccessMsg: "콘텐츠가 삭제되었어요.",
    alertErrorMsg: "message.review.other.delete.fail"
});

const handleHideFeedSaga = function*(action) {
    try {
        const { data } = yield call(feedAPI.handleHideFeed, action.payload);
        yield put({
            type: HANDLE_HIDE_FEED_SUCCESS,
            payload: data,
            prev: action.payload
        });
        if (action.payload?.showAlert) {
            yield put(
                openAlert({
                    text: action.payload?.isHide
                        ? "콘텐츠가 비공개 처리되었어요.\n[내 활동 내역]-[내 콘텐츠]에서 확인 가능해요."
                        : "콘텐츠 비공개가 해제되었어요."
                })
            );
        }
    } catch (e) {
        yield put(openAlert({ text: "비공개 상태 변경에 실패하였습니다." }));
        yield put({
            type: HANDLE_HIDE_FEED_FAILURE,
            payload: { error: e.response }
        });
    }
};

export function* feedSaga() {
    yield takeLatest(GET_FEED, getFeedSaga);
    yield takeLatest(GET_FEED_LIST, getFeedListSaga);
    yield takeLatest(GET_MY_FEED_LIST, getMyFeedListSaga);
    yield takeLatest(GET_FEED_PICK_LIST, getFeedPickListSaga);
    yield takeLatest(GET_FEED_RECOMMEND_KEYWORD_LIST, getFeedRecommendKeywordSaga);
    yield takeLatest(GET_PORTFOLIO_CONTENT_LIST, getPortfolioContentListSaga);
    yield takeLatest(GET_PORTFOLIO_SHORTFORM_LIST, getPortfolioShortformListSaga);
    yield takeLatest(GET_BIZ_FEED_LIST, getBizFeedListSaga);
    yield takeLeading(SUBMIT_OTHER_FEED, submitOtherFeedSaga);
    yield takeLeading(REMOVE_OTHER_FEED, removeOtherFeedSaga);
    yield takeLeading(HANDLE_HIDE_FEED, handleHideFeedSaga);
}
