import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useUserType } from "lib/customHooks/useAuth";
import { useAllBrand } from "lib/customHooks/useBrand";
import { useMessengerState, useMessengerAction } from "lib/customHooks/useMessenger";
import { getAllBrandList } from "stores/modules/brand/action";
import {
    setSearchOptions,
    searchMessageBox,
    getMessageBoxList,
    resetSearchOptions
} from "stores/modules/messenger/action";
import SearchBox from "components/messenger/side/SearchBox";
import { escapeString } from "lib/common";

const SearchMessageBoxContainer = ({ searchStatus, setSearchStatus }) => {
    const dispatch = useDispatch();
    const { closeMessenger } = useMessengerAction();

    const status = useSelector(({ messenger }) => messenger.isOpen);
    const userType = useUserType();
    const allBrands = useAllBrand();
    const searchedMessageBox = useMessengerState("searchedMessageBox");
    const searchOptions = useMessengerState("searchOptions");

    const onCloseMessenger = () => {
        closeMessenger();
    };

    const onSetSearchKeyword = keyword => {
        dispatch(setSearchOptions({ keyword }));
    };

    const onFilterMessageBox = brandId => {
        dispatch(setSearchOptions({ brandId }));
    };

    const onChangePrevStatus = () => {
        if (searchOptions.viewType === "all") {
            setSearchStatus("inactive");
            dispatch(resetSearchOptions());
        } else {
            dispatch(setSearchOptions({ viewType: "all" }));
        }
    };

    useEffect(() => {
        if (userType === "Seller" && status) {
            dispatch(getAllBrandList());
        }
        // eslint-disable-next-line
    }, [userType, status]);

    useEffect(() => {
        dispatch(getMessageBoxList({ brand: searchOptions.brandId }));
        // eslint-disable-next-line
    }, [searchOptions.brandId]);

    useEffect(() => {
        if (searchOptions.keyword) {
            dispatch(
                searchMessageBox({ ...searchOptions, keyword: escapeString(searchOptions.keyword) })
            );
        } else if (searchOptions.skip > 0) {
            dispatch(getMessageBoxList({ brand: searchOptions.brandId, skip: searchOptions.skip }));
        } else {
            dispatch(resetSearchOptions());
        }
        // eslint-disable-next-line
    }, [searchOptions.keyword, searchOptions.viewType, searchOptions.skip]);

    return (
        <SearchBox
            userType={userType}
            searchStatus={searchStatus === "active"}
            setSearchStatus={setSearchStatus}
            searchOptions={searchOptions}
            searchedMessageBox={searchedMessageBox}
            brandList={allBrands.list}
            closeMessenger={onCloseMessenger}
            changePrevStatus={onChangePrevStatus}
            setSearchKeyword={onSetSearchKeyword}
            filterMessageBox={onFilterMessageBox}
        />
    );
};

export default SearchMessageBoxContainer;
