import React, { useState, useMemo, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import { IconFont, Button, AlertModal } from "reviewshare-common-component";
import { useUserType } from "lib/customHooks/useAuth";
import { sendMessage, selectWriteInfo } from "stores/modules/messenger/action";
import { checkImage } from "lib/common";
import { changeFileType } from "utils/file";
import useCheckMobile from "lib/customHooks/useCheckMobile";
import useRelationStatus from "lib/customHooks/useRelationStatus";
import FormattedMessage from "components/common/FormattedMessage";
import AttachedFileList from "components/messenger/main/AttachedFileList";
import MainSideOpenButtons from "components/messenger/main/MainSideOpenButtons";

import "./WriteMessage.scss";

const FILE_LENGTH_LIMIT = 10;
const FILE_SIZE_LIMIT = 1024 * 1024 * 100;
const WriteMessage = () => {
    const dispatch = useDispatch();
    const isMobile = useCheckMobile();
    const fileInput = useRef();
    const userType = useUserType();
    const mainStatus = useSelector(({ messenger }) => messenger.mainStatus);
    const writeInfo = useSelector(({ messenger }) => messenger.writeInfo);
    const selectedMessageBoxId = useSelector(
        ({ messenger }) => messenger.selectedMessageBox.messageBoxId
    );

    const { relationStatus: firstTargetRelationStatus } = useRelationStatus({
        status: writeInfo?.toList?.[0]?.relation?.status,
        userId: writeInfo?.toList?.[0]?.reviewerId
    });

    const [inputText, setInputText] = useState("");
    const [fileItemList, setFileItemList] = useState([]);
    const [alert, setAlert] = useState({
        status: false,
        text: ""
    });

    const onCheckPossibleSend = () => {
        if (mainStatus === "write") {
            if (userType === "Seller") {
                return !!writeInfo.from.brandId && writeInfo.toList.length > 0;
            } else {
                return writeInfo.toList.length > 0;
            }
        }
        if (mainStatus === "messagebox") {
            return writeInfo.isPossibleSend;
        }
        return false;
    };

    const onCheckPossibleWrite = () => {
        if (mainStatus === "write") {
            return true;
        }
        if (mainStatus === "messagebox") {
            if (firstTargetRelationStatus === "block") {
                return false;
            }
            return writeInfo.isPossibleSend;
        }
        return false;
    };

    const possibleSend = useMemo(onCheckPossibleSend, [userType, writeInfo, mainStatus]);
    const possibleWrite = useMemo(onCheckPossibleWrite, [
        writeInfo?.isPossibleSend,
        mainStatus,
        firstTargetRelationStatus
    ]);

    const onResetFileInputValue = () => {
        if (fileInput && fileInput.current) {
            fileInput.current.value = null;
        }
    };

    const onAddFile = file => {
        const reader = new FileReader();

        const fileFormat = changeFileType(file.name);
        const lastDotPosition = file.name.lastIndexOf(".");

        const fileItem = {
            file,
            format: fileFormat,
            name: file.name.substring(0, lastDotPosition)
        };

        const isDuplicateName = fileItemList.find(item => item.name === fileItem.name);

        if (isDuplicateName) {
            let counter = 1;

            while (fileItemList.some(item => item.name === `${fileItem.name}(${counter})`)) {
                counter++;
            }

            fileItem.name = `${fileItem.name}(${counter})`;
            fileItem.file = new File([file], `${fileItem.name}.${fileFormat}`, { type: file.type });
        }

        if (checkImage(file.type)) {
            reader.addEventListener("load", () => {
                setFileItemList(prev => [
                    ...prev,
                    {
                        ...fileItem,
                        preview: reader.result
                    }
                ]);
            });

            reader.readAsDataURL(file);
        } else {
            setFileItemList(prev => [...prev, fileItem]);
        }
    };

    const onSelectFiles = e => {
        if (!e.target.files || !e.target.files.length) {
            return;
        }

        const files = e.target.files;
        let isFail = false;

        for (let index = 0; index < files.length; index++) {
            const file = files[index];

            if (index < FILE_LENGTH_LIMIT - fileItemList.length) {
                if (file.size > FILE_SIZE_LIMIT) {
                    isFail = true;
                } else {
                    onAddFile(file);
                }
            } else {
                isFail = true;
                break;
            }
        }

        onResetFileInputValue();
        if (isFail) {
            setAlert({
                status: true,
                text: "파일은 최대 10개, 100MB까지 첨부가 가능합니다.\n확인 후 다시 시도해 주세요."
            });
        }
    };

    const onRemoveFile = index => {
        const _fileItemList = [...fileItemList];
        _fileItemList.splice(index, 1);
        setFileItemList(_fileItemList);
    };

    const onSendMessage = () => {
        if (!possibleSend || (!fileItemList.length && !inputText)) return;
        setInputText("");
        setFileItemList([]);
        onResetFileInputValue();

        const formData = new FormData();
        if (userType === "Seller") {
            formData.append(
                "reviewerIdList",
                writeInfo.toList.map(item => item.reviewerId).join(",")
            );
            formData.append("brandId", writeInfo.from.brandId);
        } else {
            formData.append("brandIdList", writeInfo.toList.map(item => item.brandId).join(","));
        }
        formData.append("text", inputText);
        if (fileItemList?.length > 0) {
            fileItemList.forEach(item => {
                formData.append("attachments", item.file);
            });
        }
        dispatch(sendMessage(formData));

        if (mainStatus === "message") {
            dispatch(
                selectWriteInfo({
                    from: writeInfo.from,
                    toList: writeInfo.toList,
                    isSendSuccess: true
                })
            );
        }
    };

    useEffect(() => {
        setInputText("");
        setFileItemList([]);
        onResetFileInputValue();
    }, [selectedMessageBoxId]);

    return (
        <>
            <div className="messenger-main-write-message">
                <MainSideOpenButtons possibleSend={possibleSend}>
                    <Button
                        className="theme4 size-s m-size-xs"
                        disabled={!possibleWrite}
                        onClick={() => fileInput.current.click()}
                    >
                        <IconFont icon="icon_attachments" />
                        <FormattedMessage id="action.attach.file" />
                    </Button>
                    <input
                        id="messenger-file-input"
                        type="file"
                        onChange={onSelectFiles}
                        disabled={!possibleWrite}
                        hidden
                        multiple
                        ref={fileInput}
                    />
                </MainSideOpenButtons>
                <div className="input-area">
                    <textarea
                        className={cx({
                            "two-lines": inputText.split("\n").length === 2,
                            "three-lines": inputText.split("\n").length > 2
                        })}
                        value={inputText}
                        onChange={e => {
                            const { value } = e.target;
                            setInputText(value);
                        }}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                if (!isMobile) {
                                    if (!e.ctrlKey && !e.shiftKey) {
                                        e.preventDefault();
                                        onSendMessage();
                                    }
                                }
                            }
                        }}
                        placeholder={
                            !possibleWrite ? "쪽지를 보낼 수 없습니다" : "메시지를 입력하세요"
                        }
                        disabled={!possibleWrite}
                    />
                    <Button
                        className="theme1 size-m web"
                        disabled={!possibleSend || (!fileItemList.length && !inputText)}
                        onClick={onSendMessage}
                    >
                        <FormattedMessage id="action.send" />
                    </Button>
                    <button
                        type="button"
                        className="mobile"
                        disabled={!possibleSend || (!fileItemList.length && !inputText)}
                        onClick={onSendMessage}
                    >
                        <IconFont icon="icon_prop_fill">
                            <FormattedMessage id="action.send" />
                        </IconFont>
                    </button>
                </div>
                <AttachedFileList fileItemList={fileItemList} removeFile={onRemoveFile} />
            </div>
            {alert.status && (
                <AlertModal handleModal={() => setAlert({ status: false, text: "" })}>
                    {alert.text}
                </AlertModal>
            )}
        </>
    );
};
export default WriteMessage;
