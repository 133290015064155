import client from "./client";

export const getMyProfile = () => client.get("/v3/users/me/profile");
export const editMyProfile = formData => client.patch("/v3/users/me/profile", formData);
export const getProfileCateoryList = () => client.get("/v3/users/profile/property");
export const getBrandList = id => client.get(`/users/${id}/enterprises`);

export const getReviewerProjectList = ({ reviewerId, targetData, brandId }) =>
    client.get(
        `/users/${reviewerId}/reviews?data=${targetData}${
            targetData === "brandRunning" || targetData === "brandComplete"
                ? `&enterprise=${brandId}`
                : ""
        }`
    );

export const getBusinessProjectList = () => client.get(`/v2/users/projects`);
export const changeEmail = ({ email }) => client.patch("/v3/users/email", { email });
