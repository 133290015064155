import React from "react";
import {
    ADDITION_RECOMMEND_USE,
    ADDITION_PRIVATE_USE,
    ADDITION_EMERGENCY_USE,
    ADDITION_SECONDARY_USE
} from "scheme/price";
import FormattedMessage from "components/common/FormattedMessage";

const limitDate = new Date();
limitDate.setDate(limitDate.getDate() + 1);

export const PROJECT_DEFAULT_KEYS = [
    "type",
    "recruits",
    "requestStartAt",
    "requestEndAt",
    "submitStartAt",
    "submitEndAt",
    "title",
    "enterprise",

    "photos",
    "itemOptions",
    "writeWarning",
    "guideLine",
    "phoneNumber",
    "contact",
    "contactTime",
    "addition",
    "recruitmentForm",

    "receiptType",

    "campaignId"
];

export const PROJECT_BY_TYPE_KEYS = {
    review: ["category", "sns"],
    content: ["category"],
    shortform: ["category", "sns"]
};

export const PROJECT_BY_CATEGORY_KEYS = {
    Delivery: ["itemName", "itemPrice", "points"],
    Visit: [
        "itemName",
        "itemPrice",
        "points",
        "visitLocation",
        "visitDesc",
        "visitInfo",
        "address",
        "addressDetail",
        "loc"
    ],
    Buy: ["itemName", "itemPrice", "points", "buyInfo"],
    Reporters: ["points"],
    None: ["points"]
};

export const PROJECT_BY_SNS_KEYS = {
    NaverBlog: ["keyword"],
    Instagram: ["hashTags"],
    Youtube: ["keyword"],
    NaverCafe: ["keyword"],
    NaverPost: ["keyword"],
    Tiktok: ["hashTags"],
    Shorts: ["hashTags"],
    Reels: ["hashTags"]
};

export const PROJECT_BY_RECEIPT_TYPE_KEYS = {
    TaxBill: [
        "sellerBusinessName",
        "sellerName",
        "sellerLocation",
        "sellerBusinessStatus",
        "sellerCategory",
        "sellerEmail",
        "sellerBusinessNumber"
    ],
    EvidenceBiz: ["sellerBusinessNumber", "isSaveSellerBusinessNumber"],
    InComeDeduct: ["sellerBusinessNumber", "isSaveSellerBusinessNumber"]
};

// initial data
export const PROJECT_STEP1_INITIAL_DATA = {
    type: "review",
    category: "Delivery",
    sns: "NaverBlog",
    visitLocation: "",
    itemName: "",
    itemPrice: "",
    points: 0,
    recruits: 0,
    requestStartAt: "",
    requestEndAt: "",
    submitStartAt: "",
    submitEndAt: "",
    title: "",
    enterprise: ""
};

export const PROJECT_STEP1_INITIAL_DATE = {
    requestStartAt: "",
    requestEndAt: "",
    submitStartAt: "",
    submitEndAt: ""
};

export const PROJECT_STEP2_INITIAL_DATA = {
    customTitle: "",
    photos: ["", "", "", ""],
    itemOptions: [],
    writeWarning: "",
    keyword: "",
    hashTags: [],
    guideLine: "",
    address: {},
    addressDetail: "",
    loc: "",
    visitDesc: "",
    visitInfo: "",
    buyInfo: {
        siteUrl: "",
        desc: ""
    },
    phoneNumber: "",
    contact: { way: "email", value: "" },
    contactTime: ""
};

export const PROJECT_STEP3_INITIAL_DATA = {
    addition: {
        recommend: { status: false },
        private: { status: false },
        emergency: { status: false },
        secondaryUse: { status: false }
    },
    recruitmentForm: {
        type: "self",
        plan: "basic"
    }
};

export const PROJECT_STEP4_INITIAL_DATA = {
    reward: 0,
    fee: 0,
    checkout: 0,
    receiptType: "None",
    isSaveSellerBusinessNumber: false,
    sellerPhoneNumber: "",
    isSaveSellerPhoneNumber: false,
    sellerBusinessName: "",
    sellerName: "",
    sellerLocation: "",
    sellerBusinessStatus: "",
    sellerCategory: "",
    sellerBusinessNumber: "",
    sellerEmail: ""
};

export const CREATE_PROJECT_STEP_LIST = [
    { text: "item.project.info.basic", value: "basic" },
    { text: "item.project.info.detail", value: "detail" },
    // { text: "플랜 선택", mobileText: "플랜 선택", value: "selfPlan" },
    { text: "action.checkout", value: "checkout" }
];

export const PROJECT_TYPE_SCHEME = [
    {
        label: "item.project.type.review",
        value: "review",
        emoji: "📝",
        text: (
            <>
                <FormattedMessage
                    id="message.project.create.type.review.guide"
                    values={{
                        uploadReview: (
                            <span className="medium-font">
                                <FormattedMessage id="message.project.create.type.review.guide.upload_review" />
                            </span>
                        )
                    }}
                />
            </>
        ),
        recommendText: "message.project.create.type.review.recommend"
    },
    {
        label: "item.project.type.content",
        value: "content",
        emoji: "🤳",
        text: (
            <>
                <FormattedMessage
                    id="message.project.create.type.content.guide"
                    values={{
                        original: (
                            <span className="medium-font">
                                <FormattedMessage id="message.project.create.type.content.guide.original" />
                            </span>
                        )
                    }}
                />
                <br className="mobile" />
                (<FormattedMessage id="message.project.create.type.content.guide.period" />)
            </>
        ),
        recommendText: "message.project.create.type.content.recommend"
    },
    {
        label: "item.project.type.shortform",
        value: "shortform",
        emoji: "🎬",
        text: <FormattedMessage id="message.project.create.type.shortform.guide" />,
        recommendText: "message.project.create.type.shortform.recommend"
    }
];

export const PROJECT_CATEGORY_SCHEME = {
    Delivery: {
        label: "item.project.category.delivery",
        id: "biz-create-1-deliverybtn",
        minDate: 10
    },
    Visit: { label: "item.project.category.visit", id: "biz-create-1-visitbtn", minDate: 10 },
    Buy: { label: "item.project.category.buy", id: "biz-create-1-buybtn", minDate: 3 },
    Reporters: {
        label: "item.project.category.reporters",
        id: "biz-create-1-reportbtn",
        minDate: 3
    },
    // Online: { label: "온라인", id: "biz-create-1-onlinebtn", minDate: 10 },
    // Offline: { label: "오프라인", id: "biz-create-1-offlinebtn", minDate: 10 },
    None: { label: "item.project.category.none", id: "biz-create-1-none", minDate: 10 }
};

export const PROJECT_PROGRESS_SCHEME = [
    {
        title: "배송형",
        content:
            "배송형 프로젝트는 리뷰어가 오프라인 매장 등에 방문하지 않고 리뷰어에게 제품이 배송되어 제품이나 서비스를 체험한 후 리뷰를 쓰는 진행방식입니다."
    },
    {
        title: "방문형",
        content:
            "방문형 프로젝트는 리뷰어가 오프라인 매장 등에 직접 방문하여 제품이나 서비스를 체험한 후 리뷰를 쓰는 진행방식입니다."
    },
    {
        title: "구매형",
        content:
            "구매형 프로젝트는 실 구매자만 리뷰 작성이 가능한 채널(쇼핑몰, SNS)에 상품/서비스 리뷰를 등록하는 진행 방식입니다."
    },
    {
        title: "기자단",
        content:
            "기자단 프로젝트는 리뷰어에게 제품/서비스 제공 없이, 포인트를 지급하여 리뷰를 작성하는 방식입니다. 무형의 서비스를 운영하거나 가격대가 높아 제품 제공이 곤란한 브랜드에게 적합합니다."
    }
];

export const PROJECT_SNS_SCHEME = {
    NaverBlog: {
        label: "네이버블로그",
        path: "icon_channel_naverblog",
        id: "biz-create-1-blogbtn"
    },
    Instagram: {
        label: "인스타그램",
        path: "icon_channel_instagram",
        id: "biz-create-1-instabtn"
    },
    Youtube: {
        label: "유튜브",
        path: "icon_channel_youtube",
        id: "biz-create-1-youtubebtn"
    },
    NaverCafe: { label: "카페", path: "icon_channel_cafe", id: "biz-create-1-cafebtn" },
    AppReview: { label: "앱", path: "icon_channel_appreview", id: "biz-create-1-appbtn" },
    OnlineComm: {
        label: "쇼핑몰",
        path: "icon_channel_shopping"
    },
    Tiktok: {
        label: "틱톡",
        path: "icon_channel_tiktok"
    },
    Shorts: {
        label: "틱톡",
        path: "icon_channel_shorts"
    },
    Reels: {
        label: "릴스",
        path: "icon_channel_reels"
    }
};

export const PROJECT_POINTS_SCHEME = [5000, 10000, 50000];

// step2
export const PROJECT_NAME_LIST_SCHEME = [
    {
        emoji: "📦",
        title: (
            <>
                <FormattedMessage id="item.project.category.delivery" />/
                <FormattedMessage id="item.project.category.buy" />
            </>
        ),
        text: (
            <>
                [<FormattedMessage id="item.brand.name" />
                ]+[
                <FormattedMessage id="item.project.item.name" />]
            </>
        )
    },
    {
        emoji: "🚙",
        title: <FormattedMessage id="item.project.category.visit" />,
        text: (
            <>
                [<FormattedMessage id="item.project.location" />
                ]+[
                <FormattedMessage id="item.brand.name" />]
            </>
        )
    },
    {
        emoji: "💻",
        title: <FormattedMessage id="item.project.category.reporters" />,
        text: (
            <>
                [<FormattedMessage id="item.project.category.reporters" />
                ]+[
                <FormattedMessage id="item.brand.name" />]
            </>
        )
    },
    {
        emoji: "🌈",
        title: <FormattedMessage id="item.project.category.none" />,
        text: (
            <>
                [<FormattedMessage id="item.brand.name" />
                ]+[입력 내용]
            </>
        )
    }
];

export const BUSINESS_CONTACT_SCHEME = [
    { label: "item.contact.kakao", value: "kakao" },
    { label: "item.contact.company", value: "company" },
    { label: "item.contact.email", value: "email" }
];

// step3

export const PROJECT_PLAN_SCHEME = [
    {
        label: "BASIC",
        value: "basic",
        price: 5000
    },
    {
        label: "STANDARD",
        value: "standard",
        price: 15000,
        secondaryUse: true,
        addition: ["자유로운 마케팅 활용", "리뷰 원본 파일 수집"]
    },
    {
        label: "PREMIUM",
        value: "premium",
        price: 20000,
        secondaryUse: true,
        addition: [
            "자유로운 마케팅 활용",
            "리뷰 원본 파일 수집",
            "리뷰어에게만 프로젝트 노출",
            "포털사이트 내 검색 노출 방지",
            "추천 프로젝트 노출"
        ],
        optionalAddition: [
            {
                label: "비공개 프로젝트",
                value: "private",
                message: (
                    <>
                        전체 사용자가 아닌 ‘<span className="medium-font">리뷰어</span>’로 로그인한
                        {"\n"}
                        사용자에게만 프로젝트가 노출되며, {"\n"}포털사이트 내 검색 시에도 해당
                        프로젝트는{"\n"}
                        노출되지 않습니다.
                    </>
                )
            },
            {
                label: "추천 프로젝트",
                value: "recommend",
                message: (
                    <>
                        클릭률이 가장 높은 메인페이지 프로젝트 {"\n"}리스트 최상단 1열 고정
                        노출됩니다.
                    </>
                )
            }
        ]
    }
];

export const ADDITION_ITEMS_SCHEME = [
    {
        value: "recommend",
        title: "추천 프로젝트",
        text: `클릭률이 가장 높은 메인 페이지 프로젝트 \n리스트 최상단 1열 고정 노출됩니다.`,
        unit: "project",
        price: ADDITION_RECOMMEND_USE,
        example: true
    },
    {
        value: "private",
        title: "비공개 모집 프로젝트",
        text: `전체 사용자가 아닌 ‘리뷰어’로 로그인한 사용자에게만 \n프로젝트가 노출되며, 포털사이트 내 검색 시에도 \n해당 프로젝트는 노출되지 않습니다.`,
        unit: "project",
        price: ADDITION_PRIVATE_USE
    },
    {
        value: "emergency",
        title: "긴급 모집 프로젝트",
        text: `플랫폼 내 리뷰어 회원들을 대상으로 진행하시는 \n프로젝트에 대한 이메일 푸시 홍보를 제공합니다. \n모집 인원 대량 유치 혹은 긴급 모집 시에 좋습니다.`,
        unit: "project",
        price: ADDITION_EMERGENCY_USE
    },
    {
        value: "secondaryUse",
        title: "리뷰 콘텐츠 마케팅 활용",
        text: `리뷰어님들이 제출한 리뷰 콘텐츠를 \n2차 마케팅 자료로 활용할 수 있습니다.`,
        unit: "reviewer",
        price: ADDITION_SECONDARY_USE
    }
];

// step4

export const PROJECT_BILL_EMAIL_SCHEME = [
    { label: "message.project.create.tax_bill.email", value: "basic" },
    { label: "message.project.create.tax_bill.new_email", value: "new" }
];
