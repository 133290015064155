import { createAction, handleActions } from "redux-actions";
import { createRequestActionTypes } from "lib/createRequestSaga";
import produce from "immer";

export const HANDLE_HAS_UNREAD_ALARM = "alarm/HANDLE_HAS_UNREAD_ALARM";
export const [
    GET_ALL_ALARM_LIST,
    GET_ALL_ALARM_LIST_SUCCESS,
    GET_ALL_ALARM_LIST_FAILURE
] = createRequestActionTypes("alarm/GET_ALL_ALARM_LIST");
export const [READ_ALARM, READ_ALARM_SUCCESS, READ_ALARM_FAILURE] = createRequestActionTypes(
    "alarm/READ_ALARM"
);
export const [REMOVE_ALARM, REMOVE_ALARM_SUCCESS, REMOVE_ALARM_FAILURE] = createRequestActionTypes(
    "alarm/REMOVE_ALARM"
);

export const handleHasUnreadAlarm = createAction(HANDLE_HAS_UNREAD_ALARM);
export const getAllAlarmList = createAction(GET_ALL_ALARM_LIST);
export const readAlarm = createAction(READ_ALARM);
export const removeAlarm = createAction(REMOVE_ALARM);

const initialState = {
    hasUnread: false,
    all: {
        counts: 0,
        list: []
    }
};

const alarm = handleActions(
    {
        [HANDLE_HAS_UNREAD_ALARM]: (state, { payload }) =>
            produce(state, draft => {
                draft.hasUnread = payload;
            }),
        [GET_ALL_ALARM_LIST_SUCCESS]: (state, { payload, prev = {} }) =>
            produce(state, draft => {
                draft.hasUnread = payload.hasUnread;
                draft.all = {
                    counts: payload.totalCounts,
                    list: prev.supplement ? [...state.all.list, ...payload.list] : payload.list
                };
            }),
        [READ_ALARM_SUCCESS]: (state, { prev, payload }) =>
            produce(state, draft => {
                draft.hasUnread = payload.hasUnread;
                draft.all.list = state.all.list.map(item => {
                    if (prev.target === "all" || item._id === prev.target) {
                        return {
                            ...item,
                            informationStatus: { ...item.informationStatus, isRead: true }
                        };
                    }

                    return item;
                });
            }),
        [REMOVE_ALARM_SUCCESS]: (state, { prev, payload }) =>
            produce(state, draft => {
                draft.hasUnread = payload.hasUnread;
                if (typeof prev.target === "number") {
                    draft.all.counts = state.all.counts - 1;
                    draft.all.list = state.all.list.filter(item => {
                        if (item._id === prev.target) {
                            return false;
                        }

                        return true;
                    });
                }
            })
    },
    initialState
);

export default alarm;
