import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconFont, Button } from "reviewshare-common-component";
import { useUserType } from "lib/customHooks/useAuth";
import { handleMessengerMainFunction } from "stores/modules/messenger/action";
import { POINT_GIFT_HISTORY_RE, POINT_GIFT_HISTORY_BIZ } from "scheme/link";
import CommonLink from "components/common/CommonLink";

import "./MainSideOpenButtons.scss";

const MainSideItems = ({ possibleSend, children }) => {
    const dispatch = useDispatch();

    const userType = useUserType();
    const mainStatus = useSelector(({ messenger }) => messenger.mainStatus);
    const writeInfo = useSelector(({ messenger }) => messenger.writeInfo);
    const brandHistoryTitle = userType === "Seller" ? "내 브랜드 참여 내역" : "브랜드 참여 내역";

    const onOpenMainSideItem = type => {
        dispatch(handleMessengerMainFunction(type));
    };

    return (
        <div className="messenger-main-side-open-buttons">
            <div className="button-area-wrapper">
                {children}
                {mainStatus === "messagebox" && (
                    <Button
                        className="theme4 size-s m-size-xs"
                        onClick={() => onOpenMainSideItem("brand")}
                    >
                        <IconFont icon="icon_hamburger" />
                        {brandHistoryTitle}
                    </Button>
                )}
                {userType === "Seller" && (
                    <Button
                        id="open-give-points-area"
                        className="theme4 size-s m-size-xs"
                        disabled={
                            !writeInfo.from.brandId || !writeInfo.toList.length || !possibleSend
                        }
                        onClick={() => onOpenMainSideItem("point")}
                    >
                        <IconFont icon="icon_gift" />
                        포인트 선물
                    </Button>
                )}
                <CommonLink
                    className="rs-button theme4 size-s m-size-xs"
                    to={userType === "Seller" ? POINT_GIFT_HISTORY_BIZ : POINT_GIFT_HISTORY_RE}
                >
                    <IconFont icon="icon_link" />
                    선물 내역
                </CommonLink>
            </div>
        </div>
    );
};
export default MainSideItems;
