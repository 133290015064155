import { createAction, handleActions } from "redux-actions";
import { createRequestActionTypes } from "lib/createRequestSaga";
import produce from "immer";
import { returnWriteInfo, addNewStatusToLastMessage } from "utils/messenger";

export const RESET_SEARCH_OPTIONS = "messenger/RESET_SEARCH_OPTIONS";

export const HANDLE_MESSENGER_OPEN_STATUS = "messenger/HANDLE_MESSENGER_OPEN_STATUS";
export const HANDLE_MESSENGER_MAIN_STATUS = "messenger/HANDLE_MESSENGER_MAIN_STATUS";
export const HANDLE_MESSENGER_MAIN_FUNCTION = "messenger/HANDLE_MESSENGER_MAIN_FUNCTION";
export const HANDLE_UNREAD_VALUE = "messenger/HANDLE_UNREAD_VALUE";
export const HANDLE_NEW_MESSAGE = "messenger/HANDLE_NEW_MESSAGE";

export const SELECT_MESSAGE_BOX = "messenger/SELECT_MESSAGE_BOX";
export const SELECT_WRITE_INFO = "messenger/SELECT_WRITE_INFO";
export const SET_WRITE_INFO = "messenger/SET_WRITE_INFO";
export const SET_SEARCH_OPTIONS = "messenger/SET_SEARCH_OPTIONS";
export const SET_WARNING_MESSAGE_BOX = "messenger/SET_WARNING_MESSAGE_BOX";

export const [
    SEARCH_MESSAGE_BOX,
    SEARCH_MESSAGE_BOX_SUCCESS,
    SEARCH_MESSAGE_BOX_FAILURE
] = createRequestActionTypes("messenger/SEARCH_MESSAGE_BOX");

export const [SEARCH_TO, SEARCH_TO_SUCCESS, SEARCH_TO_FAILURE] = createRequestActionTypes(
    "messenger/SEARCH_TO"
);

export const [
    GET_MESSAGE_BOX_LIST,
    GET_MESSAGE_BOX_LIST_SUCCESS,
    GET_MESSAGE_BOX_LIST_FAILURE
] = createRequestActionTypes("messenger/GET_MESSAGE_BOX_LIST");

export const [
    GET_MESSAGE_LIST,
    GET_MESSAGE_LIST_SUCCESS,
    GET_MESSAGE_LIST_FAILURE
] = createRequestActionTypes("messenger/GET_MESSAGE_LIST");

export const [SEND_MESSAGE, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_FAILURE] = createRequestActionTypes(
    "messenger/SEND_MESSAGE"
);

export const [
    SEND_GIFT_POINT,
    SEND_GIFT_POINT_SUCCESS,
    SEND_GIFT_POINT_FAILURE
] = createRequestActionTypes("messenger/SEND_GIFT_POINT");

export const [
    GET_BRAND_HISTORY,
    GET_BRAND_HISTORY_SUCCESS,
    GET_BRAND_HISTORY_FAILURE
] = createRequestActionTypes("messenger/GET_BRAND_HISTORY");

//socket
export const ADD_MESSAGE_ITEM = "messenger/ADD_MESSAGE_ITEM";
export const ADD_MESSAGE_BOX_ITEM = "messenger/ADD_MESSAGE_BOX_ITEM";

const initialState = {
    // 쪽지 창 오픈 여부
    isOpen: false,
    // 대화방 상태 ["write", "messagebox", "none"]
    mainStatus: "none",
    // ["selectTo", "selectFrom", "brand", "point", "none"]
    mainFunction: "none",
    hasUnreadMessage: false,

    progress: {},

    writeInfo: {
        isPossibleSend: false,
        toList: [],
        from: {}
    },

    messageBox: {
        counts: 0,
        list: []
    },

    newMessage: null,

    selectedMessageBoxOptions: {
        reviewerId: null,
        brandId: null,
        lastMessageId: null
    },
    selectedMessageBox: {
        messageBoxId: null,
        counterpart: {},
        receiver: {},
        list: [],
        warning: false,

        // 전체 리스트를 기준으로 했을때 이전, 다음내역이 있는지
        hasPrevious: false,
        hasNext: false
    },

    brandHistory: {
        reviewer: {},
        brand: {},
        projectCounts: 0,
        suggestionCounts: 0,
        projectList: [],
        suggestionList: []
    },

    searchOptions: {
        viewType: "all",
        brandId: null,
        keyword: "",
        skip: 0
    },

    searchedMessageBox: {
        counterpart: {
            counts: 0,
            list: []
        },
        message: {
            counts: 0,
            list: []
        }
    },

    searchedTo: {
        counts: 0,
        list: []
    }
};

export const resetSearchOptions = createAction(RESET_SEARCH_OPTIONS);

export const handleMessengerOpenStatus = createAction(HANDLE_MESSENGER_OPEN_STATUS);
export const handleMessengerMainStatus = createAction(HANDLE_MESSENGER_MAIN_STATUS);
export const handleMessengerMainFunction = createAction(HANDLE_MESSENGER_MAIN_FUNCTION);
export const handleUnreadValue = createAction(HANDLE_UNREAD_VALUE);

export const selectMessageBox = createAction(SELECT_MESSAGE_BOX, id => id);
export const selectWriteInfo = createAction(SELECT_WRITE_INFO, obj => obj);
export const searchMessageBox = createAction(SEARCH_MESSAGE_BOX);
export const searchTo = createAction(SEARCH_TO);

export const setSearchOptions = createAction(SET_SEARCH_OPTIONS, options => options);
export const setWarningMessageBox = createAction(SET_WARNING_MESSAGE_BOX);

export const getMessageBoxList = createAction(GET_MESSAGE_BOX_LIST);
export const getMessageList = createAction(GET_MESSAGE_LIST);
export const getBrandHistory = createAction(GET_BRAND_HISTORY);

export const sendMessage = createAction(SEND_MESSAGE, formData => formData);
export const sendGiftPoint = createAction(SEND_GIFT_POINT, obj => obj);

//socket
export const addMessageItem = createAction(ADD_MESSAGE_ITEM);
export const addMessageBoxItem = createAction(ADD_MESSAGE_BOX_ITEM);

const messenger = handleActions(
    {
        [RESET_SEARCH_OPTIONS]: state =>
            produce(state, draft => {
                draft.searchOptions = {
                    ...initialState.searchOptions,
                    brandId: state.searchOptions.brandId
                };
                draft.searchedMessageBox = initialState.searchedMessageBox;
            }),
        [HANDLE_MESSENGER_OPEN_STATUS]: (state, { payload }) =>
            produce(state, draft => {
                draft.isOpen = payload;
            }),
        [HANDLE_MESSENGER_MAIN_STATUS]: (state, { payload }) =>
            produce(state, draft => {
                draft.mainStatus = payload;
                if (payload === "write") {
                    draft.writeInfo = initialState.writeInfo;
                }
                if (payload !== "messagebox") {
                    draft.brandHistory = initialState.brandHistory;
                    draft.selectedMessageBox = initialState.selectedMessageBox;
                    draft.selectedMessageBoxOptions = initialState.selectedMessageBoxOptions;
                }
            }),
        [HANDLE_MESSENGER_MAIN_FUNCTION]: (state, { payload }) =>
            produce(state, draft => {
                draft.mainFunction = payload;
            }),
        [HANDLE_UNREAD_VALUE]: (state, { payload }) =>
            produce(state, draft => {
                draft.hasUnreadMessage = payload;
            }),
        [HANDLE_NEW_MESSAGE]: (state, { payload }) =>
            produce(state, draft => {
                draft.newMessage = payload;
            }),
        [SELECT_MESSAGE_BOX]: (state, { payload }) =>
            produce(state, draft => {
                const { reviewerId, brandId, lastMessageId = null } = payload;
                const isSelectedMessageBox =
                    reviewerId === state.selectedMessageBoxOptions.reviewerId &&
                    brandId === state.selectedMessageBoxOptions.brandId;
                const isSameLastMessageId =
                    lastMessageId === state.selectedMessageBoxOptions.lastMessageId;

                draft.mainStatus = "messagebox";
                draft.mainFunction = initialState.mainFunction;
                draft.messageBox.list = [...state.messageBox.list].map(item => {
                    if (item.reviewerId === reviewerId && item.brandId === brandId) {
                        return { ...item, isNew: false };
                    }
                    return item;
                });
                draft.selectedMessageBoxOptions = {
                    reviewerId,
                    brandId,
                    lastMessageId: lastMessageId || null
                };
                if (!isSelectedMessageBox || (isSelectedMessageBox && !isSameLastMessageId)) {
                    draft.brandHistory = initialState.brandHistory;
                    draft.selectedMessageBox = initialState.selectedMessageBox;
                }
            }),
        [SET_WRITE_INFO]: (state, { payload }) =>
            produce(state, draft => {
                draft.isOpen = true;
                draft.writeInfo = {
                    from: payload.from,
                    toList: payload.toList,
                    isPossibleSend: payload.isPossibleSend
                };
            }),
        [SET_SEARCH_OPTIONS]: (state, { payload }) =>
            produce(state, draft => {
                draft.searchOptions = {
                    ...state.searchOptions,
                    ...{ skip: initialState.searchOptions.skip },
                    ...payload
                };
                if (payload.keyword && payload.keyword !== state.searchOptions.keyword) {
                    draft.searchedMessageBox = initialState.searchedMessageBox;
                }
            }),
        [SET_WARNING_MESSAGE_BOX]: (state, { payload }) =>
            produce(state, draft => {
                draft.selectedMessageBox = {
                    ...state.selectedMessageBox,
                    warning: payload === state.selectedMessageBox.messageBoxId
                };
            }),
        [SEARCH_MESSAGE_BOX_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                const hasSkip = prev.skip > 0;
                if (payload.counterpartList) {
                    draft.searchedMessageBox.counterpart = {
                        counts: payload.searchedCounterpartTotalCounts,
                        list: hasSkip
                            ? [
                                  ...state.searchedMessageBox.counterpart.list,
                                  ...payload.counterpartList
                              ]
                            : payload.counterpartList
                    };
                }
                if (payload.messageList) {
                    draft.searchedMessageBox.message = {
                        counts: payload.searchedMessageTotalCounts,
                        list: hasSkip
                            ? [...state.searchedMessageBox.message.list, ...payload.messageList]
                            : payload.messageList
                    };
                }
            }),
        [SEARCH_TO]: (state, { payload }) =>
            produce(state, draft => {
                if (!payload.skip) {
                    draft.searchedTo = initialState.searchedTo;
                }
            }),
        [SEARCH_TO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.searchedTo = {
                    counts: payload.totalCounts,
                    list: [...state.searchedTo.list, ...payload.list]
                };
            }),
        [GET_MESSAGE_BOX_LIST]: (state, { payload = {} }) =>
            produce(state, draft => {
                if (!payload.skip) {
                    draft.messageBox = initialState.messageBox;
                }
            }),
        [GET_MESSAGE_BOX_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.messageBox = {
                    counts: payload.totalCounts,
                    list: [...state.messageBox.list, ...payload.list]
                };
            }),
        [GET_MESSAGE_LIST_SUCCESS]: (state, { payload, prev }) =>
            produce(state, draft => {
                if (state.mainStatus === "messagebox") {
                    const { counterpart, receiver } = payload;
                    if (counterpart && receiver) {
                        // 대화방이 있는 경우
                        const { from, toList, isPossibleSend } = returnWriteInfo({
                            from: receiver,
                            toList: [counterpart]
                        });
                        const oldList = state.selectedMessageBox.list;
                        const newList = payload.messageList;

                        draft.writeInfo = { from, toList, isPossibleSend };
                        draft.selectedMessageBox = {
                            ...initialState.selectedMessageBox,
                            messageBoxId: payload.messageBoxId,
                            counterpart,
                            receiver,
                            list: prev.after ? [...oldList, ...newList] : [...newList, ...oldList]
                        };
                        draft.selectedMessageBoxOptions = {
                            ...state.selectedMessageBoxOptions,
                            reviewerId: counterpart.reviewerId || receiver.reviewerId,
                            brandId: counterpart.brandId || receiver.brandId
                        };

                        if (oldList.length < 1) {
                            draft.selectedMessageBox.hasPrevious = payload.hasPrevious;
                            draft.selectedMessageBox.hasNext = payload.hasNext;
                        } else {
                            draft.selectedMessageBox.hasPrevious =
                                oldList[0].messageId > newList[0].messageId
                                    ? payload.hasPrevious
                                    : state.selectedMessageBox.hasPrevious;
                            draft.selectedMessageBox.hasNext =
                                oldList[oldList.length - 1].messageId <
                                newList[newList.length - 1].messageId
                                    ? payload.hasNext
                                    : state.selectedMessageBox.hasNext;
                        }
                    } else {
                        // 대화방이 없는 경우 새쪽지쓰기로 이동시켜줌
                        draft.mainStatus = "write";
                        draft.selectedMessageBoxOptions = initialState.selectedMessageBoxOptions;
                        draft.selectedMessageBox = initialState.selectedMessageBox;
                        draft.brandHistory = initialState.brandHistory;
                    }
                }
            }),

        [GET_BRAND_HISTORY]: (state, { payload }) =>
            produce(state, draft => {
                if (payload.skip) {
                    draft.brandHistory = initialState.selectedMessageBox.brandHistory;
                }
            }),
        [GET_BRAND_HISTORY]: (state, { payload }) =>
            produce(state, draft => {
                if (!payload.skip) {
                    draft.brandHistory.projectList = initialState.brandHistory.projectList;
                    draft.brandHistory.suggestionList = initialState.brandHistory.suggestionList;
                }
            }),
        [GET_BRAND_HISTORY_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                const { projectList, suggestionList } = state.brandHistory;
                draft.brandHistory = {
                    reviewer: payload.reviewer,
                    brand: payload.brand,
                    projectCounts: payload.countOfProject,
                    suggestionCounts: payload.countOfSuggestion,
                    projectList: [...projectList, ...payload.projectList],
                    suggestionList: [...suggestionList, ...payload.suggestionList]
                };
            }),
        [SEND_MESSAGE]: state =>
            produce(state, draft => {
                // 안 불러온 내용이 남아있음 리셋 시켜줌
                if (state.selectedMessageBox.list.length > 0 && state.selectedMessageBox.hasNext) {
                    draft.brandHistory = initialState.brandHistory;
                    draft.selectedMessageBox = initialState.selectedMessageBox;
                    draft.selectedMessageBoxOptions = initialState.selectedMessageBoxOptions;
                }
            }),
        [SEND_MESSAGE_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                const { successTargetList } = payload;
                const reviewerId =
                    state.writeInfo.from.reviewerId || state.writeInfo.toList[0].reviewerId;
                const brandId = state.writeInfo.from.brandId || state.writeInfo.toList[0].brandId;
                const isSelectedMessageBox =
                    successTargetList.length === 1 &&
                    reviewerId === state.selectedMessageBoxOptions.reviewerId &&
                    brandId === state.selectedMessageBoxOptions.brandId;
                const { filteredToList } = returnWriteInfo({ toList: successTargetList });

                draft.mainStatus = "messagebox";
                draft.mainFunction = "none";
                draft.writeInfo.toList = filteredToList;

                if (successTargetList.length > 1 || isSelectedMessageBox) {
                    // 메시지 내용 바로 추가
                    const newMessageList = payload.messageList;
                    draft.selectedMessageBox.list = [
                        ...state.selectedMessageBox.list,
                        ...addNewStatusToLastMessage(newMessageList)
                    ];
                } else {
                    // 해당 메시지 방으로 이동
                    draft.brandHistory = initialState.brandHistory;
                    draft.selectedMessageBox = initialState.selectedMessageBox;
                    draft.selectedMessageBoxOptions = {
                        ...initialState.selectedMessageBoxOptions,
                        reviewerId,
                        brandId
                    };
                }
            }),
        [ADD_MESSAGE_ITEM]: (state, { payload }) =>
            produce(state, draft => {
                draft.selectedMessageBox.list = [
                    ...state.selectedMessageBox.list,
                    ...addNewStatusToLastMessage(payload)
                ];
            }),
        [ADD_MESSAGE_BOX_ITEM]: (state, { payload }) =>
            produce(state, draft => {
                const filteredBrandId = state.searchOptions.brandId;
                const isPossibleAdd =
                    !filteredBrandId || (filteredBrandId && filteredBrandId === payload[0].brandId);

                if (isPossibleAdd) {
                    const newList = [...payload, ...state.messageBox.list];
                    const filteredList = newList.filter((item, index) => {
                        const findIndex = newList.findIndex(
                            messageBox => messageBox.messageBoxId === item.messageBoxId
                        );
                        return findIndex === index;
                    });
                    draft.messageBox.list = filteredList;
                }
                draft.hasUnreadMessage = payload[0].isNew;
            })
    },
    initialState
);

export default messenger;
