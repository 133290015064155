import React, { Suspense } from "react";
import { isMobile } from "lib/common";
import GlobalHeaderContainer from "containers/core/GlobalHeaderContainer";
import BottomTabBarPortal from "components/base/portal/BottomTabBarPortal";

import "./GlobalCommon.scss";
import "./PublicPageTemplate.scss";

const PublicPageTemplate = ({ children, customMobileHeader, bottomTabBar }) => (
    <>
        <div className="public-page-template">
            {(!isMobile() || (isMobile() && !customMobileHeader)) && <GlobalHeaderContainer />}
            <div className="page-body">
                <Suspense fallback={null}>{children}</Suspense>
            </div>
        </div>
        {bottomTabBar && <BottomTabBarPortal />}
    </>
);

export default PublicPageTemplate;
