import { numberWithCommas, convertSnsToEng } from "lib/common";

export const convertSns = sns => {
    switch (sns) {
        case "NaverBlog":
            return "item.sns.naver.blog";
        case "NaverCafe":
            return "item.sns.cafe";
        case "NaverPost":
            return "item.sns.naver.post";
        case "Facebook":
            return "item.sns.facebook";
        case "Instagram":
            return "item.sns.instagram";
        case "KakaoStory":
            return "item.sns.kakao.story";
        case "Youtube":
            return "item.sns.youtube";
        case "Tiktok":
            return "item.sns.tiktok";
        case "Shorts":
            return "item.sns.shorts";
        case "Reels":
            return "item.sns.reels";
        case "NaverInfluencer":
            return "item.sns.naver.influencer";
        case "AppReview":
            return "item.sns.app";
        case "SelfMarket":
            return "item.sns.market.self";
        case "OnlineComm":
            return "item.sns.market.online";
        case "OtherSns":
            return "item.sns.other";
        default:
            return sns;
    }
};

export const convertSnsValue = sns => {
    switch (sns) {
        case "NaverBlog":
            return "item.sns.naver.blog.today";
        case "Instagram":
        case "Reels":
            return "item.sns.instagram.follower";
        case "Youtube":
        case "Shorts":
            return "item.sns.youtube.follower";
        case "Tiktok":
            return "item.sns.tiktok.follower";
        case "NaverInfluencer":
            return "item.sns.naver.influencer.fan";
        default:
            return sns;
    }
};

export const returnSnsValue = value => {
    if (value === 0) {
        return 0;
    }
    if (!value || value < 0) {
        return "-";
    }
    if (value >= 10000) {
        return `${numberWithCommas(Math.floor(value / 1000) / 10)}만`;
    }
    if (value >= 1000) {
        return `${numberWithCommas(Math.floor(value / 100) / 10)}천`;
    }
    return value;
};

export const checkNeedKeyword = sns => {
    return ["NaverBlog", "Youtube", "NaverPost", "NaverCafe"].indexOf(sns) > -1;
};

export const checkNeedHashTag = sns => {
    return ["Instagram", "Tiktok", "Reels", "Shorts"].indexOf(sns) > -1;
};

export const checkOtherSns = channel => {
    const array = [
        "NaverBlog",
        "Instagram",
        "Youtube",
        "NaverPost",
        "KakaoStory",
        "Facebook",
        "Tiktok",
        "Reels",
        "Shorts"
    ];

    return array.indexOf(convertSnsToEng(channel)) === -1;
};

export const returnSnsByProjectSns = sns => {
    switch (sns) {
        case "NaverBlog":
        case "NaverCafe":
        case "NaverPost":
        case "Facebook":
        case "Instagram":
        case "KakaoStory":
        case "Youtube":
        case "Tiktok":
            return sns;
        case "Shorts":
            return "Youtube";
        case "Reels":
            return "Instagram";
        default:
            return null;
    }
};

export const checkNeedFollowerSns = sns => {
    const array = ["NaverBlog", "Instagram", "Youtube", "Tiktok", "Reels", "Shorts"];

    return array.indexOf(sns) > -1;
};

export const checkIsValidValue = ({ value, sns }) => {
    if (!value) return false;
    if (value.indexOf("/") > -1) return false;
    if (value.indexOf("@") > -1) return false;

    const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    if (korean.test(value)) return false;

    if (["Youtube", "Shorts"].indexOf(sns) > -1) {
        if (value.substring(0, 2) !== "UC") {
            return false;
        }
    }

    return true;
};
