import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconFont, MoreMenu, MoreMenuItem, Profile } from "reviewshare-common-component";
import { useProgressItems } from "lib/customHooks/useProgress";
import { returnName } from "utils/messenger";
import { setChangeCondition } from "stores/modules/relation/action";
import { handleMessengerMainStatus } from "stores/modules/messenger/action";
import { useUserType } from "lib/customHooks/useAuth";
import useRelationStatus from "lib/customHooks/useRelationStatus";
import FormattedMessage from "components/common/FormattedMessage";
import Skeleton from "components/common/Skeleton";
import MessageBoxToList from "components/messenger/main/MessageBoxToList";

import "./MessageBoxInfo.scss";

const MessageBoxInfo = () => {
    const dispatch = useDispatch();

    const userType = useUserType();
    const from = useSelector(({ messenger }) => messenger.writeInfo.from);
    const toList = useSelector(({ messenger }) => messenger.writeInfo.toList);
    const selectedMessageBox = useSelector(({ messenger }) => messenger.selectedMessageBox);
    const isSearching =
        useProgressItems("messenger/GET_MESSAGE_LIST") && !selectedMessageBox.list.length;
    const onlyOneTarget = useMemo(() => toList.length === 1, [toList.length]);
    const { relationStatus: firstTargetRelationStatus } = useRelationStatus({
        status: toList?.[0]?.relation?.status,
        userId: toList?.[0]?.reviewerId
    });

    const onCheckSetBlock = () => {
        dispatch(
            setChangeCondition({
                type: "block",
                status: "check",
                userList: [{ _id: toList[0].reviewerId, nickname: toList[0].nickname }]
            })
        );
    };

    const onResetMainStatus = () => {
        dispatch(handleMessengerMainStatus("none"));
    };

    return (
        <div className="messenger-main-message-box-info">
            <button type="button" className="back-btn" onClick={onResetMainStatus}>
                <IconFont icon="icon_line_arrow_left">뒤로가기</IconFont>
            </button>
            <div className="title-area">
                {isSearching ? (
                    <Skeleton count={userType === "Seller" ? 2 : 1} />
                ) : (
                    <>
                        <MessageBoxToList />
                        {toList.length > 1 && (
                            <div className="to-list">
                                <div className="to-list-wrapper">
                                    <ul>
                                        {toList.map((item, index) => (
                                            <li key={index}>
                                                {item.nickname && (
                                                    <Profile
                                                        className="size-xxs m-size-xs"
                                                        noBand
                                                        img={item.profileImg}
                                                        alt={`${item.nickname}님의 프로필 사진`}
                                                    />
                                                )}
                                                <p>
                                                    {userType === "Seller"
                                                        ? returnName(
                                                              item.nickname,
                                                              item.isDeletedReviewer
                                                          )
                                                        : returnName(
                                                              item.brandName,
                                                              item.isDeletedBrand
                                                          )}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {userType === "Seller" && (
                            <h4>
                                <FormattedMessage id="item.brand" />:{" "}
                                {returnName(from.brandName, from.isDeleted)}
                            </h4>
                        )}
                    </>
                )}
            </div>
            {userType === "Seller" && onlyOneTarget && firstTargetRelationStatus !== "block" && (
                <MoreMenu direction="bottomRight">
                    <MoreMenuItem onClick={onCheckSetBlock}>
                        <FormattedMessage id="action.relation.block" />
                    </MoreMenuItem>
                </MoreMenu>
            )}
        </div>
    );
};
export default MessageBoxInfo;
