import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import App from "components/App";
import createSagaMiddleware from "redux-saga";
import rootReducer, { rootSaga } from "stores";
import { getUserInfoAsTokenInLocalStorage, setVisitor } from "stores/modules/auth";
import { createBrowserHistory } from "history";
import { getStorage } from "lib/storage";

const isDev = process.env.NODE_ENV === "development";
const devTools = isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = devTools || compose;
const customHistory = createBrowserHistory({ forceRefresh: true });
const sagaMiddleware = createSagaMiddleware({
    context: {
        history: customHistory
    }
});
// const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

const loadUser = () => {
    try {
        let userToken;

        if (getStorage("reviewshare-at") || getStorage("reviewshare-re-at")) {
            userToken = getStorage("reviewshare-at") || getStorage("reviewshare-re-at");
        }

        if (!userToken) {
            store.dispatch(setVisitor());
            return;
        }
        store.dispatch(getUserInfoAsTokenInLocalStorage({ init: true }));
    } catch (e) {
        console.log("Fail get user info as a token.");
    }
};

sagaMiddleware.run(rootSaga);
loadUser();

const Root = () => (
    <CookiesProvider>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </CookiesProvider>
);

export default Root;
