import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { createRequestActionTypes } from "lib/createRequestSaga";

export const RESET_SUGGEST_USER_INFO = "suggest/RESET_SUGGEST_USER_INFO";
export const RESET_SUGGESTION_INFO = "suggest/RESET_SUGGESTION_INFO";
export const SET_SUGGEST_USER_INFO = "suggest/SET_SUGGEST_USER_INFO";
export const SELECT_SUGGESTION = "suggest/SELECT_SUGGEST";

export const [
    GET_RECENT_SUGGESTION,
    GET_RECENT_SUGGESTION_SUCCESS,
    GET_RECENT_SUGGESTION_FAILURE
] = createRequestActionTypes("suggest/GET_RECENT_SUGGESTION");

export const [
    GET_SUGGESTION,
    GET_SUGGESTION_SUCCESS,
    GET_SUGGESTION_FAILURE
] = createRequestActionTypes("suggest/GET_SUGGESTION");

export const [
    GET_REVIEWER_INFO,
    GET_REVIEWER_INFO_SUCCESS,
    GET_REVIEWER_INFO_FAILURE
] = createRequestActionTypes("suggest/GET_REVIEWER_INFO");

export const [
    GET_SUGGESTION_LIST_OF_BUSINESS,
    GET_SUGGESTION_LIST_OF_BUSINESS_SUCCESS,
    GET_SUGGESTION_LIST_OF_BUSINESS_FAILURE
] = createRequestActionTypes("suggest/GET_SUGGESTION_LIST_OF_BUSINESS");

export const [
    GET_SUGGESTION_LIST_OF_REVIEWER,
    GET_SUGGESTION_LIST_OF_REVIEWER_SUCCESS,
    GET_SUGGESTION_LIST_OF_REVIEWER_FAILURE
] = createRequestActionTypes("suggest/GET_SUGGESTION_LIST_OF_REVIEWER");

export const [
    CANCEL_SUGGESTION,
    CANCEL_SUGGESTION_SUCCESS,
    CANCEL_SUGGESTION_FAILURE
] = createRequestActionTypes("suggest/CANCEL_SUGGESTION");

export const [
    ACCEPT_SUGGESTION,
    ACCEPT_SUGGESTION_SUCCESS,
    ACCEPT_SUGGESTION_FAILURE
] = createRequestActionTypes("suggest/ACCEPT_SUGGESTION");

export const [
    REJECT_SUGGESTION,
    REJECT_SUGGESTION_SUCCESS,
    REJECT_SUGGESTION_FAILURE
] = createRequestActionTypes("suggest/REJECT_SUGGESTION");

export const [
    SUBMIT_RESULT,
    SUBMIT_RESULT_SUCCESS,
    SUBMIT_RESULT_FAILURE
] = createRequestActionTypes("suggest/SUBMIT_RESULT");

export const [
    CHANGE_SUBMIT_END_DAY,
    CHANGE_SUBMIT_END_DAY_SUCCESS,
    CHANGE_SUBMIT_END_DAY_FAILURE
] = createRequestActionTypes("suggest/CHANGE_SUBMIT_END_DAY");

export const resetSuggestUserInfo = createAction(RESET_SUGGEST_USER_INFO);
export const resetSuggestionInfo = createAction(RESET_SUGGESTION_INFO);
export const setSuggestUserInfo = createAction(SET_SUGGEST_USER_INFO, user => user);
export const selectSuggestion = createAction(SELECT_SUGGESTION, info => info);
export const changeSubmitEndDay = createAction(CHANGE_SUBMIT_END_DAY);
export const getRecentSuggestion = createAction(GET_RECENT_SUGGESTION);
export const getSuggestion = createAction(GET_SUGGESTION, suggestionId => suggestionId);
export const getReviewerInfo = createAction(GET_REVIEWER_INFO, suggestionId => suggestionId);
export const getSuggestionListOfBusiness = createAction(
    GET_SUGGESTION_LIST_OF_BUSINESS,
    option => option
);
export const getSuggestionListOfReviewer = createAction(
    GET_SUGGESTION_LIST_OF_REVIEWER,
    option => option
);
export const cancelSuggestion = createAction(CANCEL_SUGGESTION, suggestionId => suggestionId);
export const acceptSuggestion = createAction(ACCEPT_SUGGESTION, ({ suggestionId, message }) => ({
    suggestionId,
    message
}));
export const rejectSuggestion = createAction(REJECT_SUGGESTION, obj => obj);
export const submitResult = createAction(SUBMIT_RESULT, obj => obj);

const initialState = {
    // suggest
    suggestUserInfo: {},
    recentSuggestInfo: null,

    // suggest detail
    suggestionInfo: {},
    selectedSuggestion: { state: null, info: null },

    // business
    suggestListOfBusiness: [],
    suggestListOfBusinessCounts: 0,
    isSearchingSuggestListOfBusiness: false,
    reviewerInfo: null,

    // reviwer
    suggestListOfReviewer: [],
    suggestListOfReviewerCounts: 0,
    isSearchingSuggestListOfReviewer: false,
    isAccept: false,
    isSubmitReviewSuccess: false
};

const suggest = handleActions(
    {
        [RESET_SUGGEST_USER_INFO]: state =>
            produce(state, draft => {
                draft.suggestUserInfo = {};
                draft.recentSuggestInfo = null;
            }),
        [RESET_SUGGESTION_INFO]: state =>
            produce(state, draft => {
                draft.suggestionInfo = initialState.suggestionInfo;
                draft.reviewerInfo = initialState.reviewerInfo;
                draft.isAccept = initialState.isAccept;
                draft.isSubmitReviewSuccess = initialState.isSubmitReviewSuccess;
                draft.selectedSuggestion = initialState.selectedSuggestion;
            }),
        [SET_SUGGEST_USER_INFO]: (state, { payload }) =>
            produce(state, draft => {
                draft.suggestUserInfo = payload;
            }),
        [SELECT_SUGGESTION]: (state, { payload }) =>
            produce(state, draft => {
                draft.selectedSuggestion.state = payload.state;
                draft.selectedSuggestion.info = payload.info;
            }),
        [CHANGE_SUBMIT_END_DAY_SUCCESS]: (state, { prev }) =>
            produce(state, draft => {
                if (state.suggestionInfo?._id) {
                    draft.suggestionInfo = {
                        ...state.suggestionInfo,
                        submitEndAt: prev.submitEndAt
                    };
                }
                draft.suggestListOfBusiness = state.suggestListOfBusiness.map(item =>
                    item._id === prev.suggestionId
                        ? { ...item, submitEndAt: prev.submitEndAt }
                        : item
                );
            }),
        [GET_RECENT_SUGGESTION_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.recentSuggestInfo = payload.suggestion;
            }),
        [GET_RECENT_SUGGESTION_FAILURE]: state =>
            produce(state, draft => {
                draft.recentSuggestInfo = null;
            }),
        [GET_SUGGESTION_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.suggestionInfo = payload.suggestion;
            }),
        [GET_REVIEWER_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.reviewerInfo = payload.user;
            }),
        [GET_SUGGESTION_LIST_OF_BUSINESS]: state =>
            produce(state, draft => {
                draft.suggestListOfBusiness = [];
                draft.isSearchingSuggestListOfBusiness = true;
                draft.suggestListOfBusinessCounts = 0;
            }),
        [GET_SUGGESTION_LIST_OF_BUSINESS_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.suggestListOfBusiness = payload.suggestionList;
                draft.isSearchingSuggestListOfBusiness = false;
                draft.suggestListOfBusinessCounts = payload.counts;
            }),
        [GET_SUGGESTION_LIST_OF_BUSINESS_FAILURE]: state =>
            produce(state, draft => {
                draft.isSearchingSuggestListOfBusiness = false;
            }),
        [GET_SUGGESTION_LIST_OF_REVIEWER]: state =>
            produce(state, draft => {
                draft.suggestListOfReviewer = [];
                draft.isSearchingSuggestListOfReviewer = true;
                draft.suggestListOfReviewerCounts = 0;
            }),
        [GET_SUGGESTION_LIST_OF_REVIEWER_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.suggestListOfReviewer = payload.suggestionList;
                draft.isSearchingSuggestListOfReviewer = false;
                draft.suggestListOfReviewerCounts = payload.counts;
            }),
        [GET_SUGGESTION_LIST_OF_REVIEWER_FAILURE]: state =>
            produce(state, draft => {
                draft.isSearchingSuggestListOfReviewer = false;
            }),
        [CANCEL_SUGGESTION_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.suggestListOfBusiness = state.suggestListOfBusiness.map(val => {
                    const _val = { ...val };
                    if (_val._id === payload.suggestionId) {
                        _val.status = "Canceled";
                        _val.isRefunded = payload.isRefunded;
                    }
                    return _val;
                });
            }),
        [ACCEPT_SUGGESTION_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.suggestListOfReviewer = state.suggestListOfReviewer.filter(
                    val => val._id !== payload.suggestion._id
                );
                draft.isAccept = true;
            }),
        [ACCEPT_SUGGESTION_FAILURE]: state =>
            produce(state, draft => {
                draft.isAccept = false;
            }),
        [REJECT_SUGGESTION_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft.suggestListOfReviewer = state.suggestListOfReviewer.map(val => {
                    if (val._id === payload.suggestion._id) {
                        return payload.suggestion;
                    }
                    return val;
                });
            }),
        [SUBMIT_RESULT_SUCCESS]: (state, { prev }) =>
            produce(state, draft => {
                draft.suggestListOfReviewer = state.suggestListOfReviewer.map(item => {
                    if (item._id === prev.suggestionId) {
                        return { ...item, isSubmitted: true, status: "Submitted" };
                    }
                    return item;
                });
                draft.isSubmitReviewSuccess = true;
            })
    },
    initialState
);

export default suggest;
