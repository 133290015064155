import React, { useState } from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";
import { IconFont } from "reviewshare-common-component";
import { isMobile } from "lib/common";
import { useUserType } from "lib/customHooks/useAuth";
import { handleMessengerMainFunction } from "stores/modules/messenger/action";
import { returnReceiverType } from "utils/messenger";
import FormattedMessage from "components/common/FormattedMessage";

import "./MessengerMainSideTemplate.scss";

const MessengerMainSideTemplate = ({ mainFunction, children }) => {
    const dispatch = useDispatch();
    const userType = useUserType();

    const [status, setStatus] = useState(true);

    const onCloseMainSide = () => {
        dispatch(handleMessengerMainFunction("none"));
    };

    const onClickCloseBtn = () => {
        setStatus(false);

        if (isMobile()) {
            setTimeout(() => onCloseMainSide(), 300);
        } else {
            onCloseMainSide();
        }
    };

    const returnMainSideTitle = () => {
        switch (mainFunction) {
            case "selectTo":
                return `받는 ${returnReceiverType(userType)} 선택`;
            case "selectFrom":
                return `브랜드 선택`;
            case "brand":
                return "브랜드 참여 내역";
            case "point":
                return "포인트 선물";
            default:
                return null;
        }
    };

    return (
        <div className={cx("messenger-main-side-content-wrapper", { active: status })}>
            <div className="messenger-main-side-title-box">
                <h3>{returnMainSideTitle()}</h3>
                <button type="button" className="close-btn" onClick={onClickCloseBtn}>
                    <IconFont icon="icon_close">
                        <FormattedMessage id="action.close" />
                    </IconFont>
                </button>
            </div>
            <div className="messenger-main-side-content-box">{children}</div>
        </div>
    );
};

export default MessengerMainSideTemplate;
